<template>
  <info-card :title="$t('common.status')" :error="!isConnected">
    <template #1>
      <info-card-row
        :icon="connectionIcon"
        :icon-color="connectionColor"
        :title="$t('common.state')"
        :sub-title="$t(`connection.state.${connectionState}`)"
      />
    </template>

    <template #2>
      <info-card-row
        :icon="connectionIcon"
        :icon-color="connectionColor"
        :title="$t('common.extra')"
        :sub-title="$t(`connection.state.${connectionExtra}`)"
      />
    </template>

    <template #3 v-if="isService">
      <info-card-row
        icon="mdi-face-agent"
        :title="$t('common.port')"
        :sub-title="connectionPort"
      >
        <a v-if="!restarting" @click="restart"> {{ $t("common.restart") }}</a>
        <base-spinner v-else />
      </info-card-row>
    </template>
  </info-card>
</template>

<script>
import InfoCard from "@components/base/InfoCard";
import InfoCardRow from "@components/base/InfoCardRow";
import { mapGetters } from "vuex";

export default {
  name: "GatewayStatusCard",
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      restarting: false,
    };
  },
  computed: {
    ...mapGetters(["getGatewayStates"]),
    ...mapGetters("auth", ["isService"]),
    stateGateway() {
      return this.getGatewayStates.find(
        (gateway) => gateway.Id === this.gateway.id
      );
    },
    connectionState() {
      return this.stateGateway?.State;
    },
    connectionExtra() {
      return this.stateGateway?.Extra;
    },
    connectionPort() {
      return this.stateGateway?.Port;
    },
    isConnected() {
      return this.connectionState === "Connected";
    },
    connectionIcon() {
      return this.isConnected ? "mdi-cloud-outline" : "mdi-cloud-off-outline";
    },
    connectionColor() {
      return this.isConnected ? "primary" : "error";
    },
  },
  methods: {
    async restart() {
      this.restarting = true;
      await this.$store.dispatch("service/restartGateway", this.gateway.id);
      this.restarting = false;
    },
  },
};
</script>

<style scoped></style>
