<template>
  <div class="fill-height">
    <NavBar>
      <portal-target name="navBarEnd" />
    </NavBar>
    <NavDrawer />
    <v-main class="fill-height">
      <v-container class="ma-0" fluid>
        <slot />
      </v-container>
    </v-main>
    <NavFooter />
  </div>
</template>

<script>
import NavBar from "@components/navbar/NavBar";
import NavDrawer from "@components/navdrawer/NavDrawer";
import NavFooter from "@components/navfooter/NavFooter";

export default {
  name: "LayoutDefaultDesktop",
  components: {
    NavBar,
    NavDrawer,
    NavFooter,
  },
};
</script>

<style scoped></style>
