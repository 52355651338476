import { Data } from "@stienen/Data";
import Vue from "vue";
import {
  _createDeviceDataObj,
  _createDeviceDataObjLc,
  _createHardwareVersionName,
  _get,
} from "./bll";
import * as types from "./mutation-types";

export default {
  [types.ADD_INDEXES](state, { dev, data }) {
    const hardwareVersionName = _createHardwareVersionName(dev);
    const idx = {};
    for (let i = 0; i < data.length; i++) {
      const ref = data[i];
      if (ref.Length !== 0) idx[ref.Index] = ref.Name;
    }
    vueSetDevice(state, { hardwareVersionName, prop: "indexes", values: idx });
  },

  [types.ADD_REFERENCES](state, { dev, data, complete }) {
    const hardwareVersionName = _createHardwareVersionName(dev);
    const refs = {};
    for (let i = 0; i < data.length; i++) {
      const ref = data[i];
      refs[ref.Name] = ref;
    }
    vueSetDevice(state, {
      hardwareVersionName,
      prop: "references",
      values: refs,
    });
    if (complete) {
      state.deviceData[hardwareVersionName].complete = true;
    }
  },

  [types.UPDATE_VALUES](state, { dev, data }) {
    const idx = _get(state, dev, "indexes");
    const refs = _get(state, dev, "references");
    let count;
    for (const item of data) {
      count = 0;
      while (count < item.Data.length) {
        const ref = refs[idx[item.Index + count]];

        if (!ref) {
          ++count;
          continue;
        }

        Vue.set(
          state.devices[dev.id].values,
          ref.Name,
          Data.read(
            {
              Data: item.Data,
              Offset: count,
            },
            ref.Type,
            ref.Mul,
            ref.Div,
            ref.Length
          )
        );
        //state.devices[dev.id].values[ref.Name] = data;
        ++count;
      }
    }
  },
  [types.UPDATE_VALUES_NEW](state, { device, data }) {
    const names = {};
    const values = data.values;

    for (let i = 0; i < values.length; i++) {
      const d = values[i];
      const key = d.varName;
      names[key] = null;
    }
    vueSetDevice(state, {
      key: "devices",
      hardwareVersionName: device.id,
      prop: "values",
      values: names,
    });

    for (const value of data.values) {
      state.devices[device.id].values[value.varName] = value.value;
    }
    state.devices[device.id].valuesComplete = true;
  },
  [types.SET_LC_TEST_RESULTS](state, { deviceId, results = {} }) {
    if (!state.devices[deviceId].hasOwnProperty("testResults")) {
      Vue.set(state.devices[deviceId], "testResults", {});
    }
    Object.entries(results).forEach(([k, v]) =>
      Vue.set(state.devices[deviceId].testResults, k, v)
    );
  },
  [types.SET_LC_DEVICE_DATA](state, { hardwareVersionName, ...data }) {
    if (!state.deviceData.versions.includes(hardwareVersionName)) {
      state.deviceData.versions.push(hardwareVersionName);
    }

    Vue.set(
      state.deviceData,
      hardwareVersionName,
      _createDeviceDataObjLc({ ...data })
    );
  },

  [types.INIT_DEVICE](state, { device, deviceObject }) {
    //this gets called twice sometimes resulting in unwanted behavior
    //such as overriding the tree
    if (!state.devices[device.id])
      Vue.set(state.devices, device.id, deviceObject);

    const name = _createHardwareVersionName(device);
    if (!state.deviceData[name]) {
      state.deviceData.versions.push(name);
      Vue.set(state.deviceData, name, _createDeviceDataObj({}));
    }
  },

  [types.SET_DEVICE_DATA](state, data) {
    const name = _createHardwareVersionName(data.device);
    state.deviceData.versions.push(name);
    Vue.set(state.deviceData, name, _createDeviceDataObj(data));
  },

  [types.SET_TREE](state, { device, tree }) {
    state.devices[device.id].tree = tree;
  },

  [types.SET_LAST_SEEN](state, { dev, timestamp }) {
    state.devices[dev.id].lastSeen = timestamp;
  },

  [types.TREE_REGISTERED_FOR_DEVICE](state, deviceId) {
    state.devices[deviceId].treeRegistered = true;
  },

  [types.MODIFY_REFRESH_VARS]: (state, { dev, varNames, add = false }) => {
    if (
      dev &&
      state.devices.hasOwnProperty(dev.id) &&
      varNames &&
      Array.isArray(varNames)
    ) {
      const refreshVars = state.devices[dev.id].refreshVars;
      varNames.forEach((name) => {
        if (!refreshVars.hasOwnProperty(name)) {
          if (add) {
            Vue.set(refreshVars, name, 1);
          }
        } else if (add) {
          ++refreshVars[name];
        } else {
          if (refreshVars[name] === 1) {
            Vue.delete(refreshVars, name);
          } else {
            --refreshVars[name];
          }
        }
      });
    }
  },
  [types.SET_HISTORY_VARS]: (state, { device, vars }) => {
    state.devices[device.id].historyVars = vars;
  },
  [types.ADD_HISTORY_VAR]: (state, { deviceId, variable }) => {
    if (
      !state.devices[deviceId].historyVars.some(
        (v) => v.varName === variable.varName
      )
    ) {
      state.devices[deviceId].historyVars.push(variable);
    }
  },
  [types.CLEAR_HISTORY_VARS]: (state, deviceId) => {
    state.devices[deviceId].historyVars = [];
  },

  [types.SET_VISIBLE_NODE_KEYS]: (state, { deviceId, nodeKeys }) =>
    (state.devices[deviceId].visibleNodeKeys = nodeKeys),
};
const vueSetDevice = (
  state,
  { key = "deviceData", hardwareVersionName, prop, values }
) => {
  Vue.set(
    state[key][hardwareVersionName],
    prop,
    Object.assign({}, state[key][hardwareVersionName][prop], values)
  );
};
