const getCurrentDisposition = (state) => state.selectedDisposition;
const selectedAt = (state) => state.selectedAt;

const getLast24HourEnabled = (state) => state.last24HourEnabled;

export default {
  getCurrentDisposition,
  selectedAt,
  getLast24HourEnabled,
};
