<template>
  <static-node
    :initial-open="initialOpen"
    :text="node.name"
    :underline="isCurrentSelectedNode"
    @clicked="onClick"
  >
    <tree-node
      v-for="child in node.children"
      :key="child.Key"
      ref="childrenRef"
      :device="device"
      :node="child"
    />
  </static-node>
</template>

<script>
import StaticNode from "@components/navdrawer/StaticNode";
import { isDef } from "@utils/helpers";
import { mapGetters } from "vuex";

export default {
  name: "TreeNode",
  components: { StaticNode },
  props: {
    node: {
      type: Object,
      required: false,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routerIsOnRemote: this.$router.currentRoute.name === "remote",
      initialOpen: false,
    };
  },
  computed: {
    ...mapGetters("rtRemote", ["getCurrentDisposition"]),
    ...mapGetters("deviceCache", ["getDispositionTitle"]),
    hasScreen() {
      return isDef(this.node.Type);
    },
    isCurrentSelectedNode() {
      return (
        this.getCurrentDisposition?.Key === this.node.Key &&
        this.routerIsOnRemote
      );
    },
  },
  methods: {
    async onClick() {
      if (this.hasScreen) {
        await this.$store.dispatch("rtRemote/onScreenSelect", this.node);
        await this.$helpers.pushRoute({ name: "remote" });
      } else {
        // first tree-node child -> static-node
        const ref = this.$refs.childrenRef[0].$children[0];
        ref.clickHandler();
      }
    },
  },
  watch: {
    "$route.name": {
      handler: function (newVal) {
        this.routerIsOnRemote = newVal === "remote";
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.getCurrentDisposition) {
      return;
    }
    const currentL = this.getCurrentDisposition.L;
    if (currentL >= this.node.L && currentL < this.node.R) {
      this.initialOpen = true;
    }
  },
};
</script>
