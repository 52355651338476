import Vue from "vue";
import router from "@router/router";

import lodash from "lodash";

Vue.$_ = lodash;
Object.defineProperties(Vue.prototype, {
  $_: {
    get() {
      return lodash;
    },
  },
});

// install snapsvg using imports-loader
// for more info read: https://jamesscheller.com/adding-snap-svg-to-vue-js-and-nuxt-js-projects/
const snap = require("imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js");
Vue.$snap = snap;
Object.defineProperties(Vue.prototype, {
  $snap: {
    get() {
      return snap;
    },
  },
});

// https://github.com/LinusBorg/portal-vue
import PortalVue from "portal-vue";

Vue.use(PortalVue);

// https://github.com/posva/vue-promised
import { Promised } from "vue-promised";

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Promised", Promised);

//https://www.highcharts.com/
//https://github.com/highcharts/highcharts-vue
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import loadBullet from "highcharts/modules/bullet";
import solidGauge from "highcharts/modules/solid-gauge";
import noDataModule from "highcharts/modules/no-data-to-display";

highchartsMore(Highcharts);
loadBullet(Highcharts);
solidGauge(Highcharts);
noDataModule(Highcharts);
Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  accessibility: { enabled: false },
  time: {
    useUTC: false,
  },
});

//for fc2 widgets
import Raphael from "raphael";

window.Raphael = Raphael;

import * as Sentry from "@sentry/vue";
import { title, version } from "@/app.config";

const sentryEnv = process.env.VUE_APP_SENTRY_ENV;

if (sentryEnv !== "none") {
  Sentry.init({
    Vue,
    release: `${title}@${version}`,
    environment: sentryEnv,
    dsn: "https://6225a704ae2e4ca48a34c6e0e405d0dd@o4504162654683136.ingest.sentry.io/4504371823640576",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/.*/],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
