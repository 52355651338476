<template>
  <v-btn :color="color" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      required: false,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped></style>
