import { apiClient } from "@api/base-client";

const endPoint = process.env.VUE_APP_ROOT_REF_GATEWAY;

const refClient = apiClient(
  `${endPoint}/refApi/v1`,
  (response) => response.data
);

export async function fetchFull(hardware, version) {
  return await refClient.get(`/reference/${hardware}/${version}`);
}

export async function GetReferenceNamesByOffset(
  hardware,
  version,
  name,
  offsets
) {
  return await refClient.get(
    `/reference/${hardware}/${version}/indexedByOffset`,
    {
      params: { name: name, offsets: offsets.join(",") },
    }
  );
}

export async function GetNamedTextsByLanguage(hardware, version, languages) {
  return await refClient.get(`/translation/${hardware}/${version}/named`, {
    params: { language: languages.join(",") },
  });
}

export async function GetTypedTextsByLanguage(hardware, version, languages) {
  return await refClient.get(`/translation/${hardware}/${version}/typed`, {
    params: { language: languages.join(",") },
  });
}

export async function GetControlTextsByLanguage(hardware, version, languages) {
  return await refClient.get(`/translation/${hardware}/${version}/control`, {
    params: { language: languages.join(",") },
  });
}

export async function GetAlarmTextsByLanguage(hardware, version, languages) {
  return await refClient.get(`/translation/${hardware}/${version}/alarm`, {
    params: { language: languages.join(",") },
  });
}
