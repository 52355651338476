import { Data } from "@stienen/Data";

export const checkTag = (target, tag) =>
  target && target.nodeName === "SELECT" ? "option" : tag;

export function _translate(str, dict) {
  let list = str.split(","),
    length = list.length,
    value;

  while (--length >= 0) {
    value = dict[list[length]];
    if (value) list[length] = value;
  }

  return _compose(list[0], list.slice(1));
}

export const _compose = (str, repl) => {
  let result = "",
    rindex = 0,
    pindex = 0,
    sindex;

  if (typeof str === "string") {
    while ((sindex = str.indexOf("%", pindex)) >= 0) {
      result += str.slice(pindex, sindex);
      switch (str.charAt(++sindex)) {
        case "r": //nobreak;
        case "d": //nobreak;
        case "n":
          if (repl && repl.length > rindex && +repl[rindex])
            result += repl[rindex++];
          break;
        case "s":
          if (repl && repl.length > rindex)
            result += String.lcfirst(_compose(repl[rindex++]));
          break;
        case "S":
          if (repl && repl.length > rindex)
            result += String.ucfirst(_compose(repl[rindex++]));
          break;
        case "%":
          result += "%";
          break;
      }
      pindex = ++sindex;
    }
    result += str.slice(pindex);
  }
  return result;
};

export const addExtraCss = (list, extra, values) => {
  if (!extra) return;

  let css = extra.split(","),
    count = css.length,
    item,
    name;

  while (--count >= 0) {
    name = css[count];
    item = values[css[count]];
    if (!item) item = values[name.substr(1)]; //try negative
    if (item) list.push(name + "_" + (item.Dev ? item.Dev.id : ""));
    else list.push(name);
  }
};

export const setStyle = (loc, attr) => {
  let style = {};
  if (loc.Properties) {
    for (let prop of loc.Properties) {
      if (prop.Style) {
        style[prop.Name] = prop.Value;
      } else {
        attr[prop.Name] = prop.Value;
      }
    }
  }
  return style;
};

export const setInputAttrs = (target, attr, loc, val, ref, classList) => {
  attr.form = val.Dev.id;
  classList.push("field");
  if (ref) {
    attr.name = ref.Name;
    attr.readOnly =
      ref.ReadOnly || ref.Min === ref.Max || classList.indexOf("fake") !== -1;
    attr.disabled = ref.Disabled;
    if (!val || val.Step === null) val.Step = ref.Step;
  } else {
    attr.name = val.Name;
    attr.title = val.Name;
    attr.readOnly = true;
  }
  if (loc.R - loc.L > 1 && ref) {
    attr.min = ref.Min;
    attr.max = ref.Max;
  } else {
    switch (val.Type) {
      case Data.T_BOOL:
        attr.type = "checkbox";
        break;
      case Data.T_STRING:
        attr.type = "text";
        if (ref) {
          attr.maxLength = ref.Max;
        }
        break;
      case Data.T_DATETIME:
        attr.type = "datetime";
        if (val.Step) attr.step = val.Step;
        if (ref) {
          attr.min = ref.Min;
          attr.max = ref.Max;
        }
        break;
      case Data.T_TIMESPAN:
        attr.type = "time";
        classList.push("time_input");
        if (val.Step) attr.step = val.Step;
        if (ref) {
          attr.min = ref.Min;
          attr.max = ref.Max;
        }
        break;
      case Data.T_INPUT:
        attr.isInput = true;
        attr.name = val.RefName;
        break;
      case Data.T_OUTPUT:
        attr.isInput = false;
        attr.name = val.RefName;
        break;
      default: // INT
        attr.type = "text";
        if (val.Step) attr.step = val.Step;
        if (ref) {
          if (ref.TranslationNameId != null) {
            if (attr.readOnly) {
              classList.push("readonly");
            }
          } else {
            attr.min = ref.Min;
            attr.max = ref.Max;
          }
        }
        break;
    }
  }
};

export function makeProps(props) {
  let result = { classList: [], style: {} },
    length = props ? props.length : 0,
    style = result.style,
    p;

  while (--length >= 0) {
    p = props[length];
    if (p.Style) {
      style[p.Name] = p.Value;
    } else {
      switch (p.Name) {
        case "className":
          result.classList = p.Value.split(" ");
          break;
        default:
          result[p.Name] = p.Value;
          break;
      }
    }
  }
  return result;
}
