<template>
  <div class="fill-height">
    <v-stepper v-model="currentStep" :style="styleObject" class="fill-height">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1">
          <slot name="h1">
            {{ $t("setup.headers.welcome") }}
          </slot>
        </v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="currentStep > 2" step="2">
          <slot name="h3">
            {{ $t("setup.headers.addGateway") }}
          </slot>
        </v-stepper-step>

        <v-divider v-if="!hideInitial" />

        <v-stepper-step
          v-if="!hideInitial"
          :complete="currentStep > 3"
          step="3"
        >
          {{ $t("setup.headers.connectYourDevices") }}
        </v-stepper-step>

        <v-divider v-if="!hideInitial" />

        <v-stepper-step v-if="!hideInitial" step="4">
          {{ $t("setup.headers.verifyYourDevices") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="ma-0 pa-0" step="1" style="width: 100vw">
          <slot name="1" />
        </v-stepper-content>

        <v-stepper-content class="ma-0 pa-0" step="2" style="width: 100vw">
          <slot name="2" />
        </v-stepper-content>

        <v-stepper-content class="ma-0 pa-0" step="3" style="width: 100vw">
          <slot name="3" />
        </v-stepper-content>

        <v-stepper-content class="ma-0 pa-0" step="4" style="width: 100vw">
          <slot name="4" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-footer app fixed height="48">
      <portal-target name="btn1" />
      <v-spacer />
      <portal-target name="btn2" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "SetupLayoutPortrait",
  props: {
    step: {
      type: Number,
      required: true,
    },
    hideInitial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentStep: {
      get() {
        return this.step;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    styleObject() {
      return {
        "border-top": `solid ${this.$vuetify.theme.currentTheme.primaryDark} 6px`,
      };
    },
  },
};
</script>

<style scoped></style>
