import * as types from "./mutation-types";

export default {
  [types.CLEAR_CUSTOMERS]: (state) => {
    state.customers = [];
  },
  [types.SET_CUSTOMERS]: (state, customers) => {
    state.customers = customers;
  },
};
