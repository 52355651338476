<script>
import { mapGetters } from "vuex";
import { _compose } from "@modules/rtcpu/render/screen-bll";

export default {
  name: "LcUnit",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: false,
      default: "12px",
    },
  },
  computed: {
    ...mapGetters("deviceCache", ["getTranslation"]),
    composeText() {
      const list = this.name
        .split(",")
        .map((label) => this.getTranslation({ label }) || "");
      return _compose(list[0], list.splice(1)) || this.name;
    },
  },
  render(h) {
    return h(
      "span",
      {
        style: {
          color: this.color,
          fontSize: this.fontSize,
          "vertical-align": "top",
        },
      },
      this.composeText
    );
  },
};
</script>
