<script>
import LcScreenDesktop from "@modules/lccpu/layout/LcScreenDesktop";
import LcScreenPortrait from "@modules/lccpu/layout/LcScreenPortrait";
import LcScreenLandscape from "@modules/lccpu/layout/LcScreenLandscape";
import { resolveApiForComponent } from "@modules/lccpu/render/get-component-api";
import {
  LcComponents,
  resolveEnumValue,
} from "@modules/lccpu/model/enum-components";
import LcEditCarousel from "@modules/lccpu/components/LcEditCarousel";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";
import { VDialog } from "vuetify/lib";
import { Data } from "@stienen/Data";
import { isDef } from "@utils/helpers";
import { mapGetters } from "vuex";

export default {
  name: "LcScreenRender",
  props: {
    screenDefinition: {
      type: Object,
      required: true,
    },
    mappedValues: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowingEditCarousel: false,
      numberOfNavButtons: 0,
    };
  },
  computed: {
    ...mapGetters("deviceCache", ["getRef", "lcGetValue"]),
    ...mapGetters("lcRemote", ["getAllTestPassesForComponent"]),
    ...mapGetters("edit", ["editModeActive"]),
    ...mapGetters("auth", ["getIsAuthorizedLc"]),
    ...mapGetters("layout", ["isDesktop", "isLandscape", "isPortrait"]),
    isHomeScreen() {
      return (
        this.screenDefinition.id === -1 && isDef(this.screenDefinition.link_id)
      );
    },
    layout() {
      if (this.isLandscape) {
        return LcScreenLandscape;
      }
      if (this.isPortrait) {
        return LcScreenPortrait;
      }
      return LcScreenDesktop;
    },
  },
  methods: {
    createContainerVNodes() {
      const { containers } = this.screenDefinition;
      const vmRef = this;

      return containers
        .filter((container) =>
          this.getAllTestPassesForComponent(this.device, container)
        )
        .map((container) => {
          const childVNodes = this.createContainerChildVNodes(container);

          return this.$createElement(
            "div",
            {
              slot: "content",
              style: {
                display: "grid",
                height: `${container.height}px`,
                width: `${container.width}px`,
                gridTemplateColumns: `repeat(${container.width}, 1px)`,
                gridTemplateRows: `repeat(${container.height}, 1px)`,
              },
              on: {
                click() {
                  vmRef.isShowingEditCarousel = true;
                },
              },
            },
            childVNodes
          );
        });
    },
    createContainerChildVNodes(container) {
      const childItems = this.screenDefinition.items.filter(
        (item) =>
          item.hasOwnProperty("containerId") &&
          item.containerId === container.id &&
          this.getAllTestPassesForComponent(this.device, item)
      );
      return this.createItemVNodes(childItems);
    },
    createNavigationVNodes() {
      const navigationComponents = this.resolveItemsOfType(
        LcComponents.NAVIGATE
      )
        .filter((component) =>
          this.getAllTestPassesForComponent(this.device, component)
        )
        .filter(
          (component) =>
            component.config.id !== 0 ||
            (component.config.isBack && this.$route.query.backButtonIcon)
        )
        .filter((component) => this.getIsAuthorizedLc(this.device, component));

      return this.createItemVNodes(navigationComponents);
    },
    createItemVNodes(screenDefinitionItems) {
      return screenDefinitionItems.map((screenDefinitionItem) => {
        const data = {
          props: {},
          class: [],
          slot: "default",
        };
        const { vm, category } = resolveApiForComponent(screenDefinitionItem);

        if (
          vm.name === "VueComponent" &&
          vm.options.name === "NOT_IMPLEMENTED"
        ) {
          data.slot = "default";
          data.props.componentName = screenDefinitionItem.component;
          return this.$createElement(vm, data);
        }

        data.slot = category;
        data.props = this.resolvePropsForComponent(screenDefinitionItem, vm);

        if (screenDefinitionItem.hasOwnProperty("location")) {
          data.style = this.resolveLocation(screenDefinitionItem);
        }

        return this.$createElement(vm, data);
      });
    },
    createLayoutVNode(navVNodes, VNodes) {
      const vmRef = this;
      return this.$createElement(
        this.layout,
        {
          props: {
            numberOfNavButtons: navVNodes.length,
            nextScreenId: this.screenDefinition.link_id,
            isHomeScreen: this.isHomeScreen,
          },
          on: {
            clicked() {
              if (vmRef.isHomeScreen) {
                vmRef.$helpers.pushRoute({
                  name: "lc-remote",
                  params: {
                    screenId: vmRef.screenDefinition.link_id,
                  },
                });
              }
            },
          },
          scopedSlots: {
            navigation: ({ index }) => navVNodes[index],
          },
        },
        VNodes
      );
    },
    createScreenLayoutVNode(layoutVNode) {
      const { containers } = this.screenDefinition;

      const editableVariableComponents = this.resolveItemsOfType(
        LcComponents.VARIABLE
      )
        .filter((variableComponent) => !variableComponent.config.isReadOnly)
        .filter((variableComponent) =>
          this.getAllTestPassesForComponent(this.device, variableComponent)
        )
        .filter((variableComponent) =>
          this.getAllTestPassesForComponent(
            this.device,
            containers.find(
              (container) => container.id === variableComponent.containerId
            )
          )
        )
        .map((variableComponent) =>
          this.resolvePropsForComponent(
            variableComponent,
            resolveApiForComponent(variableComponent).vm
          )
        );

      const layoutChildrenVNodes = [layoutVNode];

      if (editableVariableComponents.length > 0) {
        const editDialogVNode = this.createEditDialogVNode(
          editableVariableComponents,
          this
        );
        layoutChildrenVNodes.push(editDialogVNode);
      }

      return this.$createElement("div", {}, layoutChildrenVNodes);
    },
    createEditDialogVNode(editableVariableComponents, vmRef) {
      const editCarouselVNode = this.$createElement(LcEditCarousel, {
        props: {
          items: editableVariableComponents,
          device: this.device,
        },
        on: {
          onCloseButtonClick() {
            vmRef.isShowingEditCarousel = false;
          },
        },
      });

      return this.$createElement(
        VDialog,
        {
          props: {
            value: this.isShowingEditCarousel,
            width: this.isDesktop ? "720px" : "360px",
          },
          attrs: {
            persistent: vmRef.editModeActive,
          },
          on: {
            click(event) {
              event.stopPropagation();
            },
            input(newValue) {
              vmRef.isShowingEditCarousel = newValue;
            },
          },
          slot: "dialog",
        },
        [editCarouselVNode]
      );
    },
    resolvePropsForComponent(screenDefinitionItem, vm) {
      const resolvedProps = this.resolveConfig(
        screenDefinitionItem.config,
        vm.props
      );

      if (vm.props.hasOwnProperty("width")) {
        resolvedProps.width =
          screenDefinitionItem.location.endX -
          screenDefinitionItem.location.startX;
      }
      if (vm.props.hasOwnProperty("height")) {
        resolvedProps.height =
          screenDefinitionItem.location.endY -
          screenDefinitionItem.location.startY;
      }

      // vm === LcVariable
      if (vm.props.hasOwnProperty("device")) {
        resolvedProps.device = this.device;
      }
      if (vm.props.hasOwnProperty("variableReference")) {
        const varName = screenDefinitionItem.config.value.slice(1);
        resolvedProps.variableReference = this.getRef(this.device, varName);
        // if (screenDefinitionItem.config.isReadOnly) {
        //   this.$nextTick(() => this.$store.dispatch('deviceCache/registerHistoryVar', varName));
        // }
      }

      return resolvedProps;
    },
    resolveConfig(config, vmProps) {
      // todo: guard against bad input, variables or tests null, undefined or variableReference missing
      // if a component has a broken interface, skip the render and log error
      const resolvedConfig = {};
      for (const [key, value] of Object.entries(config)) {
        const vmProp = vmProps[key];

        if (Array.isArray(value)) {
          resolvedConfig[key] = value.map((v) =>
            this.resolveFormattedValue(v, vmProp)
          );
        } else {
          resolvedConfig[key] = this.resolveFormattedValue(value, vmProp);
        }
      }
      return resolvedConfig;
    },
    resolveFormattedValue(value, vmProp) {
      const resolvedValue = this.lcGetValue(this.device, value);

      if (vmProp.type.name === LcWidgetValue.name) {
        //a static value. sometimes this is the case (klc 100 meter)
        if (typeof value !== "string") {
          return new LcWidgetValue(resolvedValue, resolvedValue.toString());
        }

        const ref = this.getRef(this.device, value.slice(1));
        const formatted = Data.compose(resolvedValue, ref.Type, ref.Step);
        return new LcWidgetValue(resolvedValue, formatted);
      }

      return resolvedValue;
    },
    // Helper methods
    resolveItemsOfType(type) {
      return this.screenDefinition.items.filter(
        (item) => resolveEnumValue(item.component) === type
      );
    },
    resolveLocation(item) {
      return {
        // moved to a pixel by pixel grid so location represents the direct grid cell
        position: "relative",
        gridColumnStart: item.location.startX || 1,
        gridColumnEnd: item.location.endX,
        gridRowStart: item.location.startY || 1,
        gridRowEnd: item.location.endY,
      };
    },
  },
  render() {
    const containerVNodes = this.createContainerVNodes();
    const navVNodes = this.createNavigationVNodes();
    const childVNodes = [...navVNodes, ...containerVNodes];

    const layoutVNode = this.createLayoutVNode(navVNodes, childVNodes);

    return this.createScreenLayoutVNode(layoutVNode);
  },
};
</script>

<style scoped></style>
