<template>
  <info-card :title="$t('common.gateway')">
    <template #1>
      <info-card-row
        icon="mdi-router-wireless-settings"
        :title="$t('common.gateway')"
        :sub-title="gatewayName"
      />
    </template>

    <template #2 v-if="licenceKey">
      <info-card-row
        icon="mdi-key"
        :title="$t('setup.step2.licenceKey')"
        :sub-title="licenceKey"
      />
    </template>

    <template #3 v-if="web485Key">
      <info-card-row
        icon="mdi-key"
        :title="$t('common.web485Key')"
        :sub-title="web485Key"
      />
    </template>
  </info-card>
</template>

<script>
import InfoCard from "@components/base/InfoCard";
import InfoCardRow from "@components/base/InfoCardRow";

export default {
  name: "GatewayInfoCard",
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gatewayName() {
      return this.gateway?.name;
    },
    licenceKey() {
      return this.gateway?.usedLicenceKey;
    },
    web485Key() {
      return this.gateway?.web485Key;
    },
  },
};
</script>

<style scoped></style>
