<template>
  <v-card-actions>
    <slot name="btn1" />
    <v-spacer />
    <slot name="btn2" />
  </v-card-actions>
</template>

<script>
export default {
  name: "DesktopButtons",
};
</script>

<style scoped></style>
