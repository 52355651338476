<template>
  <base-card error :title="$t('setup.step2.serverNotEligible')">
    <v-card-text>
      <p>
        {{ $t("setup.step2.serverOnVersion", { displayUrl, currentVersion }) }}
      </p>
      <p>
        {{ $t("setup.step2.pleaseUpgrade", { requiredVersion }) }}
      </p>
    </v-card-text>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button outlined text @click="$emit('back')">
          {{ $t("common.back") }}
        </base-button>
      </template>
    </button-holder>
  </base-card>
</template>

<script>
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "MigrationVersionError",
  components: { ButtonHolder },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    migration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayUrl() {
      //strip off https://
      return this.url.substring(8);
    },
    currentVersion() {
      return this.migration.Fc2Version;
    },
    requiredVersion() {
      return this.migration.RequiredFc2Version;
    },
  },
};
</script>

<style scoped></style>
