import {Util as util} from '@utils/Util';

function stripPX(value, dflt) {
    if (!value) return dflt;
    if (isNaN(value)) value = value.replace('px', '');
    return +value;
}

function createCanvas(target, attr, dfltWidth, dfltHeight) {
    let style = attr.style,
        width = stripPX(style.width, dfltWidth),
        height = stripPX(style.height, dfltHeight),
        elem = util.MakeElement('div', attr),
        canvas = new Raphael(elem, width, height);
    // target.appendChild(elem);
    target.replaceWith(elem);
    return canvas;
}

function setAttr(result) {
    // state {Target, Attr, Offset, Scale, Extra}
    const state = this;
    let dict = {},
        value = result,
        attr = state.Attr,
        scale = state.Scale,
        offset = state.Offset,
        fix = state.Fix,
        options = state.Options,
        extra = state.Extra, count, args;
    if (options) value = options[value];
    if (offset) value += offset;
    if (scale) value *= scale;
    if (fix) value += fix;
    if (extra) {
        count = extra.length;
        args = [];
        while (--count >= 0) {
            if (extra[count] === null) {
                args[count] = value;
            } else {
                args[count] = extra[count];
            }
        }
        value = args.join(',');
    }
    dict[attr] = value;
    state.Target.animate(dict, 0, '>');
}

/* x: X coordinate
 * y: Y coordinate
 * w: Width
 * h: Height
 * cw: Column Width
 * rh: Row Height
 */
export const drawGrid = function (canvas, x, y, w, h, cw, rh, color) {
    let cols = Math.floor(w / cw),
        rows = Math.floor(h / rh),
        xoff = x + (w - (cols * cw)) / 2,
        yoff = y + (h - (rows * rh)) / 2,
        path = [];
    ++cols;
    while (--cols >= 0) {
        path.push('M', xoff, y, 'L', xoff, y + h);
        xoff += cw;
    }
    ++rows;
    while (--rows >= 0) {
        path.push('M', x, yoff, 'L', x + w, yoff);
        yoff += rh;
    }
    return canvas.path(path.join(',')).attr({stroke: color || '#000'});
}


export const Core = {createCanvas, setAttr, drawGrid};
