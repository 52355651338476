export const Pc485ConnectionLogEnum = [
  "NotDefined",
  "GatewayApplicationStarted",
  "PC485Restarted",
  "PC485Connected",
  "PC485Disconnected",
  "PC485Responding",
  "PC485NotResponding",
];

export const isConnected = (status) =>
  ["PC485Connected", "PC485Responding", "PC485Restarted"].indexOf(status) !==
  -1;
