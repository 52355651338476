import * as types from "./mutation-types";
import {
  GetCurrentAlarmDevices,
  GetGatewaysState,
  GetScreenGroup,
} from "@api/config-service";
import { notifyUserTranslation } from "@error/helpers";
import { _isSameVersion } from "@state/modules/rt-remote/bll";
import { isDef, isEmptyObjectOrArray, isUndef } from "@utils/helpers";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { Messenger } from "@api/messenger";
import { Pc485ConnectionLogEnum } from "./bll";
import { adminService } from "@api/admin-service";

async function init({ commit, dispatch }, { deviceId } = {}) {
  try {
    const gateways = await adminService.fetchGateways();
    commit(types.SET_GATEWAYS, gateways);

    const customScreenIds = await adminService.fetchCustomScreenIds();
    const customScreens = await _fetchCustomScreens(customScreenIds);
    commit(types.SET_SCREENGROUPS, customScreens);

    let gateway;
    let device;

    if (deviceId) {
      gateway = gateways.find((gateways) =>
        gateways.devices.find((device) => device.id === deviceId)
      );
      if (gateway) {
        device = gateway.devices.find((device) => device.id === deviceId);
      }
    }

    gateway ??= gateways[0];

    await dispatch("fetchGatewayStates");

    await dispatch("deviceCache/init", null, { root: true });
    await dispatch("onGatewayChange", { gateway, device });
    const alarmAmount = await dispatch("fetchAlarms", false);
    await dispatch("subscription/initSupportedDevices", null, { root: true });

    Messenger.kick();

    return alarmAmount > 0;
  } catch (err) {
    console.error(err);
    dispatch("setAlarmsLoaded", { root: true });
    await notifyUserTranslation("error.init");
  }
}

function setDeviceAvailable({ commit }, device) {
  commit(types.SET_DEVICE_AVAILABLE, device);
}

async function fetchAlarms(
  { state, commit, dispatch, rootGetters },
  shouldNotifyUser
) {
  try {
    const gatewayIds = state.gateways.map((g) => g.id);
    const alarms = await GetCurrentAlarmDevices(
      rootGetters["settings/getLanguage"],
      true,
      gatewayIds
    );
    if (shouldNotifyUser && alarms.length > state.currentAlarmDevices.length) {
      await notifyUserTranslation("notification.newAlarmDevice", "error");
    }
    commit(types.SET_CURRENT_ALARM_DEVICES, alarms);
    return alarms.length;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch("setAlarmsLoaded", { root: true });
  }
}

async function fetchGatewayStates({ commit, state }) {
  const gatewayIds = state.gateways.map((g) => g.id);
  const gatewayStates = await GetGatewaysState(gatewayIds);
  commit(types.SET_GATEWAY_STATES, gatewayStates);
}

async function onGatewayChange(
  { commit, dispatch, rootGetters },
  { gateway, device, dispositionNames }
) {
  try {
    const isId = (input) => typeof input === "string";

    if (isId(gateway)) {
      gateway = rootGetters["getGatewayById"](gateway);
    }

    const licencedDevices = gateway.devices.filter(
      (device) => device.isLicenced
    );
    if (isId(device)) {
      device = licencedDevices.find((dev) => dev.id === device);
    }
    if (isUndef(device) && licencedDevices.length > 0) {
      device = licencedDevices[0];
    }

    commit(types.SET_SELECTED_GATEWAY, gateway);
    await dispatch("onSelectDevice", { device, dispositionNames });
  } catch (err) {
    console.error(err);
    await notifyUserTranslation("error.gatewayChangeFailed", "error");
  }
}

async function onSelectDevice(
  { getters, dispatch, commit, rootGetters },
  { device = {}, dispositionNames = [] }
) {
  try {
    dispatch("setLoading", { value: true });
    const currentDevice = getters.getSelectedDevice;

    if (currentDevice.id === device.id && !dispositionNames.length) {
      return;
    }
    await dispatch("deviceCache/initFull", device, {
      root: true,
    });

    const tree = await dispatch("deviceCache/initializeTree", device, {
      root: true,
    });

    let disposition;
    if (tree.ok) {
      if (!isEmptyObjectOrArray(dispositionNames)) {
        //comes from alarm overview
        disposition = tree.data.find((node) =>
          dispositionNames.includes(node.Name)
        );
      } else if (
        !_isSameVersion(device, currentDevice) ||
        // switch from customer screen between same hardware version
        rootGetters["rtRemote/getCurrentDisposition"]?.isCustomScreen
      ) {
        disposition = tree.data[0];
      }
    }

    // Type === null means no screen
    // so find child that does have one
    if (
      disposition &&
      disposition.Type === null &&
      disposition.children.length > 0
    ) {
      const childWithScreen = disposition.children.find(
        (child) => child.Type != null
      );
      if (childWithScreen) {
        disposition = childWithScreen;
      }
    }

    commit(types.SET_SELECTED_DEVICE, device);
    if (isDef(disposition)) {
      commit("rtRemote/SET_SELECTED_DISPOSITION", disposition);
    }
  } catch (err) {
    console.error(err);
    await notifyUserTranslation("error.deviceSelectFailed", "error");
  } finally {
    dispatch("setLoading", { value: false });
  }
}

function toggleSideNav({ commit }, payload) {
  commit(types.SET_SIDE_NAV, payload);
}

async function updateGatewayStatus(
  { state, commit },
  { gatewayIndex, status, stamp }
) {
  if (
    gatewayIndex >= 0 &&
    gatewayIndex < state.gateways.length &&
    status &&
    status > 0 &&
    status <= Pc485ConnectionLogEnum.length
  ) {
    commit(types.SET_GATEWAY_STATUS, {
      status: Pc485ConnectionLogEnum[status],
      gatewayIndex,
      stamp,
    });
  }
}

async function updateStreamerStatus({ state, commit, dispatch }, isConnected) {
  commit(types.SET_STREAM_STATUS, isConnected);
  if (!isConnected) {
    state.gateways.forEach((gateway, i) => {
      dispatch("updateGatewayStatus", {
        gatewayIndex: i,
        Stamp: DateTimeConverter.formatDate(Date.now()),
        Data: 4,
      });
    });
  }
}

async function updateDeviceLastSeen({ commit }, { device, msg }) {
  const stamp = DateTimeConverter.parseDate(msg.Stamp).getTime();
  const deviceId = device.id;
  commit(types.SET_DEVICE_LAST_SEEN, { deviceId, stamp });
}

async function onTimeoutMessageReceived({ commit }, { device, msg }) {
  await notifyUserTranslation("error.noConnectionToDevice", undefined, {
    deviceName: device.displayName,
  });
  const stamp = DateTimeConverter.parseDate(msg.Stamp).getTime();
  const deviceId = device.id;
  commit(types.SET_DEVICE_TIMEOUT_STATUS, { deviceId, stamp });
}

async function setLoading({ commit }, { value, text = "" }) {
  commit(types.SET_LOADING, value);
  commit(types.SET_LOADING_TEXT, text);
}

async function setAlarmsLoaded({ commit }) {
  commit(types.ALARMS_LOADED, true);
}

async function setGatewayAlarmFilter({ commit }, gatewayId) {
  commit(types.SET_GATEWAY_ALARM_FILTER, gatewayId);
}

const _fetchCustomScreens = async (screenGroups) => {
  const newGroups = [];
  for (const group of screenGroups) {
    const newGroup = await GetScreenGroup(group.id);
    newGroups.push({
      ...newGroup,
      owner: group.owner,
      displayName: newGroup.Name,
      Screens: newGroup.Screens.map((screen) =>
        Object.assign({}, screen, {
          Type: screen.Id,
          Prefix: null,
          isCustomScreen: true,
        })
      ),
    });
  }
  return newGroups;
};

export default {
  init,
  onSelectDevice,
  onGatewayChange,
  setDeviceAvailable,
  fetchAlarms,
  fetchGatewayStates,
  setAlarmsLoaded,
  toggleSideNav,
  updateGatewayStatus,
  onTimeoutMessageReceived,
  updateDeviceLastSeen,
  updateStreamerStatus,
  setLoading,
  setGatewayAlarmFilter,
};
