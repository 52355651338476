<template>
  <div>
    <input
      class="text-center lc-text"
      style="width: 100%; font-size: 20px"
      :type="type"
      :value="value"
      v-on="listeners"
    />
    <div class="text-center">
      <LcUnit v-if="unit" :name="unit" font-size="20px" color="black" />
    </div>
  </div>
</template>

<script>
import LcUnit from "@modules/lccpu/components/LcUnit";
import { mapGetters } from "vuex";

export default {
  components: {
    LcUnit,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Date],
      default: "",
    },
    unit: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters("edit", ["editModeActive"]),
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit("input", e.target.value, this.value, e),
      };
    },
  },
};
</script>

<style scoped>
.lc-text {
  font-family: lc-font, serif;
  white-space: nowrap;
}
</style>
