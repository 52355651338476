<template>
  <div class="connection-indicator-container">
    <v-tooltip bottom open-delay="350">
      <template #activator="{ on }">
        <v-icon
          v-on="on"
          large
          color="red darken-2"
          size="30"
          v-show="!connected"
        >
          mdi-alert
        </v-icon>
      </template>
      <span class="Caption">
        <slot name="error">
          {{ $t("common.status") }} :{{ $t("common.disconnected") }}
        </slot>
        <br />

        {{ $t("connection.lastActive", { time: parseDate }) }}
      </span>
    </v-tooltip>

    <!--      <v-tooltip bottom open-delay="350">-->
    <!--        <template #activator="{ on }">-->
    <!--          <v-icon-->
    <!--              v-on="on"-->
    <!--              large-->
    <!--              color="primary"-->
    <!--              size="30"-->
    <!--              v-show="connected"-->
    <!--          >mdi-check-circle-->
    <!--          </v-icon>-->
    <!--        </template>-->
    <!--        <span class="Caption">-->
    <!--        <slot name="success">-->
    <!--          {{ $t('common.status') }} :{{ $t('common.connected') }}-->
    <!--        </slot>-->
    <!--        <br/>-->
    <!--        {{ $t('connection.lastUpdate', {time: parseDate}) }}-->
    <!--      </span>-->
    <!--      </v-tooltip>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { isDef } from "@utils/helpers";

export default {
  name: "ConnectionIndicator",
  props: {
    connected: {
      type: Boolean,
      required: true,
      default: false,
    },
    lastUpdate: {
      type: String,
      required: true,
    },
  },
  computed: {
    parseDate() {
      return isDef(this.lastUpdate)
        ? DateTimeConverter.ISO8601Date(
            DateTimeConverter.parseDate(this.lastUpdate)
          ).slice(0, -4)
        : this.$t("common.unknown");
    },
  },
};
</script>

<style scoped>
.connection-indicator-container {
  height: 65px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
