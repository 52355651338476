import Vue from "vue";
import * as types from "./mutation-types";

export default {
  [types.SET_LC_DEVICE_DEFINITION]: (
    state,
    { hardwareVersionName, ...lcDeviceDefinition }
  ) =>
    Vue.set(state.lcDeviceDefinitions, hardwareVersionName, lcDeviceDefinition),
};
