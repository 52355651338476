<template>
  <div :title="name" class="rtInputOutput">
    <input
      type="number"
      min="0"
      max="32"
      v-model="module"
      :name="`${name}_module`"
      class="rtInputOutput-input"
      @keydown.prevent
      v-on="listeners"
      :disabled="getIsProvidingService"
    />
    <select
      :name="`${name}_module_type`"
      v-model="moduleType"
      class="rtInputOutput-select"
      v-on="listeners"
      :disabled="getIsProvidingService"
    >
      <option
        v-for="item in dict"
        :key="item.value"
        :name="item.module"
        :value="item.value"
      >
        {{ item.module }}
      </option>
    </select>
    <input
      type="number"
      min="0"
      max="32"
      v-model="connector"
      :name="`${name}_connector`"
      class="rtInputOutput-input"
      @keydown.prevent
      v-on="listeners"
      :disabled="getIsProvidingService"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "RtInputOutput",
  inheritAttrs: false,
  props: {
    isInput: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dict: [],
      module: "",
      moduleType: "",
      connector: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getIsProvidingService"]),
    composedValue() {
      return this.calcModule + this.calcType + parseInt(this.connector);
    },
    calcModule() {
      return parseInt(this.module) * 1000;
    },
    calcType() {
      return parseInt(this.moduleType) * 100;
    },
    listeners() {
      return {
        ...this.$listeners,
        //input does not work for select
        //change does
        change: (e) => this.$emit("input", this.composedValue, this.value, e),
      };
    },
  },
  methods: {
    formatValueFor(type, value) {
      if (value.toString().length === 1) {
        this[type] = "0" + value;
      }
    },
  },
  watch: {
    connector(newValue) {
      this.formatValueFor("connector", newValue);
    },
    module(newValue) {
      this.formatValueFor("module", newValue);
    },
  },
  created() {
    if (this.isInput) {
      this.dict = [
        { module: "K", value: 0 },
        { module: "L", value: 1 },
        { module: "M", value: 2 },
        { module: "N", value: 3 },
        { module: "O", value: 4 },
        { module: "P", value: 5 },
        { module: "Q", value: 6 },
        { module: "R", value: 7 },
        { module: "S", value: 8 },
        { module: "T", value: 9 },
      ];
    } else {
      this.dict = [
        { module: "A", value: 0 },
        { module: "B", value: 1 },
        { module: "C", value: 2 },
        { module: "D", value: 3 },
        { module: "E", value: 4 },
        { module: "F", value: 5 },
        { module: "G", value: 6 },
        { module: "H", value: 7 },
        { module: "I", value: 8 },
        { module: "J", value: 9 },
      ];
    }
    this.connector = this.value % 100;
    this.moduleType = ((this.value % 1000) - this.connector) / 100;
    this.module = Math.floor(this.value / 1000);
  },
};
</script>

<style scoped>
.rtInputOutput {
  display: inline-block;
  border: 1px solid lightgray;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.rtInputOutput-input {
  text-align: left;
  width: 48px;
  border: 2px solid #c3c3c3;
}

.rtInputOutput-select {
  text-align: center;
  width: 40px;
  border: 2px solid #c3c3c3;
}
</style>
