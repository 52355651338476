<template>
  <v-footer v-if="shouldShow" app class="nav-footer" inset padless>
    <v-slide-group class="slide-group" show-arrows>
      <v-slide-item
        v-for="(item, index) in getDevicesOnSelectedGateway"
        :key="index"
      >
        <div
          :class="{
            unlocked: item.isLicenced,
            locked: !item.isLicenced,
            selected: isSelected(item),
          }"
          class="disable-select"
          @click="() => onDeviceChangeHandler(item)"
        >
          <span
            :class="{
              'mdi-devices': item.isLicenced,
              'mdi-lock': !item.isLicenced,
            }"
            class="mdi icon"
          />
          <span class="text">{{ item.name }}</span>
        </div>
      </v-slide-item>
    </v-slide-group>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import { _isLcCpu } from "@state/modules/cache/bll";
import { _isSameVersion } from "@state/modules/rt-remote/bll";

const validRoutes = ["remote", "lc-remote"];

export default {
  name: "NavFooter",
  data() {
    return {
      currentRoute: this.$router.currentRoute.name,
      shouldShow: this.validateRoute(),
    };
  },
  computed: {
    ...mapGetters(["getDevicesOnSelectedGateway", "getSelectedDevice"]),
    ...mapGetters("rtRemote", ["getCurrentDisposition"]),
  },
  methods: {
    async onDeviceChangeHandler(device) {
      if (!device.isLicenced) {
        return;
      }
      let screenId = null;
      if (_isLcCpu(device)) {
        if (device === this.getSelectedDevice) {
          screenId = -1;
        }
        if (_isSameVersion(this.getSelectedDevice, device)) {
          if (this.$router.currentRoute.name === "lc-remote") {
            screenId = this.$router.currentRoute.params.screenId;
          } else {
            screenId = -1;
          }
        } else {
          screenId = -1;
        }
      }
      await this.$store.dispatch("onSelectDevice", { device }, { root: true });
      await this.$helpers.pushDeviceRoute(device, screenId);
    },
    validateRoute() {
      return !(
        this.getCurrentDisposition?.isCustomScreen ||
        validRoutes.indexOf(this.currentRoute) === -1
      );
    },
    isSelected(device) {
      return this.getSelectedDevice.id === device.id;
    },
    refresh() {
      this.shouldShow = this.validateRoute();
    },
  },
  watch: {
    "$route.name": {
      handler(newVal) {
        this.currentRoute = newVal;
        this.shouldShow = this.validateRoute();
      },
      deep: true,
    },
    getCurrentDisposition: {
      handler() {
        this.shouldShow = this.validateRoute();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-footer {
  background-color: var(--v-white-base);
  border-top: var(--v-grey20-base) 1px solid;

  .slide-group {
    width: 100vw;

    ::v-deep.v-slide-group__next,
    ::v-deep.v-slide-group__prev {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 35px;
      background-color: var(--v-white-base);
      color: var(--v-grey20-base);
    }

    div {
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 0px 20px;
      background-color: var(--v-white-base);

      .text {
        padding-left: 5px;
      }

      .selected {
        border-top: var(--v-primary-base) 1px solid;
        cursor: pointer;
        color: var(--v-primary-base);
        background-color: var(--v-grey10-base);
      }
    }
  }

  .unlocked {
    &:hover {
      transition: 0.2s;
      border-top: var(--v-primary-base) 1px solid;
      cursor: pointer;
      color: var(--v-primary-base);
      background-color: var(--v-grey05-base);
    }

    .icon {
      font-weight: bolder;
      font-size: 16px;
      color: var(--v-primary-base);
    }
  }

  .locked {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    color: var(--v-grey60-base);

    .icon {
      font-weight: bolder;
      font-size: 16px;
      color: var(--v-grey60-base);
    }
  }
}
</style>
