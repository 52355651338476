<template>
  <!-- mt is niet netjes-->
  <highcharts
    :options="temperatureChartOptions"
    :callback="temperatureRenderCallback"
    class="mt-16"
  />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const flameIcon = "wdg_flame";
const temperatureIcon = "wdg_temp_green";

const typeEnum = {
  ROOM: "ROOM",
  INLET: "INLET",
  FLOOR: "FLOOR",
};

export default {
  name: "WidgetHeatingAnalog",
  props: {
    type: {
      required: true,
      type: String,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    actualControl: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    bandwidth: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    hysteresis: {
      type: Number,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    isCurveEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 360,
    },
    height: {
      type: Number,
      required: false,
      default: 270,
    },
  },
  mixins: [WidgetBase],
  computed: {
    temperatureChartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.colors.lightGreen,
          ...this.calculateTemperatureMetrics(),
        },
        yAxis: {
          title: false,
          min: 0,
          max: 100,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          lineColor: this.colors.darkGray,
          lineWidth: 1,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          tickWidth: 0,
          lineColor: this.colors.darkGray,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
              defer: 0,
            },
            enableMouseTracking: false,
            marker: {
              enabled: false,
            },
            lineWidth: this.width / 600,
          },
        },
        series: [
          {
            color: "black",
            name: "Temp",
            data: [
              [0, 90],
              [10, 90],
              [75, 10],
              this.status ? [80, 10] : [75, 0], //knikpunt
              [80, 0],
              [100, 0],
            ],
          },
          {
            color: "black",
            name: "actual control horizontal",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.actualControlX, this.actualControlY],
              [101, this.actualControlY],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "left max vertical dash line",
            dashStyle: "dash",
            data: [
              [10, 0],
              [10, 90],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "bottom hysteresis dash",
            dashStyle: "dash",
            data: [
              [0, 10],
              [this.actualControl.raw > 0 ? 80 : 75, 10],
              [this.actualControl.raw > 0 ? 80 : 75, 0],
            ],
          },
          {
            color: "black",
            name: "actualMeasurement vertical",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.actualMeasurementX, 101],
              [this.actualMeasurementX, this.actualMeasurementY],
            ],
          },
        ],
      };
    },
    temperatureMargin() {
      const x = this.width * 0.2;
      const y = this.temperatureHeight * 0.125;
      return [y, x, y, x];
    },
    temperatureHeight() {
      return this.height * 0.75;
    },
    actualControlX() {
      const factor =
        (this.actualControl.raw - this.min.raw) / (this.max.raw - this.min.raw);
      const x = 75 - 65 * factor;
      return Math.max(10, x);
      // return this.minMax(x, 10, 75);
    },
    actualControlY() {
      if (this.actualControl.raw < 0) {
        return 0;
      }

      const factor =
        (this.actualControl.raw - this.min.raw) / (this.max.raw - this.min.raw);
      const y = 10 + 80 * factor;
      return Math.min(90, y);
      // return this.minMax(y, 10, 90);
    },
    actualMeasurementX() {
      if (this.actualMeasurement.raw > this.setpoint.raw) {
        const x =
          80 +
          ((this.actualMeasurement.raw -
            (this.setpoint.raw + this.hysteresis)) *
            (80 - 75)) /
            this.hysteresis;
        return Math.min(100, x);
      }

      //else it's between min and max (on diagonal)
      const factor = Math.abs(
        (this.actualMeasurement.raw - this.setpoint.raw) / this.bandwidth.raw
      );
      const x = 75 - 65 * factor;
      return Math.max(10, x);
    },
    actualMeasurementY() {
      //kan beter?
      let max = this.setpoint.raw;
      if (this.actualControl.raw < 0) {
        max += this.hysteresis;
      }
      if (this.actualMeasurement.raw > max) {
        return 0;
      }

      const factor =
        (this.setpoint.raw - this.actualMeasurement.raw) / this.bandwidth.raw;
      const y = 10 + 80 * factor;
      return this.minMax(y, 10, 90);
    },
  },
  methods: {
    calculateTemperatureMetrics() {
      return {
        width: this.width,
        height: this.temperatureHeight,
        margin: this.temperatureMargin,
        spacing: [0, 0, 0, 0],
      };
    },
    temperatureRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 20;
        const radius = this.plotMetrics.height / 30;

        this.renderActualMeasurementText();

        this.renderMinMaxText(radius);
        this.renderSetPointText(radius);
        this.renderBandWidthText(radius);
        this.renderActualControlText();
        this.renderFlameIcon();

        this.renderThermometerIcon();
      } catch (err) {
        console.error(err);
      }
    },
    renderActualMeasurementText() {
      const actualMeasurementX = this.translateX(this.actualMeasurementX);
      this.renderTextCentered(
        this.actualMeasurement.formatted,
        actualMeasurementX,
        this.plotMetrics.height * 0.05
      );
    },
    renderMinMaxText(radius) {
      const minY = this.translateY(10);
      this.renderVentilationTextLeft(this.min, radius, minY);
      const maxY = this.translateY(90);
      this.renderVentilationTextLeft(this.max, radius, maxY);
    },
    renderVentilationTextLeft(value, radius, absoluteY) {
      const afterX = this.renderTextCentered(
        value.formatted,
        this.plotMetrics.x / 2,
        absoluteY - this.charWidth
      );
      if (!this.isCurveEnabled) {
        const circleX = afterX + radius;
        this.renderCircle(circleX, absoluteY, radius);
      }
    },
    renderSetPointText(radius) {
      const setPointX = this.translateX(75);
      const setPointCircleX = this.renderTextCentered(
        this.setpoint.formatted,
        setPointX,
        this.plotMetrics.endY
      );
      const circleY = this.plotMetrics.endY + radius * 1.3;

      if (!this.isCurveEnabled) {
        const circleX = setPointCircleX + radius * 2;
        this.renderCircle(circleX, circleY, radius);
      }
    },
    renderBandWidthText(radius) {
      const bandWidthX = this.translateX(10);
      const circleY = this.plotMetrics.endY + radius * 1.3;
      const bandwidthCircleX = this.renderTextCentered(
        this.bandwidth.formatted,
        bandWidthX,
        this.plotMetrics.endY
      );
      const circleX = bandwidthCircleX + radius * 2;
      this.renderCircle(circleX, circleY, radius);
    },
    renderActualControlText() {
      const actualControlY =
        this.translateY(this.actualControlY) - this.charWidth;
      this.renderText(
        this.actualControl.formatted,
        this.plotMetrics.endX,
        actualControlY
      );
    },
    renderFlameIcon() {
      const flameIconX = this.plotMetrics.x * 0.2;
      const flameIconY = this.plotMetrics.y / 2;
      const flameIconWidth = this.plotMetrics.x * 0.3;
      const flameIconHeight = this.plotMetrics.height * 0.12;
      this.renderIconS3(
        flameIcon,
        flameIconX,
        flameIconY,
        flameIconWidth,
        flameIconHeight
      );
      this.renderText(
        `${this.units.PERCENT}`,
        flameIconX + flameIconWidth * 0.9,
        flameIconY
      );
    },
    renderThermometerIcon() {
      const thermosIconWidth = this.plotMetrics.x / 2;
      const thermosIconX = this.plotMetrics.endX + thermosIconWidth / 2;
      const thermosIconY = this.plotMetrics.endY;
      const thermosIconHeight = this.plotMetrics.height / 8;
      this.renderIconS3(
        temperatureIcon,
        thermosIconX,
        thermosIconY,
        thermosIconWidth,
        thermosIconHeight
      );
      this.renderText(
        this.units.DEGREE,
        this.plotMetrics.endX + this.plotMetrics.width / 5,
        this.plotMetrics.endY
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.temperatureRenderCallback);
  },
};
</script>

<style scoped></style>
