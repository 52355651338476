function AppendChild(child) {
  if (child || child === 0) {
    if (!child.tagName) {
      this.appendChild(document.createTextNode(child));
    }
    else {
      this.appendChild(child);
    }
  }
}

function gebi(elem) {
  if (typeof(elem) !== 'object') return document.getElementById(elem);
  return elem;
}

export const Util = {
  GenId: function () {
    return Math.random().toString(16).slice(2);
  },
  AppendChild: function (elem, child) {
    elem = gebi(elem);
    if (Array.isArray(child)) {
      child.forEach(AppendChild, elem);
    }
    else {
      AppendChild.call(elem, child);
    }
    return elem;
  },
  SetCss: function (elem, css) {
    this.Extend(elem.style, css);
  },
  SetAttrs: function (elem, attrs) {
    elem = gebi(elem);

    var key, value;

    if (attrs) {
      for (key in attrs) {
        value = attrs[key];
        switch (key) {
          case 'style':
            this.SetCss(elem, value);
            break;
          case 'classList':
            if (value.length > 0) {
              elem.className = value.join(' ');
            }
            break;
          case 'form':
            elem.setAttribute('form', value);
          //IE 6-8 & Safari dont support elem.setAttribute('form', value);
          case 'type': //IE8 ?
            elem.setAttribute(key, value);
            break;
          default:
            elem[key] = value;
            break;
        }
      }
    }
    return elem;
  },
  MakeElement: function (type, attrs) {
    var e = this.SetAttrs(document.createElement(type), attrs), a = arguments, l = a.length, i;

    for (i = 2; i < l; ++i) {
      this.AppendChild(e, a[i]);
    }
    return e;
  },
  Extend: function (dst, src, throwIfOverwrite) {
    dst = dst || {};

    var name;

    if (src) {
      if (throwIfOverwrite) {
        for (name in src) {
          if (dst.hasOwnProperty(name)) {
            throw "Stienen.Util.Extend overwriting property";
          }
          dst[name] = src[name];
        }
      }
      else {
        for (name in src) {
          dst[name] = src[name];
        }
      }
    }
    return dst;
  },
  MakeHash: function (list, key, value) {
    var result = {}, count = list.length, item;

    if (value) {
      if (key) {
        while (--count >= 0) {
          item = list[count];
          result[item[key]] = item[value];
        }
      }
      else {
        for (key in list) {
          if (list.hasOwnProperty(key)) {
            item = list[key];
            result[key] = item[value];
          }
        }
      }
    }
    else {
      while (--count >= 0) {
        item = list[count];
        result[item[key]] = item;
      }
    }
    return result;
  },
  ISO8601Part: function (/*part, date*/) {
    var args = arguments, date = args[1] || new Date();

    date = date.toISOString(); // YYYY-MM-DDTHH:mm:SS.sssZ
    switch (args[0] || 0) {
      case 0: /*ms*/
        return date.substring(0, 10) + ' ' + date.substring(11, 23);// YYYY-MM-DD HH:mm:SS.sss
      case 1: /*s*/
        return date.substring(0, 10) + ' ' + date.substring(11, 19);// YYYY-MM-DD HH:mm:SS
      case 2: /*m*/
      case 3: /*h*/
        return date.substring(0, 10) + ' ' + date.substring(11, 16);// YYYY-MM-DD HH:mm
      case 4: /*d*/
      case 5: /*w*/
        return date.substring(0, 10);	// YYYY-MM-DD
      case 6: /*m*/
      case 7: /*q*/
        return date.substring(0, 7);	// YYYY-MM
      case 8: /*y*/
        return date.substring(0, 4);	// YYYY
    }
  },

  //get prefix
  getpre: function (prefix, i) {
    var split = prefix.split(',');

    return split[i];
  },
  prefix: function (current, prefix) {
    var idx = current.indexOf('#'), inc = '';

    if (prefix && idx > 0) {
      if (idx > 1) {
        inc = current.slice(1, idx);
      }
      prefix = this.getpre(prefix, +inc);
      return current.replace(inc + '#', prefix);
    }
    return current;
  },
};
