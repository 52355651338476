import Vue from "vue";
import * as types from "./mutation-types";

export default {
  [types.SET_SELECTED_DISPOSITION]: (state, disposition) => {
    state.selectedDisposition = disposition;
    state.selectedAt = Date.now();
  },

  [types.SET_SCREEN_DEFINITION]: (state, screen) =>
    Vue.set(state.screenDefs, screen.Id, screen),

  //triggers vue update process
  [types.REFRESH_TREE]: (state) => (state.treeCreatedAt = Date.now()),

  [types.SET_LAST_24_HOUR_ENABLED]: (state, enabled) =>
    (state.last24HourEnabled = enabled),
  [types.SET_LOADING_ACTUAL]: (state, actual) =>
    (state.customLoadingActual = actual),
  [types.INCREMENT_LOADING_CURRENT]: (state, deviceId) =>
    state.customLoadingCurrent.add(deviceId),
  [types.RESET_LOADING]: (state) => {
    state.customLoadingCurrent = new Set();
    state.customLoadingActual = new Set();
  },
};
