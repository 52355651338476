import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { uuidv4 } from "@utils/helpers";

export function INITIAL_STATE() {
  return {
    token: null,
    user: null,
    mainAccount: null,
    customerAccount: null,
    impersonatedCustomer: null,
    userStatus: "USER_UNINITIALIZED",
    showAuth: false,
    channel: uuidv4(),

    //TODO SERVICE
    lcRoles: {
      0: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"], //temp?
      100: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"], //temp?
      200: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"], //temp?
      300: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"],
      400: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"],
      500: ["MASTER", "INSTALLER", "MANAGER", "USER2", "USER1"],
      600: ["USER2", "USER1"],
      700: ["USER2", "USER1"],
      800: ["USER2", "USER1"],
      900: ["USER2", "USER1"],
    },
  };
}

const state = INITIAL_STATE();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
