import { isDef, isUndef } from "@utils/helpers";
import { ScreenNotFoundError, SpecifiedError } from "@error/types";
import { _createHardwareVersionName } from "../cache/bll";

const getLcScreenDefinition = (state) => (device, screenId) => {
  if (isUndef(device)) {
    throw new SpecifiedError("Lc CPU Device missing");
  }
  const hardwareVersionName = _createHardwareVersionName(device);
  let result;
  if (isDef(screenId) && state.lcDeviceDefinitions[hardwareVersionName]) {
    result = state.lcDeviceDefinitions[hardwareVersionName].screens.find(
      (screen) => screen.id === screenId
    );
  }
  if (isUndef(result)) {
    throw new ScreenNotFoundError(
      `Screen not found, navigation link broken: ${screenId}`
    );
  } else {
    return result;
  }
};

const getLcTestNamesForDevice = (state, getters) => (device, labels) => {
  const results = new Set();
  const testChunks = getters.getTestChunksForDevice(device);

  for (const key in testChunks) {
    if (testChunks[key].some((i) => labels.includes(i))) {
      results.add(key);
    }
  }
  return [...results];
};

const getTestChunksForDevice = (state) => (device) => {
  const hardwareVersionName = _createHardwareVersionName(device);
  if (
    state.lcDeviceDefinitions[hardwareVersionName] &&
    state.lcDeviceDefinitions[hardwareVersionName].hasOwnProperty("testChunks")
  ) {
    const { testChunks } = state.lcDeviceDefinitions[hardwareVersionName];
    return testChunks;
  }
};

const getAlarmIconForDevice = (state) => (device, alarmCode) => {
  const hardwareVersionName = _createHardwareVersionName(device);
  const { alarmIcons } = state.lcDeviceDefinitions[hardwareVersionName];
  return alarmIcons.find((icon) => icon.code === alarmCode);
};

const getAllTestPassesForComponent =
  (state, getters, rootState, rootGetters) =>
  (device, { test }) => {
    if (isUndef(test) || !Array.isArray(test)) {
      return true;
    }
    return test.every((t) => rootGetters["deviceCache/lcGetValue"](device, t));
  };

const getLcScreenById = (state) => (device, screenId) => {
  const hardwareVersionName = _createHardwareVersionName(device);
  const { screens } = state.lcDeviceDefinitions[hardwareVersionName];
  return screens.find((screen) => screen.id === screenId);
};

export default {
  getLcScreenDefinition,
  getLcTestNamesForDevice,
  getTestChunksForDevice,
  getAlarmIconForDevice,
  getAllTestPassesForComponent,
  getLcScreenById,
};
