<template>
  <v-sheet class="mx-auto" elevation="8">
    <v-row no-gutters class="py-2" style="background-color: #ededed">
      <v-col class="ps-2">
        <base-button icon @click="$emit('onCloseButtonClick')" color="black">
          <v-icon>mdi-close</v-icon>
        </base-button>
      </v-col>

      <v-col class="d-flex justify-end pe-2">
        <TheEditToolbar />
      </v-col>
    </v-row>

    <v-divider class="pt-2 mx-8" />

    <v-row no-gutters class="d-flex justify-center">
      <v-slide-group class="pa-4" center-active v-model="current">
        <v-slide-item
          v-for="(item, index) in items"
          :key="index"
          class="tab-item"
          v-slot="{ toggle }"
        >
          <v-card
            :disabled="!device.edit"
            outlined
            class="variable-card mx-2"
            @click="toggle"
            :ripple="false"
            :style="{ 'border-color': borderColor(item, index) }"
            style="color: white"
          >
            <v-row class="mt-6">
              <v-col class="d-flex justify-center">
                <LcBaseIcon :name="item.icon" />
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-8">
              <v-col class="d-flex justify-center">
                <LcVariable enableEditMode v-bind="item" />
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row no-gutters class="d-flex justify-center">
      <v-slide-group v-model="current" class="py-2" center-active>
        <v-slide-item
          v-for="(item, index) in items"
          :key="item.tab"
          class="tab-item"
          v-slot="{ toggle }"
        >
          <v-icon small @click="toggle">
            {{
              current === index
                ? "mdi-checkbox-blank-circle"
                : "mdi-checkbox-blank-circle-outline"
            }}
          </v-icon>
        </v-slide-item>
      </v-slide-group>
    </v-row>
  </v-sheet>
</template>

<script>
import LcVariable from "@modules/lccpu/components/LcVariable";
import LcBaseIcon from "@modules/lccpu/components/LcBaseIcon";
import TheEditToolbar from "@components/edit/TheEditToolbar";
import { mapGetters } from "vuex";

export default {
  name: "LcEditCarousel",
  components: {
    LcVariable,
    LcBaseIcon,
    TheEditToolbar,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  computed: {
    ...mapGetters("edit", ["getEditedValue"]),
  },
  methods: {
    isEdited(value) {
      const editedValue = this.getEditedValue(
        this.device,
        value.variableReference.Name
      );
      return editedValue !== null; // && editedValue !== value.value;
    },
    borderColor(item, index) {
      // both colors are 50% darker
      if (this.isEdited(item)) {
        return this.current === index ? "#be7b00" : "#ffb42b";
      }
      return this.current === index ? "#8f8f8f" : "#e0e0e0";
    },
    change(toggle, index) {
      toggle();
      this.index = index;
    },
  },
};
</script>

<style scoped>
.variable-card {
  border-radius: 12px;
  height: 200px;
  width: 140px;
  margin: 24px 0 24px 0;
}
</style>
