import {Core} from './Core'

function meter(target, attr, loc, values, width, height, rows, cols, offset, scale) {
  const canvas = Core.createCanvas(target, attr, width, height);
  width = canvas.width;
  height = canvas.height;
  Core.drawGrid(canvas,5, 5, width - 10, height - 5, (width - 10) / cols, (height - 5) / rows);
  let mom = canvas.rect(6, 6, 0, height - 6).attr('fill', 'black');
  let inst = canvas.path('M6,4L10,0 2,0Z').attr('fill', 'black');

  // measure
  const measure = Core.setAttr.bind({
    Attr: 'width',
    Target: mom,
    Offset: offset || 0,
    Scale: (width - 10) / (scale || 1)
  });

  // setting
  const setting = Core.setAttr.bind({
    Attr: 'transform',
    Target: inst,
    Offset: offset || 0,
    Scale: (width - 10) / (scale || 1),
    Extra: ['T', null, 0]
  });

  values[0].registerCallback(measure);
  values[1].registerCallback(setting);
}

/*
 * loc.Extra:=Tmom,Tregel
 */
export function tempmeter(target, attr, loc, values) {
  let width = attr.style.width || 200;
  meter(target, attr, loc, values, width, 15, 1, 3, -10, 30);
}

/*
 * loc.Extra:=VENT,STAND
 */
export function ventmeter(target, attr, loc, values) {
  let width = attr.style.width || 100;
  meter(target, attr, loc, values, width, 15, 1, 4, 0, 100);
}

