<template>
  <v-form ref="form" v-model="valid">
    <v-card outlined>
      <v-card-title>{{ $t("setup.step2.selectGateway") }}</v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                v-model="gateway"
                :disabled="loading"
                :label="$t('common.gateway')"
                :rules="gatewayRules"
                prepend-icon="mdi-router-wireless-settings"
                required
                :items="gateways"
                item-text="Name"
                return-object
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :items="devices"
                :headers="headers"
                dense
                :mobile-breakpoint="0"
                hide-default-footer
                :height="6 * 32"
                :items-per-page="-1"
                fixed-header
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button :disabled="loading" outlined text @click="$emit('back')">
          {{ $t("common.back") }}
        </base-button>
      </template>
      <template #btn2>
        <base-button :disabled="!valid" :loading="loading" @click="validate">
          {{ $t("common.ready") }}
        </base-button>
      </template>
    </button-holder>
  </v-form>
</template>

<script>
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "GatewaySelector",
  components: { ButtonHolder },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    migration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      gateway: null,
      gatewayRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.gateway") }),
      ],
      headers: [
        {
          text: this.$t("common.devices"),
          align: "left",
          value: "Name",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    gateways() {
      return this.migration.Gateways;
    },
    devices() {
      return this.gateway?.Devices;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store.dispatch("subscription/setMigratingGateway", this.gateway);
      }
    },
  },
};
</script>

<style scoped></style>
