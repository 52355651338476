import { adminService } from "@api/admin-service";
import * as types from "./mutation-types";
import { isDef } from "@utils/helpers";
import { Encoding } from "@stienen/Encoding";

async function initializeSettings({ commit }, settings) {
  commit(types.SET_LANGUAGE, getSettingValue("language", settings));
  commit(types.SET_TOOLTIP_CHART, getSettingValue("tooltipChart", settings));
  commit(types.SET_TOOLTIP_EVENTS, getSettingValue("tooltipEvents", settings));
  commit(types.SET_HOME, getSettingValue("home", settings));
  commit(
    types.SET_TOOLTIP_NUMBER_OF_EVENTS,
    getSettingValue("tooltipNumberOfEvents", settings)
  );
}

async function setLanguage({ commit }, language) {
  await setSetting("language", language);
  commit(types.SET_LANGUAGE, language);
}

async function setHome({ commit }, home) {
  await setSetting("home", home);
  commit(types.SET_HOME, home);
}

async function initBrowserLanguage({ commit }) {
  commit(types.SET_LANGUAGE, Encoding.language);
}

async function setTooltipChart({ commit }, tooltipChart) {
  await setSetting("tooltipChart", tooltipChart);
  commit(types.SET_TOOLTIP_CHART, tooltipChart);
}

async function setTooltipEvents({ commit }, tooltipEvents) {
  await setSetting("tooltipEvents", tooltipEvents);
  commit(types.SET_TOOLTIP_EVENTS, tooltipEvents);
}

async function setTooltipNumberOfEvents({ commit }, tooltipNumberOfEvents) {
  await setSetting("tooltipNumberOfEvents", tooltipNumberOfEvents);
  commit(types.SET_TOOLTIP_NUMBER_OF_EVENTS, tooltipNumberOfEvents);
}

async function setSetting(settingName, settingValue) {
  const settings = [{ name: settingName, value: settingValue }];
  //todo error handling
  return await adminService.setSettings(settings).settings;
}

function getSettingValue(settingName, data) {
  const found = data.find((i) => i.name === settingName);
  return found && isDef(found.value) && found.value !== "undefined"
    ? found.value
    : null;
}

export default {
  initializeSettings,
  setLanguage,
  setHome,
  initBrowserLanguage,
  setTooltipChart,
  setTooltipEvents,
  setTooltipNumberOfEvents,
};
