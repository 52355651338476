<template>
  <highcharts :options="pulseChartOptions" :callback="pulseRenderCallback" />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import { isDef } from "@utils/helpers";

const RVIcon = "wdg_rv_green";
const RVIconDark = "wdg_rv_gph_gray";

const RVMaxSprayIcon = "wdg_spraycooling_off";
const RVMaxSprayIconDisabled = "wdg_spraycooling_off_gray";
const RVMaxPadIcon = "wdg_padcooling_off_green";
const RVMaxPadIconDisabled = "wdg_padcooling_off_gray";
const RVMaxTPIcon = "wdg_cooling_off_green";
const RVMaxTPIconDisabled = "wdg_cooling_off_gray";

const pulseIcon = "wdg_pulse_green";
const pulseIconDisabled = "wdg_pulse_gray";

//used in front of the pulse component
const ventIcon = "wdg_vent_on";
const sprayIcon = "wdg_spraycooling_green";
const sprayIconDisabled = "wdg_spraycooling_gray";
const padIcon = "wdg_padcooling_green";
const padIconDisabled = "wdg_padcooling_off";
const tPIcon = "wdg_cooling_gph_green";
const tPIconDisabled = "wdg_temp_gray";

const typeEnum = {
  SPRAY: "SPRAY",
  PAD: "PAD",
  RV_LIMIT: "RV_LIMIT",
};

export default {
  name: "PulseComponent",
  mixins: [WidgetBase],
  props: {
    type: {
      type: String,
      required: false, // from cooling
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true, // isPulsMode from StepVent
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    pulsTime: {
      type: Number,
      required: true, // pulsPauseTime from StepVent
    },
    currentTime: {
      type: Number,
      required: true, // actualTime from StepVent
    },
    isEnabledRV: {
      type: [Boolean, Number],
      required: false,
      default: true,
    },
    actualRV: {
      type: Number,
      required: false, // from cooling
    },
    maxRV: {
      type: Number,
      required: false, // from cooling
    },
    width: {
      type: Number,
      default: 360,
    },
    height: {
      type: Number,
      default: 67.5,
    },
  },
  computed: {
    RVIcon() {
      return this.isEnabled ? RVIcon : RVIconDark;
    },
    RVMaxSprayIcon() {
      return this.isEnabled ? RVMaxSprayIcon : RVMaxSprayIconDisabled;
    },
    RVMaxPadIcon() {
      return this.isEnabled ? RVMaxPadIcon : RVMaxPadIconDisabled;
    },
    RVMaxTPIcon() {
      return this.isEnabled ? RVMaxTPIcon : RVMaxTPIconDisabled;
    },
    pulseIcon() {
      return this.isEnabled ? pulseIcon : pulseIconDisabled;
    },
    sprayIcon() {
      return this.isEnabled ? sprayIcon : sprayIconDisabled;
    },
    padIcon() {
      return this.isEnabled ? padIcon : padIconDisabled;
    },
    tpIcon() {
      return this.isEnabled ? tPIcon : tPIconDisabled;
    },
    typeIcon() {
      //first icon (type) (in cooling)
      if (this.type) {
        switch (this.type) {
          case typeEnum.SPRAY:
            return this.sprayIcon;
          case typeEnum.PAD:
            return this.padIcon;
          case typeEnum.RV_LIMIT:
            return this.tpIcon;
        }
      }
      //else is in step vent
      return ventIcon;
    },
    rvMaxIcon() {
      switch (this.type) {
        case typeEnum.SPRAY:
          return this.RVMaxSprayIcon;
        case typeEnum.PAD:
          return this.RVMaxPadIcon;
        case typeEnum.RV_LIMIT:
          return this.RVMaxTPIcon;
      }
      return "";
    },
    pulseChartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "bullet",
          inverted: true,
          plotBackgroundColor: this.isEnabled
            ? this.colors.lightGreen
            : "transparent",
          backgroundColor: this.colors.background,
          ...this.calculateMetrics(),
        },
        xAxis: {
          lineColor: this.isEnabled ? this.colors.darkGray : "transparent",
        },
        yAxis: {
          title: false,
          categories: [],
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
          min: 0,
          max: 100,
          lineColor: this.isEnabled ? this.colors.darkGray : "transparent",
          lineWidth: 1,
        },
        plotOptions: {
          bullet: {
            color: "transparent",
            borderColor: "transparent",
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            name: "pulseTime",
          },
        ],
      };
    },
    margin() {
      const x = this.width * 0.2;
      const y = this.height / 2.4;
      return [y, x, this.height * 0.25, x];
    },
  },
  methods: {
    calculateMetrics() {
      return {
        width: this.width,
        height: this.height,
        margin: this.margin,
        spacing: [0, 0, 0, 0],
      };
    },
    pulseRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 3;
        this.strokeWidth = this.width / 600;

        if (this.isEnabledRV) {
          this.renderRV();
        }

        if (!this.isEnabled) {
          return;
        }

        this.renderMaxLine();
        this.renderPulseLine();
        this.renderCurrentTimeLine();
        this.renderTypeIcon();
        this.renderPulseIcon();
      } catch (err) {
        console.error(err);
      }
    },
    renderRV() {
      if (isDef(this.actualRV)) {
        this.renderText(
          `${Math.floor(this.maxRV)}%`,
          this.plotMetrics.x + this.plotMetrics.width / 2,
          0
        );

        const RVIconX = this.plotMetrics.x + this.plotMetrics.width / 20;
        const RVIconY = this.chartMetrics.y;
        const RVIconWidth = this.plotMetrics.width / 10;
        const RVIconHeight = this.plotMetrics.height / 1.5;

        this.renderIconS3(
          this.RVIcon,
          RVIconX,
          RVIconY,
          RVIconWidth,
          RVIconHeight
        );
      }
      if (isDef(this.maxRV)) {
        this.renderText(
          `${Math.floor(this.actualRV)}%`,
          this.plotMetrics.x + this.plotMetrics.width / 6,
          0
        );

        const RVIconX = this.plotMetrics.x + this.plotMetrics.width / 3;
        const RVIconY = this.chartMetrics.y;
        const RVIconWidth = this.plotMetrics.width / 10;
        const RVIconHeight = this.plotMetrics.height / 1.5;

        this.renderIconS3(
          this.rvMaxIcon,
          RVIconX,
          RVIconY,
          RVIconWidth,
          RVIconHeight
        );

        //circle after max RV
        const radius = this.plotMetrics.height / 5;
        const circleX = this.plotMetrics.x + this.plotMetrics.width / 1.45;
        const circleY = radius * 1.5;
        this.renderCircle(circleX, circleY, radius);
      }
    },
    renderMaxLine() {
      let maxX = this.translateX(90);
      const dottedMaxLine = [
        "M",
        maxX,
        this.plotMetrics.y,
        "L",
        maxX,
        this.plotMetrics.endY,
      ];
      this.renderLine(dottedMaxLine, {
        color: this.colors.darkGray,
        dash: this.strokeWidth,
      });

      const textMaxTime = this.formatTime(this.max);
      this.renderTextCentered(textMaxTime, maxX, this.plotMetrics.endY);
    },
    renderPulseLine() {
      const pulseTimeX = this.translateX((this.pulsTime / this.max) * 90);
      const pulseTimeY = this.plotMetrics.y + this.plotMetrics.height * 0.2;
      const pulseTimeLine = [
        "M",
        this.plotMetrics.x,
        pulseTimeY,
        "L",
        pulseTimeX,
        pulseTimeY,
        "L",
        pulseTimeX,
        this.plotMetrics.endY,
      ];
      this.renderLine(pulseTimeLine);
    },
    renderCurrentTimeLine() {
      const currentTimeX = Math.max(
        this.translateX((this.currentTime / this.max) * 90),
        this.plotMetrics.x
      );
      const currentTimeStartY =
        this.plotMetrics.y - this.plotMetrics.height * 0.1;
      const currentTimeEndY =
        this.plotMetrics.endY + this.plotMetrics.height * 0.1;
      const currentTimeLine = [
        "M",
        currentTimeX,
        currentTimeStartY,
        "L",
        currentTimeX,
        currentTimeEndY,
      ];
      this.renderLine(currentTimeLine);
    },
    renderTypeIcon() {
      const typeIconX = this.plotMetrics.x * 0.05;
      const typeIconY = this.chartMetrics.centerY;
      const typeIconWidth = this.plotMetrics.x * 0.4;
      const typeIconHeight = this.plotMetrics.height / 1.5;
      this.renderIconS3(
        this.typeIcon,
        typeIconX,
        typeIconY,
        typeIconWidth,
        typeIconHeight
      );
    },
    renderPulseIcon() {
      const pulseIconX = this.plotMetrics.x * 0.35;
      const pulseIconY = this.chartMetrics.centerY;
      const pulseIconWidth = this.plotMetrics.x * 0.4;
      const pulseIconHeight = this.plotMetrics.height / 1.5;
      this.renderIconS3(
        this.pulseIcon,
        pulseIconX,
        pulseIconY,
        pulseIconWidth,
        pulseIconHeight
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.pulseRenderCallback);
  },
};
</script>

<style scoped></style>
