import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { fsm } from "@plugins/edit-mode-state-machine";

const state = {
  timers: [],
  events: {},
  devices: [],
  fsm: fsm(),

  SendToMultipleActive: false,
  confirmActive: false,

  confirmPromise: null,
  resolve: null,
  reject: null,

  // used by editToolbar
  type: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
