<script>
import { _translate } from "@modules/rtcpu/render/screen-bll";
import { mapGetters } from "vuex";
import { screenElementBasics } from "@mixins/props";
import { isDef } from "@utils/helpers";

export default {
  name: "RtWeekdayText",
  mixins: [screenElementBasics],
  computed: {
    ...mapGetters("deviceCache", ["getTranslation", "getValue"]),
    index() {
      const date = isDef(this.deviceDate)
        ? this.deviceDate.getDay()
        : new Date().getDay();

      return (date + 12 - this.values[0]) % 7;
    },
    deviceDate() {
      return this.getValue(this.dev, "DATUM");
    },
    label() {
      return this.values[this.index + 1];
    },
    values() {
      return this.loc.Value.split(",");
    },
    weekDayText() {
      const dict = {
        [this.label]: this.getTranslation({
          dev: this.dev,
          label: this.label,
        }),
      };
      return _translate(this.label, dict);
    },
  },
  render(createElement) {
    return createElement("span", this.weekDayText);
  },
};
</script>
<style scoped></style>
