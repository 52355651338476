<template>
  <v-dialog v-model="dialog" max-width="600">
    <template #activator="{ on, attrs }">
      <v-icon color="warning" style="cursor: pointer" v-bind="attrs" v-on="on">
        mdi-help-circle-outline
      </v-icon>
    </template>

    <base-card :title="$t('common.help')">
      <v-card-text>
        {{ $t("setup.cardHelp") }}
      </v-card-text>

      <v-card-actions>
        <v-img
          :height="223"
          :width="350"
          contain
          src="@assets/img/setup/PasjeVoor.png"
        />
      </v-card-actions>

      <v-card-actions>
        <v-spacer />
        <base-button outlined text @click="dialog = false">
          {{ $t("common.close") }}
        </base-button>
        <v-spacer />
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardHelpDialog",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
