import WidgetAlarmCode from "@modules/lccpu/components/widgets/alarm-code/WidgetAlarmCode";
import WidgetAlarmPercentage from "@modules/lccpu/components/widgets/alarm-percentage/WidgetAlarmPercentage";
import WidgetAlarmTemperature from "@modules/lccpu/components/widgets/alarm-temperature/WidgetAlarmTemperature";

export const AlarmCode = new Map([["1.0", WidgetAlarmCode]]);

export const AlarmPercentage = new Map([["1.0", WidgetAlarmPercentage]]);

export const AlarmTemperature = new Map([["1.0", WidgetAlarmTemperature]]);

import WidgetClockOnOff from "@modules/lccpu/components/widgets/clock-onoff/WidgetClockOnOff";

export const ClockOnOff = new Map([["1.0", WidgetClockOnOff]]);

import WidgetCoolingAnalog from "@modules/lccpu/components/widgets/cooling-analog/WidgetCoolingAnalog";
import WidgetCoolingOnOff from "@modules/lccpu/components/widgets/cooling-onoff/WidgetCoolingOnOff";
import WidgetCoolingTP from "@modules/lccpu/components/widgets/cooling-tp/WidgetCoolingTP";

export const CoolingAnalog = new Map([["1.0", WidgetCoolingAnalog]]);

export const CoolingOnOff = new Map([["1.0", WidgetCoolingOnOff]]);

export const CoolingTP = new Map([["1.0", WidgetCoolingTP]]);

import WidgetCurve from "@modules/lccpu/components/widgets/curve/WidgetCurve";
import WidgetCurvePercentage from "@modules/lccpu/components/widgets/curve-percentage/WidgetCurvePercentage";
import WidgetCurveTemperature from "@modules/lccpu/components/widgets/curve-temperature/WidgetCurveTemperature";
import WidgetCurveVentilation from "@modules/lccpu/components/widgets/curve-ventilation/WidgetCurveVentilation";

export const Curve = new Map([["1.0", WidgetCurve]]);

export const CurvePercentage = new Map([["1.0", WidgetCurvePercentage]]);

export const CurveTemperature = new Map([["1.0", WidgetCurveTemperature]]);

export const CurveVentilation = new Map([["1.0", WidgetCurveVentilation]]);

import WidgetHeatingAnalog from "@modules/lccpu/components/widgets/heating-analog/WidgetHeatingAnalog";
import WidgetHeatingOnOff from "@modules/lccpu/components/widgets/heating-onoff/WidgetHeatingOnOff";

export const HeatingAnalog = new Map([["1.0", WidgetHeatingAnalog]]);

export const HeatingOnOff = new Map([["1.0", WidgetHeatingOnOff]]);

import WidgetMeter from "@modules/lccpu/components/widgets/meter/WidgetMeter";

export const Meter = new Map([["1.0", WidgetMeter]]);

import WidgetStepVentilation from "@modules/lccpu/components/widgets/step-ventilation/WidgetStepVentilation";
import WidgetVentilation from "@modules/lccpu/components/widgets/ventilation/WidgetVentilation";

export const StepVentilation = new Map([["1.0", WidgetStepVentilation]]);

export const Ventilation = new Map([["1.0", WidgetVentilation]]);
