/*jslint eqeqeq: true, regexp: true, newcap: true, immed: true */
import {Core} from './Core'

function n(v) {
  return +v.toFixed(3);
}

function path(canvas, pathstr, attr) {
  let count = pathstr.length, item;
  while (--count >= 0) {
    item = pathstr[count];
    if (!isNaN(item)) {
      pathstr[count] = +item.toFixed(3);
    }
  }
  return canvas.path(pathstr).attr(attr);
}

function leg(canvas, x, y, w, h) {
  canvas.rect(n(x), n(y), n(w), n(h)).attr({
    stroke: 'none',
    fill: '0-gray-silver'
  });
}

function outline(canvas, w, h, o, c) {
  path(canvas, ['M', o, h / 3 * 2, 'L', o, c + o, 'A', c, c, 0, 0, 1, c + o, o, 'L', w - c - o, o, 'A', c, c, 0, 0, 1, w - o, c + o, 'L', w - o, h / 3 * 2, w / 14 * 8, h / 6 * 5, w / 14 * 6, h / 6 * 5, 'Z'], {
    stroke: 'black',
    fill: 'white'
  });
  return path(canvas, ['M', w / 14 * 6, h / 6 * 5, w / 28 * 13, h / 12 * 11, w / 28 * 15, h / 12 * 11, w / 14 * 8, h / 6 * 5, 'Z'], {
    stroke: 'black',
    fill: 'white'
  });
}

function fill(canvas, w, h, o, c) {
  return path(canvas, ['M', o, h / 3 * 2, 'L', o, c + o, 'A', c, c, 0, 0, 1, c + o, o, 'L', w - c - o, o, 'A', c, c, 0, 0, 1, w - o, c + o, 'L', w - o, h / 3 * 2, w / 14 * 8, h / 6 * 5, w / 14 * 6, h / 6 * 5, 'Z'], {
    stroke: 'none',
    fill: '0-yellow-yellowgreen',
    'clip-rect': [0, h, w, h]
  });
}

/*
 * loc.Extra:=PERC
 */
export function silo(target, attr, loc, values) {
  let canvas = Core.createCanvas(target, attr, 70, 200),
    width = canvas.width,
    height = canvas.height,
    offset = 1, corner = 5, cont, out;
  leg(canvas, offset, height / 3 * 2, width / 14, height / 3);
  leg(canvas, width - width / 14 - offset, height / 3 * 2, width / 14, height / 3);
  out = outline(canvas, width, height, offset, corner);
  cont = fill(canvas, width, height, offset + 1, corner);

  const perc = Core.setAttr.bind({
    Target: cont,
    Attr: 'clip-rect',
    Offset: -100,
    Scale: (height / 6 * 5) / -100,
    Extra: [0, null, width, height]
  });
  const status = Core.setAttr.bind({Target: out, Attr: 'fill', Options: ['white', 'red']});

  values[0].registerCallback(perc);
  values[1].registerCallback(status);
}
