<template>
  <div>
    <v-card outlined class="pb-4">
      <v-card-title>
        {{ $t("setup.step2.migrateQuestion") }}
      </v-card-title>

      <v-card-actions class="ma-2">
        <v-row>
          <v-col>
            <base-button
              block
              outlined
              :disabled="disabled"
              @click="choose('migrate')"
            >
              <v-icon class="me-2">mdi-cloud-upload-outline</v-icon>
              {{ $t("setup.step2.yesMigrate") }}
            </base-button>
          </v-col>
          <v-col
            ><base-button block :disabled="disabled" @click="choose('new')">
              <v-icon class="me-2">mdi-backspace-reverse-outline</v-icon>
              {{ $t("setup.step2.noNew") }}
            </base-button>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button
          v-if="!hideBack"
          outlined
          text
          :disabled="disabled"
          @click="$emit('back')"
        >
          {{ $t("common.back") }}
        </base-button>
      </template>
    </button-holder>
  </div>
</template>

<script>
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "ChooseType",
  components: { ButtonHolder },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideBack: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    choose(type) {
      this.$emit("onChoice", type);
    },
  },
};
</script>

<style scoped></style>
