import lf from "localforage";
import { isDef, isUndef } from "@utils/helpers";
import { localDBVersion } from "@/app.config";

lf.config({
  driver: [lf.INDEXEDDB, lf.LOCALSTORAGE],
  name: "FC-cache",
  version: 1.0,
  description: "",
});

export function getItem(key) {
  if (isDef(key) && Array.isArray(key) && key.length > 0) {
    key = key.join(".");
  }
  return new Promise((resolve, reject) => {
    lf.getItem(key)
      .then((value) => {
        resolve(value);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function setItem(key, value) {
  if (isDef(key) && Array.isArray(key) && key.length > 0) {
    key = key.join(".");
  }
  value = {
    created: new Date().getTime(),
    data: value,
  };

  return new Promise((resolve, reject) => {
    lf.setItem(key, value)
      .then((value) => {
        resolve(value);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function removeItem(key) {
  return new Promise((resolve, reject) => {
    lf.removeItem(key)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function clear() {
  return new Promise((resolve, reject) => {
    lf.clear()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function length() {
  return new Promise((resolve, reject) => {
    lf.length()
      .then((numberOfKeys) => {
        resolve(numberOfKeys);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function key(keyIndex) {
  return new Promise((resolve, reject) => {
    lf.key(keyIndex)
      .then((keyName) => {
        resolve(keyName);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function keys() {
  return new Promise((resolve, reject) => {
    lf.keys()
      .then((keys) => {
        resolve(keys);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function iterate(callback) {
  if (isUndef(callback) || typeof callback !== "function")
    return Promise.reject("no valid callback provide");
  return new Promise((resolve, reject) => {
    lf.iterate(callback)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const localForage = {
  getItem,
  setItem,
  removeItem,
  clear,
  length,
  keys,
  iterate,
};

async function _checkLocalDBVersion() {
  const localSchemaVersion = await localForage.getItem(["schema-version"]);
  if (isUndef(localSchemaVersion) || localDBVersion !== localSchemaVersion) {
    await lf.clear();
    await lf.setItem("schema-version", localDBVersion);
    return true;
  }
  return false;
}

_checkLocalDBVersion().then(() => {});
