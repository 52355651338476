<template>
  <highcharts :options="gaugeOptions" :callback="gaugeRenderCallback" />
</template>

<script>
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import { Chart } from "highcharts-vue";

export default {
  name: "GaugeComponent",
  props: {
    icon: {
      type: String,
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 90,
    },
  },
  mixins: [WidgetBase],
  computed: {
    statusColor() {
      return this.status ? this.colors.primary : this.colors.disabledGray;
    },
    gaugeOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "solidgauge",
          ...this.calculateGaugeMetrics(),
          backgroundColor: "transparent",
        },
        pane: {
          background: {
            backgroundColor: this.colors.background,
            innerRadius: 0,
            outerRadius: "100%", //default 105%
            borderWidth: 0,
          },
        },
        yAxis: {
          min: 0,
          max: 1,
          minorTickInterval: null,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        colors: [this.statusColor],
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            innerRadius: "85%", //default 60
            name: "value",
            data: [1],
          },
        ],
      };
    },
  },
  methods: {
    calculateGaugeMetrics() {
      const sideLength = this.width;
      return {
        width: sideLength,
        height: sideLength,
        spacing: [0, 0, 0, 0],
      };
    },
    gaugeRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.renderCenterIcon();
      } catch (err) {
        console.error(err);
      }
    },
    renderCenterIcon() {
      const iconWidth = this.chartMetrics.width * 0.6;
      const iconHeight = this.chartMetrics.height * 0.5;
      const iconX = this.chartMetrics.centerX - iconWidth * 0.5;
      const iconY = this.chartMetrics.centerY - iconHeight * 0.5;

      this.renderIconS3(this.icon, iconX, iconY, iconWidth, iconHeight, {
        z: 0,
      }); // z = under gauge
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.gaugeRenderCallback);
  },
};
</script>

<style scoped></style>
