<template>
  <info-card :title="$t('common.statistics')">
    <template #1>
      <info-card-row
        :key="refreshKey"
        icon="mdi-history"
        :title="$t('common.registered')"
        :sub-title="lastSeenFor(gateway.created)"
      />
    </template>
  </info-card>
</template>

<script>
import InfoCard from "@components/base/InfoCard";
import InfoCardRow from "@components/base/InfoCardRow";
import { DateTimeConverter } from "@stienen/DateTimeConverter";

export default {
  name: "GatewayStatisticsCard",
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
    refreshKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    lastSeenFor(timestamp) {
      return timestamp
        ? DateTimeConverter.timeAgo(timestamp)
        : this.$t("common.notYet");
    },
  },
};
</script>

<style scoped></style>
