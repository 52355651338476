<script>
import Layout from "./LayoutEmpty";

export default {
  name: "RenderlessLayoutEmpty",
  created() {
    this.$parent.$emit("update:layout", Layout);
  },
  render() {
    return this.$slots.default[0];
  },
};
</script>
