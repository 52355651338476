import { apiClient, getCustomerId } from "@api/base-client";

const endPoint = process.env.VUE_APP_ROOT_WORKER_GATEWAY;

const workerClient = apiClient(
  `${endPoint}/workerApi/v1`,
  (response) => response.data
);
const fetchData = async (varRequests, start, end, interval) => {
  return await workerClient.post("/data", varRequests, {
    params: { start, end, interval },
    timeout: 5 * 60 * 1000,
  });
};

const fetchEventLog = async (deviceId, language = "en") => {
  return await workerClient.get(`/eventLog/${deviceId}?language=${language}`);
};
const fetchMetrics = async () => {
  const customerId = getCustomerId();
  return await workerClient.get(`/data/metrics/${customerId}`);
};

export const workerService = {
  fetchData,
  fetchEventLog,
  fetchMetrics,
};
