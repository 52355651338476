<template>
  <v-container fluid>
    <v-row class="d-flex justify-start">
      <v-select
        :items="stallen"
        v-model="selectedHouse"
        v-if="stallen.length > 1"
        return-object
        item-text="stal"
        :label="labelStal"
        dense
        style="max-width: 175px; margin-right: 24px"
      />
      <v-select
        :items="selectedHouse.afdelingen"
        v-if="selectedHouse"
        v-model="selectedValve"
        return-object
        item-text="nr"
        :label="labelNr"
        dense
        style="max-width: 175px"
      />
    </v-row>
    <v-row v-if="selectedValve">
      <RtDeviceScreen
        v-for="ventiel of selectedValve.ventielen"
        :key="ventiel.ventiel"
        :disposition="createDisp(ventiel)"
      />
    </v-row>
  </v-container>
</template>

<script>
import RtDeviceScreen from "@modules/rtcpu/render/RtDeviceScreen";
import { mapGetters } from "vuex";
import { isUndef } from "@utils/helpers";

export default {
  name: "RtValvesKFV",
  components: {
    RtDeviceScreen,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    screenId: {
      type: String,
      required: true,
    },
    prefix: {
      type: Array,
      required: false,
    },
    dispKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valveCount: 0,
      selectedValve: null,
      selectedHouse: null,
      stallen: [],
    };
  },
  watch: {
    selectedHouse: {
      handler(newValue) {
        if (newValue) {
          this.selectedValve =
            newValue.afdelingen.length > 0 ? newValue.afdelingen[0] : [];
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("deviceCache", ["getTranslation", "getValuesFor"]),
    labelNr() {
      return this.getTranslation({ dev: this.device, label: "" });
    },
    labelStal() {
      return this.getTranslation({ dev: this.device, label: "Stal" });
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      const { ALG_A_VENTIELEN: valveCount } = this.getValuesFor(this.device, [
        "ALG_A_VENTIELEN",
      ]);
      this.valveCount = valveCount;
      const varNames = this.getVarNames(valveCount);
      const data = this.getValuesFor(this.device, varNames);
      const reduced = this.processAfdelingen(varNames, data);
      this.setStallenToArray(reduced);
    },
    processAfdelingen(names, data) {
      const reduced = {};
      for (let i = 1; i <= this.valveCount; i++) {
        const stal = data[`V${i}_STAL`];
        const afd = data[`V${i}_AFD`];
        const nr = data[`V${i}_NR`];
        const ventiel = { ventiel: i, stal, afd, nr };
        if (!reduced.hasOwnProperty(stal)) {
          reduced[stal] = {};
        }
        if (!reduced[stal].hasOwnProperty(afd)) {
          reduced[stal][afd] = [];
        }
        reduced[stal][afd].push(ventiel);
      }
      return reduced;
    },
    setStallenToArray(data) {
      Object.keys(data).forEach((stal, idx) => {
        this.stallen.push({ stal: parseInt(stal) });
        const afdelingen = [];
        Object.keys(data[stal]).forEach((afdeling) => {
          let ventielen = data[stal][afdeling];
          ventielen = ventielen.sort(this.customSort);
          afdelingen.push({ nr: parseInt(afdeling), ventielen });
        });

        this.stallen[idx].afdelingen = afdelingen.sort(this.customSort);
      });
      this.selectedHouse = this.stallen.length > 0 ? this.stallen[0] : [];
    },
    getVarNames(count) {
      const names = [];

      for (let i = 1; i <= count; i++) {
        names.push("V" + i + "_STAL");
        names.push("V" + i + "_AFD");
        names.push("V" + i + "_NR");
      }

      return names;
    },
    createDisp(ventiel) {
      return {
        Type: this.screenId,
        Prefix: this.createPrefix(ventiel),
        Key: this.dispKey,
      };
    },
    createPrefix(item) {
      const { ventiel } = item;
      if (isUndef(ventiel)) return null;
      const prefs = this.prefix.map((p) => `${p}${ventiel}_`);
      return prefs.join(",");
    },
    createNVentielNames(nr) {
      return [`V${nr}_STAL`, `V${nr}_AFD`, `V${nr}_NR`];
    },
    customSort(a, b) {
      return a.nr < b.nr ? -1 : a.nr > b.nr ? 1 : 0;
    },
  },
};
</script>

<style scoped></style>
