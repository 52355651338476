<template>
  <img v-if="!error" :src="src" v-on="$listeners" @error="onError" />
  <v-icon v-else> mdi-image-broken </v-icon>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LcBaseIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedDevice"]),
    baseUrl() {
      const base = "/icons";
      return `${base}/${this.getSelectedDevice.hardware}/${this.getSelectedDevice.version}`;
    },
    src() {
      return `${this.baseUrl}/${this.name}.svg`;
    },
  },
  methods: {
    onError() {
      this.error = true;
      console.error(`LcIcon missing: ${this.src}`);
    },
  },
};
</script>

<style scoped></style>
