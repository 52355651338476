<template>
  <div id="container">
    <gauge-component
      id="gauge"
      :icon="iconName"
      :status="status"
      :width="width / 4"
    />
    <highcharts :options="chartOptions" :callback="heatingRenderCallback" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import GaugeComponent from "@modules/lccpu/components/widgets/GaugeComponent";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

//todo a lot of code can be shared with WidgetCoolingOnOff

const setPointValue = 50;
const hysteresisValue = 60;
const minValue = 0;
const maxValue = 100;

const typeEnum = {
  STANDARD: "STANDARD",
};

export default {
  name: "WidgetHeatingOnOff",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    hysteresis: {
      type: Number,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    isCurveEnabled: {
      //not used?
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    height: {
      type: Number,
      default: 272,
    },
  },
  mixins: [WidgetBase],
  computed: {
    iconName() {
      let color = "gray";
      if (this.isEnabled) {
        color = this.status ? "green" : "black";
      }
      return `wdg_heating_${color}`;
    },
    chartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "bullet",
          inverted: true,
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.colors.lightGray,
          ...this.calculateHeatingMetrics(),
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          gridLineWidth: 0,
          min: minValue,
          max: maxValue,
          title: false,
          plotBands: [
            {
              from: -Infinity,
              to: this.status ? hysteresisValue : setPointValue,
              color: this.isEnabled
                ? this.colors.lightGreen
                : this.colors.darkGray,
            },
          ],
        },
        plotOptions: {
          bullet: {
            color: "transparent",
            borderColor: "transparent",
          },
        },
        series: [setPointValue],
      };
    },
  },
  methods: {
    calculateHeatingMetrics() {
      const margin = [
        this.height / 1.5,
        this.width * 0.1,
        this.height / 5.4,
        this.width * 0.1,
      ];
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin,
      };
    },
    heatingRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 5;
        //max 5 ? educated guess
        this.strokeWidth = Math.min(this.plotMetrics.height / 20, 5);

        this.renderBorders();
        this.renderLightGrayBackground();
        this.renderSetPointAndHysteresis();

        if (this.isEnabled) {
          this.renderActualMeasurement();
        }
      } catch (err) {
        console.error(err);
      }
    },
    renderBorders() {
      //todo this is duplicate
      //edged stick out 10% of the smallest (width or height)
      //can also be done by highcharts but without sticking out
      const offWidth =
        this.plotMetrics.width /
        10 /
        Math.max(this.plotMetrics.width / this.plotMetrics.height, 1);
      const offHeight =
        this.plotMetrics.height /
        10 /
        Math.max(this.plotMetrics.height / this.plotMetrics.width, 1);
      const borderPath = [
        "M",
        this.plotMetrics.x,
        this.plotMetrics.y - offHeight,
        "L",
        this.plotMetrics.x,
        this.plotMetrics.endY + offHeight,
        "M",
        this.plotMetrics.x - offWidth,
        this.plotMetrics.endY,
        "L",
        this.plotMetrics.endX,
        this.plotMetrics.endY,
      ];
      this.renderLine(borderPath, { color: this.colors.disabledGray });
    },
    renderLightGrayBackground() {
      //render light gray 'background'
      const backgroundX = this.width * 0.04;
      const backgroundY = this.height * 0.25;
      const backgroundWith = this.width * 0.92;
      const backgroundHeight = this.height * 0.65;

      this.renderRectangle(
        backgroundX,
        backgroundY,
        backgroundWith,
        backgroundHeight,
        {
          color: this.colors.lightGray,
          z: -1,
        }
      );
    },
    renderSetPointAndHysteresis() {
      //one value on the graph in pixels
      let setPointX = this.translateX(setPointValue);
      let hysteresisX = this.translateX(hysteresisValue);

      let darkLine;
      let dottedLine;

      //meaning which line is dashed and which is green (or gray when disabled)
      if (this.status) {
        darkLine = [
          "M",
          this.plotMetrics.x,
          this.plotMetrics.y,
          "L",
          hysteresisX,
          this.plotMetrics.y,
          "L",
          hysteresisX,
          this.plotMetrics.endY,
          "L",
          this.plotMetrics.endX,
          this.plotMetrics.endY,
        ];
        dottedLine = [
          "M",
          setPointX,
          this.plotMetrics.y,
          "L",
          setPointX,
          this.plotMetrics.endY,
        ];
      } else {
        darkLine = [
          "M",
          this.plotMetrics.x,
          this.plotMetrics.y,
          "L",
          setPointX,
          this.plotMetrics.y,
          "L",
          setPointX,
          this.plotMetrics.endY,
          "L",
          this.plotMetrics.endX,
          this.plotMetrics.endY,
        ];
        dottedLine = [
          "M",
          setPointX,
          this.plotMetrics.y,
          "L",
          hysteresisX,
          this.plotMetrics.y,
          "L",
          hysteresisX,
          this.plotMetrics.endY,
        ];
      }

      const darkLineColor = this.isEnabled
        ? this.colors.darkGreen
        : this.colors.disabledGray;

      this.renderLine(darkLine, { color: darkLineColor });

      const dashInterval = this.plotMetrics.height / 10;

      const dottedLineColor = this.isEnabled
        ? this.colors.darkGray
        : this.colors.disabledGray;
      this.renderLine(dottedLine, {
        color: dottedLineColor,
        dash: dashInterval,
      });

      if (this.isEnabled) {
        const targetText = `${this.setpoint.formatted}${this.units.DEGREE}`;

        const afterTargetX = this.renderTextCentered(
          targetText,
          setPointX,
          this.plotMetrics.endY * 1.01
        );

        const radius = this.charWidth * 0.5;
        const circleX = afterTargetX + radius * 2;
        const circleY = this.plotMetrics.endY + radius * 2;
        this.renderCircle(circleX, circleY, radius);
      }
    },
    renderActualMeasurement() {
      const actualMeasurement = this.actualMeasurement.raw;
      const setPoint = this.setpoint.raw;
      const hysteresis = this.hysteresis;
      // Dit is overgenomen van embedded om de grafiek 100% overeen te laten komen

      // Hysterese in afbeelding is fixed, daarom dient de weergave hier
      // hiertussen lineair te zijn. Buiten de hysterese is de weergave
      // niet lineair. Dit omdat het bereik van de weergave anders te
      // beperkt is.

      // de squareRootFactor (42, magisch getal op pld) moet delen door 3 (14)
      const squareRootFactor = 14;

      let diff;
      if (actualMeasurement < setPoint) {
        const squareRoot = Math.sqrt(setPoint - actualMeasurement + 1);
        diff = -(squareRootFactor * squareRoot - squareRootFactor);
      } else if (actualMeasurement <= setPoint + hysteresis) {
        diff =
          ((actualMeasurement - setPoint) * (hysteresisValue - setPointValue)) /
          hysteresis;
      } else {
        const squareRoot = Math.sqrt(
          actualMeasurement - setPoint + hysteresis + 1
        );
        diff =
          squareRootFactor * squareRoot -
          squareRootFactor +
          (hysteresisValue - setPointValue);
      }

      const relativeValue = setPointValue + diff;
      const absoluteActualMeasurementX = this.translateX(relativeValue);

      const actualMeasurementX = this.minMax(
        absoluteActualMeasurementX,
        this.plotMetrics.x,
        this.plotMetrics.endX
      );

      //line sticks out 20%
      const actualMeasurementStartY =
        this.plotMetrics.y - this.plotMetrics.height * 0.2;
      const actualMeasurementLine = [
        "M",
        actualMeasurementX,
        actualMeasurementStartY,
        "L",
        actualMeasurementX,
        this.plotMetrics.endY,
      ];

      this.renderLine(actualMeasurementLine, { color: this.colors.primary });

      //drawing the text (actual and target)
      const actualText = `${this.actualMeasurement.formatted}${this.units.DEGREE}`;
      this.renderTextCentered(
        actualText,
        actualMeasurementX,
        actualMeasurementStartY - this.charWidth * 2
      );
    },
  },
  components: {
    GaugeComponent,
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.heatingRenderCallback);
  },
};
</script>

<style scoped>
#container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

#gauge {
  position: absolute;
  top: 9%;
  z-index: 1;
  left: 37.5%;
  /*zelfde dit is center*/
  /*right: 37.5%;*/
}
</style>
