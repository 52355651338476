import { render, staticRenderFns } from "./SetupLayoutPortrait.vue?vue&type=template&id=a3a33ecc&scoped=true&"
import script from "./SetupLayoutPortrait.vue?vue&type=script&lang=js&"
export * from "./SetupLayoutPortrait.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3a33ecc",
  null
  
)

export default component.exports