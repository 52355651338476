<template>
  <div class="pt-2" style="transform: translate(18px)">
    <span
      v-if="hasChildren"
      :class="[isOpen ? 'mdi-minus' : 'mdi-plus', 'icon mdi']"
      @click="clickHandler"
    >
    </span>

    <v-hover v-slot="{ hover }">
      <a :style="styleObject(hover)" @click="clickHandler">
        <span :style="underlineStyle">{{ text }}</span>
        <span v-if="warning" style="color: #ffbb33"> ⚠</span>
      </a>
    </v-hover>

    <span v-show="isOpen">
      <slot />
    </span>

    <v-chip v-if="notification > 0" class="ms-2" color="error" small>
      {{ notification }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "StaticNode",
  props: {
    text: {
      type: [String, Number],
      required: false,
      default: "node",
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    notification: {
      type: Number,
      required: false,
      default: 0,
    },
    initialOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.initialOpen,
    };
  },
  computed: {
    hasChildren() {
      return !!this.$slots.default?.length;
    },
    underlineStyle() {
      return {
        "text-decoration": this.underline ? "underline" : "none",
      };
    },
  },
  methods: {
    styleObject(hover) {
      return {
        color: hover ? "white" : "#D3D3D3",
        "text-decoration": hover ? "underline" : "none",
      };
    },
    clickHandler() {
      if (this.locked) return;
      this.isOpen = !this.isOpen;
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped></style>
