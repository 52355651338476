<template>
  <Promised :pending-delay="0" :promise="getScreen" :class="screenTypeClass">
    <template #pending>
      <base-spinner />
    </template>

    <template #default="screen">
      <v-container class="ma-0">
        <v-row no-gutters>
          <v-col>
            <RefreshingRtBuildTemplate
              :screenState="screen.state"
              :mappedValues="screen.mappedValues"
            />
          </v-col>
          <v-col cols="5" class="justify-end" style="min-width: 500px">
            <rt-last24-hour
              v-if="getLast24HourEnabled"
              :device="getSelectedDevice"
              :history-vars="getHistoryVars"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #rejected="error">
      <rt-error-card>
        {{ $t(error) }}
      </rt-error-card>
    </template>
  </Promised>
</template>

<script>
import { mapGetters } from "vuex";
import RtLast24Hour from "@modules/rtcpu/components/RtLast24Hour";

export default {
  name: "RtDeviceScreen",
  components: { RtLast24Hour },
  props: {
    disposition: {
      type: [Object, String],
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getSelectedDevice"]),
    ...mapGetters("deviceCache", ["getHistoryVars"]),
    ...mapGetters("rtRemote", ["getLast24HourEnabled"]),
    getScreen() {
      return this.$store.dispatch("rtRemote/rtSelectScreen", {
        disposition: this.disposition,
        device: this.getSelectedDevice,
      });
    },
    screenTypeClass() {
      return this.disposition.isCustomScreen ? "custom-screen" : "screen";
    },
  },
};
</script>

<!--does not work with @import-->
<style scoped src="../../../../public/assets/css/screen.css" />
<style scoped src="../../../../public/assets/css/screen.custom.css" />

<!--does not work with src-->
<style scoped>
@import "../../../../public/assets/css/screen.base.css";
</style>
