import { apiClient } from "@api/base-client";

const k8sEndpoint = process.env.VUE_APP_ROOT_STATE_GATEWAY;
const stateClient = apiClient(
  `${k8sEndpoint}/stateApi/v1`,
  (response) => response.data
);

export async function fetchState(deviceId) {
  return await stateClient.get(`valueRecords/${deviceId}/full`);
}
