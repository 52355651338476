<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="columns">
        <v-card outlined>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-icon size="40">mdi-emoticon-sad-outline</v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-h6 text-center">
                {{ $t("common.somethingWrong") }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-subtitle-1 red--text text-center">
                <slot>
                  {{ $t("error.unknown") }}
                </slot>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="text-body-2 text-center mt-6 py-0 grey--text text--darken-1"
              >
                <p>{{ $t("error.writeUs") }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-center mt-2">
                <base-button @click="onClick">
                  <v-icon class="mr-2">mdi-arrow-left</v-icon>
                  {{ $t("common.goBack") }}
                </base-button>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LcErrorCard",
  computed: {
    ...mapGetters("layout", ["isDesktop"]),
    columns() {
      return this.isDesktop ? 4 : 12;
    },
  },
  methods: {
    onClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
