import * as types from "./mutation-types";
import { notifyUser } from "@error/helpers";
import { adminService } from "@api/admin-service";
import { roles } from "@utils/permissions";

async function init({ commit, dispatch, rootGetters }) {
  try {
    dispatch("setLoading", { value: true }, { root: true });
    const { userInvites } =
      rootGetters["auth/getImpersonatedCustomer"] ||
      rootGetters["auth/getCustomerAccount"];

    for (const u of userInvites) {
      u.edit = roles.getEditByRole(u.role);

      //no need to fetch when already valid
      if (!u.invitationValid) {
        u.invitationValid = await adminService.fetchUserStatus(u.userId);
      }
    }

    commit(types.SET_USER_INVITES, userInvites);
  } catch (err) {
    console.error(err);
  } finally {
    dispatch("setLoading", { value: false }, { root: true });
  }
}

async function updateUser({ commit }, user) {
  try {
    const userInvite = await adminService.updateUser(user);
    userInvite.edit = roles.getEditByRole(userInvite.role);
    userInvite.invitationValid = await adminService.fetchUserStatus(
      userInvite.userId
    );
    commit(types.UPDATE_USER_INVITE, userInvite);
    return userInvite;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function deleteUser({ commit }, { userId }) {
  try {
    const deletedUser = await adminService.deleteUser(userId);
    commit(types.DELETE_USER, deletedUser);
  } catch (err) {
    console.error(err);
  }
}

async function resendInvite(state, { userId }) {
  try {
    await adminService.postResendInvite(userId);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function initializeDealers({ commit }) {
  try {
    const dealers = await adminService.fetchDealers();
    commit(types.SET_DEALERS, dealers);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function inviteDealer({ commit }, email) {
  try {
    const dealer = await adminService.inviteDealer(email);
    commit(types.SET_DEALER, dealer);
  } catch (err) {
    console.error(err);
    notifyUser("warning", "could not invite " + email);
  }
}

async function revokeDealer({ commit }, dealer) {
  try {
    const response = await adminService.revokeDealer(dealer.id);
    if (response.dealerId) {
      commit(types.REMOVE_DEALER, dealer);
      return response;
    }
  } catch (err) {
    console.error(err);
    notifyUser("warning", "could not delete " + dealer.email);
  }
  return false;
}

async function updateDealer({ commit }, dealer) {
  try {
    const response = await adminService.updateDealer(dealer.id, dealer.active);
    if (response) {
      commit(types.SET_DEALER, response);
      return response;
    }
  } catch (err) {
    console.error(err);
    notifyUser("warning", "could not edit " + dealer.email);
  }
  return false;
}

export default {
  init,
  updateUser,
  deleteUser,
  resendInvite,

  initializeDealers,
  inviteDealer,
  revokeDealer,
  updateDealer,
};
