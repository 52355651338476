<template>
  <v-main>
    <v-container fluid class="fill-height background pa-0">
      <slot />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "LayoutEmpty",
};
</script>

<style scoped>
.background {
  background-image: url("~@/assets/brand/Background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>
