var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldShow)?_c('v-footer',{staticClass:"nav-footer",attrs:{"app":"","inset":"","padless":""}},[_c('v-slide-group',{staticClass:"slide-group",attrs:{"show-arrows":""}},_vm._l((_vm.getDevicesOnSelectedGateway),function(item,index){return _c('v-slide-item',{key:index},[_c('div',{staticClass:"disable-select",class:{
          unlocked: item.isLicenced,
          locked: !item.isLicenced,
          selected: _vm.isSelected(item),
        },on:{"click":() => _vm.onDeviceChangeHandler(item)}},[_c('span',{staticClass:"mdi icon",class:{
            'mdi-devices': item.isLicenced,
            'mdi-lock': !item.isLicenced,
          }}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.name))])])])}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }