<template>
  <div :class="containerClass" class="disable-select">
    <div
      :class="[
        buttonClass,
        isHome ? 'lc-nav-button-home-blue' : 'lc-nav-button-home-green',
      ]"
    />
    <LcBaseIcon
      v-if="showPrevious"
      :name="getImgPath('button_arrow_previous')"
      class="lc-nav-previous"
    />
    <LcBaseIcon
      v-if="isNext"
      :name="getImgPath('LC-Nav-Next')"
      class="lc-nav-next"
    />
    <LcBaseIcon
      v-if="isAlarm"
      :name="getImgPath('dot_red')"
      class="lc-nav-notify"
    />
    <LcBaseIcon :name="getImgPath()" class="lc-nav-content" />
    <div @click="navigate" :class="clickClass" />
  </div>
</template>

<script>
import { isDef } from "@utils/helpers";
import LcBaseIcon from "@modules/lccpu/components/LcBaseIcon";
import { mapGetters } from "vuex";

export default {
  name: "LcNavButton",
  components: {
    LcBaseIcon,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isHome: {
      type: Boolean,
      required: true,
    },
    isBack: {
      type: Boolean,
      required: true,
    },
    isNext: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAlarm: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    isBlink: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    isEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Number,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      backButtonIcon: "",
    };
  },
  computed: {
    ...mapGetters("layout", ["isDesktop"]),
    showPrevious() {
      return this.isBack || this.isHome;
    },
    containerClass() {
      return !this.isDesktop ? "lc-nav-container-small" : "lc-nav-container";
    },
    clickClass() {
      return !this.isDesktop ? "lc-nav-click-small" : "lc-nav-click";
    },
    buttonClass() {
      return !this.isDesktop ? "lc-nav-button-small" : "lc-nav-button";
    },
  },
  methods: {
    navigate() {
      if (this.isBack) {
        this.$router.go(-1);
      } else {
        this.$helpers.pushRoute({
          name: "lc-remote",
          params: {
            screenId: this.id,
          },
          query: {
            backButtonIcon: this.icon,
          },
        });
      }
    },
    getImgPath(imgName) {
      let icon;
      if (isDef(imgName)) {
        icon = imgName;
      } else if (this.isHome) {
        icon = "nav_home";
      } else if (this.isBack) {
        icon = this.backButtonIcon;
      } else {
        icon = this.icon;
      }

      return `${icon}`;
    },
  },
  created() {
    this.backButtonIcon = this.$route.query.backButtonIcon;
  },
};
</script>

<style scoped>
.lc-nav-container,
.lc-nav-container-small {
  display: grid;
  background-color: #007c60;
}

.lc-nav-container {
  width: 104px;
  height: 86px;
  grid-template-columns: 11px 4px 10px 50px 2px 8px 2px 4px 13px;
  grid-template-rows: 12px 5px 4px 45px 8px 12px;
}

.lc-nav-container-small {
  width: 78px;
  height: 64px;
  grid-template-columns: 8px 3px 7px 37px 2px 6px 2px 3px 10px;
  grid-template-rows: 9px 4px 3px 33px 6px 9px;
}

.lc-nav-button,
.lc-nav-button-small {
  grid-column: 2;
  grid-row: 2;
}

.lc-nav-button {
  width: 80px;
  height: 62px;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
}

.lc-nav-button-small {
  width: 60px;
  height: 46px;
  border-radius: 7px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4);
}

.lc-nav-button-home-green {
  background-color: #006b56;
}

.lc-nav-button-home-blue {
  background-color: #005058;
}

.lc-nav-previous,
.lc-nav-content,
.lc-nav-next,
.lc-nav-notify {
  width: 100%;
}

.lc-nav-previous {
  grid-column: 3;
  grid-row: 4;
}

.lc-nav-content {
  grid-column: 4;
  grid-row: 4;
}

.lc-nav-next {
  grid-column: 6;
  grid-row: 4;
}

.lc-nav-notify {
  grid-column: 6;
  grid-row: 3;
}

.lc-nav-click,
.lc-nav-click-small {
  grid-column: 2;
  grid-row: 2;
  z-index: 2;
}

.lc-nav-click {
  width: 80px;
  height: 62px;
  border-radius: 10px;
}

.lc-nav-click-small {
  width: 60px;
  height: 46px;
  border-radius: 7px;
}

.lc-nav-click:hover,
.lc-nav-click-small:hover {
  background-color: black;
  opacity: 0.1;
  cursor: pointer;
}
</style>
