import * as types from "./mutation-types";
import { adminService } from "@api/admin-service";
import { notifyUser } from "@error/helpers";

async function init({ commit }) {
  commit(types.CLEAR_MECHANICS);
  const mechanics = await adminService.fetchMechanics();
  commit(types.SET_MECHANICS, mechanics);
}

async function addMechanic({ commit }, email) {
  try {
    const mechanic = await adminService.addMechanic(email);
    commit(types.SET_MECHANIC, mechanic);
  } catch (err) {
    console.error(err);
    notifyUser("warning", "could not invite " + email);
  }
}

async function deleteMechanic({ commit }, mechanic) {
  try {
    const response = await adminService.deleteMechanic(mechanic.id);
    if (response.success) {
      commit(types.REMOVE_MECHANIC, mechanic);
      return response;
    }
  } catch (err) {
    console.error(err);
    notifyUser("warning", "could not delete " + mechanic.email);
  }
  return false;
}

export default {
  init,
  addMechanic,
  deleteMechanic,
};
