<template>
  <v-dialog v-model="show" persistent max-width="325">
    <v-card>
      <v-card-title>
        {{ $t("common.confirm") }}
      </v-card-title>
      <v-card-text>
        {{ $t("editMode.disclaimer") }}
      </v-card-text>

      <v-card-actions>
        <base-button text outlined @click.native="discard">
          {{ $t("common.no") }}
        </base-button>
        <v-spacer />
        <base-button @click.native="accept">
          {{ $t("common.yes") }}
        </base-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmEditDialog",
  props: {
    show: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    accept() {
      this.$store.dispatch("edit/confirm", "resolve", { root: true });
    },
    discard() {
      this.$store.dispatch("edit/confirm", "reject", { root: true });
    },
  },
};
</script>

<style scoped></style>
