import {Util} from "@utils/Util";
import {saveAs} from 'file-saver';

export function createCsv(rows, caption, deviceName, gatewayName) {
    const stamp = Util.ISO8601Part(1).replace(/:/g, '.');
    if (caption == null) {
        caption = "table";
    }

    const fileName = `${gatewayName}_${deviceName}_${caption}_${stamp}.csv`;
    const charset = 'utf-8';
    const data = new Blob(
        [rows],
        {type: 'text/csv;charset=' + charset + ';'}
    );
    saveAs(data, fileName);
}
