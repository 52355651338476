export const roles = (() => {
  const types = [
    {
      name: "service",
      displayName: "Service",
      level: 100,
      withoutEdit: 200,
      icon: "mdi-shield-crown"
    },
    {
      name: "admin",
      displayName: "Admin",
      level: 300,
      withoutEdit: 300,
      icon: "mdi-shield-crown-outline"
    },
    {
      name: "install",
      displayName: "Install",
      level: 400,
      withoutEdit: 500,
      icon: "mdi-chevron-triple-up"
    },
    {
      name: "user",
      displayName: "User",
      level: 700,
      withoutEdit: 800,
      icon: "mdi-chevron-double-up"
    },
    {
      name: "municipal",
      displayName: "Municipal",
      level: 900,
      withoutEdit: 900,
      icon: "mdi-chevron-up"
    }
  ];

  function getRoleByValue(roleInt) {
    return types.find(role => role.level === roleInt || role.withoutEdit === roleInt);
  }

  function getEditByRole(role) {
    return ![200, 500, 800, 900].includes(role);
  }

  function getAvailableRoles(userRole) {
    return types.filter(role => userRole < role.level);
  }

  return {
    getAvailableRoles,
    getRoleByValue,
    getEditByRole
  };
})();
