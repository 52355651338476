<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="settings.timeout"
    :color="notification.color"
    :elevation="settings.elevation"
    top
  >
    <v-row class="align-center">
      <v-col cols="2">
        <v-icon color="black">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="6" style="color: black">
        <span> {{ notification.text }} </span>
      </v-col>
      <v-spacer />
      <v-col>
        <base-button dark text @click="hide" color="black">
          <v-icon>mdi-close</v-icon>
        </base-button>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NotificationSnackbar",
  props: {
    index: {
      type: Number,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapState("settings", {
      settings: (state) => state.notifications,
    }),
    snackbar: {
      get: function () {
        return this.show;
      },
      set: function () {
        this.$store.dispatch(
          "errorService/hideNotification",
          this.notification.index,
          { root: true }
        );
      },
    },
    icon() {
      switch (this.notification.color) {
        case "warning":
          return "mdi-alert-circle-outline";
        case "error":
          return "mdi-alert-decagram-outline";
        default:
          return "mdi-check-circle-outline";
      }
    },
  },
  methods: {
    hide() {
      this.snackbar = false;
    },
  },
};
</script>

<style scoped></style>
