<template>
  <layout-default>
    <base-card
      :title="title"
      icon="mdi-database-plus"
      @click="createBackup"
      :disabled="loading"
    >
      <v-data-table
        :headers="backupHeaders"
        :items="reversed"
        :loading="loading"
        :hide-default-footer="backups.length < 11"
        fixed-header
        dense
      >
        <template #item.Created="{ item }">
          {{ formatDate(item.lastModified) }}
        </template>

        <template #item.Actions="{ item }">
          <a @click="download(item)">
            {{ $t("common.download") }}
          </a>
        </template>
      </v-data-table>
    </base-card>
  </layout-default>
</template>

<script>
import { dataService } from "@api/data-service";
import { mapGetters } from "vuex";
import { notifyUserTranslation } from "@error/helpers";

export default {
  name: "BackupPage",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      backups: [],
      backupHeaders: [
        {
          text: this.$t("common.created"),
          sortable: false,
          value: "Created",
        },
        {
          text: this.$t("common.actions"),
          sortable: false,
          value: "Actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("settings", ["getLanguage"]),
    reversed() {
      return this.backups.slice().reverse();
    },
    title() {
      return this.$t("backups.backupsDevice", {
        deviceName: this.device?.name,
      });
    },
  },
  methods: {
    async init() {
      if (!this.device?.id) {
        return;
      }
      this.loading = true;
      this.backups = await dataService.fetchBackups(this.device.id);
      this.loading = false;
    },
    async createBackup() {
      this.loading = true;
      try {
        notifyUserTranslation("backups.creating", "info");
        const backup = await dataService.createBackup(this.device.id);
        this.backups.push(backup);
        notifyUserTranslation("backups.success", "info");
      } catch (err) {
        console.error(err);
        notifyUserTranslation("backups.error", "error");
      } finally {
        this.loading = false;
      }
    },
    async download(backup) {
      const blob = await dataService.downloadBackup(backup.key);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.device.name}-backup.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString(this.getLanguage);
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped></style>
