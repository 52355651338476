<template>
  <div
    @click="navigate"
    :style="{ color: $vuetify.theme.currentTheme.primary }"
    style="cursor: pointer"
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RtDisposition",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getSelectedDevice"]),
    ...mapGetters("deviceCache", ["getTree"]),
  },
  methods: {
    async navigate() {
      const tree = this.getTree(this.getSelectedDevice.id);
      let disposition = tree.rawDispositions.find(
        (disposition) => disposition.Prefix === this.link
      );
      await this.$store.dispatch("rtRemote/onScreenSelect", disposition);
      await this.$store.dispatch("rtRemote/retryLoadTree");
    },
  },
};
</script>

<style scoped></style>
