import Vue from "vue";

const withActiveRefresh = (component) => {
  return Vue.component("withActiveRefresh", {
    render(h) {
      return h(component, {
        props: { ...this.$props },
        on: { ...this.$listeners },
        attrs: this.$attrs,
      });
    },
    props: component.props || [],
    mounted() {
      // register values for the request manager
      Object.entries(this.mappedValues).forEach(([deviceId, mapped]) => {
        this.$store.dispatch(
          "deviceCache/modifyRefreshVars",
          {
            deviceId,
            varNames: mapped.varNames,
            action: "ADD",
          },
          { root: true }
        );
      });
    },
    beforeDestroy() {
      // unregister values
      Object.entries(this.mappedValues).forEach(([deviceId, mapped]) => {
        this.$store.dispatch(
          "deviceCache/modifyRefreshVars",
          {
            deviceId,
            varNames: mapped.varNames,
            action: "REMOVE",
          },
          { root: true }
        );
      });
    },
  });
};

export default withActiveRefresh;
