import {Core} from './Core'

function draw_rect(canvas, x, y, width, height, color, opaq, rounding) {
  return canvas.rect(x, y, width, height, (rounding * (Math.min(width, height) / 2))).attr({
    fill: color,
    opacity: opaq
  });
}

function GetNofObj(obj) {
  let count = 0;

  for (let id in obj) {
    if (obj.hasOwnProperty(id))
      ++count;
  }

  return count;
}


//*** Led *************

/*  loc.Extra:=
 *	-  opaq         = Led Opacity			mandatory		[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  color	= Led Color			default: 'gray'		[ white, silver, gray, black, red, maroon, yellow, olive, lime, green, aqua, teal, blue, navy, fuchsia, purple ]
 *	-  rounding	= Led corner rounding		default: 1		[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 */
function led(target, attr, loc, values) {
  let canvas = Core.createCanvas(target, attr, 30, 30),
    width = canvas.width,
    height = canvas.height,
    sw = Math.max(0.5, Math.min(width, height) / 25),
    colour = values[1],
    rounding = values[2] || 1,  //default circle
    draw_width = width - sw * 2,
    draw_height = height - sw * 2;
  const opaq = (values[0].isAction && values[0].result.ok) ? values[0].result.data : 0;
  canvas.rect(sw, sw, draw_width, draw_height, rounding * (Math.min(draw_width, draw_height) / 2)).attr({
    fill: 'gray',
    opacity: 1
  });
  const led = draw_rect(canvas, sw, sw, draw_width, draw_height, colour, opaq, rounding);
  const opaqCallback = Core.setAttr.bind({Target: led, Attr: 'opacity'});
  values[0].registerCallback(opaqCallback);
}

//*** Led *************

//*** Ledbar Running leds *************

function rotateLeds(LedSpeed, RUNleds) {
  let LedBar = RUNleds.LedBar,
    TimerID = RUNleds.TimerID,
    k = GetNofObj(LedBar) - 1,
    keep = LedBar[k].attr('opacity');

  if (TimerID != null) {
    clearTimeout(TimerID);
    TimerID = null
  }

  if (LedSpeed > 0) {

    while (--k >= 0) LedBar[k + 1].attr({'opacity': LedBar[k].attr('opacity')});  //set current+1 led's opacity as current one
    LedBar[0].attr({'opacity': keep});  //set first led's opacity as last led's opacity
    TimerID = setTimeout(rotateLeds, LedSpeed * 1000 / GetNofObj(LedBar), LedSpeed, RUNleds);
  }
}

function draw_RUNleds(canvas, width, height, size, NOFleds, LedColor, LedOpaq, LedRounding, LedsPattern) {
  let LedWidth = (width - ((NOFleds + 3) * size)) / NOFleds,
    LedHeight = height - (size * 4),
    LedsArray = {},
    i = NOFleds,
    opacity,opaq;

  while (--i >= 0) {
    opaq = LedsPattern.substr(i, 1) == '1' ? LedOpaq : 0;
    LedsArray[i] = draw_rect(canvas, (size * 2) + (i * (LedWidth + size)), size * 2, LedWidth, LedHeight, LedColor, opaq, LedRounding)
  }

  return LedsArray;
}

/*  loc.Extra:=
 *	-  LedSpeed     = Speed with which the Pattern of Leds run across the bar [in seconds]		mandatory
 *	-  LBcolor	= LedBar Color						default: 'gray'			[ white, silver, gray, black, red, maroon, yellow, olive, lime, green, aqua, teal, blue, navy, fuchsia, purple ]
 *	-  LBopaq	= LedBar Opacity					default: 0.5			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  LBrounding	= LedBar corner rounding				default: 0.1			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  NOFleds      = Number of Leds on the LedBar				default: 20
 *	-  LedColor     = Color of the Leds					default: red
 *	-  LedOpaq	= Led Opacity active leds				default: 1			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  LedRounding	= Led corner rounding					default: 0.1			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  LedsPattern  = Pattern of the Leds lit running accross the LedBar	default: 111    e.g. 3 = ..11.. ; 7 = ..111.. ; 27 = ..11.11..
 */
function ledbar(target, attr, loc, values) {
  let canvas = Core.createCanvas(target, attr, 200, 30),
    width = canvas.width,
    height = canvas.height,
    sw = Math.max(0.5, Math.min(width, height) / 25),
    text = loc.Extra.split(','),
    LedSpeed = values[0],
    LBcolor = text[1] || 'gray',
    LBopaq = +text[2] || 0.5,
    LBrounding = +text[3] || 0.1,
    NOFleds = +text[4] || 20,
    LedColor = text[5] || 'red',
    LedOpaq = +text[6] || 1,
    LedRounding = +text[7] || 0.1,
    LedsPattern = +text[8] || 7,
    RUNleds = {},
    i;

  LedsPattern = LedsPattern.toString(2);
  i = NOFleds - LedsPattern.length;
  while (i-- > 0) LedsPattern = LedsPattern + '0';

  draw_rect(canvas, sw, sw, width - sw * 2, height - sw * 2, LBcolor, LBopaq, LBrounding);
  RUNleds.LedBar = draw_RUNleds(canvas, width, height, sw, NOFleds, LedColor, LedOpaq, LedRounding, LedsPattern);
  RUNleds.TimerID = null;

  if (isNaN(LedSpeed)) {
    const LedSpeed = rotateLeds.bind(RUNleds);
    values[0].registerCallback(LedSpeed);
  }
  else {
    rotateLeds(LedSpeed, RUNleds);
  }
}

//*** Ledbar Running leds *************


//*** Ledbar VU *************

function ShowPercentage(Percentage) {
  const VUleds = this;
  if (Percentage < 0) Percentage = 0;
  if (Percentage > 1) Percentage = 1;
  const ShowLeds = Math.ceil(GetNofObj(VUleds) * Percentage);

  let i = -1;
  while (++i < ShowLeds) VUleds[i].attr({'opacity': 1});          //set leds 1 to ShowLeds to opacity = 1
  while (++i < VUleds.length - 1) VUleds[i].attr({'opacity': 0});   //set the rest to opacity = 0
}

function draw_VUleds(canvas, width, height, size, ColorCount, rounding) {
  let NOFleds = 0,
    LedWidth = 0,
    LedHeight = 0,
    h = 0,
    i = ColorCount.length - 1,
    LedsArray = {};

  while (i > 0) {
    NOFleds = NOFleds + parseInt(ColorCount[i]);
    i -= 2
  }
  LedWidth = (width - ((NOFleds + 3) * size)) / NOFleds;
  LedHeight = height - (size * 4);
  i = ColorCount.length - 1;

  while (i > 0) {
    h = parseInt(ColorCount[i]);
    while (h-- > 0) {
      LedsArray[--NOFleds] = draw_rect(canvas, (size * 2) + ((NOFleds) * (LedWidth + size)), size * 2, LedWidth, LedHeight, ColorCount[i - 1], 0, rounding)
    }
    i -= 2
  }
  return LedsArray;
}

/*  loc.Extra:=
 *	-  Percentage	= percentage of total leds visible starting from low range towards high range		mandatory
 *	-  LBcolor	= LedBar Color				default: 'gray'			[ white, silver, gray, black, red, maroon, yellow, olive, lime, green, aqua, teal, blue, navy, fuchsia, purple ]
 *	-  LBopaq	= LedBar Opacity			default: 0.5			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  LBrounding	= LedBar corner rounding		default: 0.1			[ 0, 0.1, 0.2, ... , 0.9, 1 ]
 *	-  ColorCount   = Array of colors and number of leds    deafult: ['lime','5','yellow','8','red','5']
 */
function ledvu(target, attr, loc, values) {
  let canvas = Core.createCanvas(target, attr, 200, 30),
    width = canvas.width,
    height = canvas.height,
    sw = Math.max(0.5, Math.min(width, height) / 25),
    text = loc.Extra.split(','),
    Percentage = isNaN(text[0]) ? values[0] : +text[0],
    LBcolor = text[1] || 'gray',
    LBopaq = +text[2] || 0.5,
    LBrounding = +text[3] || 0.1,
    ColorCount = text.slice(4),
    ledbar_VU,
    VUleds = {};

  if (ColorCount.length === 0) ColorCount = ['lime', '7', 'yellow', '10', 'red', '7'];

  draw_rect(canvas, sw, sw, width - sw * 2, height - sw * 2, LBcolor, LBopaq, LBrounding);
  VUleds = draw_VUleds(canvas, width, height, sw, ColorCount, LBrounding);

  const show = ShowPercentage.bind(VUleds);
  if (isNaN(Percentage) && Percentage.hasOwnProperty('subject')) {
    Percentage.registerCallback(show)
  }
  else {
    show(Percentage);
  }
}

export {led, ledbar, ledvu}
