const clockTypes = {
  off: 0,
  temperature: 1,
  ventilation: 2,
  pressure: 3,
  humidity: 4,
  THI: 5,
  CO2: 6,
  light: 7,
  water: 8,
  feed: 9,
  "water/feed": 10,
  "feed/water": 11,
  NH3: 12,
  windchill: 13,
  airspeed: 14,
};

export const clockConfiguration = {
  [clockTypes.off]: {},
  [clockTypes.temperature]: {
    decimals: 1,
    div: 100,
    unitLabel: "graadC",
    titleLabel: "clk_staltemperatuur",
    alarmZoneActive: true,
  },
  [clockTypes.ventilation]: {
    decimals: 0,
    div: 100,
    unitLabel: "procent",
    titleLabel: "clk_ventilatie",
    alarmZoneActive: true,
    unitMethod: (unit) => unit.slice(0, -1),
  },
  [clockTypes.pressure]: {
    decimals: 0,
    div: 100,
    unitLabel: "pascal",
    titleLabel: "clk_druk",
    alarmZoneActive: true,
  },
  [clockTypes.humidity]: {
    decimals: 0,
    div: 100,
    unitLabel: "procent",
    titleLabel: "clk_rv",
    alarmZoneActive: true,
    unitMethod: (unit) => unit.slice(0, -1),
  },
  [clockTypes.THI]: {
    decimals: 0,
    div: 100,
    unitLabel: "",
    titleLabel: "clk_thi",
    alarmZoneActive: true,
  },
  [clockTypes.CO2]: {
    decimals: 0,
    div: 1,
    unitLabel: "ppm",
    titleLabel: "clk_co2",
    alarmZoneActive: true,
  },
  [clockTypes.light]: {
    decimals: 0,
    div: 10,
    unitLabel: "lux",
    titleLabel: "clk_dakramen",
    alarmZoneActive: false,
  },
  [clockTypes.water]: {
    decimals: 0,
    div: 1,
    unitLabel: "ml_dier",
    titleLabel: "clk_24water",
    alarmZoneActive: false,
  },
  [clockTypes.feed]: {
    decimals: 0,
    div: 1,
    unitLabel: "g_dier",
    titleLabel: "clk_24voer",
    alarmZoneActive: false,
  },
  [clockTypes["water/feed"]]: {
    decimals: 2,
    div: 100,
    unitLabel: "WV",
    titleLabel: "clk_24watervoer",
    alarmZoneActive: false,
  },
  [clockTypes["feed/water"]]: {
    decimals: 2,
    div: 100,
    unitLabel: "VW",
    titleLabel: "clk_24voerwater",
    alarmZoneActive: false,
  },
  [clockTypes.NH3]: {
    decimals: 1,
    div: 100,
    unitLabel: "ppm",
    titleLabel: "clk_nh3",
    alarmZoneActive: true,
  },
  [clockTypes.windchill]: {
    decimals: 0,
    div: 100,
    unitLabel: "graadC",
    titleLabel: "clk_windchill",
    alarmZoneActive: false,
  },
  [clockTypes.airspeed]: {
    decimals: 0,
    div: 100,
    unitLabel: "m_s",
    titleLabel: "clk_luchtsnelheid",
    alarmZoneActive: false,
  },
};
