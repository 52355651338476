import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Encoding } from "@stienen/Encoding";

const state = {
  featureToggles: {
    tooltipChart: true,
    tooltipEvents: true,
    tooltipNumberOfEvents: 10,
    home: true,
    rtLast24Hour: true,
  },
  language: Encoding.language,
  home: undefined,
  editMode: {
    sendTimeout: 30 * 1000,
  },
  streamer: {
    enabled: true,
    minDelay: 20 * 1000,
    maxDelay: 120 * 1000,
    requestTimeout: 9 * 1000,
    initialDelay: 0.5 * 1000,
  },
  cachingMode: {
    ENABLED: true,
    SCREEN: true,
    CUSTOM_SCREEN: false,
    DEVICE_CACHE_MENU: true,
    DEVICE_CACHE_STATICS: true,
    DEVICE_HISTORY: false,
    EXPIRES: 30, // (days)
  },
  collector: {
    ERRORS: true,
  },
  notifications: {
    timeout: 6 * 1000,
    elevation: 8,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
