import { Calc } from "@stienen/Calc";
import { Util } from "@utils/Util";
import { uniq } from "lodash";
import { isDef, isUndef } from "@utils/helpers";
import { ReplaySubject } from "rxjs";
import store from "@state/store";
import * as types from "@state/modules/cache/mutation-types";

export function _init(screen, prefix, rootGetters) {
  const mappedValues = {
    static: { varNames: [], exprVarNames: [], innerExpr: [], data: {} },
  };
  const getConsts = rootGetters["deviceCache/getConstants"];

  if (!screen.hasOwnProperty("exprList")) {
    screen.exprList = [];
  }

  if (!screen.hasOwnProperty("onDestroy")) {
    screen.onDestroy = onDestroy.bind(screen);
  }

  for (let val of screen.Values) {
    let devId;
    if (isUndef(val.Number) && val.Expression.length > 1) {
      val.isCombinedExpr = true;
      val.Dev = null;
    } else {
      val.Dev = screen.Devices[val.Number]?.Device;
      devId = val.Dev?.id;

      if (!mappedValues.hasOwnProperty(devId) && isDef(devId)) {
        mappedValues[devId] = { varNames: [], exprVarNames: [], innerExpr: [] };
      }
    }

    if (isNaN(val.Expression)) {
      // resolve prefixes and constants in expressions
      const constExpr = !val.isCombinedExpr ? getConsts(val.Dev) : null;
      val.Expression = Calc.prepare(val.Expression, constExpr, true, prefix);
    }

    let current = [];
    //Calc.values pushes into current
    Calc.values(val.Expression, current, prefix);
    if (!val.isCombinedExpr && isDef(devId))
      mappedValues[devId].varNames.push(...current);

    if (val.Expression.length === 1) {
      // for ref by offset, also see dispositions References

      //dit crashte want dan was val.Expression bv [300]
      //dus of dit kan checken op type
      //weet het zelf ook niet
      if (val.Name !== "FC_INT_USERROLE") {
        val.RefName = val.Expression[0].substr(1);
      }
    }

    val.subject = new ReplaySubject();
    val.registerCallback = subscribe.bind(val);
    val.watchers = [];

    val.isAction =
      (Array.isArray(val.Expression) && val.Expression.length > 1) ||
      isDef(val.Action);
    val.actionIsCalc =
      val.isAction && !(isDef(val.Action) && isDef(val.Initial));
    if (val.isAction) {
      val.actionVars = uniq(current);
      val.result = null;
      screen.exprList.push(val);
      if (!val.isCombinedExpr && isDef(devId))
        mappedValues[devId].exprVarNames.push(...current);
    }
  }

  screen["prefix"] = prefix; // used as a key for vue's update system
  screen.Values = screen.Values.reduce((acc, obj) => {
    return {
      ...acc,
      [obj.Name]: obj,
    };
  }, {});

  return mappedValues;
}

function onDestroy() {
  const names = Object.keys(this.Values);
  for (let name of names) {
    const val = this.Values[name];
    val.watchers.forEach((unwatch) => unwatch());
  }
}

function subscribe(fn) {
  this.subject.subscribe(fn);
}

// same as _mergeProps with different parameter values
export function _merge(list, refs, hash, lkey, rkey) {
  let loffset = 0,
    roffset = 0,
    lcount = list.length,
    rcount = refs.length,
    litem,
    ritem,
    litemk,
    litemh;

  if (!rkey) rkey = lkey;
  for (; roffset < rcount && loffset < lcount; ++loffset) {
    ritem = refs[roffset];
    litem = list[loffset];
    litemk = litem[lkey];
    litemh = litem[hash] = [];
    while (roffset < rcount && ritem[rkey] === litemk) {
      litemh.push(ritem);
      ritem = refs[++roffset];
    }
  }
}

export function _mergeProps(
  list,
  refs,
  hash = "Properties",
  lkey = "L",
  rkey = "Loc"
) {
  let loffset = 0,
    roffset = 0,
    lcount = list.length,
    rcount = refs.length,
    litem,
    ritem,
    litemk,
    litemh;

  if (!rkey) rkey = lkey;
  for (; roffset < rcount && loffset < lcount; ++loffset) {
    ritem = refs[roffset];
    litem = list[loffset];
    litemk = litem[lkey];
    litemh = litem[hash] = [];
    while (roffset < rcount && ritem[rkey] === litemk) {
      litemh.push(ritem);
      ritem = refs[++roffset];
    }
  }
}

export function _build(
  locs,
  isScreenBuild,
  callback,
  l = 0,
  r = 0xffff,
  state = { Index: 0 }
) {
  const structure = [];
  for (; state.Index < locs.length; ++state.Index) {
    let current = locs[state.Index],
      isClone;
    if (isScreenBuild) {
      isClone = current && current.hasOwnProperty("Type") && current.Type === 7;
    }
    if (l < current.L && current.R < r) {
      const tmp = current;
      structure.push(
        callback ? (current = callback(current, state.Index)) : current
      );
      if (!isClone) {
        ++state.Index;
        current.children = _build(
          locs,
          isScreenBuild,
          callback,
          tmp.L,
          tmp.R,
          state
        );
      } else {
        // unpack last result because its nested 1 level to deep
        // todo: fix this where ever the mistake is introduced
        if (
          structure.length > 0 &&
          Array.isArray(structure[structure.length - 1])
        ) {
          structure.push(...structure.pop());
        }
      }
    } else {
      --state.Index;
      break;
    }
  }
  return structure;
}

export function _checkTranslation(item, prefix, names, trans) {
  let list = item.split(","),
    length = list.length,
    current;

  while (--length >= 0) {
    current = list[length];
    if (current.charAt(0) === "$") {
      current = list[length] = Util.prefix(current, prefix);
      names.push(current.substr(1));
    } else {
      if (isNaN(current)) {
        trans.push(current);
      }
    }
  }
  return list.join(",");
}

export function _checkExpression(info, item, prefix, names, consts) {
  item = Calc.prepare(item, consts, true, prefix);
  if (item === null) {
    console.warn("error preparing " + info);
  }
  Calc.values(item, names, prefix);
  return item;
}

export const _isSameVersion = (dev, other) => {
  return (
    dev &&
    other &&
    dev.hardware === other.hardware &&
    dev.version === other.version
  );
};

export function _setLcTestWatchers(data, commit, device, getters, testChunks) {
  Object.keys(data).forEach((name) => {
    const testVarNames = testChunks[name];
    const callback = _updateTestResultsCallback.bind({
      commit,
      deviceId: device.id,
      testVarNames,
    });
    store.watch(() => getters.getValue(device, name), callback, {
      immediate: true,
    });
  });
}

function _updateTestResultsCallback(data) {
  // each bit is a test result
  const results = {};
  this.testVarNames.forEach((name, index) => {
    results[name] = !!(data & (0x1 << index));
  });

  this.commit(types.SET_LC_TEST_RESULTS, {
    deviceId: this.deviceId,
    results,
  });
}
