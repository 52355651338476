<script>
import RtScreenElement from "./RtScreenElement";

export default {
  name: "RtBuildTemplate",
  props: {
    screenState: {
      type: Object,
      required: true,
      validator: (state) =>
        state.hasOwnProperty("structured") && state.structured.length > 0,
    },
    mappedValues: {
      type: Object,
      required: true,
    },
    isRoot: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  beforeDestroy() {
    this.screenState.onDestroy();
  },
  render(h) {
    return !this.isRoot
      ? h(
          RtScreenElement,
          {
            props: {
              loc: this.screenState.structured[0],
              screenState: this.screenState,
              mappedValues: this.mappedValues,
            },
            key: `${this.screenState.Id}_${this.screenState.Devices[0].Device.Id}`,
          },
          this.$slots.default
        )
      : h(
          "div",
          {
            key: `${this.screenState.Id}_${this.screenState.Devices[0].Device.Id}`,
          },
          this.screenState.structured.map((loc) =>
            h(RtScreenElement, {
              props: {
                loc,
                screenState: this.screenState,
                mappedValues: this.mappedValues,
              },
            })
          )
        );
  },
};
</script>
