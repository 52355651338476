<template>
  <div>
    <div v-if="spinner">
      <img
        v-if="shadow"
        src="@assets/brand/LogoFarmConnectSpinnerShadow.svg"
        :width="width"
        alt="FarmConnect"
      />
      <img
        v-else
        src="@assets/brand/LogoFarmConnectSpinner.svg"
        :width="width"
        alt="FarmConnect"
      />
    </div>
    <div v-else>
      <img
        v-if="shadow"
        src="@assets/brand/LogoFarmConnectShadow.svg"
        :width="width"
        alt="FarmConnect"
      />
      <img
        v-else
        src="@assets/brand/LogoFarmConnect.svg"
        :width="width"
        alt="FarmConnect"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoComponent",
  props: {
    width: {
      type: Number,
      required: false,
      default: 600,
    },
    shadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    spinner: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
