<template>
  <highcharts
    :options="temperatureBulletOptions"
    :callback="temperatureRenderCallback"
  />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const thermometerOutlineGreen = "wdg_thermometer";
const thermometerOutlineGray = "wdg_thermometer_gray";
const indicatorIconGreen = "wdg_indicator";
const indicatorIconGray = "wdg_indicator_gray";

export default {
  name: "WidgetAlarmTemperature",
  props: {
    rangeMin: {
      type: Number,
      required: true,
    },
    rangeMax: {
      type: Number,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 224, //* 3
    },
    height: {
      type: Number,
      default: 270, //* 3
    },
  },
  mixins: [WidgetBase],
  computed: {
    thermometerSubstanceColor() {
      return this.isEnabled
        ? this.enabledSubstanceColor
        : this.disabledSubstanceColor;
    },
    enabledSubstanceColor() {
      return this.status ? this.colors.error : this.colors.black;
    },
    disabledSubstanceColor() {
      return this.status ? this.colors.disabledRed : this.colors.disabledGray;
    },
    textColor() {
      return this.isEnabled ? this.colors.black : this.colors.disabledGray;
    },
    thermometerOutlineIcon() {
      return this.isEnabled ? thermometerOutlineGreen : thermometerOutlineGray;
    },
    indicatorIcon() {
      return this.isEnabled ? indicatorIconGreen : indicatorIconGray;
    },
    minThermometerValue() {
      return this.rangeMin / 100; //eg 1500 -> 15
    },
    maxThermometerValue() {
      return this.rangeMax / 100; //eg 4500 -> 45
    },
    temperatureBulletOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "bullet",
          backgroundColor: this.colors.background,
          plotBackgroundColor: "transparent",
          ...this.calculateBulletMetrics(),
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          lineWidth: 0,
          tickColor: "transparent",
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          min: this.minThermometerValue,
          max: this.maxThermometerValue,
          gridLineColor: "transparent",
          labels: {
            enabled: false,
          },
          title: false,
        },
        plotOptions: {
          bullet: {
            color: this.thermometerSubstanceColor,
            states: {
              hover: {
                enabled: false,
              },
            },
            targetOptions: {
              height: 0,
            },
            borderWidth: 0,
            pointWidth: Infinity,
          },
        },
        series: [
          {
            data: [
              { y: this.actualMeasurement.raw, target: this.setpoint.raw },
            ],
          },
        ],
      };
    },
  },
  methods: {
    calculateBulletMetrics() {
      const yMargin = this.height * 0.15;
      const xMargin = this.width * 0.45;
      const margin = [yMargin, xMargin, this.height * 0.3, xMargin];
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin,
      };
    },
    temperatureRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.width / 20;
        this.strokeWidth = this.plotMetrics.width / 15;

        this.renderThermometer();
        this.renderTextCentered(
          this.units.DEGREE,
          this.chartMetrics.centerX,
          this.chartMetrics.y
        );
        this.renderMinMax();
        this.renderSetPoint();
        this.renderActualMeasurement();
      } catch (err) {
        console.error(err);
      }
    },
    renderThermometer() {
      this.renderThermometerOutline();
      this.renderThermometerBottomSubstance();
    },
    renderThermometerOutline() {
      this.renderIconS3(
        this.thermometerOutlineIcon,
        this.chartMetrics.x,
        this.chartMetrics.y,
        this.chartMetrics.width,
        this.chartMetrics.height
      );
    },
    renderThermometerBottomSubstance() {
      //render circle in thermometer
      //measurements are to fit the svg on the background
      const circleRadius = this.plotMetrics.width * 1.4;
      const circleX = this.plotMetrics.centerX;
      const circleY = this.plotMetrics.endY * 1.197;

      this.renderCircle(circleX, circleY, circleRadius, {
        color: this.thermometerSubstanceColor,
      });

      //render small part sticking out of bottom circle
      this.renderRectangle(
        this.plotMetrics.centerX - this.plotMetrics.width / 2,
        this.plotMetrics.endY,
        this.plotMetrics.width,
        circleRadius,
        { color: this.thermometerSubstanceColor }
      );
    },
    renderMinMax() {
      this.renderLimitLine(
        this.min,
        this.isEnabled ? "blue" : this.colors.disabledGray
      );
      this.renderLimitLine(
        this.max,
        this.isEnabled ? "red" : this.colors.disabledGray
      );
    },
    renderLimitLine(value, color) {
      const margin = this.plotMetrics.width * 0.8;

      const lineX = this.plotMetrics.x - margin;
      const lineEndX = this.plotMetrics.endX + margin;

      const limitedValue = this.minMax(
        value.raw,
        this.minThermometerValue,
        this.maxThermometerValue
      );
      //deze is net wat anders? (translateY) 1/2
      const lineY =
        this.plotMetrics.endY -
        (limitedValue - this.minThermometerValue) *
          this.plotMetrics.oneAmountInPixelsY;

      let borderPath = ["M", lineX, lineY, "L", lineEndX, lineY];
      this.renderLine(borderPath, { color: color });

      const afterTextX = this.renderText(
        value.formatted,
        lineEndX,
        lineY - this.charWidth,
        this.textColor
      );

      this.renderSmallGreenCircle(afterTextX, lineY);
    },
    renderSetPoint() {
      //render text
      const setPointValue = this.minMax(
        this.setpoint.raw,
        this.minThermometerValue,
        this.maxThermometerValue
      );

      //deze is net wat anders? (translateY) 2/2
      const setPointY =
        this.plotMetrics.endY -
        (setPointValue - this.minThermometerValue) *
          this.plotMetrics.oneAmountInPixelsY;
      const setPointX = this.chartMetrics.x + this.chartMetrics.width * 0.04;

      const afterTextX = this.renderText(
        this.setpoint.formatted,
        setPointX,
        setPointY - this.charWidth,
        this.textColor
      );

      const afterCircleX = this.renderSmallGreenCircle(afterTextX, setPointY);

      //render indicator
      const indicatorY = setPointY - this.charWidth / 2;
      const indicatorWidth = this.chartMetrics.width * 0.1;

      const indicatorHeight = this.chartMetrics.height * 0.05;

      this.renderIconS3(
        this.indicatorIcon,
        afterCircleX,
        indicatorY,
        indicatorWidth,
        indicatorHeight
      );
    },
    renderActualMeasurement() {
      const actualMeasurementX = this.chartMetrics.centerX;
      const actualMeasurementY = this.chartMetrics.height * 0.8;

      this.renderTextCentered(
        this.actualMeasurement.formatted,
        actualMeasurementX,
        actualMeasurementY,
        "white"
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.temperatureRenderCallback);
  },
};
</script>

<style scoped></style>
