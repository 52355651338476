import Vue from "vue";
import _ from "lodash";
import { Encoding } from "@stienen/Encoding";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import * as types from "./mutation-types";
import { isConnected } from "./bll";

export default {
  [types.SET_GATEWAYS]: (state, gateways) => {
    gateways = gateways.map((gateway) => {
      gateway.Status = "PC485Connected";
      gateway.LastUpdate = gateway.Lastactive;
      gateway.isConnected = isConnected(gateway.Status);
      gateway.displayName = gateway.name;
      gateway.devices = gateway.devices.map((device) => ({
        ...device,
        gatewayId: gateway.id,
        displayName: `${gateway.name} - ${device.name}`,
        gatewayName: gateway.name,
        available: false,
      }));
      return gateway;
    });
    state.gateways = gateways;
  },
  [types.SET_GATEWAY_STATES]: (state, gatewayStates) => {
    state.gatewayStates = gatewayStates;
    for (const gatewayState of gatewayStates) {
      const existingGateway = state.gateways.find(
        (g) => g.id === gatewayState.Id
      );
      for (const device of gatewayState.Devices) {
        const existingDevice = existingGateway.devices.find(
          (d) => d.id === device.Id
        );
        if (existingDevice) {
          existingDevice.End = device.End;
        }
      }
    }
  },
  [types.SET_DEVICE_AVAILABLE]: (state, device) => {
    const gateway = state.gateways.find((g) => g.id === device.gatewayId);
    gateway.devices.find((d) => d.id === device.id).available = true;
  },
  [types.SET_SCREENGROUPS]: (state, screenGroups) =>
    Vue.set(state, "screenGroups", screenGroups),
  [types.SET_SIDE_NAV]: (state, shouldShow = !state.sideNav) =>
    (state.sideNav = shouldShow),
  [types.SET_SELECTED_GATEWAY]: (state, gateway) =>
    (state.selectedGateway = gateway),
  [types.SET_SELECTED_DEVICE]: (state, device) =>
    (state.selectedDevice = device),
  [types.SET_DEVICE_TIMEOUT_STATUS]: (state, { deviceId, stamp }) =>
    (state.deviceCache.devices[deviceId].lastMessageTimeout = stamp),
  [types.SET_DEVICE_LAST_SEEN]: (state, { deviceId, stamp }) =>
    (state.deviceCache.devices[deviceId].lastSeen = stamp),

  [types.SET_STREAM_STATUS]: (state, isConnected) => {
    state.streamerConnected = isConnected;
    state.streamerLastStatusUpdate = DateTimeConverter.formatDate(Date.now());
  },

  [types.SET_GATEWAY_STATUS]: (state, { status, gatewayIndex, stamp }) => {
    state.gateways[gatewayIndex].Status = status;
    state.gateways[gatewayIndex].isConnected = isConnected(status);
    state.gateways[gatewayIndex].LastUpdate = stamp;
  },

  [types.SET_CURRENT_ALARM_DEVICES]: (state, data) => {
    data.forEach((device, index) => {
      if (state.currentAlarmDevices.length - 1 < index) {
        state.currentAlarmDevices.push(device);
      } else if (!_.isEqual(state.currentAlarmDevices[index], device)) {
        data[index].DeviceName = Encoding.decodeString(data[index].DeviceName);
        Vue.set(state.currentAlarmDevices, index, device);
      }
    });
    if (state.currentAlarmDevices.length > data.length) {
      state.currentAlarmDevices.splice(
        data.length,
        state.currentAlarmDevices.length - data.length
      );
    }
  },
  [types.ALARMS_LOADED]: (state, data) => (state.alarmsLoaded = data),
  [types.SET_LOADING]: (state, loading) => (state.loading = loading),
  [types.SET_LOADING_TEXT]: (state, text) => (state.loadingText = text),
  [types.SET_GATEWAY_ALARM_FILTER]: (state, gatewayId) =>
    (state.alarmGatewayFilter = gatewayId),
};
