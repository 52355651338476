import StateMachine from "javascript-state-machine";
import { notifyUserTranslation } from "@error/helpers";
import { translateStatic } from "@plugins/helpers";

export const fsm = () =>
  new StateMachine({
    init: "read_only",
    transitions: [
      {
        name: "EDIT",
        from: ["read_only", "rejected", "timed_out"],
        to: "editable",
      },
      {
        name: "DISCARD",
        from: ["editable", "rejected", "timed_out"],
        to: "read_only",
      },
      { name: "SEND", from: "editable", to: "pending" },
      { name: "TIMEOUT", from: "pending", to: "timed_out" },
      { name: "REJECT", from: "pending", to: "rejected" },
      { name: "ACCEPT", from: "pending", to: "read_only" },
    ],
    methods: {
      onReject(evt, translationKey, color) {
        notifyUserTranslation(`editMode.${translationKey}`, color);
      },
      onTimeout(evt, translationKey, color) {
        notifyUserTranslation(`editMode.${translationKey}`, color);
      },
      onDiscard() {
        notifyUserTranslation("notification.changesDiscarded");
      },
      onAccept() {
        notifyUserTranslation("notification.settingsAccepted", "success");
      },
      onLeaveReadOnly() {
        window.addEventListener("beforeunload", stillInEditMode);
      },
      onEnterReadOnly() {
        window.removeEventListener("beforeunload", stillInEditMode);
      },
    },
  });

function stillInEditMode() {
  return translateStatic("notification.pendingLeave");
}
