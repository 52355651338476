import Vue from "vue";
import App from "./App.vue";
import router from "@router/router";
import store from "./state/store";
import vuetify from "@plugins/vuetify";

import "./global-components";
import "./additional-libraries";
import "@plugins/helpers-plugin";
import "@plugins/validation";

import "@plugins/amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import i18n from "./i18n";
import { notifyUserTranslation } from "@error/helpers";

import config from "@/app.config";
Vue.prototype.$appVersion = config.version;
Vue.prototype.$appTitle = config.title;
Vue.prototype.$favIcon = config.favicon;

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/amplify-\w*/];

onAuthUIStateChange(async (state, user) => {
  if (state === AuthState.SignedIn) {
    await store.dispatch("auth/authChangedHandler", user, { root: true });
  }
  if (state === AuthState.SignIn) {
    await store.dispatch("auth/showAuth", null, { root: true });
  }
  if (state === AuthState.ConfirmSignUp) {
    notifyUserTranslation("login.gotMail", "success");
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
