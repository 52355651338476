import * as types from "./mutation-types";
import { INITIAL_STATE } from "@state/modules/auth";
import * as Sentry from "@sentry/vue";

export default {
  [types.SET_TOKEN]: (state, token) => {
    state.token = token;
  },
  [types.SET_USER]: (state, user) => {
    Sentry.setUser({ email: user.email });
    state.user = user;
  },
  [types.SET_USER_STATUS]: (state, value) => (state.userStatus = value),
  [types.RESET_USER]: (state) => {
    Object.assign(state, INITIAL_STATE());
  },
  [types.SHOW_AUTH]: (state) => (state.showAuth = true),
  [types.SET_MAIN_ACCOUNT]: (state, mainAccount) =>
    (state.mainAccount = mainAccount),
  [types.SET_CUSTOMER_ACCOUNT]: (state, customerAccount) =>
    (state.customerAccount = customerAccount),
  [types.SET_IMPERSONATED_CUSTOMER]: (state, impersonatedCustomer) =>
    (state.impersonatedCustomer = impersonatedCustomer),
  [types.SET_SERVICE_ACCESS]: (state, allow) =>
    (state.customerAccount.allowServiceAccess = allow),
};
