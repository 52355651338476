<template>
  <portal to="modals">
    <v-overlay>
      <qrcode-stream :key="_uid" @decode="onDecode" @init="onInit">
        <base-spinner v-if="loading" :size="64" />
        <slot>
          <base-button v-if="!loading" dark fab @click="onBack">
            <v-icon> mdi-arrow-left </v-icon>
          </base-button>
        </slot>
      </qrcode-stream>
    </v-overlay>
  </portal>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "AddGatewayQR",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      loading: false,
      camera: "auto",
    };
  },
  methods: {
    onBack() {
      this.$emit("onBack");
    },
    onDecode(decoded) {
      this.$emit("onDecode", decoded);
    },
    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
