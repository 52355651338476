<template>
  <highcharts ref="chart" :options="chartOptions" />
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "RtChart",
  components: {
    highcharts: Chart,
  },
  props: {
    values: {
      type: Array,
      required: true,
    },
    TableInfo: {
      type: Object,
      required: true,
    },
    ColumnInfos: {
      type: Array,
      required: true,
    },
    start: {
      type: Date,
      required: true,
    },
    end: {
      type: Date,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    yAxisBase() {
      return {
        title: {
          enabled: false,
        },
        labels: {
          formatter() {
            if (this.value % 1 === 0) {
              return this.value.toFixed(1);
            }
            return this.value;
          },
        },
      };
    },
    chartOptions() {
      return {
        title: {
          text: this.plotName,
        },
        chart: {
          zoomType: "xy",
        },
        legend: {
          align: this.$vuetify.breakpoint.smAndDown ? "center" : "right",
          verticalAlign: this.$vuetify.breakpoint.smAndDown ? "bottom" : "top",
          layout: "vertical",
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          max: +this.end,
        },
        yAxis: [
          {
            ...this.yAxisBase,
          },
          {
            ...this.yAxisBase,
            opposite: true,
          },
          {
            ...this.yAxisBase,
            opposite: true,
          },
          {
            ...this.yAxisBase,
            opposite: true,
          },
        ],
        series: this.series,
      };
    },
    series() {
      const series = [];
      const yAxes = {};
      let startY = 0;

      for (const columnInfo of this.ColumnInfos) {
        if (!columnInfo.isVisible) {
          //at the end this is filtered out
          //when not added, the order will be wrong
          series.push(undefined);
          continue;
        }

        const step = columnInfo.Step;
        if (!yAxes.hasOwnProperty(step)) {
          yAxes[step] = startY++;
        }

        // formula taken from Data.js
        const valueDecimals = -Math.round(Math.log10(step));

        const serie = {
          name: columnInfo.Translated || columnInfo.Name,
          step: "left", // or 'center' or 'right'
          data: [],
          yAxis: yAxes[step],
          color: `#${`000000${columnInfo.Color.toString(16)}`.substr(-6)}`,
          tooltip: { valueDecimals },
        };

        series.push(serie);
      }

      let i = 0;
      for (const value of this.values) {
        let currentSeries = series[i];

        if (!currentSeries) {
          i++;
          continue;
        }

        for (const val of value) {
          currentSeries.data.push([+new Date(val.dateTime), val.value]);
        }

        series[i] = currentSeries;
        i++;
      }
      return series.filter((serie) => serie !== undefined);
    },
    plotName() {
      if (this.TableInfo) {
        return this.TableInfo?.Translated;
      }
      return "Chart";
    },
  },
  watch: {
    loading(loading) {
      if (loading) this.$refs.chart?.chart.showLoading();
      else this.$refs.chart?.chart.hideLoading();
    },
  },
};
</script>

<style scoped></style>
