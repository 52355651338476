import * as configClient from "@api/config-service";
import * as refClient from "@api/ref-service";
import * as stateClient from "@api/state-service";
import {
  _checkDeviceObj,
  _createDevicesObj,
  _createHardwareVersionName,
  _deviceValid,
  _get,
  _getPositions,
  _isLcCpu,
  _mapRefs,
  _mapTextByLabel,
  _mapTextByTranslationNameId,
  _resolveConstants,
} from "./bll";
import {
  InitError,
  InvalidDeviceObjectError,
  NoDataError,
  SpecifiedError,
} from "@error/types";
import { createErrorObject, createSuccessObject } from "@error/helpers";
import {
  _build,
  _checkExpression,
  _checkTranslation,
  _setLcTestWatchers,
} from "@state/modules/rt-remote/bll";
import { isDef, isEmptyObjectOrArray, isUndef } from "@utils/helpers";
import { uniq } from "lodash";
import RequestManager from "@plugins/requestManager";
import * as types from "./mutation-types";
import { _compose } from "@modules/rtcpu/render/screen-bll";
import { localForage } from "@plugins/local-forage";
import { getLcDeviceDefinition } from "@api/lc-screens-service";
import { Calc } from "@stienen/Calc";
import { roles } from "@utils/permissions";

async function init({ dispatch, rootState }) {
  // _pruneLocalCache(null, false);
  const allDevices = rootState.gateways.flatMap((gateway) => gateway.devices);
  for (const device of allDevices) {
    await dispatch("initDevice", device);
  }
  return Promise.resolve(true);
}

async function initVersion({ state, dispatch }, device) {
  const name = _createHardwareVersionName(device);
  if (state.deviceData[name].initialized) {
    return;
  }
  if (_isLcCpu(device)) {
    await dispatch("lcInitVersion", device);
  } else {
    await dispatch("rtInitVersion", device);
  }

  return Promise.resolve({
    hardware: device.hardware,
    version: device.version,
  });
}

async function initFull({ dispatch }, device) {
  await dispatch("initVersion", device);
  await dispatch("initConfig", device);
  await dispatch("fetchFromDatabase", device);
}

async function rtInitVersion({ commit, dispatch, rootGetters }, device) {
  //length !== 6 ? -> constants, namedTexts, typedTexts, controlTexts, alarmTexts, namedTexts
  const validateVersionResponse = (data, device) => {
    // fail fast, no recovery
    if (isUndef(data) || !Array.isArray(data) || data.length !== 6) {
      throw new InitError(
        `Response not valid: ${device.hardware}, ${device.version}`
      );
    }
    data.forEach((rsp) => {
      if (isEmptyObjectOrArray(rsp)) {
        throw new InitError(
          `Version data Unknown or incomplete: ${device.hardware}, ${device.version}`
        );
      }
    });
  };

  const { hardware, version } = _checkDeviceObj(device);
  const lang = rootGetters["settings/getLanguage"];

  let data = await localForage.getItem([
    "deviceCache",
    "static",
    hardware,
    version,
  ]);

  if (isUndef(data) || data.data.lang !== lang) {
    // fetch

    const namedTextsPromise = refClient.GetNamedTextsByLanguage(
      hardware,
      version,
      [lang]
    );

    data = await Promise.all([
      dispatch("fetchConstants", device),
      namedTextsPromise,
      refClient.GetTypedTextsByLanguage(hardware, version, [lang]),
      refClient.GetControlTextsByLanguage(hardware, version, [lang]),
      refClient.GetAlarmTextsByLanguage(hardware, version, [lang]),
      namedTextsPromise,
    ]);

    data[1] = _mapTextByLabel(data[1]);
    data[2] = _mapTextByLabel(data[2]);
    data[3] = _mapTextByLabel(data[3]);
    data[4] = _mapTextByLabel(data[4]);
    data[5] = _mapTextByTranslationNameId(data[5]);

    try {
      validateVersionResponse(data, device);
      if (
        rootGetters["settings/getCachingModeByType"]("DEVICE_CACHE_STATICS")
      ) {
        await localForage.setItem(
          ["deviceCache", "static", hardware, version],
          { lang, data }
        );
      } else {
        await localForage.removeItem([
          "deviceCache",
          "static",
          hardware,
          version,
        ]);
      }
    } catch (err) {
      console.error(err);
    }
  } else {
    // unpack
    data = data.data.data;
  }

  commit(types.SET_DEVICE_DATA, {
    device,
    constants: data[0],
    namedText: data[1],
    typedText: data[2],
    controlText: data[3],
    alarmText: data[4],
    namedTextIndexed: data[5],
    initialized: true,
  });

  return Promise.resolve({ hardware, version });
}

async function lcInitVersion({ commit, dispatch, rootGetters }, device) {
  try {
    const hardwareVersionName = _createHardwareVersionName(device);
    const { hardware, version } = device;

    let deviceDefinition = await localForage.getItem([
      "deviceCache",
      "lcScreen",
      hardware,
      version,
    ]);
    if (isUndef(deviceDefinition)) {
      deviceDefinition = await getLcDeviceDefinition(device);
      await localForage.setItem(
        ["deviceCache", "lcScreen", hardware, version],
        deviceDefinition
      );
    } else {
      deviceDefinition = deviceDefinition.data;
    }

    const lang = rootGetters["settings/getLanguage"];
    const namedTextRaw = await refClient.GetNamedTextsByLanguage(
      hardware,
      version,
      [lang]
    );
    const namedText = _mapTextByLabel(namedTextRaw);

    commit(types.SET_LC_DEVICE_DATA, {
      hardwareVersionName,
      meta: deviceDefinition.meta,
      typedText: deviceDefinition.typedText,
      namedText,
      initialized: true,
    });

    await dispatch("lcRemote/lcInitScreens", deviceDefinition, { root: true });
  } catch (err) {
    console.error(err);
  }
}

async function initDevice({ commit, rootGetters }, device) {
  _checkDeviceObj(device);
  device.edit =
    roles.getEditByRole(device.role) &&
    !rootGetters["auth/getIsProvidingService"];
  const deviceObject = _createDevicesObj({ device });
  commit(types.INIT_DEVICE, { device, deviceObject });
  RequestManager.watchDevice(device);
  return true;
}

async function fetchConstants(context, device) {
  const { hardware, version } = device;
  const constants = await configClient.GetDeviceConstants(hardware, version);
  return _resolveConstants(constants);
}

function initLcTestVariables(
  { commit, rootGetters, getters },
  { device, testChunks }
) {
  const data = rootGetters["deviceCache/getValuesFor"](
    device,
    Object.keys(testChunks)
  );
  _setLcTestWatchers(data, commit, device, getters, testChunks);
}

async function initializeTree({ state, dispatch, getters, commit }, device) {
  try {
    //fetch tree if not present in store
    if (
      _deviceValid(state, device) &&
      !state.devices[device.id].treeRegistered
    ) {
      await dispatch("fetchTree", device);
      commit(types.TREE_REGISTERED_FOR_DEVICE, device.id);
    }

    const tree = getters.getTree(device.id);

    if (!tree.builtTree) {
      tree.builtTree = await dispatch("buildTreeForDevice", {
        device,
        structured: tree.structured,
      });
    }
    return createSuccessObject(tree.builtTree);
  } catch (err) {
    console.error(err);
    if (err instanceof InvalidDeviceObjectError) {
      return createErrorObject("No valid device selected");
    }
    if (err instanceof NoDataError) {
      return createErrorObject(err);
    }
    return err instanceof SpecifiedError
      ? createErrorObject(err)
      : createErrorObject("Something went wrong during loading");
  }
}

async function fetchTree({ commit, getters, rootGetters }, device) {
  const { id, hardware, version } = _checkDeviceObj(device);
  let tree = await localForage.getItem([
    "deviceCache",
    "tree",
    id,
    hardware,
    version,
  ]);
  if (isUndef(tree)) {
    // fetch
    const rawDispositions = await configClient.GetDispositions(
      device.id,
      hardware,
      version
    );
    if (isUndef(rawDispositions) || rawDispositions.length === 0) {
      throw new NoDataError("Tree unavailable");
    }

    const varNames = [],
      trans = [],
      cdict = getters.getConstants(device);

    // process
    const structured = _build(rawDispositions, false, (de, index) => {
      const currExprVars = [];
      de.Name = _checkTranslation(de.Name, de.Prefix, varNames, trans);
      if (de.Text)
        de.Text = _checkTranslation(de.Text, de.Prefix, varNames, trans);

      // not sure what enabled is good for
      de.Enabled = de.Enabled
        ? _checkExpression(
            de.L + "-" + de.R + ": " + de.Name,
            de.Enabled,
            de.Prefix,
            currExprVars,
            cdict,
            rootGetters
          )
        : null;

      de.Visible = de.Visible
        ? _checkExpression(
            de.L + "-" + de.R + ": " + de.Name,
            de.Visible,
            de.Prefix,
            currExprVars,
            cdict,
            rootGetters
          )
        : true;
      de.hasNodeTest = false;
      de.isVisible = true;
      de.isCustomScreen = false;

      if (Array.isArray(de.Visible)) {
        de.hasNodeTest = true;
        de.expr = { expr: de.Visible, vars: uniq(currExprVars) };
        de.Visible = false;
      }

      varNames.push(...currExprVars);
      de["Key"] = index; // to be used by v-for :key
      return de;
    });

    tree = {
      rawDispositions,
      structured,
      varNames: uniq(varNames),
    };
  } else {
    // unpack
    tree = tree.data;
  }

  if (rootGetters["settings/getCachingModeByType"]("DEVICE_CACHE_MENU")) {
    await localForage.setItem(
      ["deviceCache", "tree", id, hardware, version],
      tree
    );
  }

  commit(types.SET_TREE, {
    device,
    tree,
  });
}

async function buildTreeForDevice({ commit, getters }, { device, structured }) {
  const role = device.role;
  const visibleNodeKeys = [];

  function checkNodeVisibility(node) {
    if (role > node.Role) {
      return false;
    }

    if (node.hasNodeTest) {
      const data = getters.getValuesFor(device, node.expr.vars);
      const result = Calc.execute(node.expr.expr, data);
      if (!result.data) {
        return false;
      }
    }

    node.name = getters.getDispositionTitle(device, node);

    const hasChildren = node.children.length > 0;
    if (hasChildren) {
      node.children = node.children.filter((child) =>
        checkNodeVisibility(child)
      );
    }

    const hasScreen = isDef(node.Type);
    const hasVisibleChildren = node.children.length > 0;
    const isVisible = hasScreen || hasVisibleChildren;

    if (isVisible) {
      visibleNodeKeys.push(node.Key);
    }

    return isVisible;
  }

  const builtTree = structured.filter(checkNodeVisibility);

  commit(types.SET_VISIBLE_NODE_KEYS, {
    deviceId: device.id,
    nodeKeys: visibleNodeKeys,
  });

  return builtTree;
}

async function scheduleOnce({ state, dispatch }, { device, names }) {
  names.forEach((name) => {
    const idx = name.indexOf("KEUZE");
    if (idx !== -1) {
      const add = name.slice(0, idx).concat("TYPE");
      names.push(add);
    }
  });
  const refs = _get(state, device, "references");
  const pos = _getPositions(refs, names);
  setTimeout(function () {
    dispatch("fetchFromDevice", { device, pos });
  }, 1500);
}

async function fetchFromDatabase({ commit, state, getters }, device) {
  try {
    if (state.devices[device.id].valuesComplete) {
      return;
    }

    const updateState = async () => {
      const data = await stateClient.fetchState(device.id);

      const refs = getters.getRefs(device);

      for (const value of data.values) {
        const ref = refs[value.varName];
        if (ref.Type === 3) {
          value.value = new Date(
            978307200000 + 86400000 * parseFloat(value.value)
          );
        }
        if (ref.Type >= 7 && ref.Type <= 16) {
          value.value = parseFloat(value.value);
        }
      }

      commit(types.UPDATE_VALUES_NEW, { device, data });
    };

    await updateState();
    // setInterval(async () => await updateState(), 10 * 60 * 1000);
  } catch (err) {
    console.error(err);
  }
}

async function initConfig({ commit, state }, device) {
  try {
    const name = _createHardwareVersionName(device);
    if (state.deviceData[name].complete) {
      return;
    }

    let cached = await localForage.getItem([
      "deviceCache",
      "config",
      device.hardware,
      device.version,
    ]);

    let data;
    let refs;

    if (cached) {
      data = cached.data.data;
      refs = cached.data.refs;
    } else {
      data = await refClient.fetchFull(device.hardware, device.version);
      refs = _mapRefs(data);
      await localForage.setItem(
        ["deviceCache", "config", device.hardware, device.version],
        { data, refs }
      );
    }

    commit(types.ADD_INDEXES, { dev: device, data });
    commit(types.ADD_REFERENCES, { dev: device, data: refs, complete: true });
  } catch (err) {
    console.error(err);
  }
}

async function fetchFromDevice(context, { device, pos }) {
  // The result will come back via long pooling (see messenger.js)
  // The streamer will commit the result to the store (see streamer.js)
  await configClient.GetValues(device.gatewayId, device.id, pos);
}

async function onDataReceived({ commit }, { dev, data }) {
  commit(types.UPDATE_VALUES, { dev, data });
}

async function modifyRefreshVars(
  { commit, getters },
  { deviceId, varNames, action }
) {
  if (isDef(varNames) && isDef(deviceId)) {
    commit(types.MODIFY_REFRESH_VARS, {
      dev: getters.getDeviceMeta(deviceId),
      varNames,
      add: action === "ADD",
    });
  }
}

//this is not a good name
function translate({ getters }, { dev, labels }) {
  try {
    const list = labels
      .split(",")
      .map(
        (label) =>
          getters.getValue(dev, label) || getters.getTranslation({ dev, label })
      );
    return Promise.resolve(_compose(list[0], list.slice(1)));
  } catch (e) {
    return Promise.reject();
  }
}

function setHistoryVars({ commit }, { device, vars }) {
  commit(types.SET_HISTORY_VARS, { device, vars });
}

function registerHistoryVar({ commit, rootGetters }, variable) {
  const deviceId = rootGetters.getSelectedDevice.id;
  variable.mul ??= 1;
  variable.div ??= 1;
  commit(types.ADD_HISTORY_VAR, { deviceId, variable });
}

function clearHistoryVars({ commit, rootGetters }) {
  const deviceId = rootGetters.getSelectedDevice.id;
  commit(types.CLEAR_HISTORY_VARS, deviceId);
}

export default {
  init,
  initFull,
  initVersion,
  initDevice,
  fetchConstants,
  fetchFromDatabase,
  fetchFromDevice,
  initConfig,
  onDataReceived,
  fetchTree,
  buildTreeForDevice,
  initializeTree,
  modifyRefreshVars,
  translate,
  scheduleOnce,
  initLcTestVariables,
  rtInitVersion,
  lcInitVersion,
  setHistoryVars,
  registerHistoryVar,
  clearHistoryVars,
};
