<template>
  <div :style="moveRight" class="nav-drawer-static-node">
    <span
      v-if="hasChildren"
      :class="[isOpen ? 'mdi-minus' : 'mdi-plus', 'icon mdi']"
      @click="clickHandler"
    />
    <span class="text">
      <span v-if="locked">{{ text }}</span>
      <a v-else @click="clickHandler">{{ text }}</a>
      &nbsp;&nbsp;
      <span v-if="warning" class="icon-warning mdi mdi-alert" />
      <span v-if="locked" class="icon-locked mdi mdi-lock" />
      <span
        v-if="disconnected"
        class="icon-connected mdi mdi-cloud-off-outline"
      />
    </span>
    <div v-show="isOpen" :class="[hasChildren ? 'slot' : '']">
      <slot />
    </div>
    <div v-if="notification > 0" :style="moveLeft" class="notification">
      {{ notification }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NavDrawerStaticNode",
  props: {
    text: {
      type: [String, typeof TranslateResult],
      required: true,
    },
    locked: {
      type: Boolean,
      required: false,
      default: false,
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    disconnected: {
      type: Boolean,
      required: false,
      default: false,
    },
    notification: {
      type: Number,
      required: false,
      default: 0,
    },
    child: {
      type: Boolean,
      required: false,
      default: false,
    },
    indent: {
      type: Number,
      required: false,
      default: 0,
      validator: (value) => value >= 0 && value <= 4,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    hasChildren() {
      return !!this.$slots.default?.length;
    },
    moveRight() {
      return this.child ? "transform: translate(20px)" : "";
    },
    moveLeft() {
      switch (this.indent) {
        case 0:
          return "transform: translate(0px)";
        case 1:
          return "transform: translate(-20px)";
        case 2:
          return "transform: translate(-40px)";
        case 3:
          return "transform: translate(-60px)";
        case 4:
          return "transform: translate(-80px)";
        case 5:
          return "transform: translate(-100px)";
        default:
          return "";
      }
    },
  },
  methods: {
    clickHandler() {
      if (this.locked) return;
      this.isOpen = !this.isOpen;
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer-static-node {
  width: 301px;
  display: grid;
  grid-template-columns: 30px 25px 206px 40px;
  padding: 5px 0 5px 0;

  span {
    a {
      color: var(--v-grey30-base);
    }

    a:hover {
      text-decoration: underline;
      color: var(--v-white-base);
    }
  }

  .icon {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    color: var(--v-grey30-base);
    font-family: var(--v-font-base);
    font-size: 18px;
    font-weight: bolder;
    line-height: 1;
  }

  .text {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    height: 20px;
    color: var(--v-grey30-base);
    font-family: var(--v-font-base);
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
  }

  .notification {
    grid-column: 4;
    grid-row: 1;
    height: 20px;
    width: 30px;
    border-radius: 10px;
    color: var(--v-white-base);
    font-family: var(--v-font-base);
    font-size: 11px;
    font-weight: bolder;
    line-height: 1;
    text-align: center;
    padding: 5px 0 5px 0;
    background-color: var(--v-error-base);
  }

  .slot {
    grid-row: 2;
    padding-top: 5px;
  }

  .icon-warning {
    color: var(--v-warning-base);
    margin-left: 5px;
  }

  .icon-connected {
    color: var(--v-error-base);
    margin-left: 5px;
  }

  .icon-locked {
    color: var(--v-grey30-base);
    margin-left: 5px;
  }
}
</style>
