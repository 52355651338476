<template>
  <highcharts :options="meterBulletOptions" :callback="meterRenderCallback" />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const minBarValue = 0;
const maxBarValue = 100;

const barOutlineIconGreen = "wdg_bar";
const barOutlineIconGray = "wdg_bar_gray";

const indicatorIconGreen = "wdg_indicator";
const indicatorIconGray = "wdg_indicator_gray";

const rvIconGreen = "wdg_rv_green";
const rvIconGray = "wdg_rv_gray";

const ventIconGreen = "wdg_vent";
const ventIconGray = "wdg_vent_gray";

const typeEnum = {
  RV: "RV",
  VENT: "VENT",
};

export default {
  name: "WidgetAlarmPercentage",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 224,
    },
    height: {
      type: Number,
      default: 270,
    },
  },
  mixins: [WidgetBase],
  computed: {
    substanceColor() {
      return this.isEnabled
        ? this.enabledSubstanceColor
        : this.disabledSubstanceColor;
    },
    enabledSubstanceColor() {
      return this.status ? this.colors.error : this.colors.black;
    },
    disabledSubstanceColor() {
      return this.status ? this.colors.disabledRed : this.colors.disabledGray;
    },
    textColor() {
      return this.isEnabled ? "black" : this.colors.disabledGray;
    },
    barOutlineIcon() {
      return this.isEnabled ? barOutlineIconGreen : barOutlineIconGray;
    },
    indicatorIcon() {
      return this.isEnabled ? indicatorIconGreen : indicatorIconGray;
    },
    rvIcon() {
      return this.isEnabled ? rvIconGreen : rvIconGray;
    },
    ventIcon() {
      return this.isEnabled ? ventIconGreen : ventIconGray;
    },
    topIcon() {
      switch (this.type) {
        case typeEnum.RV:
          return this.rvIcon;
        case typeEnum.VENT:
          return this.ventIcon;
      }
      return "";
    },
    meterBulletOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "bullet",
          backgroundColor: this.colors.background,
          ...this.calculateBulletMetrics(),
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          lineWidth: 0,
          tickColor: "transparent",
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          min: minBarValue,
          max: maxBarValue,
          gridLineColor: "transparent",
          labels: {
            enabled: false,
          },
          title: false,
        },
        plotOptions: {
          bullet: {
            color: this.substanceColor,
            borderWidth: 0,
            pointWidth: Infinity,
          },
        },
        series: [
          {
            data: [this.actualMeasurement.raw],
          },
        ],
      };
    },
  },
  methods: {
    calculateBulletMetrics() {
      const yMargin = this.height * 0.14;
      const xMargin = this.width * 0.45;
      const margin = [yMargin, xMargin, yMargin, xMargin];
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin,
      };
    },
    meterRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.strokeWidth = this.width / 200;
        this.charWidth = this.width / 21;

        this.renderOutlineBar();
        this.renderMinMax();
        this.renderActualMeasurement();
        this.renderIconOnTop();

        if (this.type === typeEnum.VENT) {
          this.renderSetPoint();
        }
      } catch (err) {
        console.error(err);
      }
    },
    renderOutlineBar() {
      this.renderIconS3(
        this.barOutlineIcon,
        this.chartMetrics.x,
        this.chartMetrics.y,
        this.chartMetrics.width,
        this.chartMetrics.height
      );
    },
    renderMinMax() {
      this.renderLimitLine(
        this.min,
        this.isEnabled ? "blue" : this.colors.disabledGray
      );
      this.renderLimitLine(
        this.max,
        this.isEnabled ? "red" : this.colors.disabledGray
      );
    },
    renderLimitLine(value, color) {
      const margin = this.plotMetrics.width * 0.8;
      const lineX = this.plotMetrics.x - margin;
      const lineEndX = this.plotMetrics.endX + margin;

      const limitedValue = this.minMax(value.raw, minBarValue, maxBarValue);
      const lineY = this.translateY(limitedValue);

      let borderPath = ["M", lineX, lineY, "L", lineEndX, lineY];
      this.renderLine(borderPath, { color });

      const textX = lineEndX * 1.05;
      const textY = lineY - this.charWidth;
      const nextX = this.renderText(
        value.formatted,
        textX,
        textY,
        this.textColor
      );

      //vars are editable in RV mode
      if (this.type === typeEnum.RV) {
        this.renderSmallGreenCircle(nextX, lineY);
      }
    },
    renderActualMeasurement() {
      const actualMeasurementX = this.plotMetrics.centerX;
      const actualMeasurementY = this.plotMetrics.endY + this.charWidth * 1.6;
      this.renderTextCentered(
        `${this.actualMeasurement.formatted}%`,
        actualMeasurementX,
        actualMeasurementY
      );
    },
    renderIconOnTop() {
      const sideLength = this.plotMetrics.width * 1.3;
      this.renderIconS3(
        this.topIcon,
        this.plotMetrics.centerX - sideLength / 2,
        this.chartMetrics.y,
        sideLength,
        sideLength
      );
    },
    renderSetPoint() {
      //render text
      const setPointY = this.translateY(this.setpoint.raw - minBarValue);
      const setPointX = this.chartMetrics.x + this.chartMetrics.width * 0.04;
      const afterTextX = this.renderText(
        this.setpoint.formatted,
        setPointX,
        setPointY - this.charWidth,
        this.textColor
      );
      const afterCircleX = this.renderSmallGreenCircle(afterTextX, setPointY);

      //render indicator
      const indicatorY = setPointY - this.charWidth / 2;
      const indicatorWidth = this.chartMetrics.width * 0.1;
      const indicatorHeight = this.chartMetrics.height * 0.05;

      this.renderIconS3(
        this.indicatorIcon,
        afterCircleX,
        indicatorY,
        indicatorWidth,
        indicatorHeight
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.meterRenderCallback);
  },
};
</script>

<style scoped></style>
