<template>
  <layout-empty>
    <v-container>
      <v-row>
        <v-spacer />
        <component :is="authenticatorComponent" v-show="!loading && showAuth" />
        <v-overlay v-if="loading">
          <base-builder-spinner />
        </v-overlay>
        <v-spacer />
      </v-row>

      <v-row>
        <v-spacer />
        <LogoStienenBe
          :width="logoStienenBeWidth"
          shadow
          style="margin-top: 50px"
          v-if="!loading && showAuth"
        />
        <v-spacer />
      </v-row>
    </v-container>
  </layout-empty>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LogoFarmConnect from "@components/base/LogoFarmConnect";
import LogoStienenBe from "@components/base/LogoStienenBe";
import AuthenticatorDesktop from "@pages/account/AuthenticatorDesktop";
import AuthenticatorMobile from "@pages/account/AuthenticatorMobile";

export default {
  name: "LoginPage",
  components: {
    LogoFarmConnect,
    LogoStienenBe,
    AuthenticatorDesktop,
    AuthenticatorMobile,
  },
  computed: {
    ...mapGetters("layout", ["isDesktop"]),
    ...mapState({
      loading: (state) => state.loading,
    }),
    ...mapState("auth", {
      showAuth: (state) => state.showAuth,
    }),
    authenticatorComponent() {
      return this.isDesktop ? AuthenticatorDesktop : AuthenticatorMobile;
    },
    logoFarmConnectWidth() {
      return this.isDesktop ? 600 : 300;
    },
    logoStienenBeWidth() {
      return this.isDesktop ? 200 : 150;
    },
  },
};
</script>

<style scoped></style>
