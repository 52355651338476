<template>
  <v-progress-circular
    :size="size"
    :width="width"
    color="primary"
    indeterminate
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "BaseSpinner",
  inheritAttrs: false,
  props: {
    width: {
      type: Number,
      required: false,
      default: 3,
    },
    size: {
      type: Number,
      required: false,
      default: 25,
    },
  },
};
</script>

<style scoped></style>
