<template>
  <div class="nav-drawer-static-header">
    <span :class="icon" class="icon mdi" />
    <span class="text"
      >{{ text }}
      <span v-if="warning" class="icon-warning mdi mdi-alert" />
    </span>
    <div v-if="notification > 0" class="notification">
      {{ notification }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NavDrawerStaticHeader",
  props: {
    icon: {
      type: String,
      required: false,
      default: "",
    },
    text: {
      type: [String, typeof TranslateResult],
      required: false,
      default: "",
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    notification: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer-static-header {
  display: grid;
  width: 301px;
  grid-template-columns: 20px 35px 206px 40px;
  height: 45px;
  color: var(--v-grey30-base);

  .icon {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    font-size: 25px;
  }

  .text {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    color: var(--v-grey30-base);
    font-family: var(--v-font-base);
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
  }

  .notification {
    grid-column: 4;
    grid-row: 1;
    height: 20px;
    width: 30px;
    border-radius: 10px;
    color: var(--v-white-base);
    font-family: var(--v-font-base);
    font-size: 11px;
    font-weight: bolder;
    line-height: 1;
    text-align: center;
    align-self: center;
    padding: 5px 0 5px 0;
    background-color: var(--v-error-base);
  }

  .icon-warning {
    color: var(--v-warning-base);
    margin-left: 5px;
  }
}
</style>
