/* todo:
  - use localStorage, validate at startup
  - build device-cache in store first, later refactor to extract logic to plugin/module
  - add watcher to language to refresh the translations if the language changes
  - add watcher that checks device updates to other versions
  - https://github.com/vuejs/vuex/blob/dev/examples/todomvc/store/index.js
*/

import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const state = {
  // deviceData holds hardware-version related data, actual values for variables etc are in devices obj
  deviceData: {
    /* see _createDeviceDataObj */
    versions: [],
  },
  devices: {
    static: {
      values: {},
    },
    /* see _createDevicesObj */
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
