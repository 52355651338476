<template>
  <div>
    <PulseComponent
      :type="type"
      :isEnabled="isEnabled"
      :min="min.raw"
      :max="max.raw"
      :pulsTime="actualControl.raw"
      :currentTime="currentTime"
      :is-enabled-r-v="isEnabledRV"
      :actualRV="actualRV"
      :maxRV="maxRV"
      :width="width"
      :height="pulseHeight"
    />
    <highcharts
      :options="coolingTPChartOptions"
      :callback="coolingTPRenderCallback"
    />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import PulseComponent from "@modules/lccpu/components/widgets/PulseComponent";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const tPIcon = "wdg_cooling_gph_green";
const tPIconDisabled = "wdg_temp_gray";

const tempIcon = "wdg_temp_green";
const tempIconDisabled = "wdg_temp_gray";

const typeEnum = {
  RV_LIMIT: "RV_LIMIT",
};

export default {
  name: "WidgetCoolingAnalog",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    bandwidth: {
      type: LcWidgetValue,
      required: true,
    },
    actualControl: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    status: {
      // raar geval
      type: [Boolean, Number, String],
      required: true,
    },
    hysteresis: {
      type: Number,
      required: true,
    },
    pulsTime: {
      type: Number,
      required: true,
    },
    currentTime: {
      type: Number,
      required: true,
    },
    isEnabledRV: {
      type: [Boolean, Number],
      required: true,
    },
    actualRV: {
      type: Number,
      required: true,
    },
    maxRV: {
      type: Number,
      required: true,
    },
    isCurveEnabled: {
      //not used?
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    height: {
      type: Number,
      default: 270,
    },
  },
  mixins: [WidgetBase],
  computed: {
    tPIcon() {
      return this.isEnabled ? tPIcon : tPIconDisabled;
    },
    tempIcon() {
      return this.isEnabled ? tempIcon : tempIconDisabled;
    },
    coolingTPChartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.isEnabled
            ? this.colors.lightGreen
            : this.colors.lightGray,
          ...this.calculateCoolingTPMetrics(),
        },
        yAxis: {
          title: false,
          min: 0,
          max: 100,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          lineColor: this.isEnabled ? this.colors.darkGray : "transparent",
          lineWidth: 1,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          tickWidth: 0,
          lineColor: this.isEnabled ? this.colors.darkGray : "transparent",
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
              defer: 0,
            },
            enableMouseTracking: false,
            marker: {
              enabled: false,
            },
            lineWidth: this.width / 600,
          },
        },
        series: [
          {
            color: "black",
            name: "Temp",
            data: [
              [0, 0],
              [this.actualControl.raw > 0 ? 20 : 25, 0],
              [this.actualControl.raw > 0 ? 20 : 25, 10],
              [25, 10],
              [90, 90],
              [100, 90],
            ],
          },
          {
            color: "black",
            name: "actual control horizontal",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.actualMeasurementX, this.actualMeasurementY],
              [101, this.actualMeasurementY],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "setpoint + bandwidth top dash",
            dashStyle: "dash",
            data: [
              [0, 90],
              [90, 90],
              [90, 0],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "setpoint bottom dash",
            dashStyle: "dash",
            data: [
              [0, 10],
              [25, 10],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "hysteresis",
            dashStyle: "dash",
            data: [
              [this.actualControl.raw > 0 ? 25 : 20, 10],
              [this.actualControl.raw > 0 ? 25 : 20, 0],
            ],
          },
          {
            color: "black",
            name: "actualMeasurement vertical",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.actualMeasurementX, 101],
              [this.actualMeasurementX, this.actualMeasurementY],
            ],
          },
        ],
      };
    },
    coolingTPMargin() {
      const x = this.marginX;
      const y = this.coolingTPHeight * 0.125;
      return [y, x, y, x];
    },
    pulseHeight() {
      return this.height * 0.25;
    },
    coolingTPHeight() {
      return this.height * 0.75;
    },
    marginX() {
      return this.width * 0.2;
    },
    marginEndX() {
      return this.width * 0.1;
    },
    actualMeasurementX() {
      // line is under setPoint
      if (this.actualMeasurement.raw <= this.setpoint.raw) {
        const x =
          25 +
          ((this.actualMeasurement.raw - this.setpoint.raw) * (25 - 20)) /
            this.hysteresis;
        return Math.max(0, x);
      }

      //else it's between min and max (on diagonal)
      const factor =
        (this.actualMeasurement.raw - this.setpoint.raw) / this.bandwidth.raw;
      const x = 25 + 65 * factor;
      return Math.min(100, x);
    },
    actualMeasurementY() {
      if (this.actualControl.raw <= 0) {
        return 0;
      }

      const factor =
        (this.actualControl.raw - this.min.raw) / (this.max.raw - this.min.raw);
      const y = 10 + 80 * factor;
      return this.minMax(y, 10, 90);
    },
  },
  methods: {
    calculateCoolingTPMetrics() {
      return {
        width: this.width,
        height: this.coolingTPHeight,
        margin: this.coolingTPMargin,
        spacing: [0, 0, 0, 0],
      };
    },
    coolingTPRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 20;
        const radius = this.plotMetrics.height / 30;

        this.renderActualMeasurementText();
        this.renderMinMaxText(radius);
        this.renderSetPointText(radius);

        //bandwidth text with circle
        this.renderBandWidthText(radius);
        this.renderActualControlText();
        this.renderTPIcon();

        this.renderThermometerIcon();

        this.renderText(
          this.units.DEGREE,
          this.plotMetrics.endX + this.plotMetrics.width / 5,
          this.plotMetrics.endY
        );
      } catch (err) {
        console.error(err);
      }
    },
    renderActualMeasurementText() {
      const actualMeasurementX = this.translateX(this.actualMeasurementX);
      this.renderTextCentered(
        `${this.actualMeasurement.formatted}${this.units.DEGREE}`,
        actualMeasurementX,
        this.plotMetrics.height * 0.05
      );
    },
    renderMinMaxText(radius) {
      const minY = this.translateY(10);
      this.renderTimeTextLeft(this.min, this.plotMetrics.x, radius, minY);
      const maxY = this.translateY(90);
      this.renderTimeTextLeft(this.max, this.plotMetrics.x, radius, maxY);
    },
    renderTimeTextLeft(value, plotX, radius, absoluteY) {
      const timeValueText = this.formatTime(value.raw);
      const afterMaxX = this.renderTextCentered(
        timeValueText,
        plotX / 2.5,
        absoluteY - this.charWidth
      );
      const circleX = afterMaxX + radius * 2;
      this.renderCircle(circleX, absoluteY, radius);
    },
    renderSetPointText(radius) {
      const minX = this.translateX(25);
      const setPointCircleX = this.renderTextCentered(
        `${this.setpoint.formatted}${this.units.DEGREE}`,
        minX,
        this.plotMetrics.endY
      );
      const circleY = this.plotMetrics.endY + radius * 1.3;

      if (this.isEnabled) {
        const circleX = setPointCircleX + radius * 2;
        this.renderCircle(circleX, circleY, radius);
      }
    },
    renderBandWidthText(radius) {
      const circleY = this.plotMetrics.endY + radius * 1.3;
      const maxX = this.translateX(90);
      const bandwidthCircleX = this.renderTextCentered(
        `${this.bandwidth.formatted}${this.units.DEGREE}`,
        maxX,
        this.plotMetrics.endY
      );
      const circleX = bandwidthCircleX + radius * 2;
      this.renderCircle(circleX, circleY, radius);
    },
    renderActualControlText() {
      const actualControlY =
        this.translateY(this.actualMeasurementY) - this.charWidth;
      this.renderText(
        this.formatTime(this.actualControl.raw),
        this.plotMetrics.endX,
        actualControlY
      );
    },
    renderTPIcon() {
      const tPIconX = this.chartMetrics.x;
      const tPIconY = this.plotMetrics.y / 2;
      const tPIconWidth = this.plotMetrics.x * 0.4;
      const tPIconHeight = this.plotMetrics.height * 0.1;
      this.renderIconS3(
        this.tPIcon,
        tPIconX,
        tPIconY,
        tPIconWidth,
        tPIconHeight
      );
    },
    renderThermometerIcon() {
      const thermosIconWidth = this.plotMetrics.x / 2;
      const thermosIconX = this.plotMetrics.endX + thermosIconWidth / 2;
      const thermosIconY = this.plotMetrics.endY;
      const thermosIconHeight = this.plotMetrics.height / 8;
      this.renderIconS3(
        this.tempIcon,
        thermosIconX,
        thermosIconY,
        thermosIconWidth,
        thermosIconHeight
      );
    },
  },
  components: {
    PulseComponent,
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.coolingTPRenderCallback);
  },
};
</script>

<style scoped></style>
