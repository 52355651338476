<template>
  <div
    @click="toggle()"
    class="nav-bar-menu-icon arrow"
    :class="[$store.state.sideNav ? 'is-active' : '']"
  >
    <div class="nav-bar-menu-icon-box">
      <div class="nav-bar-menu-icon-inner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBarMenuIcon",
  methods: {
    toggle() {
      this.$store.dispatch("toggleSideNav", !this.$store.state.sideNav);
    },
  },
};
</script>

<style scoped>
.nav-bar-menu-icon {
  background-color: transparent;
  opacity: 0.35;
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}

.nav-bar-menu-icon:hover {
  border-radius: 10px;
  opacity: 0.6;
}

.nav-bar-menu-icon-box {
  width: 25px;
  height: 10px;
  display: inline-block;
  position: relative;
}

.nav-bar-menu-icon-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.nav-bar-menu-icon-inner,
.nav-bar-menu-icon-inner::before,
.nav-bar-menu-icon-inner::after {
  width: 25px;
  height: 4px;
  background-color: #000;
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.nav-bar-menu-icon-inner::before,
.nav-bar-menu-icon-inner::after {
  content: "";
  display: block;
}

.nav-bar-menu-icon-inner::before {
  top: -7px;
}

.nav-bar-menu-icon-inner::after {
  bottom: -7px;
}

.arrow.is-active .nav-bar-menu-icon-inner::before {
  transform: translate3d(-4px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.arrow.is-active .nav-bar-menu-icon-inner::after {
  transform: translate3d(-4px, 0, 0) rotate(45deg) scale(0.7, 1);
}
</style>
