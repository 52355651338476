<template>
  <div>
    <v-toolbar flat>
      <v-col md="6">
        <StienenDatePicker v-on:refresh="refresh" v-model="dates" />
      </v-col>

      <v-spacer />

      <v-tooltip bottom>
        <template #activator="{ on }">
          <base-button icon v-on="on" @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </base-button>
        </template>
        {{ $t("history.refreshTooltip") }}
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <base-button icon v-on="on" @click="onexport">
            <v-icon>mdi-export</v-icon>
          </base-button>
        </template>
        {{ $t("history.exportTooltip") }}
      </v-tooltip>
    </v-toolbar>

    <v-data-table
      :headers="getHeaders"
      :items="alarmRecords"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      multi-sort
    />
  </div>
</template>

<script>
import {
  GetAlarmHistoryByDevice,
  GetAlarmHistoryByLogin,
} from "@api/config-service";
import { isDef } from "@utils/helpers";
import { mapGetters } from "vuex";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import StienenDatePicker from "@components/StienenDatePicker";
import { _compose } from "@modules/rtcpu/render/screen-bll";
import { createCsv } from "@stienen/Export";

const headers = [
  {
    value: "Index",
    text: "#",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "Index",
      hideForMoreDevices: false,
    },
  },
  {
    value: "Timestamp",
    text: "Timestamp",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "DatumTijd",
      hideForMoreDevices: false,
    },
  },
  {
    value: "Gateway",
    text: "Gateway",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "Gateway",
      hideForMoreDevices: true,
    },
  },
  {
    value: "Device",
    text: "Device",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "Apparaat",
      hideForMoreDevices: true,
    },
  },
  {
    value: "AlarmStatus",
    text: "AlarmStatus",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "AlarmStatus",
      hideForMoreDevices: false,
    },
  },
  {
    value: "AlarmCode",
    text: "AlarmCode",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "AlarmCode",
      hideForMoreDevices: false,
    },
  },
  {
    value: "ControlType",
    text: "ControlType",
    align: "right",
    meta: {
      userLevel: 900,
      translationLabel: "Regeling",
      hideForMoreDevices: false,
    },
  },
  {
    value: "AlarmCode_",
    text: "AlarmCode",
    align: "right",
    meta: {
      userLevel: 300,
      translationLabel: "AlarmCode",
      hideForMoreDevices: false,
      suffix: " #",
    },
  },
  {
    value: "ControlType_",
    text: "ControlType",
    align: "right",
    meta: {
      userLevel: 300,
      translationLabel: "Regeling",
      hideForMoreDevices: false,
      suffix: " #",
    },
  },
];

export default {
  name: "AlarmHistory",
  props: ["values", "fullState"],
  components: {
    StienenDatePicker,
  },
  data() {
    return {
      sortBy: [],
      sortDesc: [],
      menu: false,
      dates: [],
      alarmRecords: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserEmail"]),
    ...mapGetters("settings", ["getLanguage"]),
    ...mapGetters("deviceCache", ["getDeviceMeta", "getTranslation"]),
    ...mapGetters([
      "getGatewayName",
      "getSelectedGateway",
      "getSelectedDevice",
    ]),
    deviceKnown() {
      return this.dev !== -1;
    },
    getHeaders() {
      // hideForMoreDevices was byLogin, this is a quick fix
      return headers
        .filter(
          (header) => header.meta.userLevel >= this.getSelectedDevice.role
        )
        .filter(
          (header) =>
            !header.meta.hideForMoreDevices ||
            this.fullState.Devices.length !== 1
        )
        .map((header) => {
          // check if byLogin was necessary
          // if (header.meta.userLevel > this.getRole && (this.deviceKnown !== header.meta.byLogin)) {
          const title = this.getTranslation({
            dev: this.dev,
            label: header.meta.translationLabel,
          });

          const translatedHeader = {
            ...header,
            text: title ? title : header.text,
          };

          if (
            header.meta.hasOwnProperty("suffix") &&
            isDef(header.meta.suffix)
          ) {
            translatedHeader.text += header.meta.suffix;
          }
          return translatedHeader;
        });
    },
    columnName() {
      return this.getHeaders.map((header) => header.value);
    },
    dev() {
      if (this.device) return this.device;
      else if (
        isDef(this.values) &&
        isDef(this.fullState) &&
        this.values.length > 1
      ) {
        return this.fullState.Devices[this.values[1]].Device;
      }
      return -1;
    },
  },
  methods: {
    async fetchData(begin, end) {
      end = new Date(
        Date.parse(end) + DateTimeConverter.MSEC_DAY
      ).toISOString();
      const data = this.deviceKnown
        ? await GetAlarmHistoryByDevice(
            this.dev.id,
            begin,
            end,
            this.getLanguage
          )
        : await GetAlarmHistoryByLogin(begin, end, this.getLanguage);
      this.process(data);
    },
    getDeviceName(id) {
      const dev = this.getDeviceMeta(id);
      return isDef(dev) ? dev.Name : "";
    },
    getDateTimeText(timestamp) {
      const date = DateTimeConverter.parseDateLocalTime(timestamp);
      return `${DateTimeConverter.dateToString(
        date
      )} ${DateTimeConverter.timeToString(date)}`;
    },
    getAlarmStateText(alarmType) {
      switch (alarmType) {
        case 0:
          return this.getTranslation({ label: "Uit" });
        case 1:
          return this.getTranslation({ label: "Aan" });
        case 2:
          return "Blocked"; //todo translate?
        case 3:
          return "Code changed"; //todo translate?
        default:
          return alarmType;
      }
    },
    process(data) {
      if (isDef(data) && Array.isArray(data) && data.length > 0) {
        this.alarmRecords = data.map((r) => {
          const result = {
            Index: r.Index,
            Timestamp: this.getDateTimeText(r.Timestamp),
            AlarmStatus: this.getAlarmStateText(r.AlarmType),
            AlarmCode: _compose(r.AlarmString, [r.AlarmExtra]),
            ControlType: _compose(r.ControlString, [r.ControlNr]),
          };

          if (this.columnName.includes("AlarmCode_")) {
            result["AlarmCode_"] = r.AlarmCode;
          }
          if (this.columnName.includes("ControlType_")) {
            result["ControlType_"] = r.ControlType;
          }
          if (this.columnName.includes("Gateway")) {
            result["Gateway"] = this.getGatewayName(r.Gid);
          }
          if (this.columnName.includes("Device")) {
            result["Device"] = this.getDeviceName(r.Did);
          }
          return result;
        });
      }
    },
    onexport() {
      const delimiter = ";";
      const endLine = "\r\n";
      if (isDef(this.alarmRecords) && this.alarmRecords.length > 0) {
        const caption = `AlarmLog_${this.getUserEmail}`;
        const header = Object.keys(this.alarmRecords[0]).join(delimiter);
        const rows = this.alarmRecords.map((r) =>
          Object.values(r).join(delimiter)
        );
        rows.unshift(header);
        createCsv(
          rows.join(endLine),
          caption,
          this.getSelectedDevice.hasOwnProperty("name")
            ? this.getSelectedDevice.name
            : "",
          this.getSelectedGateway.hasOwnProperty("name")
            ? this.getSelectedGateway.name
            : ""
        );
      }
    },
    refresh() {
      const [begin, end] = this.dates;
      this.fetchData(begin, end);
    },
  },
};
</script>

<style scoped></style>
