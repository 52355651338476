<template>
  <div>
    <subscription-device-scan :devices="devices" hide-difference scanning>
      <template #title>
        <v-icon class="mr-4" color="primary" x-large> mdi-magnify </v-icon>
        {{
          $t("setup.step4.scanningForDevices", { gatewayName: gateway.name })
        }}
      </template>
    </subscription-device-scan>

    <slot />

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button outlined text @click="$emit('back')">
          {{ $t("common.back") }}
        </base-button>
      </template>
      <template #btn2>
        <base-button
          :disabled="!devices.length"
          :loading="loading"
          @click="complete"
        >
          {{ $t("common.confirm") }}
        </base-button>
      </template>
    </button-holder>
  </div>
</template>

<script>
import SubscriptionDeviceScan from "@components/admin/subscription/SubscriptionDeviceScan";
import { mapGetters } from "vuex";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "DeviceScanInitial",
  components: {
    ButtonHolder,
    SubscriptionDeviceScan,
  },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getGateways", "getAllOwnedDevices"]),
    ...mapGetters("subscription", ["getCreatedGateway"]),
    gateway() {
      return this.getCreatedGateway || this.getGateways[0] || {};
    },
    devices() {
      return this.getAllOwnedDevices.filter(
        (device) => device.gatewayId === this.gateway.id
      );
    },
    availableDevices() {
      return this.getAllOwnedDevices.filter((d) => d.available);
    },
  },
  methods: {
    async complete() {
      this.loading = true;
      this.$emit("complete", this.availableDevices);
    },
  },
};
</script>

<style scoped></style>
