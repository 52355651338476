import * as types from "@state/modules/error/mutation-types";

function notifyUser(
  { state, commit },
  { color = "warning", text = "Unspecified error", show = true }
) {
  commit(types.PUSH_NOTIFICATION, {
    color,
    text,
    show,
    index: state.notifications.length,
  });
}

function hideNotification({ commit }, index) {
  commit(types.HIDE_NOTIFICATION, index);
}

export default {
  notifyUser,
  hideNotification,
};
