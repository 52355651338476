import { notifyUserTranslation } from "@error/helpers";

const cancelToken = axios.CancelToken;
const source = cancelToken.source();
const Callbacks = [];

import axios from "axios";
import store from "@state/store";
import { apiClient } from "@api/base-client";

//this messenger might need abort function in the future
//todo refactor flow

const config = {
  timeout: 0, //60 * 1000, // default is `0` (no timeout, from client side)
  cancelToken: source.token,
};

const endPoint = process.env.VUE_APP_ROOT_API + "Messenger.ashx";
const messengerClient = apiClient(endPoint, (response) => response.data);

function makeRequestToMessenger() {
  try {
    if (!store.getters["auth/loggedIn"]) {
      return;
    }

    const params = { Stamp: new Date().toISOString() };
    messengerClient
      .get("", { params, ...config })
      .then((rsp) => onRequestSuccess(rsp))
      .catch((e) => onRequestError(e));
  } catch (err) {
    retryRequestIn10Seconds();
    console.error(err);
  }
}

async function onRequestSuccess(data) {
  try {
    await store.dispatch("updateStreamerStatus", true, { root: true });
    data.forEach((msg) => Callbacks.forEach((cb) => cb(msg)));
    makeRequestToMessenger();
  } catch (err) {
    console.error(err);
    retryRequestIn10Seconds();
    await store.dispatch("updateStreamerStatus", false, { root: true });
    notifyUserTranslation("error.streamerDisconnected", "error");
  }
}

async function onRequestError(err) {
  console.error(err);
  retryRequestIn10Seconds();
  await store.dispatch("updateStreamerStatus", false, { root: true });
}

function retryRequestIn10Seconds() {
  setTimeout(makeRequestToMessenger, 10000);
}

export const Messenger = {
  kick: makeRequestToMessenger,
  RegisterCallback: (callback) => Callbacks.push(callback),
};
