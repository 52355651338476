<template>
  <layout-default>
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="4">
          <gateway-info-card :gateway="getSelectedGateway" />
        </v-col>

        <v-col cols="12" lg="4">
          <gateway-status-card :gateway="getSelectedGateway" />
        </v-col>

        <v-col cols="12" lg="4">
          <gateway-statistics-card
            :gateway="getSelectedGateway"
            :refresh-key="refreshKey"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="7">
          <device-list-card
            @updated="refresh"
            :devices="getDevicesOnSelectedGateway"
            :key="refreshKey"
          />
        </v-col>
        <v-col cols="12" lg="5">
          <gateway-uptime-chart :gateway="getSelectedGateway" />
        </v-col>
      </v-row>
    </v-container>
  </layout-default>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceListCard from "@components/overview/cards/DeviceListCard";
import GatewayUptimeChart from "@components/overview/cards/GatewayUptimeChart";
import GatewayInfoCard from "@components/overview/cards/GatewayInfoCard";
import GatewayStatusCard from "@components/overview/cards/GatewayStatusCard";
import GatewayStatisticsCard from "@components/overview/cards/GatewayStatisticsCard";

export default {
  name: "GatewayOverviewPage",
  components: {
    GatewayStatisticsCard,
    GatewayStatusCard,
    GatewayInfoCard,
    GatewayUptimeChart,
    DeviceListCard,
  },
  data() {
    return {
      refreshTimerId: null,
      refreshInterval: 10 * 1000,
      refreshKey: new Date().toString(),
    };
  },
  computed: {
    ...mapGetters(["getSelectedGateway", "getDevicesOnSelectedGateway"]),
    ...mapGetters("auth", ["getImpersonatedCustomer"]),
  },
  methods: {
    async refresh() {
      const { id } = this.getImpersonatedCustomer || {};
      await this.$store.dispatch("fetchGatewayStates", id);
      this.refreshKey = new Date().toString();
      // this.$forceUpdate(); //doesn't work here
    },
  },
  async mounted() {
    await this.refresh();
    this.refreshTimerId = setInterval(this.refresh, this.refreshInterval);
  },
  beforeDestroy() {
    clearInterval(this.refreshTimerId);
  },
};
</script>

<style scoped></style>
