<template>
  <highcharts
    :options="curveTemperatureOptions"
    :callback="curveTemperatureRenderCallback"
  />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const temperatureIcon = "wdg_temp_green";
const temperatureIconDisabled = "wdg_temp_gray";

export default {
  name: "WidgetCurveTemperature",
  props: {
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 50,
    },
  },
  mixins: [WidgetBase],
  computed: {
    temperatureIcon() {
      return this.isEnabled ? temperatureIcon : temperatureIconDisabled;
    },
    curveTemperatureOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: "transparent",
          ...this.calculateMetrics(),
        },
      };
    },
  },
  methods: {
    curveTemperatureRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.chartMetrics.height / 3.5;
        this.renderActualMeasurement();
      } catch (err) {
        console.error(err);
      }
    },
    renderActualMeasurement() {
      const iconWidth = this.chartMetrics.width * 0.1;
      const iconHeight = this.chartMetrics.height * 0.7;
      const iconY = this.chartMetrics.y + this.chartMetrics.height * 0.1;

      this.renderIconS3(
        this.temperatureIcon,
        this.chartMetrics.x,
        iconY,
        iconWidth,
        iconHeight
      );
      const actualMeasurementX =
        this.chartMetrics.x + iconWidth - this.charWidth;
      return this.renderText(
        `${this.actualMeasurement.formatted}${this.units.DEGREE}`,
        actualMeasurementX,
        this.chartMetrics.centerY - this.charWidth
      );
    },
    calculateMetrics() {
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
      };
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.curveTemperatureRenderCallback);
  },
};
</script>

<style scoped></style>
