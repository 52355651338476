/* How to use

Parameters StepControl
1: DeviceIndex	(Template = 0)
2: MaxSteps		(R2_UITAANTAL)
3: HalfStep		(R2_HALFSTEP)
4: CurrentState	(R2_STAP)

Parameters Proportional
1: DeviceIndex	(Template = 0)
2: MaxSteps		(R1_UITAANTAL)
3: HalfStep		(0)
4: CurrentState	(R1_STAP1)

Add variables into the edit values table
Use the given name as parameters for the widget

*/
import {Core} from './Core'
import {isDef, isUndef} from "@utils/helpers";
import {SpecifiedError} from "@error/types";

const HalfStepOn = require('@assets/img/step-control/HalfStepOn.png');
const HalfStepOff = require('@assets/img/step-control/HalfStepOff.png');
const FullStepOn = require('@assets/img/step-control/FullStepOn.png');
const FullStepOff = require('@assets/img/step-control/FullStepOff.png');

export function stepControl(target, attr, loc, values) {
    const maxSteps = values[1];
    const halfStep = values[2];
    const currentState = values[3];

    // Check parameters
    if (isDef(maxSteps) && isDef(halfStep) && isDef(currentState)) {

        const state = {
            target,
            attr,
            canvas: null,
            maxStepsValue: null,
            halfStepValue: null,
            currentStateValue: null
        };

        const maxStepsCb = setMaxSteps.bind(state);
        const currentStateCb = setCurrentState.bind(state);

        maxSteps.registerCallback(maxStepsCb);
        currentState.registerCallback(currentStateCb);

        if (halfStep !== "0") {
            const halfStepCb = setHalfStep.bind(state);
            halfStep.registerCallback(halfStepCb);
        }
    }
}


function setMaxSteps(data) {
    if (this.maxStepsValue !== data) {
        this.maxStepsValue = data;
        draw.apply(this);
    }
}

function setHalfStep(data) {
    if (this.halfStepValue !== data) {
        this.halfStepValue = data;
        draw.apply(this);
    }
}

function setCurrentState(data) {
    if (this.currentStateValue !== data) {
        this.currentStateValue = data;
        draw.apply(this);
    }
}

function draw() {
    let {target, attr, canvas, maxStepsValue, halfStepValue, currentStateValue} = this;
    if (isUndef(maxStepsValue) || isUndef(currentStateValue)) return;
    if (canvas !== null) canvas.remove();
    canvas = Core.createCanvas(target, attr, (maxStepsValue * 28), 30);
    if (halfStepValue === 1) {
        const halfStepActive = currentStateValue % 2;
        const stepActive = parseInt(currentStateValue / 2);

        if (halfStepActive === 1) {
            canvas.image(HalfStepOn, 0, 4, 26, 26);
        } else {
            canvas.image(HalfStepOff, 0, 4, 26, 26);
        }

        for (let i = 1; i < maxStepsValue; i++) {
            if (i <= stepActive) {
                canvas.image(FullStepOn, (i * 28), 4, 26, 26);
            } else {
                canvas.image(FullStepOff, (i * 28), 4, 26, 26);
            }
        }
    } else {
        for (let i = 0; i < maxStepsValue; i++) {
            if (i < currentStateValue) {
                canvas.image(FullStepOn, (i * 28), 4, 26, 26);
            } else {
                canvas.image(FullStepOff, (i * 28), 4, 26, 26);
            }
        }
    }
}
