<template>
  <Promised :promise="getLcScreen">
    <template #pending>
      <base-spinner />
    </template>

    <template #default="{ screenDefinition, mappedValues, device }">
      <LcScreenRender
        :screenDefinition="screenDefinition"
        :mappedValues="mappedValues"
        :device="device"
      />
    </template>

    <template #rejected="error">
      <lc-error-card>
        {{ $t(error) }}
      </lc-error-card>
    </template>
  </Promised>
</template>

<script>
export default {
  name: "LcDeviceScreen",
  data() {
    return {
      disposition: null,
    };
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    screenId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    getLcScreen() {
      // I don't know what changes but initially Vue update is called 6 times
      if (!this.disposition) {
        /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
        this.disposition = this.$store.dispatch("lcRemote/lcSelectScreen", {
          deviceId: this.deviceId,
          screenId: this.screenId,
        });
      }
      return this.disposition;
    },
  },
};
</script>

<style scoped></style>
