<template>
  <LayoutDefault>
    <CurrentAlarmOverview />
  </LayoutDefault>
</template>

<script>
import CurrentAlarmOverview from "@components/CurrentAlarmOverview";

export default {
  name: "AlarmPage",
  components: {
    CurrentAlarmOverview,
  },
  page: {
    title: "Alarm Page",
  },
};
</script>

<style scoped></style>
