import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import { DateTimeConverter } from "@stienen/DateTimeConverter";

const state = {
  streamerConnected: true,
  streamerLastStatusUpdate: DateTimeConverter.formatDate(Date.now()),
  gateways: [],
  gatewayStates: [],
  screenGroups: [],
  currentAlarmDevices: [],
  alarmGatewayFilter: null,
  alarmsLoaded: false,
  selectedGateway: {},
  selectedDevice: {},
  sideNav: false,
  loading: false,
  loadingText: undefined,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
