<template>
  <highcharts :options="curveOptions" :callback="curveRenderCallback" />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import { WdgUnitValues } from "@modules/lccpu/model/enum-components";

const dateIcon = "date_green";
const dateIconDisabled = "wdg_date_gray";

export default {
  name: "WidgetCurve",
  props: {
    count: {
      type: Number,
      required: true,
    },
    unitX: {
      type: String,
      required: true,
    },
    unitY1: {
      type: String,
      required: true,
    },
    unitY2: {
      type: String,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    currentPointX: {
      type: Number,
      required: true,
    },

    yAxisNrG1: {
      type: Number,
      required: false,
      default: 1,
    },
    yAxisNrG2: {
      type: Number,
      required: false,
      default: 1,
    },
    yAxisNrG3: {
      type: Number,
      required: false,
      default: 1,
    },

    countG1: {
      type: Number,
      required: false,
    },
    countG2: {
      type: Number,
      required: false,
    },
    countG3: {
      type: Number,
      required: false,
    },

    colorG1: {
      type: String,
      required: false,
      default: "black",
    },
    colorG2: {
      type: String,
      required: false,
      default: "black",
    },
    colorG3: {
      type: String,
      required: false,
      default: "black",
    },

    pointXG1: {
      type: Array,
      required: false,
    },
    pointYG1: {
      type: Array,
      required: false,
    },
    pointXG2: {
      type: Array,
      required: false,
    },
    pointYG2: {
      type: Array,
      required: false,
    },
    pointXG3: {
      type: Array,
      required: false,
    },
    pointYG3: {
      type: Array,
      required: false,
    },

    width: {
      type: Number,
      default: 272,
    },
    height: {
      type: Number,
      default: 168,
    },
  },
  mixins: [WidgetBase],
  computed: {
    enabledColor() {
      return this.isEnabled ? this.colors.black : this.colors.disabledGray;
    },
    dateIcon() {
      return this.isEnabled ? dateIcon : dateIconDisabled;
    },
    curveOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: "transparent",
          plotBackgroundColor: this.isEnabled
            ? this.colors.lightGreen
            : this.colors.lightGray,
          ...this.calculateMetrics(),
          style: {
            fontFamily: "lc-font",
          },
        },
        plotOptions: {
          series: {
            lineWidth: this.width / 300,
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 200,
            },
          },
        },
        xAxis: {
          labels: {
            style: {
              color: this.enabledColor,
            },
          },
          tickAmount: 10,
          lineColor: "gray",
          tickLength: this.width / 50,
          tickWidth: this.height / 200,
          tickColor: "gray",
          gridLineColor: "lightgray",
          gridLineWidth: 1,
          endOnTick: true,
          tickPositions: this.calculateTickPositionsX(),
        },
        yAxis: [
          {
            title: false,
            labels: {
              style: {
                color: this.enabledColor,
              },
            },
            allowDecimals: false,
            minorTickInterval: 1,
            tickAmount: 5,
            lineColor: "gray",
            lineWidth: this.height / 200,
            tickLength: this.width / 50,
            tickWidth: this.height / 200,
            tickColor: "gray",
            gridLineColor: "lightgray",
            gridLineWidth: 1,
            minorGridLineColor: "transparent",
          },
          {
            title: false,
            labels: {
              style: {
                color: this.enabledColor,
              },
            },
            opposite: true,
            allowDecimals: false,
            tickAmount: 5,
            lineColor: "gray",
            lineWidth: this.height / 200,
            tickLength: this.width / 50,
            tickWidth: this.height / 200,
            tickColor: "gray",
            gridLineColor: "transparent",
            gridLineWidth: 1,
            minorGridLineColor: "transparent",
          },
        ],
        series: this.series,
      };
    },
    series() {
      const series = [];
      // 'G' var index starts with 1..
      for (let lineNumber = 1; lineNumber <= this.count; lineNumber++) {
        const data = [];

        const numberOfPoints = this[`countG${lineNumber}`];
        const xValues = this[`pointXG${lineNumber}`];
        const yValues = this[`pointYG${lineNumber}`];
        const yAxis = this[`yAxisNrG${lineNumber}`] - 1;
        const color = this[`colorG${lineNumber}`];

        for (
          let lineNumberPointIndex = 0;
          lineNumberPointIndex < numberOfPoints;
          lineNumberPointIndex++
        ) {
          data.push([
            xValues[lineNumberPointIndex],
            yValues[lineNumberPointIndex],
          ]);
        }

        series.push({
          name: "",
          data,
          yAxis,
          color: this.isEnabled ? color : this.colors.disabledGray,
        });
      }
      return series;
    },
  },
  methods: {
    calculateTickPositionsX() {
      const min = this.pointXG1[0];
      const max = this.pointXG1[3];

      //dit zelf gemaakt, maar klopt niet #1825
      const result = [];

      const totalDifference = Math.abs(max - min);
      const step = Math.ceil(totalDifference / 9);

      for (let i = 0; i < 10; i++) {
        result.push(min + i * step);
      }

      return result;
    },
    curveRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.strokeWidth = Math.min(this.plotMetrics.height / 200, 5);
        this.charWidth = this.plotMetrics.height / 18;

        this.renderCurrentPointX();
        this.renderEndOfLines();
        this.renderUnits();
        this.renderDateIcon();
      } catch (err) {
        console.error(err);
      }
    },
    renderCurrentPointX() {
      const limited = this.minMax(
        this.currentPointX,
        this.plotMetrics.minGraphValueX,
        this.plotMetrics.maxGraphValueX
      );
      const x = this.translateX(
        Math.abs(limited - this.plotMetrics.minGraphValueX)
      );
      const currentPointXLine = [
        "M",
        x,
        this.plotMetrics.y,
        "L",
        x,
        this.plotMetrics.endY,
      ];

      this.renderLine(currentPointXLine, { color: this.enabledColor });
      this.renderTextCentered(
        this.currentPointX.toString(),
        x,
        this.chartMetrics.y,
        this.enabledColor
      );
    },
    renderEndOfLines() {
      for (let lineNumber = 1; lineNumber <= this.count; lineNumber++) {
        const max = this[`countG${lineNumber}`];
        const series = this.chart.series[lineNumber - 1];

        const { x, y } = series.points[max - 1];

        const yAxisNr = this[`yAxisNrG${lineNumber}`];

        const multiplierY =
          yAxisNr === 1
            ? this.plotMetrics.oneAmountInPixelsY
            : this.plotMetrics.oneAmountInPixelsY2;
        const minValueY =
          yAxisNr === 1
            ? this.plotMetrics.minGraphValueY
            : this.plotMetrics.minGraphValueY2;

        const lineEndX = this.translateX(
          Math.abs(x - this.plotMetrics.minGraphValueX)
        );
        const lineEndY =
          this.plotMetrics.endY - multiplierY * Math.abs(y - minValueY); //kan met translateY?

        // this line can go after #1825 is fixed
        if (lineEndX > this.plotMetrics.endX) return;

        let line = [
          "M",
          lineEndX,
          lineEndY,
          "L",
          this.plotMetrics.endX,
          lineEndY,
        ];

        setTimeout(
          () => this.renderLine(line, { color: this.enabledColor }),
          1000
        );
      }
    },
    renderUnits() {
      const unit1Text = WdgUnitValues[this.unitY1];
      const unit2Text = WdgUnitValues[this.unitY2];
      this.renderText(
        unit1Text,
        this.chartMetrics.x,
        this.chartMetrics.y,
        this.enabledColor
      );
      this.renderText(
        unit2Text,
        this.chartMetrics.x +
          this.chartMetrics.width -
          this.charWidth * unit2Text.length,
        this.chartMetrics.y,
        this.enabledColor
      );
    },
    renderDateIcon() {
      const width = this.chartMetrics.width * 0.08;
      const height = this.chartMetrics.height * 0.08;

      this.renderIconS3(
        this.dateIcon,
        this.plotMetrics.endX + width / 2,
        this.plotMetrics.endY + height / 2,
        width,
        height
      );
    },
    calculateMetrics() {
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin: [
          this.height / 10,
          this.width * 0.13,
          this.height * 0.15,
          this.width * 0.13,
        ],
      };
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.curveRenderCallback);
  },
};
</script>

<style scoped></style>
