import { isValidDeviceObj } from "@utils/validators";
import { isDef } from "@utils/helpers";

export const screenElementBasics = {
  props: {
    loc: {
      type: Object,
      required: true,
      validator: (loc) => loc.hasOwnProperty("Value"),
    },
    state: {
      type: Object,
      required: false,
      default: () => {},
    },
    device: {
      type: Object,
      required: false,
      validator: (dev) => isValidDeviceObj(dev),
    },
  },
  computed: {
    val_obj() {
      return isDef(this.loc.Value) &&
        isDef(this.state) &&
        this.state.hasOwnProperty(this.loc.Value)
        ? this.state[this.loc.Value]
        : null;
    },
    dev() {
      if (isDef(this.device)) {
        return this.device;
      }
      if (isDef(this.val_obj) && this.val_obj.hasOwnProperty("Dev")) {
        return this.val_obj.Dev;
      }
      return null;
    },
    extra() {
      return this.loc.hasOwnProperty("Extra") &&
        isDef(this.loc.Extra) &&
        typeof this.loc.Extra === "string"
        ? this.loc.Extra.split(",")
        : this.loc.Extra;
    },
  },
};
