<template>
  <div>
    <PulseComponent
      :isEnabled="isPulsMode"
      :max="pulsCycleTime"
      :pulsTime="pulsPauseTime"
      :currentTime="actualTime"
      :width="width"
      :height="pulseHeight"
    />
    <highcharts
      :options="stepVentChartOptions"
      :callback="stepVentRenderCallback"
    />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import PulseComponent from "@modules/lccpu/components/widgets/PulseComponent";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const ventilationIcon = "wdg_vent_on";
const temperatureIcon = "wdg_temp_green";

export default {
  name: "WidgetStepVentilation",
  props: {
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    actualControl: {
      type: LcWidgetValue,
      required: true,
    },
    setpoint: {
      type: LcWidgetValue,
      required: true,
    },
    bandwidth: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    stepDown: {
      type: LcWidgetValue,
      required: true,
    },
    stepUp: {
      type: LcWidgetValue,
      required: true,
    },
    isPulsMode: {
      type: [Boolean, Number],
      required: true,
    },
    isPulsActive: {
      type: [Boolean, Number],
      required: true,
    },
    pulsCycleTime: {
      type: Number,
      required: true,
    },
    pulsPauseTime: {
      type: Number,
      required: true,
    },
    actualTime: {
      type: Number,
      required: true,
    },
    isCurveEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    height: {
      type: Number,
      default: 270,
    },
  },
  mixins: [WidgetBase],
  computed: {
    stepVentChartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.colors.lightGreen,
          ...this.calculateTemperatureMetrics(),
        },
        yAxis: {
          title: false,
          min: 0,
          max: 100,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          lineColor: this.colors.darkGray,
          lineWidth: 1,
        },
        xAxis: {
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          tickWidth: 0,
          lineColor: this.colors.darkGray,
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0,
              defer: 0,
            },
            enableMouseTracking: false,
            marker: {
              enabled: false,
            },
            lineWidth: this.width / 600,
          },
        },
        series: [
          {
            color: "black",
            name: "Temp",
            data: [
              [0, 10],
              [25, 10],
              [90, 90],
              [100, 90],
            ],
          },
          {
            color: "black",
            name: "actual control horizontal",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.actualControlX, this.actualControlY],
              [101, this.actualControlY],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "setpoint + bandwidth top dash",
            dashStyle: "dash",
            data: [
              [0, 90],
              [90, 90],
              [90, 0],
            ],
          },
          {
            color: this.colors.darkGray,
            name: "setpoint bottom dash",
            dashStyle: "dash",
            data: [[25, 10]],
          },
          {
            color: this.colors.darkGray,
            name: "hysteresis",
            dashStyle: "dash",
            data: [
              [this.actualControl.raw > 0 ? 25 : 20, 10],
              [this.actualControl.raw > 0 ? 25 : 20, 0],
            ],
          },
          {
            color: "black",
            name: "actualMeasurement vertical",
            marker: {
              enabled: true,
              symbol: "circle",
              radius: this.width / 300,
            },
            data: [
              [this.calculateXForVerticalLine(this.actualMeasurement.raw), 101],
              [
                this.calculateXForVerticalLine(this.actualMeasurement.raw),
                this.calculateYForVerticalLine(this.actualMeasurement.raw),
              ],
            ],
          },
          {
            color: this.stepDown.raw ? "gray" : "transparent",
            name: "actualMeasurement vertical",
            dashStyle: "shortdash",
            data: [
              [this.calculateXForVerticalLine(this.stepDown.raw), 95],
              [
                this.calculateXForVerticalLine(this.stepDown.raw),
                this.calculateYForVerticalLine(this.stepDown.raw),
              ],
            ],
          },
          {
            color: this.stepUp.raw ? "gray" : "transparent",
            name: "actualMeasurement vertical",
            dashStyle: "shortdash",
            data: [
              [this.calculateXForVerticalLine(this.stepUp.raw), 95],
              [
                this.calculateXForVerticalLine(this.stepUp.raw),
                this.calculateYForVerticalLine(this.stepUp.raw),
              ],
            ],
          },
        ],
      };
    },
    temperatureMargin() {
      const x = this.width * 0.2;
      const y = this.temperatureHeight * 0.125;
      return [y, x, y, x];
    },
    pulseHeight() {
      return this.height * 0.25;
    },
    temperatureHeight() {
      return this.height * 0.75;
    },
    actualControlX() {
      const factor =
        (this.actualControl.raw - this.min.raw) / (this.max.raw - this.min.raw);
      const x = 25 + 65 * factor;
      return this.minMax(x, 25, 90);
    },
    actualControlY() {
      const factor =
        (this.actualControl.raw - this.min.raw) / (this.max.raw - this.min.raw);
      const y = 10 + 80 * factor;
      return this.minMax(y, 10, 90);
    },
  },
  methods: {
    calculateTemperatureMetrics() {
      return {
        width: this.width,
        height: this.temperatureHeight,
        margin: this.temperatureMargin,
        spacing: [0, 0, 0, 0],
      };
    },
    calculateXForVerticalLine(input) {
      // line is under setPoint
      if (input <= this.setpoint.raw) {
        //this calculation is also from embedded
        const x = 25 + (input - this.setpoint.raw) * (25 - 20);
        return Math.max(0, x);
      }

      //else it's between min and max (on diagonal) and scales linear
      const factor = (input - this.setpoint.raw) / this.bandwidth.raw;
      const x = 25 + 65 * factor;
      return Math.min(100, x);
    },
    calculateYForVerticalLine(value) {
      const factor = (value - this.setpoint.raw) / this.bandwidth.raw;
      const y = 10 + 80 * factor;
      return this.minMax(y, 10, 90);
    },
    stepVentRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 20;
        const radius = this.plotMetrics.height / 30;

        this.renderActualMeasurementText();
        if (this.stepUp.raw) {
          this.renderStepUpText();
        }

        if (this.stepDown.raw) {
          this.renderStepDownText();
        }

        this.renderMinMaxText(radius);
        this.renderSetPointText(radius);
        this.renderBandWidthText(radius);
        this.renderActualControlText();

        this.renderVentilationIcon();
        this.renderThermometerIcon();
      } catch (err) {
        console.error(err);
      }
    },
    renderActualMeasurementText() {
      const actualMeasurementX = this.translateX(
        this.calculateXForVerticalLine(this.actualMeasurement.raw)
      );
      this.renderTextCentered(
        this.actualMeasurement.formatted,
        actualMeasurementX,
        this.plotMetrics.height * 0.05
      );
    },
    renderStepUpText() {
      const stepUpX =
        this.translateX(this.calculateXForVerticalLine(this.stepUp.raw)) +
        this.charWidth * 3;
      this.renderTextCentered(
        this.stepUp.formatted,
        stepUpX,
        this.plotMetrics.y,
        "gray"
      );
    },
    renderStepDownText() {
      const stepDownX =
        this.translateX(this.calculateXForVerticalLine(this.stepDown.raw)) -
        this.charWidth * 3;
      this.renderTextCentered(
        this.stepDown.formatted,
        stepDownX,
        this.plotMetrics.y,
        "gray"
      );
    },
    renderMinMaxText(radius) {
      const minY = this.translateY(10);
      this.renderVentilationTextLeft(this.min, radius, minY);
      const maxY = this.translateY(90);
      this.renderVentilationTextLeft(this.max, radius, maxY);
    },
    renderVentilationTextLeft(value, radius, absoluteY) {
      const afterMaxX = this.renderTextCentered(
        value.formatted,
        this.plotMetrics.x / 2,
        absoluteY - this.charWidth
      );
      if (this.isCurveEnabled) {
        const circleX = afterMaxX + radius;
        this.renderCircle(circleX, absoluteY, radius);
      }
    },
    renderSetPointText(radius) {
      const minX = this.translateX(25);
      const setPointCircleX = this.renderTextCentered(
        this.setpoint.formatted,
        minX,
        this.plotMetrics.endY
      );
      const circleY = this.plotMetrics.endY + radius * 1.3;

      if (this.isCurveEnabled) {
        const circleX = setPointCircleX + radius * 2;
        this.renderCircle(circleX, circleY, radius);
      }
    },
    renderBandWidthText(radius) {
      const maxX = this.translateX(90);
      const circleY = this.plotMetrics.endY + radius * 1.3;
      const bandwidthCircleX = this.renderTextCentered(
        this.bandwidth.formatted,
        maxX,
        this.plotMetrics.endY
      );
      const circleX = bandwidthCircleX + radius * 2;
      this.renderCircle(circleX, circleY, radius);
    },
    renderActualControlText() {
      const actualControlY =
        this.translateY(this.actualControlY) - this.charWidth;
      this.renderText(
        this.actualControl.formatted,
        this.plotMetrics.endX,
        actualControlY
      );
    },
    renderVentilationIcon() {
      const ventilationIconX = this.plotMetrics.x * 0.05;
      const ventilationIconY = this.plotMetrics.y / 2;
      const ventilationIconWidth = this.plotMetrics.x * 0.4;
      const ventilationIconHeight = this.plotMetrics.height * 0.1;
      this.renderIconS3(
        ventilationIcon,
        ventilationIconX,
        ventilationIconY,
        ventilationIconWidth,
        ventilationIconHeight
      );

      //🐓🐥🍗🐔
      this.renderText(
        `${this.units.VENTILATION_PER_ANIMAL}`,
        ventilationIconX + ventilationIconWidth * 0.9,
        ventilationIconY
      );
    },
    renderThermometerIcon() {
      const thermosIconWidth = this.plotMetrics.x / 2;
      const thermosIconX = this.plotMetrics.endX + thermosIconWidth / 2;
      const thermosIconY = this.plotMetrics.endY;
      const thermosIconHeight = this.plotMetrics.height / 8;
      this.renderIconS3(
        temperatureIcon,
        thermosIconX,
        thermosIconY,
        thermosIconWidth,
        thermosIconHeight
      );
      this.renderText(
        this.units.DEGREE,
        this.plotMetrics.endX + this.plotMetrics.width / 5,
        this.plotMetrics.endY
      );
    },
  },
  components: {
    PulseComponent,
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.stepVentRenderCallback);
  },
};
</script>

<style scoped></style>
