<template>
  <div class="fill-height">
    <NavBar>
      <portal-target name="navBarEnd" />
    </NavBar>
    <NavDrawer />

    <v-main class="fill-height">
      <v-container class="pa-0">
        <slot />
      </v-container>
    </v-main>

    <NavFooter />
  </div>
</template>
<script>
import NavBar from "@components/navbar/NavBar";
import NavDrawer from "@components/navdrawer/NavDrawer";
import NavFooter from "@components/navfooter/NavFooter";

export default {
  name: "LayoutDefaultPortrait",
  components: {
    NavBar,
    NavDrawer,
    NavFooter,
  },
};
</script>

<style scoped></style>
