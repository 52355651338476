import * as types from "./mutation-types";

export default {
  [types.SET_USER_INVITES]: (state, userInvites) =>
    (state.userInvites = userInvites),
  [types.UPDATE_USER_INVITE]: (state, userInvite) => {
    const existing = state.userInvites.find(
      (i) => i.userId === userInvite.userId
    );
    if (existing) {
      Object.assign(existing, userInvite);
    } else {
      state.userInvites.push(userInvite);
    }
  },
  [types.DELETE_USER]: (state, deletedUser) => {
    const i = state.userInvites
      .map((i) => i.userId)
      .indexOf(deletedUser.userId);
    if (i !== -1) state.userInvites.splice(i, 1);
  },
  [types.SET_DEALERS]: (state, dealers) => (state.dealers = dealers),
  [types.SET_DEALER]: (state, newDealer) => {
    const existingDealer = state.dealers.find(
      (dealer) => dealer.id === newDealer.id
    );
    if (existingDealer) {
      Object.assign(existingDealer, newDealer);
    } else {
      state.dealers.push(newDealer);
    }
  },
  [types.REMOVE_DEALER]: (state, dealer) => {
    const i = state.dealers.map((i) => i.id).indexOf(dealer.id);
    if (i !== -1) state.dealers.splice(i, 1);
  },
};
