<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title>
        {{
          $t("editMode.sourceDeviceLabel", {
            deviceName: getSelectedDevice.name,
            gatewayName: getSelectedDevice.gatewayName,
          })
        }}
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="availableDevices"
        item-key="id"
        show-select
        class="elevation-1"
        @toggle-select-all="toggleSelectAll"
        @item-selected="toggle"
        selectable-key="isLicenced"
      />

      <v-card-actions>
        <v-spacer />
        <base-button text outlined @click="cancel">
          {{ $t("common.cancel") }}
        </base-button>
        <base-button @click="accept">
          {{ $t("common.confirm") }}
        </base-button>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "SendToMultiple",
  props: ["show"],
  data() {
    return {
      headers: [
        {
          text: this.$t("common.device"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("common.gateway"),
          align: "left",
          sortable: false,
          value: "gatewayName",
        },
      ],
    };
  },
  created() {
    this.availableDevices.forEach((dev) => {
      dev.selected = !!this.eventDevices.find((obj) => obj.id === dev.id);
    });
  },
  computed: {
    ...mapGetters(["getCompatibleDevices", "getSelectedDevice"]),
    ...mapState("edit", {
      SendToMultipleActive: (state) => state.SendToMultipleActive,
      eventDevices: (state) => state.devices,
    }),
    availableDevices() {
      return this.getCompatibleDevices.filter((dev) => {
        if (dev.id !== this.getSelectedDevice.id) {
          const result = cloneDeep(dev);
          result.selected = false;
          return result;
        }
      });
    },
  },
  methods: {
    accept() {
      const selected = this.availableDevices.filter((dev) => dev.selected);
      selected.push(this.getSelectedDevice);
      this.$store.commit("edit/SET_EVENT_DEVICES", selected, { root: true });
      this.$store.commit("edit/TOGGLE_SEND_TO_MULTIPLE_ACTIVE");
    },
    cancel() {
      this.$store.commit("edit/TOGGLE_SEND_TO_MULTIPLE_ACTIVE");
    },
    toggleSelectAll({ value }) {
      this.getCompatibleDevices
        .filter((d) => d.isLicenced)
        .forEach((dev) => (dev.selected = value));
    },
    toggle({ item, value }) {
      this.getCompatibleDevices.find(
        (device) => device.id === item.id
      ).selected = value;
    },
  },
};
</script>

<style scoped></style>
