import { tempmeter, ventmeter } from "@stienen/widgets/Meter";
import { silo } from "@stienen/widgets/Silo";
import { led, ledbar, ledvu } from "@stienen/widgets/Leds";
import { stepControl } from "@stienen/widgets/StepControl";
import { gauge270 } from "@stienen/widgets/Gauge";
import dashClock from "@modules/rtcpu/components/widgets/DashClock";
import alarmHistory from "@modules/rtcpu/components/widgets/AlarmHistory";

export const Widgets = {
  alarmHistory,
  dashClock,
  tempmeter,
  ventmeter,
  led,
  ledbar,
  ledvu,
  silo,
  stepControl,
  gauge270,
};
