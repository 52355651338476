<template>
  <LayoutDefault>
    <div :class="this.$vuetify.breakpoint.lgAndUp ? '' : 'scroll'">
      <LcDeviceScreen :device-id="deviceId" :screenId="this.screenId" />
    </div>
  </LayoutDefault>
</template>
<script>
import LcDeviceScreen from "@modules/lccpu/render/LcDeviceScreen";

export default {
  name: "LcRemotePage",
  page: {
    title: "Remote",
  },
  components: {
    LcDeviceScreen,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    screenId: {
      type: [Number, String],
      required: false,
      default: -1,
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow: scroll;
}
</style>
