<template>
  <div>
    <portal to="btn1">
      <slot name="btn1"></slot>
    </portal>
    <portal to="btn2">
      <slot name="btn2"></slot>
    </portal>
  </div>
</template>

<script>
export default {
  name: "PortraitButtons",
};
</script>

<style scoped></style>
