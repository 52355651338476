<template>
  <v-app v-resize="onResize">
    <component :is="layout">
      <router-view :layout.sync="layout" :key="$route.fullPath" />
    </component>
    <notification-queue />
    <portal-target name="modals" style="z-index: 6" />
  </v-app>
</template>

<script>
import NotificationQueue from "@components/notification/NotificationQueue";
import LayoutEmpty from "@layouts/LayoutEmpty";

export default {
  name: "app",
  components: {
    NotificationQueue,
  },
  data() {
    return {
      layout: LayoutEmpty,
    };
  },
  methods: {
    onResize() {
      this.$store.dispatch("layout/detectLayout");
    },
  },
  mounted() {
    document.title = `${this.$appTitle} ${this.$appVersion}`;
    this.$store.dispatch("settings/initBrowserLanguage");
  },
};
</script>

<style lang="scss">
@import "../public/assets/css/stienen.css";

@font-face {
  font-family: "lc-font";
  src: url(assets/font/lucidia/Lucida-Console-Regular.ttf);
}
</style>
