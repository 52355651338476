<template>
  <span :name="val_obj.RefName">{{ combinedText }}</span>
</template>

<script>
import { _compose } from "../render/screen-bll";
import { mapGetters } from "vuex";
import { screenElementBasics } from "@mixins/props";

export default {
  name: "RtAlarmText",
  mixins: [screenElementBasics],
  data() {
    return {
      text: "",
    };
  },
  computed: {
    ...mapGetters("deviceCache", ["getAlarmText", "getValue"]),
    codeValue() {
      return this.getValue(this.dev, this.val_obj.RefName);
    },
    extraValue() {
      return this.getValue(this.dev, this.state[this.extra[0]].RefName);
    },
    combinedText() {
      let extra = this.extraValue;
      let { id, text } = this.getAlarmText(this.dev, this.codeValue);
      if (id && id < 0) {
        extra = this.codeValue;
      }
      return text ? _compose(text, [extra]) : this.codeValue;
    },
  },
};
</script>

<style scoped></style>
