<script>
import { screenElementBasics } from "@mixins/props";
import { Widgets } from "@modules/rtcpu/components/widgets/Widgets";
import { isDef } from "@utils/helpers";

export default {
  name: "RtWidget",
  mixins: [screenElementBasics],
  props: ["fullState"],
  inheritAttrs: false,
  data() {
    return {
      watchers: [],
      widget: null,
    };
  },
  computed: {
    isVueComponent() {
      return (
        isDef(this.widget) &&
        typeof this.widget !== "function" &&
        this.widget.hasOwnProperty("render")
      );
    },
    widgetValues() {
      return this.extra.map((name) => {
        const val_obj = this.state[name];
        return val_obj ? val_obj : name;
      });
    },
  },
  render(h) {
    try {
      if (this.isVueComponent) {
        if (this.widgetValues.length > 0) {
          return h(this.widget, {
            props: {
              values: this.widgetValues,
              fullState: this.fullState,
            },
            attrs: this.$attrs,
          });
        } else {
          return h("span", "template incorrect: no data");
        }
      } else {
        // todo: TEST PLACEHOLDER
        // this might mess create a flash on the page because on next tick it will be replaced
        return h("span", { ref: "widget" }, `placeholder: ${this.loc.Value}`);
      }
    } catch (err) {
      console.error(err);
      return h("span", `error: ${this.loc.Value}`);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const target = this.$refs.widget;
      if (target && isDef(this.widget) && typeof this.widget === "function") {
        try {
          this.widget(
            target,
            this.$attrs,
            this.loc,
            this.widgetValues,
            this.fullState
          );
        } catch (err) {
          console.warn(
            "Screens__function_build__case8: Exception creating widget " +
              this.loc.Value +
              ": "
          );
          console.warn(err);
        }
      }
    });
  },
  created() {
    this.widget = Widgets[this.loc.Value];
  },
  beforeDestroy() {
    this.watchers.forEach((unwatch) => unwatch());
  },
};
</script>

<style></style>
