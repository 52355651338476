<template>
  <v-form ref="form" v-model="valid" :disabled="disabled">
    <v-card :loading="loading" outlined>
      <v-card-title> {{ $t("setup.step2.fc2Login") }}</v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-model="url"
                :disabled="loading"
                :label="$t('common.server')"
                :rules="urlRules"
                prepend-icon="mdi-cloud-upload-outline"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="userName"
                :disabled="loading"
                :label="$t('common.username')"
                :rules="userNameRules"
                prepend-icon="mdi-account"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="password"
                :disabled="loading"
                :label="$t('common.password')"
                :rules="passwordRules"
                prepend-icon="mdi-lock"
                required
                type="password"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button :disabled="loading" outlined text @click="$emit('back')">
          {{ $t("common.back") }}
        </base-button>
      </template>
      <template #btn2>
        <base-button :disabled="!valid" :loading="loading" @click="validate">
          {{ $t("common.login") }}
        </base-button>
      </template>
    </button-holder>
  </v-form>
</template>

<script>
import { util } from "@components/admin/setup/migration/auth/crypto";
import { HMAC } from "@components/admin/setup/migration/auth/hmac";
import { SHA256 } from "@components/admin/setup/migration/auth/sha256";
import { adminService } from "@api/admin-service";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";
import { notifyUser } from "@error/helpers";

export default {
  name: "Fc2Authenticator",
  components: { ButtonHolder },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      url: "",
      urlRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.server") }),
        (v) => !!v.startsWith("https://") || this.$t("error.startHttps"),
      ],
      userName: "",
      userNameRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.username") }),
      ],
      password: "",
      passwordRules: [
        (v) =>
          !!v ||
          this.$t("error.isRequired", { field: this.$t("common.password") }),
      ],
      endpoint: "Config.svc/Gateways",
    };
  },
  computed: {
    date() {
      return Date.now();
    },
    strippedUrl() {
      return this.url.replace(/\/+$/, "");
    },
    fullUrl() {
      return `${this.strippedUrl}/webservice/${this.endpoint}?_=${this.date}`;
    },
    pass() {
      return util.bytesToBase64(
        HMAC(SHA256, this.password, this.userName, { asBytes: true })
      );
    },
    authToken() {
      return util.bytesToBase64(
        HMAC(SHA256, this.fullUrl, util.base64ToBytes(this.pass), {
          asBytes: true,
        })
      );
    },
    authHeader() {
      return `${this.userName},${this.authToken}`;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.fetchGateways();
      }
    },
    async fetchGateways() {
      this.loading = true;

      try {
        const migration = await adminService.fetchMigration(
          this.strippedUrl,
          this.authHeader,
          this.date
        );

        this.$emit("onComplete", migration, this.strippedUrl);
      } catch (err) {
        notifyUser("error", "wrong credentials");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
