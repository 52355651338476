<template>
  <div>
    <v-row no-gutters style="height: 100vh">
      <div
        v-if="numberOfNavButtons"
        class="d-flex px-4 pt-2"
        style="background-color: #007c60"
      >
        <div
          class="d-flex flex-column justify-start"
          v-for="(column, columnIndex) in Math.ceil(numberOfNavButtons / 4)"
          :key="columnIndex"
        >
          <div v-for="(row, rowIndex) in 4" :key="rowIndex">
            <slot
              name="navigation"
              v-bind="{ item: column, row, index: columnIndex * 4 + rowIndex }"
            >
              <div style="height: 64px" />
            </slot>
          </div>
        </div>
      </div>

      <div v-if="numberOfNavButtons" class="vertical-nav-end-bar" />

      <div
        :style="{
          'background-color': numberOfNavButtons ? '#f8f8f8' : 'transparent',
        }"
        @click="$emit('clicked')"
      >
        <slot name="content" />
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LcScreenLandscape",
  props: {
    numberOfNavButtons: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.vertical-nav-end-bar {
  height: 100vh;
  width: 6px;
  border-right: 4px solid #007c60;
}
</style>
