export const isEmptyString = s => (!s && typeof s === 'string' && (s === "" || !s.trim()));

export const isEmptyObjectOrArray = o => {
    return (
        isDef(o)
        && (typeof o !== 'string')
        && ((Array.isArray(o) && o.length === 0) || (isObject(o) && Object.keys(o).length === 0)));
};

export const resolveAfter = function (ms, promise) {
    // always resolves
    let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
            clearTimeout(id);
            resolve();
        }, ms);
        return 'TIMED_OUT';
    });

    // Returns a race between the timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ])
};

// !! copied from vue source code !!
// These helpers produce better VM code in JS engines due to their
// explicitness and function inlining.
export function isDef(v) {
    return v !== undefined && v !== null;
}

export function isUndef(v) {
    return v === undefined || v === null;
}

/**
 * Quick object check - this is primarily used to tell
 * Objects from primitive values when we know the value
 * is a JSON-compliant type.
 */
export function isObject(obj) {
    return obj !== null && typeof obj === 'object';
}

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
