<template>
  <base-card :error="error" :title="title">
    <v-divider class="mx-4" />

    <v-list>
      <slot name="1">
        <info-card-row style="height: 62px" />
      </slot>
      <v-divider class="mx-4" />
      <slot name="2">
        <info-card-row style="height: 62px" />
      </slot>
      <v-divider class="mx-4" />
      <slot name="3">
        <info-card-row style="height: 62px" />
      </slot>
    </v-list>

    <slot />
  </base-card>
</template>

<script>
import InfoCardRow from "@components/base/InfoCardRow";

export default {
  name: "InfoCard",
  components: { InfoCardRow },
  props: {
    title: {
      type: [String, typeof TranslateResult],
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
