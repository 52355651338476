import store from "@state/store";
import { translateStatic } from "@plugins/helpers";

export const createSuccessObject = (data) => ({
  ok: true,
  data,
});

export const createErrorObject = (e) => ({
  ok: false,
  error: e instanceof Error ? e : new Error(e),
});

export const notifyUserTranslation = (
  translationKey,
  color = undefined,
  params = null
) =>
  notifyUser({
    text: translateStatic(translationKey, params),
    color,
  });

//maybe call notifyUser with $t? seems better
export const notifyUser = (color, text, show) => {
  const args = typeof color === "string" ? { color, text, show } : color;
  store.dispatch("errorService/notifyUser", args, { root: true });
};
