import { _isSameVersion } from "../modules/rt-remote/bll";
import { isDef, isEmptyString } from "@utils/helpers";

const getSelectedGateway = (state) => state.selectedGateway;
const getGateways = (state) => state.gateways;
const getOwnedGateways = (state) => state.gateways.filter((g) => g.owner);
const getGatewayStates = (state) => state.gatewayStates;
const getSelectedDevice = (state) => state.selectedDevice;
const getGatewayName = (state) => (gatewayId) =>
  state.gateways.find((gateway) => gateway.id === gatewayId).name;
const getAllDevices = (state) =>
  state.gateways.reduce((acc, gateway) => acc.concat(gateway.devices), []);
const getAllOwnedDevices = (state, getters) =>
  getters.getOwnedGateways.reduce(
    (acc, gateway) => acc.concat(gateway.devices),
    []
  );

const getDevicesOnSelectedGateway = (state, getters) =>
  getters.getSelectedGateway?.devices || [];

const getGatewayById = (state) => (gatewayId) =>
  state.gateways.find((gateway) => gateway.id === gatewayId);

const checkConnection = (state, getters) => (gatewayId) => {
  const gateway = getters.getGatewayById(gatewayId);
  return gateway ? gateway.isConnected && state.streamerConnected : false;
};
const getCompatibleDevices = (state, getters) =>
  getters.getDevicesOnSelectedGateway.filter((other) =>
    _isSameVersion(getters.getSelectedDevice, other)
  );

const getAllUserScreenGroups = (state) =>
  state.screenGroups
    .filter(
      (group) =>
        !(
          group.Hidden ||
          group.Name.startsWith("_") ||
          group.Name.startsWith("__")
        )
    )
    .map((group) => ({
      ...group,
      Screens: group.Screens.filter((screen) => isEmptyString(screen.Valid)),
    }));

const getCustomScreens = (state, getters) =>
  getters.getAllUserScreenGroups.flatMap((g) => g.Screens);

const getCustomScreenById = (state) => (id) =>
  state.screenGroups.flatMap((g) => g.Screens).find((s) => s.Id === id);

const getAllOwnedScreenGroups = (state, getters) =>
  getters.getAllUserScreenGroups.filter((s) => s.owner);

const getAlarmsForGateway = (state) => (gatewayId) =>
  state.currentAlarmDevices.filter((alarm) => alarm.Gid === gatewayId);

const getCurrentAlarmsFiltered = (state, getters) =>
  isDef(state.alarmGatewayFilter)
    ? getters.getAlarmsForGateway(state.alarmGatewayFilter)
    : state.currentAlarmDevices;

export default {
  getGatewayName,
  getGateways,
  getOwnedGateways,
  getAllDevices,
  getAllOwnedDevices,
  getGatewayStates,
  getSelectedGateway,
  getSelectedDevice,
  getDevicesOnSelectedGateway,
  getCompatibleDevices,
  getGatewayById,
  checkConnection,
  getAllUserScreenGroups,
  getCustomScreens,
  getCustomScreenById,
  getAllOwnedScreenGroups,
  getAlarmsForGateway,
  getCurrentAlarmsFiltered,
};
