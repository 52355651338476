import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

import en from "vuetify/lib/locale/en";
import nl from "vuetify/lib/locale/nl";

Vue.use(Vuetify);

// light background green #dbeae7
// light background red   #ffe3de

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Colors
        primary: "#008478",
        primaryDark: "#006c63",
        success: "#008478",
        info: "#008478",
        secondary: "#b0bec5",
        warning: "#ffbb33",
        yellowDark: colors.yellow.darken2,
        error: "#ff4444",
        white: "#ffffff",
        black: "#000000",
        level1: "#2E2D2C",
        level2: "#3C3C3B",
        level3: "#4A4A49",
        level4: "#575756",
        level5: "#646363",
        grey05: "#f6f6f6",
        grey10: "#ededed",
        grey20: "#dadada",
        grey30: "#c6c6c6",
        grey40: "#b2b2b2",
        grey50: "#9d9d9c",
        grey60: "#878787",
        grey70: "#706f6f",
        grey80: "#575756",
        grey90: "#3c3c3b",
        "background-color": colors.grey.lighten4,
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: {
      en,
      nl,
    },
    current: en,
  },
});
