import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import Amplify, { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

const amplifyConfig = {
  region: "eu-central-1",
  userPoolId: process.env.VUE_APP_AWS_COGNITO_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_AWS_APP_CLIENT_ID,
};

Amplify.configure(amplifyConfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

//does not work in translation files

I18n.putVocabulariesForLanguage("en", {
  [Translations.NO_ACCOUNT_TEXT]: "Bought FarmConnect?",
});

I18n.putVocabulariesForLanguage("nl", {
  [Translations.BACK_TO_SIGN_IN]: "Terug naar inloggen",
  [Translations.CHANGE_PASSWORD_ACTION]: "Wijzigen",
  [Translations.CHANGE_PASSWORD]: "Wachtwoord wijzigen",
  [Translations.CODE_LABEL]: "Verificatiecode",
  [Translations.CODE_PLACEHOLDER]: "Voer code in",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Bevestigingscode",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Voer uw code in",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Aanmelden bevestigen",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Je code kwijt?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Code opnieuw verzenden",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Bevestigen",
  [Translations.CONFIRM_SMS_CODE]: "Bevestig SMS-code",
  [Translations.CONFIRM_TOTP_CODE]: "TOTP-code bevestigen",
  [Translations.CONFIRM]: "Bevestigen",
  [Translations.CREATE_ACCOUNT_TEXT]: "Account maken",
  [Translations.EMAIL_LABEL]: "E-mailadres *",
  [Translations.EMAIL_PLACEHOLDER]: "Vul uw e-mailadres in",
  [Translations.FORGOT_PASSWORD_TEXT]: "Wachtwoord vergeten?",
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:
    "Minder dan twee MFA-typen beschikbaar",
  [Translations.NEW_PASSWORD_LABEL]: "Nieuw wachtwoord",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Voer uw nieuwe wachtwoord in",
  [Translations.NO_ACCOUNT_TEXT]: "FarmConnect gekocht?",
  [Translations.USERNAME_REMOVE_WHITESPACE]:
    "Gebruikersnaam mag geen spaties bevatten",
  [Translations.PASSWORD_REMOVE_WHITESPACE]:
    "Wachtwoord kan niet beginnen of eindigen met een spatie",
  [Translations.PASSWORD_LABEL]: "Wachtwoord *",
  [Translations.PASSWORD_PLACEHOLDER]: "Voer uw wachtwoord in",
  [Translations.PHONE_LABEL]: "Telefoonnummer *",
  [Translations.PHONE_PLACEHOLDER]: "(555) 555-1212",
  [Translations.QR_CODE_ALT]: "qrcode",
  [Translations.RESET_PASSWORD_TEXT]: "Wachtwoord opnieuw instellen",
  [Translations.RESET_YOUR_PASSWORD]: "Reset uw wachtwoord",
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: "Selecteer MFA-type",
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: "Verifiëren",
  [Translations.SEND_CODE]: "Code verzenden",
  [Translations.SUBMIT]: "Verzenden",
  [Translations.SETUP_TOTP_REQUIRED]: "TOTP moet worden geconfigureerd",
  [Translations.SIGN_IN_ACTION]: "Aanmelden",
  [Translations.SIGN_IN_HEADER_TEXT]: "Log in op uw account",
  [Translations.SIGN_IN_TEXT]: "Aanmelden",
  [Translations.SIGN_IN_WITH_AMAZON]: "Aanmelden bij Amazon",
  [Translations.SIGN_IN_WITH_AUTH0]: "Aanmelden met Auth0",
  [Translations.SIGN_IN_WITH_AWS]: "Aanmelden met AWS",
  [Translations.SIGN_IN_WITH_FACEBOOK]: "Aanmelden met Facebook",
  [Translations.SIGN_IN_WITH_GOOGLE]: "Aanmelden met Google",
  [Translations.SIGN_OUT]: "Uitloggen",
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "E-mail",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Heb je een account?",
  [Translations.SIGN_UP_HEADER_TEXT]: "Maak een nieuw account",
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "Wachtwoord",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Account maken",
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "Gebruikersnaam",
  [Translations.SKIP]: "Overslaan",
  [Translations.SUCCESS_MFA_TYPE]: "Succes! Uw MFA-type is nu:",
  [Translations.TOTP_HEADER_TEXT]: "Scannen en verificatiecode invoeren",
  [Translations.TOTP_LABEL]: "Vul beveiligingscode in:",
  [Translations.TOTP_ISSUER]: "AWSCognito",
  [Translations.TOTP_SETUP_FAILURE]: "TOTP-configuratie is mislukt",
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: "Beveiligingstoken verifiëren",
  [Translations.TOTP_SUCCESS_MESSAGE]: "ToTP succesvol instellen!",
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:
    "Mislukt! Kan MFA op dit moment niet configureren",
  [Translations.USERNAME_LABEL]: "Gebruikersnaam *",
  [Translations.USERNAME_PLACEHOLDER]: "Vul uw gebruikersnaam in",
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: "E-mail",
  [Translations.VERIFY_CONTACT_HEADER_TEXT]:
    "Accountherstel vereist geverifieerde contactgegevens",
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: "Telefoonnummer",
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: "Verzenden",
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: "Verifiëren",
  [Translations.ADDRESS_LABEL]: "Adres",
  [Translations.ADDRESS_PLACEHOLDER]: "Voer uw adres in",
  [Translations.NICKNAME_LABEL]: "Bijnaam",
  [Translations.NICKNAME_PLACEHOLDER]: "Vul je bijnaam in",
  [Translations.BIRTHDATE_LABEL]: "Verjaardag",
  [Translations.BIRTHDATE_PLACEHOLDER]: "Vul je geboortedatum in",
  [Translations.PICTURE_LABEL]: "Afbeelding-URL",
  [Translations.PICTURE_PLACEHOLDER]: "Voer uw afbeeldings-URL in",
  [Translations.FAMILY_NAME_LABEL]: "Familienaam",
  [Translations.FAMILY_NAME_PLACEHOLDER]: "Vul uw achternaam in",
  [Translations.PREFERRED_USERNAME_LABEL]: "Voorkeursgebruikersnaam",
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]:
    "Vul uw gewenste gebruikersnaam in",
  [Translations.GENDER_LABEL]: "Geslacht",
  [Translations.GENDER_PLACEHOLDER]: "Vul uw geslacht in",
  [Translations.PROFILE_LABEL]: "Profiel-URL",
  [Translations.PROFILE_PLACEHOLDER]: "Voer uw profiel-URL in",
  [Translations.GIVEN_NAME_LABEL]: "Voornaam",
  [Translations.GIVEN_NAME_PLACEHOLDER]: "Vul je voornaam in",
  [Translations.ZONEINFO_LABEL]: "Tijdzone",
  [Translations.ZONEINFO_PLACEHOLDER]: "Voer uw tijdzone in",
  [Translations.LOCALE_LABEL]: "Lokaal",
  [Translations.LOCALE_PLACEHOLDER]: "Voer uw landinstelling in",
  [Translations.UPDATED_AT_LABEL]: "Bijgewerkt om",
  [Translations.UPDATED_AT_PLACEHOLDER]:
    "Voer de tijd in waarop de informatie voor het laatst is bijgewerkt",
  [Translations.MIDDLE_NAME_LABEL]: "Tweede naam",
  [Translations.MIDDLE_NAME_PLACEHOLDER]: "Voer je tweede naam in",
  [Translations.WEBSITE_LABEL]: "Website",
  [Translations.WEBSITE_PLACEHOLDER]: "Voer uw website in",
  [Translations.NAME_LABEL]: "Volledige naam",
  [Translations.NAME_PLACEHOLDER]: "Vul je volledige naam in",
  [Translations.PHOTO_PICKER_TITLE]: "Titel kiezer",
  [Translations.PHOTO_PICKER_HINT]:
    "Aanvullende tekst of inhoud kan deze ruimte hier innemen",
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]:
    "Tip voor tijdelijke aanduiding",
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: "Knop",
  [Translations.IMAGE_PICKER_TITLE]: "Voeg profielfoto toe",
  [Translations.IMAGE_PICKER_HINT]: "Bekijk de afbeelding voor het uploaden",
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]:
    "Tik om afbeelding te selecteren",
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: "Uploaden",
  [Translations.PICKER_TEXT]: "Kies een bestand",
  [Translations.TEXT_FALLBACK_CONTENT]: "Terugvalinhoud",
  [Translations.CONFIRM_SIGN_UP_FAILED]: "Bevestigen aanmelding mislukt",
  [Translations.SIGN_UP_FAILED]: "Aanmelden mislukt",
});
