<template>
  <v-form ref="form" v-model="valid" :disabled="disabled">
    <v-card :loading="loading" outlined>
      <v-card-title>
        {{ $t("setup.headers.addGateway") }}
        <span v-if="getMigratingGateway !== null">
          &nbsp;{{
            $t("setup.step2.migratingGateway", {
              gatewayName: getMigratingGateway.Name,
            })
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-model="gatewayName"
                :counter="maxGatewayNameLength"
                :disabled="loading"
                :label="$t('setup.step2.chooseName')"
                :rules="gatewayNameRules"
                prepend-icon="mdi-router-wireless-settings"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="licenceKey"
                :counter="licenceKeyLength"
                :counter-value="counter"
                :disabled="loading"
                :label="$t('setup.step2.licenceKey')"
                :rules="licenceKeyRules"
                prepend-icon="mdi-key"
                required
                text-transform="uppercase"
              >
                <template #append-outer>
                  <v-icon v-if="isPortrait && licenceKey" @click="qrScanShow">
                    mdi-qrcode-edit
                  </v-icon>
                  <card-help-dialog v-else />
                </template>
              </v-text-field>

              <base-button
                v-if="isPortrait && !licenceKey"
                class="mt-4"
                style="width: 100%"
                @click="qrScanShow"
              >
                <v-icon class="pe-4"> mdi-qrcode-scan </v-icon>
                {{ $t("setup.step2.orScanQrCode") }}
              </base-button>

              <add-gateway-q-r
                v-if="showCamera"
                :initial-show="initialOpen"
                class="mt-4"
                @onBack="qrScanHide"
                @onDecode="qrScanDone"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn1>
        <base-button :disabled="loading" outlined text @click="onBack">
          {{ $t("common.back") }}
        </base-button>
      </template>
      <template #btn2>
        <base-button :disabled="!valid" :loading="loading" @click="validate">
          {{ $t("common.validate") }}
        </base-button>
      </template>
    </button-holder>
  </v-form>
</template>

<script>
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";
import AddGatewayQR from "@components/admin/setup/AddGatewayQR";
import CardHelpDialog from "@components/admin/setup/CardHelpDialog";
import { mapGetters } from "vuex";

export default {
  name: "GatewayValidation",
  components: {
    ButtonHolder,
    AddGatewayQR,
    CardHelpDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showCamera: false,
      valid: false,
      loading: false,
      maxGatewayNameLength: 30,
      web485Key: null,
      gatewayName: "",
      gatewayNameRules: [
        (v) =>
          !!v || this.$t("error.isRequired", { field: this.$t("common.name") }),
        (v) =>
          (v && v.length <= this.maxGatewayNameLength) ||
          this.$t("setup.step2.nameLength", {
            length: this.maxGatewayNameLength,
          }),
      ],
      licenceKeyLength: 12,
      licenceKey: "",
      initialOpen: false,
      licenceKeyRules: [
        (v) =>
          !!v || this.$t("error.isRequired", { field: this.$t("common.key") }),
        (v) =>
          (v && v.replace(/-/gi, "").length === this.licenceKeyLength) ||
          this.$t("setup.step2.keyLength", { length: this.licenceKeyLength }),
      ],
    };
  },
  computed: {
    ...mapGetters("layout", ["isPortrait"]),
    ...mapGetters("subscription", ["getMigratingGateway"]),
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.createGateway();
      }
    },
    async createGateway() {
      this.loading = true;

      const createdGatewayOk = await this.$store.dispatch(
        "subscription/createGateway",
        {
          gatewayFc2: {
            ...this.getMigratingGateway,
            Name: this.gatewayName,
          },
          licenceKey: this.licenceKey,
        }
      );

      this.loading = false;

      if (createdGatewayOk) {
        this.$refs.form.reset();
        this.$emit("next");
      }
    },
    counter(value) {
      return value ? value.replace(/-/gi, "").length : 0;
    },
    qrScanDone(qrResult) {
      this.licenceKey = qrResult;
      this.showCamera = false;
    },
    qrScanShow() {
      this.showCamera = true;
    },
    qrScanHide() {
      this.showCamera = false;
    },
    onBack() {
      this.$store.dispatch("subscription/setMigratingGateway", null);
      this.$emit("back");
    },
  },
  watch: {
    licenceKey() {
      if (!this.licenceKey) {
        return;
      }

      this.licenceKey = this.licenceKey.toUpperCase();

      let realNumber = this.licenceKey.replace(/-/gi, "");
      let dashedNumber = realNumber.match(/.{1,2}/g);
      this.licenceKey = dashedNumber.join("-");
    },
  },
};
</script>

<style scoped lang="scss">
span {
  color: var(--v-primary-base);
}
</style>
