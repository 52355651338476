import { apiClient } from "@api/base-client";

const k8sEndpoint = process.env.VUE_APP_ROOT_DATA_GATEWAY;
const dataClient = apiClient(
  `${k8sEndpoint}/dataApi/v1`,
  (response) => response.data
);

const fetchBackups = async (deviceId) => {
  return await dataClient.get(`/backup/${deviceId}`);
};

const createBackup = async (deviceId) => {
  return await dataClient.post("/backup", deviceId);
};

const downloadBackup = async (key) => {
  return await dataClient.get(`/backup/${key}`, {
    responseType: "blob",
  });
};

export const dataService = {
  fetchBackups,
  createBackup,
  downloadBackup,
};
