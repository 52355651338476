<script>
import {
  addExtraCss,
  makeProps,
  setInputAttrs,
  setStyle,
} from "../render/screen-bll";
import { Data } from "@stienen/Data";
import { mapGetters } from "vuex";
import { screenElementBasics } from "@mixins/props";
import { isDef, isUndef } from "@utils/helpers";
import { notifyUser } from "@error/helpers";
import TooltipContainer from "@modules/rtcpu/components/tooltip/TooltipContainer";

export default {
  name: "RtVariableRef",
  mixins: [screenElementBasics],
  inheritAttrs: false,
  data() {
    return {
      attr: {},
      properties: {},
      varIsEdited: false,
      error: null,
      calcValue: null,
    };
  },
  created() {
    // todo: move to screenElement to make this more reusable ?
    this.properties = makeProps(this.loc.Properties);
    let att = {};
    setInputAttrs(
      this.target,
      att,
      this.loc,
      this.val_obj,
      this.ref,
      this.properties.classList
    );
    if (this.val_obj) {
      if (this.tag === "select") {
        // part of a select box
        // todo: option box variable text
        // att.value = this.target.min + this.target.length;
        // todo: don't understand this param: "this.state" for css?!?!
        addExtraCss(
          this.properties.classList,
          this.loc.Extra,
          this.state || {}
        );
      } else {
        // todo: fix this, shouldn't this be in screenElement.vue
        if (this.loc.Extra)
          Array.prototype.push.apply(
            this.properties.classList,
            this.loc.Extra.split(",")
          );
      }
      //this is defined below as well?
      if (this.val_obj.actionIsCalc) {
        let updateCalc = (newValue) => (this.calcValue = newValue);
        updateCalc = updateCalc.bind(this);
        this.val_obj.registerCallback(updateCalc);
      }
    }
    // todo: refactor, this is confusing
    this.properties.style = setStyle(this.loc, att);
    this.attr = { ...this.attr, ...att };
    if (this.attr.hasOwnProperty("type") && this.attr.type === "time") {
      this.properties.style.width = "105px";
    }
    if (this.val_obj.actionIsCalc) {
      let updateCalc = (newValue) => (this.calcValue = newValue);
      updateCalc = updateCalc.bind(this);
      this.val_obj.registerCallback(updateCalc);
    }
    if (
      !this.attr.readOnly &&
      (this.ref.Type === Data.T_STRING_UNICODE || this.tag === "select")
    ) {
      this.properties.style["text-align"] = "start";
    }
  },
  computed: {
    ...mapGetters("deviceCache", [
      "getRef",
      "getValue",
      "getTypedText",
      "getTranslation",
    ]),
    ...mapGetters("edit", ["editModeActive", "getEditedValue", "setDataError"]),
    ...mapGetters("layout", ["isDesktop"]),
    varName() {
      if (isDef(this.val_obj) && !this.val_obj.isAction) {
        return this.val_obj.RefName;
      } else if (isDef(this.loc.RefName)) {
        return this.loc.RefName;
      } else if (isDef(this.val_obj)) {
        //"CEN 11 Centrale afzuiging" 161 228 has this case
        // isAction is true, val_obj.RefName IS defined
        // loc.RefName is not defined
        //again with KL6000 (hw 151) @climate overview custom screens.
        return this.val_obj.RefName;
      } else {
        return null;
      }
    },
    dataType() {
      return this.val_obj ? this.val_obj.Type : -1;
    },
    tag() {
      if (this.dataType === Data.T_INPUT || this.dataType === Data.T_OUTPUT) {
        return "RtInputOutput";
      } else if (
        this.dataType === Data.T_TIMESPAN ||
        this.dataType === Data.T_TIMESPAN_UI32
      ) {
        return "RtBaseInput";
      } else if (this.ref && this.ref.TranslationNameId !== null) {
        return "select";
      } else if (this.dataType === 5 && this.loc.children.length > 0) {
        return "select";
      }
      return "RtBaseInput";
    },
    options() {
      if (this.tag === "select") {
        //"normal" select
        const { id, text } = this.getTypedText(this.dev, this.ref.Tid);
        if (id !== -1) {
          return text;
        }
        // al10 select
        const labels = this.loc.children.map((c) => c.Value);

        //same logic as deviceCache/translate
        return labels.map(
          (label) =>
            this.getValue(this.dev, label) ||
            this.getTranslation({ dev: this.dev, label })
        );
      }
      return null;
    },
    ref() {
      const reference = this.getRef(this.dev, this.varName);
      if (isUndef(reference)) return null;
      if (this.dev && isDef(this.dev.edit)) reference.Disabled = !this.dev.edit;
      return reference;
    },
    value() {
      // make sure Vues reactive system doesn't overwrite the newly changed value
      // on discard, the getValue getter will be run again, and the value will be restored
      if (
        this.editModeActive &&
        this.varIsEdited &&
        this.getEditedValue(this.dev, this.varName) !== null
      ) {
        return this.getEditedValue(this.dev, this.varName);
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.varIsEdited = false;

      if (this.varName) {
        let value = this.getValue(this.dev, this.varName);
        return isDef(value)
          ? Data.compose(value, this.ref.Type, this.ref.Step)
          : null;
      }
      if (this.val_obj.actionIsCalc && isDef(this.calcValue)) {
        return Data.compose(this.calcValue, Data.T_DOUBLE, this.val_obj.Step);
      }
      return null;
    },
    classList() {
      return {
        ...this.properties.classList.reduce((acc, obj) => {
          return {
            ...acc,
            [obj]: true,
          };
        }, {}),
        changed: this.varIsEdited,
        SetDataFailed:
          this.varIsEdited && this.setDataError(this.dev, this.varName),
      };
    },
  },
  render(createElement) {
    const isReadOnly = this.attr.readOnly;
    const domProps = this.options ? { value: this.value } : {};

    const data = {
      attrs: {
        ...this.attr,
        ...this.$attrs,
        title: "",
      },
      domProps,
      props: {
        value: this.options ? null : this.value,
      },
      on: {
        input: (newValue) => {
          if (this.val_obj.isAction) {
            notifyUser({ text: this.$t("editMode.valueBasedOnCalc") });
          } else {
            const event = {
              dev: this.dev,
              refName: this.ref.Name,
              originalValue: this.value,
              newValue:
                typeof newValue === "object" ? newValue.target.value : newValue,
              ref: this.ref,
            };

            this.$store.dispatch("edit/onInput", event, { root: true });
            this.varIsEdited = true;
          }
        },
        ...this.$listeners,
      },
      style: this.properties.style,
      class: this.classList,
      key: `${this.dev.id}_${this.varName}`,
    };

    const children = this.options
      ? this.options.map(
          // BUG #1694
          (text, i) =>
            createElement(
              "option",
              { attrs: { value: this.ref.Min + i } },
              text
            )
          // (text) => createElement("option", { attrs: { value: text } }, text)
        )
      : this.$slots.default;

    if (isReadOnly) {
      data.style["pointer-events"] = "none";
      this.$nextTick(() =>
        this.$store.dispatch("deviceCache/registerHistoryVar", {
          varName: this.varName,
        })
      );
    }

    if (data.class["non-edit"]) {
      data.style["pointer-events"] = "none";
    }

    if (
      (this.tag === "RtBaseInput" || this.tag === "select") &&
      this.isDesktop
    ) {
      const element = createElement(this.tag, data, children);

      const popupData = {
        props: {
          varName: this.varName,
          isReadOnly,
          device: this.dev,
        },
      };

      return createElement(TooltipContainer, popupData, [element]);
    } else {
      return createElement(this.tag, data, children);
    }
  },
};
</script>

<style scoped></style>
