<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :min-width="minWidth"
    :max-width="maxWidth"
  >
    <template #activator="{ on }">
      <div v-on="on">
        <v-text-field
          v-model="dateRangeText"
          append-icon="mdi-calendar-range"
          :label="$t('history.dateRange')"
          readonly
        />
      </div>
    </template>

    <v-date-picker
      color="primary"
      :range="isRangePicker"
      no-title
      scrollable
      v-model="innerDates"
      :allowed-dates="disabledAfterToday"
    >
      <v-spacer />
      <base-button text @click="menu = !menu">
        {{ $t("common.ok") }}
      </base-button>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { isDef } from "@utils/helpers";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { Util } from "@utils/Util";

export default {
  name: "StienenDatePicker",
  model: {
    prop: "dates",
  },
  props: {
    minWidth: {
      type: String,
      default: "290px",
    },
    maxWidth: {
      type: String,
      default: "290px",
    },
    dates: {
      type: [Array, String],
      required: true,
    },
    isRangePicker: {
      type: Boolean,
      default: true,
    },
    rangeoffset: {
      type: Number,
      default: 60 * 60 * 24,
    },
  },
  data() {
    return {
      menu: false,
      innerDates: null,
    };
  },
  computed: {
    dateRangeText() {
      let result;
      if (isDef(this.innerDates)) {
        if (Array.isArray(this.innerDates)) {
          result = this.innerDates.join(" ~ ");
        } else {
          result = DateTimeConverter.dateToString(this.innerDates);
        }
      }
      return isDef(result) ? result : "";
    },
  },
  methods: {
    disabledAfterToday(date) {
      return date <= new Date().toISOString();
    },
    swapDates() {
      const tmp = this.innerDates[0];
      this.innerDates[0] = this.innerDates[1];
      this.innerDates[1] = tmp;
    },
    setInitialRange() {
      if (this.isRangePicker) {
        const now = new Date().getTime();
        const start = new Date(now - this.rangeoffset * 1000);
        const range = new Date(now);

        this.innerDates = [];
        this.innerDates[0] = Util.ISO8601Part(4, start);
        this.innerDates[1] = Util.ISO8601Part(4, range);
      } else {
        this.innerDates = "";
      }
      this.onChange();
    },
    onChange() {
      this.$emit("input", this.innerDates);
    },
  },
  watch: {
    menu(newVal) {
      if (!newVal) {
        if (this.innerDates.length === 1) {
          // if only start date selected, take the current day to end
          const now = DateTimeConverter.dateToString(new Date());
          this.innerDates.push(now);
        }
        this.onChange();
      }
    },
    innerDates() {
      if (
        Array.isArray(this.innerDates) &&
        this.innerDates.length === 2 &&
        this.innerDates[0] > this.innerDates[1]
      ) {
        this.swapDates();
      }
    },
  },
  created() {
    this.setInitialRange();
  },
};
</script>

<style scoped></style>
