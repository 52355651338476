<template>
  <span v-on="listeners">
    <span :style="{ color: color, fontSize: fontSize }">{{ value }}</span>
    <LcUnit v-if="unit" :name="unit" :color="color" />
    <v-icon v-if="!isReadOnly" x-small color="#007c60" style="top: -3px">
      mdi-circle
    </v-icon>
  </span>
</template>

<script>
import LcUnit from "@modules/lccpu/components/LcUnit";

export default {
  name: "LcValue",
  components: {
    LcUnit,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        mouseenter: (e) => this.$parent.$emit("eventEnter", e),
        mouseleave: (e) => this.$parent.$emit("eventLeave", e),
      };
    },
  },
};
</script>

<style scoped></style>
