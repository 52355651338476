import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  screenDefs: {}, // raw screen cache, might as well put this on
  selectedDisposition: null,
  selectedAt: Date.now(),
  treeCreatedAt: Date.now(),
  last24HourEnabled: false,
  customLoadingCurrent: new Set(),
  customLoadingActual: new Set(),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
