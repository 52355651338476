import { apiClient, getCustomerId } from "@api/base-client";

//todo make customerId constructor?
import store from "@state/store";

const endPoint = process.env.VUE_APP_ROOT_USER_GATEWAY;

const userClient = apiClient(
  `${endPoint}/userApi/v1`,
  (response) => response.data
);

const fetchUser = async () => {
  const customerId = getCustomerId();
  return await userClient.get(`/Users/${customerId}`);
};

const fetchUserStatus = async (userId) => {
  return await userClient.get(`/Users/${userId}/status`);
};

const postResendInvite = async (userId) => {
  return await userClient.post(`/Users/${userId}/resendInvite`);
};

const fetchUserEmailById16 = async (id16) => {
  return await userClient.get(`/Users/${id16}`);
};

const fetchCustomer = async (customerId = getCustomerId()) => {
  return await userClient.get(`/Customers/${customerId}`);
};

const fetchGateways = async () => {
  const customerId = getCustomerId();
  return await userClient.get(`/Gateways/${customerId}`);
};

const fetchCustomScreenIds = async () => {
  const customerId = getCustomerId();
  return await userClient.get(`/CustomScreens/${customerId}`);
};

//subscription
const fetchHardwareProducts = async () => {
  return await userClient.get("/Products");
};

const fetchSubscription = async () => {
  const customerId = getCustomerId();
  return await userClient.get(`/Subscriptions/${customerId}`);
};

//setup / update subscription
const acceptLicence = async () => {
  return await userClient.post("/Users/acceptLicence");
};

const createSetupIntentSession = async (successUrl, cancelUrl) => {
  const customerId = getCustomerId();
  return await userClient.post(`/SetupIntents/${customerId}`, {
    successUrl: successUrl,
    cancelUrl: cancelUrl,
  });
};

const createCheckoutSession = async (successUrl, cancelUrl, products) => {
  const customerId = getCustomerId();
  return await userClient.post(`/Checkouts/${customerId}`, {
    successUrl: successUrl,
    cancelUrl: cancelUrl,
    products: products,
  });
};

const createPortalSession = async (returnUrl) => {
  const customerId = getCustomerId();
  return await userClient.post(`/PortalSessions/${customerId}`, {
    returnUrl: returnUrl,
  });
};

const updateSubscription = async (productRequests) => {
  const customerId = getCustomerId();
  return await userClient.put(`/Subscriptions/${customerId}`, {
    productRequests: productRequests,
  });
};

//back
const fetchMigration = async (url, header, date) => {
  return await userClient.get("/Fc2Migrations", {
    params: {
      url: url,
      header: header,
      date: date,
    },
  });
};

//invitations
const setServiceAccess = async (allowed) => {
  const customerId = getCustomerId();
  return await userClient.post(`/Customers/${customerId}/serviceAccess`, {
    allowed: allowed,
  });
};

const updateUser = async (user) => {
  const customerId = getCustomerId();
  return await userClient.post(`/Users/${customerId}`, user);
};

const deleteUser = async (userId) => {
  const customerId = getCustomerId();
  return await userClient.delete(`/Users/${customerId}`, {
    data: {
      userId: userId,
    },
  });
};

//dealer-invites
const fetchDealers = async () => {
  const customerId = getCustomerId();
  return await userClient.get(`/DealerInvites/${customerId}`);
};

const inviteDealer = async (dealerEmail) => {
  const customerId = getCustomerId();
  return await userClient.post(`/DealerInvites/${customerId}`, {
    dealerEmail: dealerEmail,
  });
};

const updateDealer = async (dealerId, active) => {
  const customerId = getCustomerId();
  return await userClient.put(`/DealerInvites/${customerId}`, {
    dealerId: dealerId,
    active: active,
  });
};

const revokeDealer = async (dealerId) => {
  const customerId = getCustomerId();
  return await userClient.delete(`/DealerInvites/${customerId}`, {
    data: {
      dealerId: dealerId,
    },
  });
};

//settings
const setSettings = async (settings) => {
  return await userClient.post("/Users/settings", settings);
};

//service
const fetchCustomers = async () => {
  return await userClient.get("/Customers");
};

const restartGateway = async (gatewayId) => {
  return await userClient.post("/Gateways/restart", { gatewayId: gatewayId });
};

//dealer
const fetchMechanics = async () => {
  const dealerId = store.getters["auth/getUserId"];
  return await userClient.get(`/Mechanics/${dealerId}`);
};

const addMechanic = async (mechanicEmail) => {
  const dealerId = store.getters["auth/getUserId"];
  return await userClient.post(`/Mechanics/${dealerId}`, {
    mechanicEmail: mechanicEmail,
  });
};

const deleteMechanic = async (mechanicId) => {
  const dealerId = store.getters["auth/getUserId"];
  return await userClient.delete(`/Mechanics/${dealerId}`, {
    data: {
      mechanicId: mechanicId,
    },
  });
};

//mechanic
const fetchCustomersMechanic = async () => {
  const mechanicId = store.getters["auth/getUserId"];
  return await userClient.get(`/Mechanics/${mechanicId}/customers`);
};

//rest
const createGateway = async (gatewayFc2, licenceKey) => {
  const customerId = getCustomerId();
  return await userClient.post(`/Gateways/${customerId}`, {
    gatewayFc2: gatewayFc2,
    licenceKey: licenceKey,
  });
};

export const adminService = {
  fetchUser,
  fetchUserStatus,
  postResendInvite,
  fetchUserEmailById16,
  fetchCustomer,
  fetchGateways,
  fetchCustomScreenIds,

  //subscription
  fetchHardwareProducts,
  fetchSubscription,

  //setup / update subscription
  acceptLicence,
  createSetupIntentSession,
  createCheckoutSession,
  createPortalSession,
  updateSubscription,

  //back
  fetchMigration,

  //invitations
  setServiceAccess,
  updateUser,
  deleteUser,

  //dealer-invites
  fetchDealers,
  inviteDealer,
  updateDealer,
  revokeDealer,

  //settings
  setSettings,

  //service
  fetchCustomers,
  restartGateway,

  //dealer
  fetchMechanics,
  addMechanic,
  deleteMechanic,

  //mechanic
  fetchCustomersMechanic,

  //rest
  createGateway,
};
