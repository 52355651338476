<template>
  <highcharts :options="chartOptions" :callback="inletRenderCallback" />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const inletIcon = "wdg_inlet_green";
const ventIcon = "wdg_vent";
const averageIcon = "wdg_vent_average";

const typeEnum = {
  VENT_BANDWIDTH: "VENT_BANDWIDTH",
  INLET: "INLET",
};

export default {
  name: "WidgetCurvePercentage",
  mixins: [WidgetBase],
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    actualX: {
      type: LcWidgetValue,
      required: true,
    },
    actualY: {
      type: LcWidgetValue,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    pointX: {
      type: Array,
      required: true,
    },
    pointY: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 272,
    },
    height: {
      type: Number,
      default: 168,
    },
  },
  computed: {
    typeIcon() {
      const map = {
        [typeEnum.VENT_BANDWIDTH]: ventIcon,
        [typeEnum.INLET]: inletIcon,
      };
      return map[this.type];
    },
    chartOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.colors.lightGreen,
          ...this.calculateInletMetrics(),
          style: {
            fontFamily: "lc-font",
          },
        },
        plotOptions: {
          series: {
            lineWidth: this.width / 300,
          },
        },
        xAxis: {
          min: 0,
          max: 100,
          tickPositions: [0, 20, 40, 60, 80, 100],
          labels: {
            style: {
              color: "black",
            },
          },
          tickAmount: 10,
          lineColor: "gray",
          tickLength: this.width / 50,
          tickWidth: this.height / 200,
          tickColor: "gray",
          gridLineColor: "lightgray",
          gridLineWidth: 1,
        },
        yAxis: {
          min: 0,
          max: 100,
          tickPositions: [0, 25, 50, 75, 100],
          title: false,
          labels: {
            style: {
              color: "black",
            },
          },
          allowDecimals: false,
          tickAmount: 5,
          lineColor: "gray",
          lineWidth: this.height / 200,
          tickLength: this.width / 50,
          tickWidth: this.height / 200,
          tickColor: "gray",
          gridLineColor: "lightgray",
          gridLineWidth: 1,
          minorGridLineColor: "transparent",
        },
        series: this.series,
      };
    },
    series() {
      const series = [];

      const data = [];
      for (
        let pointYIndex = 0;
        pointYIndex < this.pointY.length;
        pointYIndex++
      ) {
        const x = this.pointX[pointYIndex];
        const y = this.pointY[pointYIndex];

        data.push([x, y]);

        series.push({
          name: "dashed line",
          data: [
            [0, y],
            [x, y],
            [x, 0],
          ], // looks like this ┐
          color: this.colors.black,
          dashStyle: "dash",
          enableMouseTracking: false,
        });
      }
      series.push({
        name: "vertical line",
        data: data,
        color: this.colors.disabledGray,
        marker: {
          enabled: true,
          symbol: "circle",
          radius: this.width / 150,
        },
        enableMouseTracking: false,
      });

      series.push({
        name: "horizontal line",
        data: [
          [this.actualY.raw, this.actualX.raw],
          [this.actualY.raw, 100],
        ],
        color: this.colors.black,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
      });

      series.push({
        name: "vertical line",
        data: [
          [this.actualY.raw, this.actualX.raw],
          [100, this.actualX.raw],
        ],
        color: this.colors.black,
        marker: {
          enabled: true,
          symbol: "circle",
          radius: this.width / 150,
        },
        enableMouseTracking: false,
      });

      return series;
    },
  },
  methods: {
    calculateInletMetrics() {
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin: [
          this.height / 10,
          this.width * 0.12,
          this.height * 0.15,
          this.width * 0.12,
        ],
      };
    },
    inletRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.strokeWidth = Math.min(this.plotMetrics.height / 200, 5);
        this.charWidth = this.plotMetrics.height / 18;

        this.renderActualX();
        this.renderActualY();

        this.renderType();
        this.renderAverage();
      } catch (err) {
        console.error(err);
      }
    },
    renderActualX() {
      const x = this.translateX(this.actualX.raw);
      this.renderTextCentered(this.actualX.formatted, x, this.chartMetrics.y);
    },
    renderActualY() {
      const y = this.translateY(this.actualY.raw);
      this.renderText(
        this.actualY.formatted,
        this.plotMetrics.endX * 1.025,
        y - this.charWidth
      );
    },
    renderType() {
      const sideLength = this.chartMetrics.height / 12;
      this.renderIconS3(
        this.typeIcon,
        this.chartMetrics.x,
        this.chartMetrics.y,
        sideLength,
        sideLength
      );
      this.renderText(
        this.units.PERCENT,
        this.chartMetrics.x + sideLength,
        this.chartMetrics.y
      );
    },
    renderAverage() {
      const sideLength = this.chartMetrics.height / 10;
      this.renderIconS3(
        averageIcon,
        this.plotMetrics.endX + sideLength * 0.45,
        this.plotMetrics.endY + sideLength / 2,
        sideLength,
        sideLength
      );
      this.renderText(
        this.units.PERCENT,
        this.plotMetrics.endX + sideLength * 1.4,
        this.plotMetrics.endY + this.charWidth
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.inletRenderCallback);
  },
};
</script>

<style scoped></style>
