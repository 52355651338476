import { subscriptionStatus } from "./index";

const getMigratingGateway = (state) => state.migratingGateway;
const getCreatedGateway = (state) => state.createdGateway;

const getProductByHardware = (state) => (hardware) =>
  state.hardwareProducts.find((product) => product.hardware === hardware);

const getSubtotal = (state) => (devices) =>
  devices.reduce(
    (acc, device) => acc + getPriceForHardware(state, device.hardware),
    0
  );

const getOldSubtotal = (state) => (devices) =>
  devices
    .filter((device) => device.isLicenced)
    .reduce(
      (acc, device) => acc + getPriceForHardware(state, device.hardware),
      0
    );

const getPriceForHardware = (state, hardware) =>
  state.hardwareProducts.find((hp) => hp.hardware === hardware)?.unitAmount ||
  0;

const getFormattedPrice =
  (state, getters, rootState, rootGetters) =>
  (amount, currency = "eur") => {
    return new Intl.NumberFormat(rootGetters["settings/getLanguage"], {
      style: "currency",
      currency,
    }).format(amount / 100);
  };

const getImageForHardware = (state) => (hardware) => {
  const hardwareProduct = state.hardwareProducts.find(
    (prod) => prod.hardware === hardware
  );
  return hardwareProduct &&
    Array.isArray(hardwareProduct.images) &&
    hardwareProduct.images.length > 0
    ? hardwareProduct.images[0]
    : require("@assets/img/subscription/UnavailableDevice.png");
};

const getHasSubscription = (state) =>
  state.subscriptionStatus !== subscriptionStatus.NO;

const getIsSubscriptionUpdated = (state, getters, rootState, rootGetters) => {
  if (!getters.getHasSubscription) {
    return false;
  }

  const ownDevices = rootGetters["getAllOwnedDevices"].filter(
    (device) => device.available
  );
  return ownDevices.every((device) => device.isLicenced);
};

export default {
  getMigratingGateway,
  getCreatedGateway,
  getProductByHardware,
  getSubtotal,
  getFormattedPrice,
  getOldSubtotal,
  getImageForHardware,
  getHasSubscription,
  getIsSubscriptionUpdated,
};
