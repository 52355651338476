<template>
  <span
    class="lc-text"
    v-if="!isModifyOnly || enableEditMode"
    style="line-height: 1"
    :style="{ 'text-align': align }"
  >
    <span v-if="enableEditMode">
      <!--true for hide in carousel-->
      <LcBaseIcon
        v-if="isIconVariable"
        @click="onInput"
        :name="iconVariablePath"
        is-read-only
      />
      <LcBaseInput
        v-else
        @input="onInput"
        :type="inputType"
        :value="formattedValue"
        :unit="unit"
      />
    </span>

    <span v-else>
      <TooltipContainer
        v-if="isDesktop"
        :is-read-only="isReadOnly"
        :device="device"
        :var-name="variableReference.Name"
      >
        <span v-if="isIconVariable">
          <LcBaseIcon :name="iconVariablePath" />
          <v-icon
            v-if="!isReadOnly"
            x-small
            color="#007c60"
            class="mt-n8"
            style="top: -3px"
          >
            mdi-circle
          </v-icon>
        </span>
        <LcValue
          v-else
          :value="formattedValue"
          :unit="unit"
          :is-read-only="isReadOnly"
          :color="fontColor"
          :font-size="fontSizePx"
        />
      </TooltipContainer>

      <span v-else>
        <span v-if="isIconVariable">
          <LcBaseIcon :name="iconVariablePath" />
          <v-icon
            v-if="!isReadOnly"
            x-small
            color="#007c60"
            class="mt-n8"
            style="top: -3px"
          >
            mdi-circle
          </v-icon>
        </span>
        <LcValue
          v-else
          :value="formattedValue"
          :unit="unit"
          :is-read-only="isReadOnly"
          :color="fontColor"
          :font-size="fontSizePx"
        />
      </span>
    </span>
  </span>
</template>

<script>
import LcBaseInput from "@modules/lccpu/components/LcBaseInput";
import LcBaseIcon from "@modules/lccpu/components/LcBaseIcon";
import { isDef } from "@utils/helpers";
import { mapGetters } from "vuex";
import { Data } from "@stienen/Data";
import TooltipContainer from "@modules/rtcpu/components/tooltip/TooltipContainer";
import LcValue from "@modules/lccpu/components/LcValue";

const textAlignEnum = ["left", "center", "right"];

export default {
  name: "LcVariable",
  components: {
    LcValue,
    LcBaseInput,
    LcBaseIcon,
    TooltipContainer,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Date],
      required: false,
      default: "",
    },
    unit: {
      type: [String, null],
      required: false,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    isModifyOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    variableReference: {
      required: true,
      type: Object,
    },
    enableEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: "left",
      validator: (value) => textAlignEnum.includes(value),
    },
    fontColor: {
      type: String,
      required: false,
      default: "black",
    },
    fontSize: {
      type: String,
      required: false,
      default: "medium",
    },
    textID: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters("deviceCache", ["getTypedText"]),
    ...mapGetters("layout", ["isDesktop"]),
    ...mapGetters("edit", ["editModeActive", "getEditedValue", "setDataError"]),
    fontSizePx() {
      switch (this.fontSize) {
        case "MEDIUM":
          return "20px";
        case "SMALL":
          return "14px";
        default:
          return "20px";
      }
    },
    isIconVariable() {
      return isDef(this.textID);
    },
    inputType() {
      if (!this.enableEditMode) {
        return null;
      }

      const attr = {};
      switch (this.variableReference.Type) {
        case Data.T_STRING:
          attr.type = "text";
          break;

        case Data.T_DATETIME:
          attr.type = "datetime";
          if (this.variableReference) {
            attr.min = this.variableReference.Min;
            attr.max = this.variableReference.Max;
          }
          break;

        case Data.T_TIMESPAN:
          attr.type = "text";
          if (this.variableReference) {
            attr.min = this.variableReference.Min;
            attr.max = this.variableReference.Max;
          }
          break;

        default: // INT
          attr.type = "text";
          if (this.variableReference) {
            attr.min = this.variableReference.Min;
            attr.max = this.variableReference.Max;
          }
          break;
      }
      return attr.type;
    },
    formattedValue() {
      if (this.isEdited) {
        return this.getEditedValue(this.device, this.variableReference.Name);
      } else {
        return Data.compose(
          this.value,
          this.variableReference.Type,
          this.variableReference.Step
        );
      }
    },
    isEdited() {
      const editedValue = this.getEditedValue(
        this.device,
        this.variableReference.Name
      );
      return this.enableEditMode && this.editModeActive && editedValue !== null;
    },
    variableIcons() {
      if (this.isIconVariable) {
        //textID is never a string?
        const index =
          this.textID && typeof this.textID === "string"
            ? parseInt(this.textID)
            : this.textID;
        const { id, text } = this.getTypedText(this.device, index);
        return id !== -1 ? text : null;
      } else {
        return null;
      }
    },
    iconIndex() {
      return this.isEdited
        ? this.getEditedValue(this.device, this.variableReference.Name)
        : this.value;
    },
    iconVariablePath() {
      if (
        isDef(this.variableIcons) &&
        this.variableIcons.length > this.iconIndex
      ) {
        return this.variableIcons[this.iconIndex];
      }
      return null;
    },
  },
  methods: {
    onInput(event) {
      if (!this.enableEditMode) {
        return;
      }

      let newValue;
      if (this.isIconVariable) {
        newValue = this.nextIconIndex();
      } else {
        //MouseEvent (from iconVariable)
        newValue = typeof event === "object" ? event.target.value : event;
      }
      const editEvent = {
        dev: this.device,
        refName: this.variableReference.Name,
        originalValue: this.formattedValue,
        newValue: newValue,
        ref: this.variableReference,
        readOnlyOverride: this.isReadOnly,
      };

      this.$store.dispatch("edit/onInput", editEvent, { root: true });
    },
    nextIconIndex() {
      return (this.iconIndex + 1) % (this.variableReference.Max + 1);
    },
  },
};
</script>

<style scoped>
.lc-text {
  font-family: lc-font, serif;
  white-space: nowrap;
}
</style>
