<template>
  <highcharts
    :options="curveVentilationOptions"
    :callback="curveVentilationRenderCallback"
  />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

const temperatureIcon = "wdg_temp_green";
const temperatureIconDisabled = "wdg_temp_gray";

const ventilationMinIcon = "wdg_vent_min_green";
const ventilationMinIconDisabled = "wdg_vent_min_gray";

const ventilationMaxIcon = "wdg_vent_max_green";
const ventilationMaxIconDisabled = "wdg_vent_max_gray";

export default {
  name: "WidgetCurveVentilation",
  props: {
    unit: {
      type: String,
      required: true,
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    min: {
      type: LcWidgetValue,
      required: true,
    },
    max: {
      type: LcWidgetValue,
      required: true,
    },
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 50,
    },
  },
  mixins: [WidgetBase],
  computed: {
    temperatureIcon() {
      return this.isEnabled ? temperatureIcon : temperatureIconDisabled;
    },
    ventilationMinIcon() {
      return this.isEnabled ? ventilationMinIcon : ventilationMinIconDisabled;
    },
    ventilationMaxIcon() {
      return this.isEnabled ? ventilationMaxIcon : ventilationMaxIconDisabled;
    },
    curveVentilationOptions() {
      return {
        ...baseChartOptions,
        chart: {
          backgroundColor: "transparent",
          ...this.calculateMetrics(),
        },
      };
    },
  },
  methods: {
    calculateMetrics() {
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
      };
    },
    curveVentilationRenderCallback(chart) {
      try {
        this.initialize(chart);
        const iconWidth = this.chartMetrics.width * 0.1;
        const iconHeight = this.chartMetrics.height * 0.7;
        const iconY = this.chartMetrics.y + this.chartMetrics.height * 0.1;
        this.charWidth = this.chartMetrics.height / 3.5;

        let nextX =
          this.renderActualMeasurement(iconWidth, iconHeight, iconY) + 5;
        nextX = this.renderMin(nextX, iconWidth, iconHeight, iconY) + 5;
        this.renderMax(nextX, iconWidth, iconHeight, iconY);
      } catch (err) {
        console.error(err);
      }
    },
    renderActualMeasurement(iconWidth, iconHeight, iconY) {
      this.renderIconS3(
        this.temperatureIcon,
        this.chartMetrics.x,
        iconY,
        iconWidth,
        iconHeight
      );
      const actualMeasurementX =
        this.chartMetrics.x + iconWidth - this.charWidth;
      return this.renderText(
        `${this.actualMeasurement.formatted}${this.units.DEGREE}`,
        actualMeasurementX,
        this.chartMetrics.centerY - this.charWidth
      );
    },
    renderMin(x, iconWidth, iconHeight, iconY) {
      this.renderIconS3(
        this.ventilationMinIcon,
        x,
        iconY,
        iconWidth,
        iconHeight
      );
      const minTextX = this.chartMetrics.x + x + iconWidth;
      return this.renderText(
        `${this.min.formatted}${this.units[this.unit]}`,
        minTextX,
        this.chartMetrics.centerY - this.charWidth
      );
    },
    renderMax(x, iconWidth, iconHeight, iconY) {
      this.renderIconS3(
        this.ventilationMaxIcon,
        x,
        iconY,
        iconWidth,
        iconHeight
      );
      const maxTextX = this.chartMetrics.x + x + iconWidth;
      return this.renderText(
        `${this.max.formatted}${this.units[this.unit]}`,
        maxTextX,
        this.chartMetrics.centerY - this.charWidth
      );
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.curveVentilationRenderCallback);
  },
};
</script>

<style scoped></style>
