import { _createErrorType } from "./error";
import { isDef } from "@utils/helpers";

export const SpecifiedError = _createErrorType(
  "SpecifiedError",
  function (msg, hint) {
    this.message = isDef(hint) ? `${msg} (${hint})` : msg;
  }
);
export const FatalError = _createErrorType("FatalError");
export const ExprError = _createErrorType("ExprError");
export const InitError = _createErrorType("InitError");
export const NoDataError = _createErrorType("NoDataError");
export const InvalidDividendError = _createErrorType("InvalidDividendError");
export const TreeRenderError = _createErrorType("TreeRenderError");
export const ScreenRenderError = _createErrorType("ScreenRenderError");
export const ScreenNotFoundError = _createErrorType("ScreenNotFoundError");
export const DeviceUnknownError = _createErrorType("DeviceUnknownError");
export const AccessError = _createErrorType("AccessError");
export const InvalidDeviceObjectError = _createErrorType(
  "InvalidDeviceObjectError",
  function (msg, extra) {
    this.message = `${msg} Extra: ${extra}`;
  }
);
