import { LayoutTypes } from "./index";

const isDesktop = (state) => state.layoutState === LayoutTypes.DESKTOP;
const isPortrait = (state) => state.layoutState === LayoutTypes.PORTRAIT;
const isLandscape = (state) => state.layoutState === LayoutTypes.LANDSCAPE;

export default {
  isPortrait,
  isLandscape,
  isDesktop,
};
