<template>
  <div class="nav-drawer-header">
    <span :class="icon" class="icon mdi" />
    <span class="name">{{ userName }}</span>
    <span v-if="impersonatedCustomer" class="service">{{
      impersonatedCustomer.email
    }}</span>
  </div>
</template>

<script>
export default {
  name: "NavDrawerHeader",
  props: {
    icon: {
      type: String,
      required: false,
      default: "",
    },
    userName: {
      type: String,
      required: false,
      default: "",
    },
    impersonatedCustomer: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer-header {
  display: grid;
  width: 301px;
  height: 64px;
  grid-template-columns: 20px 35px 246px;
  color: var(--v-grey30-base);

  .icon {
    grid-column: 2;
    grid-row: 1;
    padding-top: 15px;
    font-size: 25px;
  }

  .name {
    grid-column: 3;
    grid-row: 1;
    padding-top: 18px;
    font-family: var(--v-font-base);
    font-size: 18px;
    font-weight: bolder;
  }

  .service {
    grid-column: 3;
    grid-row: 1;
    padding-top: 40px;
    font-family: var(--v-font-base);
    font-size: 12px;
    color: var(--v-warning-base);
  }
}
</style>
