<template>
  <base-card>
    <base-last24h-chart :device="device" :height="322" :vars="filtered" />
  </base-card>
</template>

<script>
import BaseLast24hChart from "@components/base/BaseLast24hChart.vue";

const blackList = ["DAG_MIN", "DATUM"];

export default {
  name: "RtLast24Hour",
  components: {
    BaseLast24hChart,
  },
  props: {
    historyVars: {
      type: Array,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filtered() {
      return this.historyVars
        .filter((newVar) => blackList.indexOf(newVar.varName) === -1)
        .filter((newVar) => !!newVar.varName)
        .filter((newVar) => !newVar.varName.startsWith("TEST"));
    },
  },
};
</script>

<style scoped></style>
