import { render, staticRenderFns } from "./ButtonHolder.vue?vue&type=template&id=244ca8e6&scoped=true&"
import script from "./ButtonHolder.vue?vue&type=script&lang=js&"
export * from "./ButtonHolder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244ca8e6",
  null
  
)

export default component.exports