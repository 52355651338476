<template>
  <base-card :title="$t('common.alarms')" :error="!!alarmsToShow.length">
    <v-divider />
    <v-data-table
      :headers="computedHeaders"
      :items="alarmsToShow"
      :loading="isLoading"
      :hide-default-footer="alarmsToShow.length < 11"
      @click:row="goToAlarmDevice"
      style="cursor: pointer"
    >
      <template #item.AlarmText="{ item }">
        {{ alarmCode(item) }}
      </template>

      <template #item.ControlText="{ item }">
        {{ controlText(item) }}
      </template>

      <template #item.actions="{ item }">
        <base-button icon>
          <v-icon @click="goToAlarmDevice(item)"> mdi-arrow-right </v-icon>
        </base-button>
      </template>

      <template #no-data>
        {{ $t("alarms.noAlarms") }}
      </template>
    </v-data-table>
  </base-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { _compose } from "@modules/rtcpu/render/screen-bll";
import { _isLcCpu } from "@state/modules/cache/bll";
import { notifyUserTranslation } from "@error/helpers";

export default {
  name: "CurrentAlarmOverview",
  data() {
    return {
      headers: [
        {
          text: this.$t("common.gateway"),
          align: "left",
          value: "GatewayName",
        },
        {
          text: this.$t("common.device"),
          value: "DeviceName",
        },
        {
          text: this.$t("common.alarm"),
          value: "AlarmText",
        },
        {
          text: this.$t("common.control"),
          sortable: false,
          value: "ControlText",
        },
        {
          text: this.$t("common.goto"),
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => !state.alarmsLoaded,
    }),
    ...mapGetters(["getGateways"]),
    ...mapGetters(["getSelectedDevice", "getCurrentAlarmsFiltered"]),
    ...mapGetters("deviceCache", ["getDeviceMeta"]),
    alarmsToShow() {
      return this.getCurrentAlarmsFiltered;
    },
    computedHeaders() {
      if (this.getGateways.length === 1) {
        return this.headers.filter((h) => h.value !== "GatewayName");
      }
      return this.headers;
    },
  },
  methods: {
    controlText(alarm) {
      let control = alarm["ControlText"];
      if (!control) {
        return alarm["ControlVarContent"];
      }
      return _compose(control, [alarm["ControlNr"]]);
    },
    alarmCode(alarm) {
      return _compose(alarm["Code"], [alarm["CodeExtra"]]);
    },
    async goToAlarmDevice(item) {
      const device = this.getDeviceMeta(item.Did);
      if (!device.isLicenced) {
        notifyUserTranslation("alarms.needLicence", "warning");
        return;
      }

      let screenId = _isLcCpu(device) ? -1 : null;

      await this.$helpers.pushDeviceRoute(device, screenId);
      await this.$store.dispatch(
        "onGatewayChange",
        {
          gateway: item.Gid,
          device: item.Did,
          dispositionNames: ["Alarm", "Alarm_"],
        },
        { root: true }
      );
      await this.$store.dispatch("rtRemote/retryLoadTree");
    },
  },
};
</script>

<style scoped></style>
