<template>
  <v-app-bar app>
    <NavBarMenuIcon />

    <v-toolbar-title>
      <slot name="title">
        <LogoFarmConnect :width="150" style="margin-top: 5px" />
      </slot>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items>
      <ConnectionIndicator :connected="connected" :lastUpdate="lastUpdate">
        <template #success>
          {{ $t("navBar.streamerConnectionOk") }}
        </template>
        <template #error>
          {{ $t("navBar.streamerConnectionLost") }}
        </template>
      </ConnectionIndicator>

      <v-row class="align-content-center">
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NavBarMenuIcon from "@components/navbar/NavBarMenuIcon";
import ConnectionIndicator from "@components/navbar/ConnectionIndicator";
import LogoFarmConnect from "@components/base/LogoFarmConnect.vue";

export default {
  name: "NavBar",
  components: {
    LogoFarmConnect,
    NavBarMenuIcon,
    ConnectionIndicator,
  },
  computed: {
    ...mapState({
      connected: (state) => state.streamerConnected,
      lastUpdate: (state) => state.streamerLastStatusUpdate,
    }),
    ...mapGetters("layout", ["isDesktop"]),
  },
};
</script>

<style scoped></style>
