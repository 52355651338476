import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@state/store";

Vue.use(VueRouter);

let initial = true;

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  try {
    handleFirstNavigationToError(routeTo);

    const authRequired = routeTo.matched.some(
      (route) => route.meta.authRequired
    );
    if (!authRequired) {
      return navigateWithEditGuard();
    }

    return store
      .dispatch("auth/validate", routeTo, { root: true })
      .then((validUser) =>
        validUser ? navigateWithEditGuard() : redirectToLogin()
      )
      .catch(redirectToError);
  } catch (error) {
    // these are fatal errors on route change
    redirectToError(error);
  }

  function navigateWithEditGuard() {
    store
      .dispatch("edit/leaveEditConfirmed", null, { root: true })
      .then((confirmed) => {
        if (confirmed) next();
      });
  }

  function redirectToLogin() {
    const redirectFrom = routeTo.fullPath;
    next({
      name: "login",
      query: { redirectFrom },
    });
  }

  function redirectToError(error) {
    console.error(error);
    next({
      name: "error",
      params: {
        message: error.message,
        error,
      },
    });
  }

  function handleFirstNavigationToError(routeTo) {
    // on reload routeTo will be the last active page before the reload
    // when last page was errorPage, go to homePage instead
    const resetPages = ["error"];
    if (initial && resetPages.includes(routeTo.name)) {
      router.replace({ name: "login" });
    }
    initial = false;
  }
});

export default router;
