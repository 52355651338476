<template>
  <LcBaseIcon :name="icon" v-if="shouldShow" />
</template>

<script>
import { mapGetters } from "vuex";
import LcBaseIcon from "@modules/lccpu/components/LcBaseIcon";
import { isDef } from "@utils/helpers";

export default {
  name: "WidgetAlarmCode",
  props: {
    code: {
      type: Number,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: 44,
    },
    height: {
      type: Number,
      default: 60,
    },
  },
  computed: {
    ...mapGetters("lcRemote", ["getAlarmIconForDevice"]),
    shouldShow() {
      return this.icon !== "";
    },
    icon() {
      const icon = this.getAlarmIconForDevice(this.device, this.code);
      if (isDef(icon)) {
        return icon.icon;
      }
      return "";
    },
  },
  components: {
    LcBaseIcon,
  },
};
</script>

<style scoped></style>
