<template>
  <fc2-authenticator
    v-if="!migration"
    :disabled="disabled"
    :show-buttons="showButtons"
    @onComplete="setMigration"
    @back="$emit('back')"
  />
  <gateway-selector
    v-else-if="migration.Migratable"
    :migration="migration"
    :show-buttons="showButtons"
    @back="reset"
  />
  <migration-version-error
    v-else
    :url="url"
    :migration="migration"
    :show-buttons="showButtons"
    @back="reset"
  />
</template>

<script>
import Fc2Authenticator from "@components/admin/setup/migration/Fc2Authenticator";
import GatewaySelector from "@components/admin/setup/migration/GatewaySelector";
import MigrationVersionError from "@components/admin/setup/migration/MigrationVersionError";

export default {
  name: "AddGatewayMigration",
  components: { MigrationVersionError, GatewaySelector, Fc2Authenticator },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      migration: null,
      url: "",
    };
  },
  methods: {
    setMigration(migration, url) {
      this.migration = migration;
      this.url = url;
    },
    reset() {
      this.migration = null;
      this.url = "";
    },
  },
};
</script>

<style scoped></style>
