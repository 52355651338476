<template>
  <v-card tile elevation="20">
    <v-row no-gutters>
      <v-col>
        <img src="@assets/brand/LoginImageMobile.png" alt="Login" />
      </v-col>
      <v-col>
        <img class="logo" src="@assets/brand/LoginLogo.svg" alt="Logo" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <amplify-authenticator>
          <amplify-sign-in
            slot="sign-in"
            username-alias="email"
            :header-text="loginHeaderText"
            :form-fields.prop="loginFormFields"
            :submit-button-text="loginSubmitText"
          />
          <amplify-sign-up
            slot="sign-up"
            username-alias="email"
            :header-text="signUpHeaderText"
            :form-fields.prop="signUpFormFields"
            :have-account-text="signupHaveAccountText"
            :sign-in-text="signUpSignInText"
            :submit-button-text="signUpSubmitText"
          />
          <amplify-forgot-password
            slot="forgot-password"
            username-alias="email"
            :header-text="resetPasswordHeaderText"
            :form-fields.prop="resetPasswordFormFields"
            :send-button-text="resetPasswordSendButtonText"
            :submit-button-text="resetPasswordSubmitButtonText"
          />
        </amplify-authenticator>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import authenticatorLabels from "@pages/account/AuthenticatorLabels";

export default {
  name: "AuthenticatorMobile",
  mixins: [authenticatorLabels],
};
</script>

<style scoped lang="scss">
amplify-authenticator {
  --container-height: 0; /* default 100vh */
  --padding: 20px;
  --box-shadow: 0;
  --border-radius: 0;
  --width: 325px;
  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: var(--v-primary-base);
  --amplify-primary-shade: var(--v-primary-base);
}

.logo {
  width: 225px;
  position: absolute;
  top: 100px;
  left: 50px;
}
</style>
