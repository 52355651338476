import { apiClient } from "@api/base-client";

const endPoint = process.env.VUE_APP_ROOT_API + "Config.svc";
const configClient = apiClient(endPoint, (response) => response.data);

export async function GetDeviceConstants(hardware, version) {
  return await configClient.get("/DeviceConstants", {
    params: {
      hardware: hardware,
      version: version,
    },
  });
}

export async function GetDispositions(did, hardware, version) {
  return await configClient.get("/Dispositions", {
    params: {
      hardware: hardware,
      version: version,
      did: did,
    },
  });
}

export async function GetValues(gid, did, pos) {
  return await configClient.post("/GetValues", {
    gid: gid,
    did: did,
    pos: pos,
  });
}

export async function SetValues(gid, did, dps, id16) {
  return await configClient.post("/SetValues", {
    gid: gid,
    did: did,
    dps: dps,
    id16: id16,
  });
}

export async function GetLastEventsOfVar(did, varName, numberOfEvents) {
  return await configClient.get("/GetLastEventsOfVar", {
    params: {
      did: did,
      varName: varName,
      numberOfEvents: numberOfEvents,
    },
  });
}

export async function GetCurrentAlarmDevices(
  language,
  relaisActive,
  gatewayIds
) {
  return await configClient.get("/GetCurrentAlarmDevices", {
    params: {
      language: language,
      relaisActive: relaisActive,
      gids: gatewayIds.join(","),
    },
  });
}

export async function GetAlarmHistoryByDevice(did, begin, end, language) {
  return await configClient.get("/GetAlarmHistoryByDevice", {
    params: {
      did: did,
      begin: begin,
      end: end,
      language: language,
    },
  });
}

export async function GetAlarmHistoryByLogin(begin, end, language) {
  return await configClient.get("/GetAlarmHistoryByLogin", {
    params: {
      begin: begin,
      end: end,
      language: language,
    },
  });
}

export async function GetGatewaysState(gids) {
  return await configClient.get("/GetGatewaysState", {
    params: { gids: gids.join(",") },
  });
}

export async function GetConnectionLogLast7Days(gatewayId) {
  return await configClient.post("/GetConnectionLogLast7Days", {
    gid: gatewayId,
  });
}

export async function GetScreenGroup(id) {
  return await configClient.get(`/ScreenGroup?id=${id}`);
}

export async function DeleteDevice(device) {
  console.log(device);
}

export async function GetEventHistory(did) {
  return await configClient.get("/GetEventHistory", {
    params: { did: did },
  });
}
