<script>
import LayoutDefaultDesktop from "./LayoutDefaultDesktop";
import LayoutDefaultPortrait from "./LayoutDefaultPortrait";
import LayoutDefaultLandscape from "./LayoutDefaultLandscape";
import { mapGetters, mapState } from "vuex";

export default {
  name: "RenderlessLayoutDefault",
  data() {
    return {
      Layout: LayoutDefaultDesktop,
    };
  },
  computed: {
    ...mapState("layout", {
      layoutState: (state) => state.layoutState,
    }),
    ...mapGetters("layout", ["isLandscape", "isPortrait"]),
  },
  methods: {
    setLayout() {
      if (this.isPortrait) {
        this.Layout = LayoutDefaultPortrait;
      } else if (this.isLandscape) {
        this.Layout = LayoutDefaultLandscape;
      } else {
        this.Layout = LayoutDefaultDesktop;
      }
      this.$parent.$emit("update:layout", this.Layout);
    },
  },
  watch: {
    layoutState() {
      this.setLayout();
    },
  },
  created() {
    this.setLayout();
  },
  render() {
    return this.$slots.default[0];
  },
};
</script>
