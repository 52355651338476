<template>
  <v-card v-bind="$attrs" :style="styleObject" elevation="1" outlined>
    <v-card-title v-if="title">
      {{ title }}
      <v-spacer />
      <base-button icon v-if="icon" @click="$emit('click')">
        <v-icon>
          {{ icon }}
        </v-icon>
      </base-button>
    </v-card-title>
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: [String, typeof TranslateResult],
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  computed: {
    color() {
      return this.error
        ? this.$vuetify.theme.currentTheme.error
        : this.$vuetify.theme.currentTheme.primaryDark;
    },
    styleObject() {
      return {
        "border-top": `solid ${this.color} 4px`,

        // "border-radius": "12px",

        // "border-image-slice": 1,
        // "border-width": "4px",
        // "border-left": 0,
        // "border-right": 0,
        // "border-bottom": 0,
        // "border-image-source": `linear-gradient(to left, #fff, ${this.color}, ${this.color}, ${this.color}, #fff)`,
      };
    },
  },
};
</script>

<style scoped></style>
