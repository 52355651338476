<template>
  <highcharts ref="chart" :options="chartOptions" />
</template>

<script>
import { Chart } from "highcharts-vue";
import { dateFormat } from "highcharts";
import { workerService } from "@api/worker-service";
import { mapGetters } from "vuex";

const fontFamily = "Helvetica, sans-serif";
export default {
  name: "BaseLast24hChart",
  components: {
    highcharts: Chart,
  },
  props: {
    vars: {
      type: Array,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      date: new Date(),
      series: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters("deviceCache", ["getRef", "getTranslationById"]),
    endTimeMs() {
      return this.date.getTime();
    },
    beginTimeMs() {
      return this.endTimeMs - 86400000;
    },
    begin() {
      return new Date(
        this.beginTimeMs - this.date.getTimezoneOffset() * 60000
      ).toISOString();
    },
    end() {
      return new Date(
        this.endTimeMs - this.date.getTimezoneOffset() * 60000
      ).toISOString();
    },
    subTitle() {
      return this.dense && this.vars.length === 1
        ? {
            text: `( ${this.vars[0].varName} )`,
            style: {
              fontWeight: "bold",
              fontFamily: fontFamily,
              fontSize: 11,
            },
            verticalAlign: true,
          }
        : {
            enabled: false,
          };
    },
    chartOptions() {
      return {
        title: {
          text: this.$t("common.last24hour"),
          align: "left",
          style: {
            fontWeight: "bold",
            fontFamily: fontFamily,
            fontSize: this.dense ? 14 : 18,
          },
        },
        subtitle: this.subTitle,
        legend: {
          enabled: !this.dense,
        },
        chart: {
          width: this.width,
          height: this.height,
          events: {
            load() {
              this.showLoading();
            },
          },
        },
        lang: {
          noData: this.$t("common.noData"),
        },
        noData: {
          style: {
            fontFamily: "Helvetica, sans-serif",
          },
        },
        xAxis: {
          min: this.beginTimeMs,
          max: this.endTimeMs,
          gridLineWidth: 1,
          type: "datetime",
          labels: {
            formatter(value) {
              const time = value.value;
              const day = 86400000;
              if (time !== 0 && time % day === 0) return "00:00";
              else return dateFormat("%H:%M", this.value);
            },
          },
        },
        yAxis: [
          {
            gridLineWidth: 1,
            title: {
              enabled: false,
            },
          },
        ],
        series: this.series,
      };
    },
  },
  methods: {
    async refresh() {
      this.$refs.chart?.chart.showLoading();
      await this.init();
      this.$refs.chart?.chart.hideLoading();
      this.date = new Date();
    },
    async init() {
      this.series = [];
      const values = await workerService.fetchData(
        this.vars.map((v) => ({
          deviceId: this.device.id,
          varName: v.varName,
        })),
        this.begin,
        this.end,
        "minute10"
      );
      this.series = await this.load(values);
    },
    async load(historyValues) {
      try {
        const series = [];
        for (const variable of this.vars) {
          let name = variable.varName;
          const values = historyValues.find((v) => v.varName === name).data;
          const data = values
            .filter((value) => !!value.value)
            .map((value) => [+new Date(value.dateTime), value.value]);

          const ref = this.getRef(this.device, name);

          if (variable.label) {
            name = variable.label;
          } else {
            const translationNameId = ref.ReferenceTranslationNameId;
            if (translationNameId) {
              name = this.getTranslationById({
                dev: this.device,
                id: translationNameId,
              });
            }
          }

          const serie = {
            name,
            data,
            marker: {
              enabled: false,
            },
          };

          if (this.vars.length === 1) {
            serie.lineWidth = 1;
            serie.color = this.$vuetify.theme.currentTheme.primary;
          }
          series.push(serie);
        }

        return series;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>

<style scoped></style>
