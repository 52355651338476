<template>
  <v-dialog v-model="show" persistent max-width="750">
    <v-card>
      <v-container class="fill-height" fluid>
        <v-row class="fill-height">
          <v-col class="align-end flexbox" cols="12">
            <span class="text-h5">
              {{ $t("editMode.rejectedSettings") }}
            </span>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table :headers="headers" :items="getChangeEvents">
        <template #item.deviceName="{ item }">
          {{ item.dev.name }}
        </template>

        <template #item.varName="{ item }">
          {{ item.ref.Name }}
        </template>

        <template #item.actions="{ item }">
          <base-button icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </base-button>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer />
        <base-button text outlined @click.native="edit">
          {{ $t("common.edit") }}
        </base-button>
        <base-button @click.native="discard">
          {{ $t("common.discard") }}
        </base-button>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SendErrorModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("common.device"),
          align: "left",
          value: "deviceName",
        },
        {
          text: this.$t("editMode.variable"),
          align: "left",
          value: "varName",
        },
        {
          text: this.$t("editMode.newValue"),
          sortable: false,
          value: "newValue",
        },
        {
          text: this.$t("editMode.reason"),
          sortable: false,
          value: "errorText",
        },
        {
          text: this.$t("editMode.hint"),
          sortable: false,
          value: "errorHint",
        },
        {
          text: this.$t("editMode.action"),
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("edit", ["getChangeEvents"]),
  },
  methods: {
    edit() {
      this.$store.dispatch("edit/transition", "edit", { root: true });
    },
    discard() {
      this.$store.dispatch("edit/discard", null, { root: true });
    },
    deleteItem(event) {
      this.$store.dispatch("edit/dropEvent", event, { root: true });
    },
  },
};
</script>

<style scoped></style>
