import store from "@state/store";
import router from "@router/router";
import { _isLcCpu } from "@state/modules/cache/bll";
import { default as i18n } from "../i18n";
import { isDef } from "@utils/helpers";

export const translateStatic = (value, params = null) => i18n.t(value, params);

export const translate = (dev, labels) =>
  store.dispatch("deviceCache/translate", { dev, labels });

export const pushDeviceRoute = async (device, screenId) => {
  if (_isLcCpu(device) && isDef(screenId)) {
    await pushRoute({
      name: "lc-remote",
      params: {
        deviceId: device.id,
        screenId: screenId || -1,
      },
    });
  } else {
    await pushRoute({ name: "remote" });
  }
};

export const pushRoute = async (route) => {
  const pathTo = typeof route === "string" ? route : route.name;
  const current = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1
  );
  if ((!current && pathTo !== "alarm") || (current && current !== pathTo)) {
    try {
      await router.push(route);
    } catch (err) {
      console.error(err);
    }
  }
};

export default {
  translate,
  pushRoute,
  pushDeviceRoute,
};
