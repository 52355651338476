import * as types from "./mutation-types";

export default {
  [types.SET_HARDWARE_PRODUCTS]: (state, hardwareProducts = []) => {
    state.hardwareProducts = hardwareProducts;
  },
  [types.SET_SUBSCRIPTION]: (state, subscription) => {
    state.subscriptionStatus = subscription.status;
  },
  [types.SET_MIGRATING_GATEWAY]: (state, migratingGateway) => {
    state.migratingGateway = migratingGateway;
  },
  [types.SET_CREATED_GATEWAY]: (state, createdGateway) => {
    state.createdGateway = createdGateway;
  },
};
