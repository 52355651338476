import axios from "axios";
import store from "@state/store";
import { pushRoute } from "@plugins/helpers";
import { getAuthHeader } from "@utils/auth";

const apiClient = (baseUrl, resultCallback) => {
  const service = axios.create({
    baseURL: baseUrl,
    // eslint-disable-next-line no-undef
    timeout: __DEV__ ? 60000 : 20000,
    validateStatus: (status) => {
      // rejects promise; if no data on response,
      // added 304 over the default
      return (status >= 200 && status < 300) || status === 304;
    },
    headers: {
      "Content-Type": "application/json",
    },
  });

  service.interceptors.request.use(
    async (config) => {
      config.headers["Authorization"] = await getAuthHeader();
      config.headers["channel"] = store.getters["auth/getChannel"];
      return config;
    },
    (err) => Promise.reject(err)
  );

  service.interceptors.response.use(resultCallback, async (err) => {
    const { response } = err;

    if (!response) {
      console.error(err);
      return Promise.reject("api response null");
    }

    if (err.config.baseURL.endsWith("Messenger.ashx")) return Promise.resolve();

    if (response.status === 401) {
      await pushRoute({
        name: "error",
        params: {
          message: "Unauthorized access",
        },
      });
    }

    return Promise.reject(err);
  });

  return service;
};

const getCustomerId = () => {
  const impersonatedCustomer = store.getters["auth/getImpersonatedCustomer"];
  return impersonatedCustomer
    ? impersonatedCustomer.id
    : store.getters["auth/getUserId"];
};

export { apiClient, getCustomerId };
