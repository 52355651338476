import * as types from "./mutation-types";
import { adminService } from "@api/admin-service";
import { notifyUserTranslation } from "@error/helpers";
import { loadStripe } from "@stripe/stripe-js";
import * as rootTypes from "@state/root/mutation-types";

async function init({ dispatch }) {
  try {
    await dispatch("initHardwareProducts");
    await dispatch("initSupportedDevices", true);
    await dispatch("initSubscription");
  } catch (err) {
    console.error(err);
  }
}

async function initHardwareProducts({ commit }) {
  try {
    const hardwareProducts = await adminService.fetchHardwareProducts();
    commit(types.SET_HARDWARE_PRODUCTS, hardwareProducts);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function initSubscription({ commit }) {
  try {
    const subscription = await adminService.fetchSubscription();
    commit(types.SET_SUBSCRIPTION, subscription);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function initSupportedDevices(
  { commit, dispatch, getters, rootGetters },
  preFetch = false
) {
  try {
    if (preFetch) {
      const gateways = await adminService.fetchGateways();
      commit(rootTypes.SET_GATEWAYS, gateways, { root: true });
    }

    let allDevices = rootGetters["getAllOwnedDevices"];

    const createdGateway = getters.getCreatedGateway;
    if (createdGateway) {
      allDevices = [...allDevices, ...createdGateway.devices];
    }

    for (const device of allDevices) {
      const { hardware } = device;
      const product = getters.getProductByHardware(hardware);
      const available = product !== undefined;
      if (available) {
        await dispatch("setDeviceAvailable", device, { root: true });
      }
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function setMigratingGateway({ commit }, migratingGateway) {
  commit(types.SET_MIGRATING_GATEWAY, migratingGateway);
}

async function createGateway({ commit }, { gatewayFc2, licenceKey }) {
  try {
    const gateway = await adminService.createGateway(gatewayFc2, licenceKey);
    if (!gateway.success) {
      await notifyUserTranslation("error.createGatewayMigration", "warning");
      return false;
    }

    commit(types.SET_CREATED_GATEWAY, gateway);
    return true;
  } catch (err) {
    console.error(err);
    await notifyUserTranslation("error.createGateway", "error");
    return false;
  }
}

async function updateSubscription({ state }, devices) {
  try {
    const productRequests = createProductRequests(
      state.hardwareProducts,
      devices.filter((device) => device.available)
    );
    await adminService.updateSubscription(productRequests);
  } catch (err) {
    await notifyUserTranslation("error.updateSubscription", "error");
    console.error(err);
  }
}

const createProductRequests = (hardwareProducts, devices) => {
  return devices.reduce((acc, device) => {
    const product = hardwareProducts.find(
      (prod) => prod.hardware === device.hardware
    );

    acc.push({
      //this is a product request
      priceId: product.id,
      deviceId: device.id,
    });

    return acc;
  }, []);
};

async function checkout({ state }, { successUrl, cancelUrl, devices }) {
  const products = createProductRequests(state.hardwareProducts, devices);
  const checkoutSession = await adminService.createCheckoutSession(
    successUrl,
    cancelUrl,
    products
  );

  const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
  stripe
    .redirectToCheckout({
      sessionId: checkoutSession.id,
    })
    .then(function (result) {
      console.warn(result);
      //todo
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
}

async function openCustomerPortal(context, returnUrl) {
  try {
    const session = await adminService.createPortalSession(returnUrl);
    window.location.href = session.url;
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export default {
  init,
  initHardwareProducts,
  initSupportedDevices,
  initSubscription,

  setMigratingGateway,
  createGateway,
  updateSubscription,
  checkout,
  openCustomerPortal,
};
