<template>
  <v-card outlined>
    <base-last24h-chart
      :device="device"
      :height="225"
      :vars="filtered"
      :width="420"
      dense
    />
  </v-card>
</template>

<script>
import BaseLast24hChart from "@components/base/BaseLast24hChart";

export default {
  name: "TooltipChart",
  components: {
    BaseLast24hChart,
  },
  props: {
    varName: {
      type: String,
      required: false,
      default: "varName",
    },
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filtered() {
      return [{ varName: this.varName }];
    },
  },
};
</script>

<style scoped></style>
