import { dateFormat } from "highcharts";
import { WdgUnitValues } from "@modules/lccpu/model/enum-components";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      chart: null,
      renderer: null,
      customRenderedItems: [],
      initialRender: true,
      renderCallbacks: [],
      defaultLocale: "en-US", //todo change,
      colors: {
        primary: "#007c60",
        error: "#f80000",
        black: "#404040",
        background: "#f8f8f8",

        lightGray: "#e8e8e8",
        darkGray: "#b4bfbc",
        disabledGray: "#888f87",

        lightGreen: "#e0f0e8",
        darkGreen: "#78a490",
        disabledRed: "#b08c68",
      },
      base: process.env.VUE_APP_ICON,
      plotMetrics: null,
      chartMetrics: null,
      strokeWidth: 10,
      charWidth: 10,
      units: WdgUnitValues,
    };
  },
  computed: {
    ...mapGetters(["getSelectedDevice"]),
    baseUrl() {
      const base = "/icons";
      return `${base}/${this.getSelectedDevice.hardware}/${this.getSelectedDevice.version}`;
    },
  },
  methods: {
    initialize(chart) {
      if (chart != null) {
        this.chart = chart;
        this.renderer = this.chart.renderer;
      }
      this.initChartMetrics();
      this.initPlotMetrics();
      this.clearCustom();
    },
    clearCustom() {
      for (const item of this.customRenderedItems) {
        item.destroy();
      }
      this.customRenderedItems = [];
    },
    toLocale(string) {
      return string.toLocaleString(this.defaultLocale, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    formatTime(time) {
      return dateFormat("%Mm%Ss", time);
    },
    minMax(val, min, max) {
      return Math.min(Math.max(val, min), max);
    },
    initChartMetrics() {
      const { x, y, width, height } = this.chart.spacingBox;

      this.chartMetrics = {
        x,
        y,
        width,
        height,
        centerX: x + width / 2,
        centerY: y + height / 2,
        endX: x + width,
        endY: y + height,
      };
    },
    initPlotMetrics() {
      const {
        plotLeft: x,
        plotTop: y,
        plotWidth: width,
        plotHeight: height,
      } = this.chart;

      const { max: maxGraphValueX, min: minGraphValueX } = this.chart.xAxis[0];

      const { max: maxGraphValueY, min: minGraphValueY } = this.chart.yAxis[0];

      let oneAmountInPixelsX;
      let oneAmountInPixelsY;
      //chart is inverted in clock and heating and maybe more in the future
      if (this.chart.inverted) {
        oneAmountInPixelsX = width / (maxGraphValueY - minGraphValueY);
        oneAmountInPixelsY = height / (maxGraphValueX - minGraphValueX);
      } else {
        oneAmountInPixelsX = width / (maxGraphValueX - minGraphValueX);
        oneAmountInPixelsY = height / (maxGraphValueY - minGraphValueY);
      }

      this.plotMetrics = {
        x,
        y,
        width,
        height,
        endX: x + width,
        endY: y + height,
        centerX: x + width / 2,
        centerY: y + height / 2,
        maxGraphValueX,
        minGraphValueX,
        maxGraphValueY,
        minGraphValueY,
        oneAmountInPixelsX,
        oneAmountInPixelsY,
      };

      if (this.chart.yAxis[1]) {
        const maxGraphValueY2 = this.chart.yAxis[1].max;
        const minGraphValueY2 = this.chart.yAxis[1].min;
        const oneAmountInPixelsY2 =
          height / (maxGraphValueY2 - minGraphValueY2);

        this.plotMetrics.maxGraphValueY2 = maxGraphValueY2;
        this.plotMetrics.minGraphValueY2 = minGraphValueY2;
        this.plotMetrics.oneAmountInPixelsY2 = oneAmountInPixelsY2;
      }
    },
    translateX(x) {
      return this.plotMetrics.x + this.plotMetrics.oneAmountInPixelsX * x;
    },
    translateY(y) {
      return (
        this.plotMetrics.y +
        this.plotMetrics.oneAmountInPixelsY *
          Math.abs(this.plotMetrics.maxGraphValueY - y)
      );
    },
    renderTextCentered(
      text,
      x,
      y,
      color = "black",
      charWidth = this.charWidth
    ) {
      text = text.toString(); // compose 0 == 0 in stead of '0'
      this.renderText(text, x, y, color, charWidth, "center");
      // x value after rendered text
      return x + (text.length / 2) * charWidth;
    },

    //todo include charWidth in 3th(attrs) object
    renderText(
      text,
      x,
      y,
      color = "black",
      charWidth = this.charWidth,
      align = "left"
    ) {
      text = text.toString();
      const scaledY = y + charWidth * 1.4;
      const textItem = this.renderer
        .text(text, x, scaledY)
        .attr({
          zIndex: 3,
          align: align,
        })
        .css({
          //font is relative but 1.6 is a good scale
          fontSize: `${charWidth * 1.6}px`,
          fontFamily: "lc-font",
          color: color,
        });
      this.renderItem(textItem);
      return x + charWidth * text.length;
    },
    renderLine(
      path,
      { strokeWidth = this.strokeWidth, color = "black", dash = 0, z = 3 } = {}
    ) {
      const line = this.renderer
        .path(path)
        .attr({
          "stroke-width": strokeWidth,
          stroke: color,
          zIndex: z,
        })
        .css({
          "stroke-dasharray": `${dash} ${dash}`,
        });
      this.renderItem(line);
    },
    renderIconS3(iconName, x, y, width, height, { z = 3 } = {}) {
      const icon = `${this.baseUrl}/${iconName}.svg`;
      this.renderIcon(icon, x, y, width, height, { z });
      return x + width;
    },
    renderIcon(icon, x, y, width, height, { z = 3 } = {}) {
      const iconItem = this.renderer.image(icon, x, y, width, height).attr({
        zIndex: z,
      });
      this.renderItem(iconItem);
    },
    renderRectangle(x, y, width, height, { color = "black", z = 3 } = {}) {
      const rectItem = this.renderer.rect(x, y, width, height).attr({
        fill: color,
        zIndex: z,
      });
      this.renderItem(rectItem);
    },
    renderCircle(x, y, radius, { z = 3, color = this.colors.primary } = {}) {
      const circleItem = this.renderer.circle(x, y, radius).attr({
        zIndex: z,
        fill: color,
      });
      this.renderItem(circleItem);
    },
    renderSmallGreenCircle(x, y) {
      const smallCircleRadius = this.plotMetrics.height * 0.025;
      const smallCircleDiameter = smallCircleRadius * 2;
      const circleX = x + smallCircleDiameter;
      this.renderCircle(circleX, y, smallCircleRadius);
      //next x
      return x + smallCircleDiameter * 2;
    },
    renderItem(item) {
      this.customRenderedItems.push(item);
      item.add();
    },
  },
  watch: {
    $props: {
      handler() {
        if (!this.initialRender) {
          this.$nextTick(() => {
            for (const renderCallBack of this.renderCallbacks) {
              renderCallBack();
            }
          });
        }
        this.initialRender = false;
      },
      deep: true,
      immediate: true,
    },
  },
};
