<template>
  <layout-default>
    <base-card :title="$t('eventLog.eventLogDevice', { deviceName })">
      <v-data-table
        :headers="headers"
        :items="eventLogData"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :hide-default-footer="eventLogData.length < 11"
        fixed-header
        dense
      >
        <template #item.Timestamp="{ item }">
          {{ convertTimeStamp(item.Timestamp) }}
        </template>
        <template #item.EventType="{ item }">
          {{ typeMap[item.EventType] }}
        </template>
        <template #item.User="{ item }">
          {{ getUserName(item) }}
        </template>
        <template #item.RemoteUser="{ value }">
          {{ translateRemote(value) }}
        </template>

        <template #no-data>
          {{ $t("eventLog.noData") }}
        </template>
      </v-data-table>
    </base-card>
  </layout-default>
</template>
<script>
import { GetEventHistory } from "@api/config-service";
import { mapGetters } from "vuex";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { adminService } from "@api/admin-service";

export default {
  name: "EventLogPage",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      eventLogData: [],
      sortBy: "Index",
      sortDesc: true,
      userNames: {},
      typeMap: {
        0: "VAR",
        1: "RANGE",
        2: "RESET",
        3: "RESTORE",
        4: "DEFAULT",
        5: "UPDATE",
        6: "PWRFAIL",
        7: "PWRRESTORE",
        8: "ACCUTEST",
        9: "LOG",
        10: "DATE",
        11: "TIME",
        12: "SYSERR",
        13: "REMOTE",
        14: "ABORT",
        15: "UNDEFINED",
        16: "PREFETCH",
        17: "USBVARS",
        18: "UNUSED",
        19: "RBS_PWR",
        20: "MODERR",
        21: "INVALID",
      },
      headers: [
        { text: "Index", value: "Index" },
        { text: "Timestamp", value: "Timestamp" },
        { text: "EventType", value: "EventType" },
        { text: "User", value: "User" },
        { text: "RemoteUser", value: "RemoteUser" },
        { text: "VarName", value: "VarName" },
        { text: "OldValue", value: "OldValue" },
        { text: "NewValue", value: "NewValue" },
      ],
    };
  },
  computed: {
    ...mapGetters("deviceCache", ["getRefName"]),
    ...mapGetters(["getSelectedGateway"]),
    deviceName() {
      return this.device?.name;
    },
  },
  methods: {
    async init() {
      if (!this.device?.id) {
        return;
      }
      this.loading = true;
      this.eventLogData = await GetEventHistory(this.device.id);

      for (const e of this.eventLogData.filter((e) => e.User > 60000)) {
        if (this.userNames[e.User]) continue;
        this.userNames[e.User] = await adminService.fetchUserEmailById16(
          e.User - 60000
        );
      }

      for (const e of this.eventLogData) {
        e.VarName = this.convertVariable(e);
      }

      this.loading = false;
    },
    convertTimeStamp(stamp) {
      let date = DateTimeConverter.parseDateLocalTime(stamp);
      return (
        DateTimeConverter.dateToString(date) +
        "_" +
        DateTimeConverter.timeToString(date)
      );
    },
    getUserName(event) {
      return event.User < 60000 || event.User >= 65000
        ? event.User
        : this.getSelectedGateway.created <
          this.convertTimeStamp(event.Timestamp)
        ? this.userNames[event.User]
        : "FarmConnect 2";
    },
    convertVariable(item) {
      let result = item.EventType;
      switch (item.EventType) {
        case 0: // VAR
        case 1: // RANGE
        case 8: // ACCUTEST
          result = this.getRefName(this.device, item.VarIndex * 2);
          break;
        case 2: // RESET
          switch (item.VarIndex) {
            case 0:
              result = "RTCPU";
              break;
            case 1:
              result = "WEC";
              break;
            case 2:
              result = "EXTERN";
              break;
          }
          break;
        case 3: // RESTORE
        case 4: // DEFAULT
        case 6: // PWRFAIL
        case 7: // PWRRESTORE
        case 9: // LOG
        case 10: // DATE
        case 11: // TIME
        case 13: // REMOTE
          result = "";
          break;
        case 5: // UPDATE
          switch (item.VarIndex) {
            case 1:
              result = "SOFTWAREVERSION";
              break;
            case 2:
              result = "VARVERSION";
              break;
            case 3:
              result = "SPECIAL";
              break;
            case 4:
              result = "MINORVERSION";
              break;
          }
          break;
        case 12: // SYSERR
          switch (item.VarIndex) {
            case 0:
              result = "NONE";
              break;
            case 1:
              result = "VDC5";
              break;
            case 2:
              result = "RTC";
              break;
            case 3:
              result = "SPRSPE";
              break;
            case 4:
              result = "EEPROM";
              break;
            case 5:
              result = "TOUCH";
              break;
            case 6:
              result = "MAC";
              break;
            case 7:
              result = "FLASH";
              break;
            case 8:
              result = "SRAM";
              break;
            case 9:
              result = "ETHER";
              break;
            case 10:
              result = "USB";
              break;
          }
          break;
      }
      return result;
    },
    translateRemote(remote) {
      return remote ? this.$t("common.yes") : this.$t("common.no");
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
<style scoped></style>
