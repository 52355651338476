<template>
  <base-card :title="title">
    <v-divider />

    <v-data-table
      dense
      :headers="computedHeaders"
      :items="devices"
      hide-default-footer
      fixed-header
      :items-per-page="-1"
      :item-class="itemRowBackground"
      :height="5 * 48"
    >
      <template #item.Name="{ item }">
        {{ item.Name }}
        <v-icon small v-if="!item.isLicenced" class="pb-1"> mdi-lock </v-icon>
      </template>

      <template #item.lastSeen="{ item }">
        <span>{{ lastSeenFor(item.End) }}</span>
      </template>

      <template #item.eventLog="{ item }">
        <base-button
          icon
          :disabled="!item.isLicenced"
          @click="() => navigateToEventLog(item)"
        >
          <v-icon> mdi-calendar-text </v-icon>
        </base-button>
      </template>

      <template #item.backups="{ item }">
        <base-button
          icon
          :disabled="!item.isLicenced"
          @click="() => navigateToBackup(item)"
        >
          <v-icon> mdi-database-arrow-right </v-icon>
        </base-button>
      </template>

      <template #item.deleteAction="{ item }">
        <delete-device-dialog :device="item" />
      </template>
    </v-data-table>
  </base-card>
</template>

<script>
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { mapGetters } from "vuex";
import DeleteDeviceDialog from "@components/overview/DeleteDeviceDialog";

export default {
  name: "DeviceListCard",
  components: { DeleteDeviceDialog },
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t("common.name"), value: "name", sortable: false },
        {
          text: this.$t("common.hardware"),
          value: "hardware",
          sortable: false,
        },
        {
          text: this.$t("common.address"),
          value: "address",
          sortable: false,
        },
        { text: this.$t("common.version"), value: "version", sortable: false },
        {
          text: this.$t("common.lastSeen"),
          value: "lastSeen",
          sortable: false,
        },
        {
          text: this.$t("common.eventLog"),
          value: "eventLog",
          sortable: false,
        },
        {
          text: this.$t("backups.backups"),
          value: "backups",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getIsProvidingService", "getIsCustomer"]),
    title() {
      return this.$t("common.devicesNumber", { number: this.devices.length });
    },
    computedHeaders() {
      if (this.getIsCustomer) {
        return this.headers.filter((h) => h.value !== "deleteAction");
      }
      return this.headers;
    },
  },
  methods: {
    itemRowBackground(item) {
      return item.isLicenced ? "" : "grey lighten-2";
    },
    lastSeenFor(timestamp) {
      return timestamp
        ? DateTimeConverter.timeAgoParse(timestamp)
        : this.$t("common.notYet");
    },
    navigateToEventLog(device) {
      this.$helpers.pushRoute({
        name: "event_log",
        params: {
          device: device,
        },
      });
    },
    navigateToBackup(device) {
      this.$helpers.pushRoute({
        name: "backups",
        params: {
          device: device,
        },
      });
    },
    deleteDevice(device) {
      console.log(device);
    },
  },
};
</script>

<style scoped></style>
