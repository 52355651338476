<template>
  <component :is="buttonHolder">
    <template #btn1>
      <slot name="btn1" />
    </template>
    <template #btn2>
      <slot name="btn2" />
    </template>
  </component>
</template>

<script>
import PortraitButtons from "@components/admin/setup/layout/PortraitButtons";
import DesktopButtons from "@components/admin/setup/layout/DesktopButtons";
import { mapGetters } from "vuex";

export default {
  name: "ButtonHolder",
  computed: {
    ...mapGetters("layout", ["isPortrait"]),
    buttonHolder() {
      if (this.isPortrait) {
        return PortraitButtons;
      }
      return DesktopButtons;
    },
  },
};
</script>

<style scoped></style>
