import * as types from "./mutation-types";
import router from "@router/router";
import { Auth } from "aws-amplify";
import { isUndef } from "@utils/helpers";
import { pushRoute } from "@plugins/helpers";
import { notifyUser } from "@error/helpers";
import { adminService } from "@api/admin-service";

async function init({ commit, dispatch, getters, rootGetters }) {
  const mainAccount = await adminService.fetchUser();
  commit(types.SET_MAIN_ACCOUNT, mainAccount);
  await dispatch("settings/initializeSettings", mainAccount.settings, {
    root: true,
  });

  if (getters.isService) {
    commit(types.SET_USER_STATUS, "SERVICE");
    await dispatch("service/init", null, { root: true });
    return Promise.resolve();
  }

  if (getters.isDealer) {
    commit(types.SET_USER_STATUS, "DEALER");
    await dispatch("dealer/init", null, { root: true });
    return Promise.resolve();
  }

  if (getters.isMechanic) {
    commit(types.SET_USER_STATUS, "MECHANIC");
    await dispatch("mechanic/init", null, { root: true });
    return Promise.resolve();
  }

  if (getters.getIsCustomer) {
    const customerAccount = await adminService.fetchCustomer();
    commit(types.SET_CUSTOMER_ACCOUNT, customerAccount);
    await dispatch("subscription/init", null, { root: true });
    if (!rootGetters["subscription/getHasSubscription"]) {
      commit(types.SET_USER_STATUS, "CUSTOMER_SETUP");
      return Promise.resolve();
    }
  }

  commit(types.SET_USER_STATUS, "VALID");
}

async function showAuth({ commit }) {
  commit(types.SHOW_AUTH);
}

async function setImpersonatedCustomer({ commit }, customer) {
  const customerAccount = await adminService.fetchCustomer(customer.id);
  commit(types.SET_IMPERSONATED_CUSTOMER, customerAccount);
}

async function authChangedHandler(
  { commit, dispatch, getters, rootGetters },
  user
) {
  dispatch("setLoading", { value: true }, { root: true });

  if (isUndef(user)) {
    commit(types.RESET_USER);
    await pushRoute("/login");
    return;
  }

  try {
    commit(types.RESET_USER);
    const idTokenJwt = user.signInUserSession.idToken.jwtToken;
    const userDataFromToken = user.signInUserSession.idToken.payload;
    commit(types.SET_TOKEN, idTokenJwt);
    commit(types.SET_USER, userDataFromToken);

    await dispatch("init");

    if (getters.checkStatus("SERVICE")) {
      await router.replace({ name: "service" });
      return;
    }

    if (getters.checkStatus("DEALER")) {
      await router.replace({ name: "dealer" });
      return;
    }

    if (getters.checkStatus("MECHANIC")) {
      await router.replace({ name: "mechanic" });
      return;
    }

    if (getters.checkStatus("CUSTOMER_SETUP")) {
      await router.replace({ name: "setup" });
      return;
    }

    let deviceId = null;

    const route = router.currentRoute.query.redirectFrom;
    if (route && route.startsWith("/lc-remote")) {
      const tokens = route.split("/").slice(2);
      deviceId = tokens[0];
    }

    const hasAlarm = await dispatch("init", { deviceId }, { root: true });

    if (!getters.checkStatus("VALID")) {
      return;
    }

    //prevent customers from navigating to pages they shouldn't be on
    const redirectUrl = router.currentRoute.query.redirectFrom;
    if (redirectUrl?.includes("setup") || redirectUrl?.includes("service")) {
      await router.replace({ name: "alarm" });
      return;
    }

    const shouldNavigateToHome =
      !redirectUrl || redirectUrl === "/" || redirectUrl === "/remote";

    if (hasAlarm && shouldNavigateToHome) {
      await router.replace({ name: "alarm" });
      return;
    }

    const home = rootGetters["settings/getHome"];
    if (home && shouldNavigateToHome) {
      await router.replace({ name: "remote" });
      const homeScreen = rootGetters["getCustomScreenById"](home);
      if (homeScreen) {
        await dispatch("rtRemote/onScreenSelect", homeScreen, {
          root: true,
        });
        return;
      }
    }
    await router.replace(redirectUrl || { name: "alarm" });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch("setLoading", { value: false }, { root: true });
  }
}

async function validate({ getters }, routeTo) {
  // if token is null, redirect to login
  if (!getters.loggedIn) {
    return null;
  }

  const { claim } = routeTo.meta;
  if (!claim) {
    return true;
  }
  return getters.checkClaim("cognito:groups", claim);
}

async function logout({ commit }) {
  // todo session logout, invalidate refresh token
  await Auth.signOut();
  commit(types.RESET_USER);
}

async function acceptLicence() {
  try {
    const user = await adminService.acceptLicence();
    return user.hasAcceptedLicence;
  } catch (err) {
    console.error(err);
    notifyUser("error", "something went wrong accepting the licence");
    return false;
  }
}

async function setServiceAccess({ commit }, allow) {
  try {
    const customer = await adminService.setServiceAccess(allow);
    commit(types.SET_SERVICE_ACCESS, customer.allowServiceAccess);
  } catch (err) {
    console.error(err);
    notifyUser("error", "something went wrong giving access");
    return false;
  }
}

export default {
  init,
  setImpersonatedCustomer,
  acceptLicence,
  setServiceAccess,
  showAuth,
  validate,
  authChangedHandler,
  logout,
};
