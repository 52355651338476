<template>
  <div
    :style="{ color: $vuetify.theme.currentTheme.primary }"
    style="cursor: pointer"
    @click="navigate"
  >
    {{ linkText }}
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RtLink",
  props: {
    screenGroup: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCustomScreenById"]),
    screenId() {
      return this.screenGroup.Extra;
    },
    linkText() {
      return this.screenGroup.Value;
    },
  },
  methods: {
    async navigate() {
      const screen = this.getCustomScreenById(this.screenId);
      await this.$store.dispatch("rtRemote/onScreenSelect", screen);
    },
  },
};
</script>

<style scoped></style>
