import Vue from "vue";
import {
  LcComponents,
  resolveEnumValue,
} from "@modules/lccpu/model/enum-components";

import LcNavButton from "@modules/lccpu/components/LcNavButton";
import LcVariable from "@modules/lccpu/components/LcVariable";
import LcBaseIcon from "@modules/lccpu/components/LcBaseIcon";
import LcText from "@modules/lccpu/components/LcText";

import * as widgetVersions from "@modules/lccpu/components/widgets/widgetVersions";
import { isUndef } from "@utils/helpers";

const COMPONENT_NOT_IMPLEMENTED = Vue.component("NOT_IMPLEMENTED", {
  name: "NOT_IMPLEMENTED",
  props: {
    componentName: {
      default: "UNKNOWN",
    },
  },
  render() {
    console.log(`Component not implemented: ${this.componentName}`);
    return null;
  },
});

export function resolveApiForComponent(item) {
  const { component } = item;
  let category = "content";
  let vm;

  const version = item.version;

  switch (resolveEnumValue(component)) {
    case LcComponents.NAVIGATE:
      vm = LcNavButton;
      category = "navigation";
      break;
    case LcComponents.VARIABLE:
      vm = LcVariable;
      break;
    case LcComponents.ICON:
      vm = LcBaseIcon;
      break;
    case LcComponents.TEXT:
      vm = LcText;
      break;

    case LcComponents.WDG_ALARM_CODE:
      vm = widgetVersions.AlarmCode.get(version);
      break;
    case LcComponents.WDG_ALARM_PERCENTAGE:
      vm = widgetVersions.AlarmPercentage.get(version);
      break;
    case LcComponents.WDG_ALARM_TEMPERATURE:
      vm = widgetVersions.AlarmTemperature.get(version);
      break;

    case LcComponents.WDG_CLOCK_ONOFF:
      vm = widgetVersions.ClockOnOff.get(version);
      break;

    case LcComponents.WDG_COOLING_ANA:
      vm = widgetVersions.CoolingAnalog.get(version);
      break;
    case LcComponents.WDG_COOLING_ONOFF:
      vm = widgetVersions.CoolingOnOff.get(version);
      break;
    case LcComponents.WDG_COOLING_TP:
      vm = widgetVersions.CoolingTP.get(version);
      break;

    case LcComponents.WDG_CURVE:
      vm = widgetVersions.Curve.get(version);
      break;
    case LcComponents.WDG_CURVE_PERC:
      vm = widgetVersions.CurvePercentage.get(version);
      break;
    case LcComponents.WDG_CURVE_TEMPERATURE:
      vm = widgetVersions.CurveTemperature.get(version);
      break;
    case LcComponents.WDG_CURVE_VENTILATION:
      vm = widgetVersions.CurveVentilation.get(version);
      break;

    case LcComponents.WDG_HEATING_ANA:
      vm = widgetVersions.HeatingAnalog.get(version);
      break;
    case LcComponents.WDG_HEATING_ONOFF:
      vm = widgetVersions.HeatingOnOff.get(version);
      break;

    case LcComponents.WDG_METER:
      vm = widgetVersions.Meter.get(version);
      break;

    case LcComponents.WDG_STEP_VENTILATION:
      vm = widgetVersions.StepVentilation.get(version);
      break;
    case LcComponents.WDG_VENTILATION:
      vm = widgetVersions.Ventilation.get(version);
      break;

    default:
      category = "HIDDEN";
      vm = COMPONENT_NOT_IMPLEMENTED;
      break;
  }

  if (isUndef(vm)) {
    category = "HIDDEN";
    vm = COMPONENT_NOT_IMPLEMENTED;
  }

  return { vm, category };
}
