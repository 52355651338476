<template>
  <base-card :title="title">
    <highcharts :options="chartOptions" />
  </base-card>
</template>

<script>
import { Chart } from "highcharts-vue";
import { GetConnectionLogLast7Days } from "@api/config-service";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { dateFormat } from "highcharts";

// GatewayApplicationStarted = 1,
// PC485Restarted,
// PC485Connected, = 3 (bv weer goede code)
// PC485Disconnected, = 4 (bv foute code)
// PC485Responding, = 5, na 1
// PC485NotResponding

export default {
  name: "GatewayUptimeChart",
  components: {
    highcharts: Chart,
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: this.$t("common.gatewayUptime"),
      connectionLog: [],
      days: {
        0: this.$t("common.sunday"),
        1: this.$t("common.monday"),
        2: this.$t("common.tuesday"),
        3: this.$t("common.wednesday"),
        4: this.$t("common.thursday"),
        5: this.$t("common.friday"),
        6: this.$t("common.saturday"),
      },
    };
  },
  computed: {
    chartOptions() {
      const self = this;
      return {
        title: {
          text: null,
        },
        chart: {
          height: 240,
          margin: [25, 50, 75, 75],
          events: {
            load() {
              // https://github.com/highcharts/highcharts-vue/issues/1
              setTimeout(this.reflow.bind(this));
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            const text = this.y
              ? self.$t("common.connected")
              : self.$t("common.disconnected");
            return (
              "<b>" +
              text +
              "</b> at <b>" +
              dateFormat("%Y-%m-%d %H:%M:%S", this.x) +
              "</b>"
            );
          },
        },
        xAxis: {
          type: "datetime",
          min: this.min,
          max: this.max,
          tickInterval: 86400000,
          labels: {
            formatter(value) {
              const date = new Date();
              date.setTime(value.value);
              const day = date.getDay();
              return self.days[day];
            },
          },
        },
        yAxis: {
          title: {
            enabled: false,
          },
          min: 0,
          max: 1,
          tickAmount: 2,
          labels: {
            formatter(value) {
              return value.value
                ? self.$t("common.online")
                : self.$t("common.offline");
            },
          },
        },
        series: this.series,
      };
    },
    min() {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      start.setHours(0, 0, 0, 0);
      return Math.floor(start.getTime());
    },
    max() {
      const end = new Date();
      end.setDate(end.getDate());
      return Math.floor(end.getTime());
    },
    series() {
      const data = [];
      let lastEvent = 4;

      for (const event of this.connectionLog) {
        const eventDate = DateTimeConverter.parseDate(event.Stamp);
        const eventMs = Math.floor(eventDate.getTime());
        data.push([Math.max(eventMs, this.min), event.Event === 3 ? 1 : 0]);
        lastEvent = event.Event;
      }

      data.push([this.max, lastEvent === 3 ? 1 : 0]);

      return [
        {
          name: "event",
          data: data,
          color: this.$vuetify.theme.currentTheme.primaryDark,
          step: "left",
          zoneAxis: "x",
        },
      ];
    },
  },
  async mounted() {
    this.connectionLog = await GetConnectionLogLast7Days(this.gateway.id);
  },
  watch: {
    async gateway() {
      this.connectionLog = await GetConnectionLogLast7Days(this.gateway.id);
    },
  },
};
</script>

<style scoped></style>
