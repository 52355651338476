<template>
  <div>
    <v-row
      v-if="numberOfNavButtons"
      no-gutters
      style="background-color: #007c60"
      class="py-4"
    >
      <v-row
        no-gutters
        v-for="(row, rowIndex) in Math.ceil(numberOfNavButtons / 4)"
        :key="rowIndex"
      >
        <v-spacer />
        <div
          v-for="(column, columnIndex) in 4"
          :key="columnIndex"
          class="d-flex justify-center"
        >
          <slot
            name="navigation"
            v-bind="{ item: row, column, index: rowIndex * 4 + columnIndex }"
          >
            <div style="width: 78px" />
          </slot>
        </div>
        <v-spacer />
      </v-row>
    </v-row>
    <div v-if="numberOfNavButtons" class="horizontal-nav-bar-end" />

    <div
      :style="styleObject"
      id="content"
      style="background-color: #f8f8f8"
      @click="$emit('clicked')"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LcScreenPortrait",
  props: {
    numberOfNavButtons: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      scaleFactor: 1,
    };
  },
  computed: {
    ...mapGetters(["getSelectedDevice", "getSelectedGateway"]),
    styleObject() {
      return {
        "transform-origin": "top left",
        transform: `scale(${this.scaleFactor})`,
      };
    },
  },
  mounted() {
    const element = document.getElementById("content");
    if (!element) {
      return;
    }
    const width = element.scrollWidth;
    const windowWidth = window.innerWidth;
    this.scaleFactor = windowWidth / width;
  },
};
</script>

<style scoped>
.horizontal-nav-bar-end {
  height: 6px;
  border-bottom: 4px solid #007c60;
}
</style>
