<template>
  <span class="lc-text" :style="styleObject" style="line-height: 1">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "LcText",
  props: {
    value: {
      type: String,
      required: true,
    },
    fontColor: {
      type: String,
      required: true,
    },
    fontSize: {
      type: String,
      required: true,
    },
    isReadOnly: {
      //not used
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    isEnabled: {
      //not used
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: "left",
      validator: (value) => ["left", "center", "right"].indexOf(value) !== -1,
    },
  },
  computed: {
    fontSizePx() {
      switch (this.fontSize) {
        case "MEDIUM":
          return "20px";
        case "SMALL":
          return "14px";
        default:
          return "20px";
      }
    },
    styleObject() {
      return {
        color: this.fontColor,
        fontSize: this.fontSizePx,
        "text-align": this.align,
      };
    },
  },
};
</script>

<style scoped>
.lc-text {
  font-family: lc-font, serif;
  white-space: nowrap;
}
</style>
