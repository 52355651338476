<template>
  <div style="width: 128px; height: 128px">
    <div v-for="index in 9" :key="index" class="ring" />
  </div>
</template>

<script>
export default {
  name: "BaseFingerprintSpinner",
};
</script>

<style lang="scss" scoped>
.ring {
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: var(--v-primary-base);
  animation: fingerprint-spinner-animation 2500ms
    cubic-bezier(0.68, -0.75, 0.265, 1.75) infinite forwards;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

@for $i from 1 to 10 {
  .ring:nth-child(#{$i}) {
    height: calc(120px / 9 + (#{$i} - 1) * 120px / 9);
    width: calc(120px / 9 + (#{$i} - 1) * 120px / 9);
    animation-delay: calc(50ms * #{$i});
  }
}

@keyframes fingerprint-spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}
</style>
