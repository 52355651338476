import { isDef, isUndef } from "@utils/helpers";

export const combineExprResults = {
  methods: {
    collectResultsForType(
      actionType = "show",
      exprList,
      actionNames,
      initial = false
    ) {
      let result;
      if (
        exprList &&
        Array.isArray(exprList) &&
        actionNames &&
        Array.isArray(actionNames)
      ) {
        let error;
        try {
          const extraToArray = (exprList, actionNames) => {
            return actionNames.map((name) =>
              name.charAt(0) === "-" // invert
                ? exprList.find((expr) => expr.Name === name.slice(1))
                : exprList.find((expr) => expr.Name === name)
            );
          };
          const actions = extraToArray(exprList, actionNames).filter(
            (val) => val.Action === actionType
          );

          for (let val of actions) {
            error = null;
            let invert = false;

            actionNames.forEach((name) => {
              if (val.Name === name.slice(1)) invert = true;
            });

            if (!isNaN(val.Expression)) {
              result = !!parseInt(val.Expression);
              continue;
            }

            if (!val.result.ok) {
              error = val.result.error;
              result = false;
            } else {
              result = invert ? !val.result.data : !!val.result.data;
            }
            if (result === false) break;
          }
        } catch (err) {
          error = err;
        }
        if (isDef(error)) {
          console.error(error);
        }
      }
      return isUndef(result) ? initial : result;
    },
  },
};
