<template>
  <div id="container">
    <gauge-component
      id="gauge"
      :icon="typeIcon"
      :status="status"
      :width="width / 4"
    />
    <highcharts
      id="clock"
      :options="clockOptions"
      :callback="clockRenderCallback"
    />
  </div>
</template>

<script>
import { dateFormat } from "highcharts";
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import GaugeComponent from "@modules/lccpu/components/widgets/GaugeComponent";
import { isUndef } from "@utils/helpers";

const minValue = 0;
const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

const clockManuallyIcon = "wdg_clock_manually";

const typeEnum = {
  MISC: "MISC",
  WATER: "WATER",
  LIGHT: "LIGHT",
  FEED: "FEED",
};

const dayValidator = (value) => value >= 0 && value <= day;

export default {
  name: "WidgetClockOnOff",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => typeEnum.hasOwnProperty(value),
    },
    currentTime: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    startTime: {
      type: Array,
      required: true,
      validator: (value) => value.every(dayValidator),
    },
    stopTime: {
      type: Array,
      required: true,
      validator: (value) => value.every(dayValidator),
    },
    isEnabled: {
      type: [Boolean, Number],
      required: true,
    },
    isAuto: {
      type: [Boolean, Number],
      required: true,
    },
    status: {
      type: [Boolean, Number],
      required: true,
    },
    width: {
      type: Number,
      default: 360,
    },
    height: {
      type: Number,
      default: 272,
    },
  },
  mixins: [WidgetBase],
  computed: {
    typeIcon() {
      const type = this.type.toLowerCase();
      const color = this.isEnabled ? "green" : "gray";
      return `wdg_clock_${type}_${color}`;
    },
    clockOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "bullet",
          inverted: true,
          backgroundColor: this.colors.background,
          plotBackgroundColor: this.isEnabled
            ? this.colors.lightGreen
            : this.colors.lightGray,
          ...this.calculateClockMetrics(),
        },
        yAxis: {
          minorTickInterval: hour,
          minorTickLength: this.height / 50,
          minorTickWidth: this.width / 150,
          minorGridLineColor: "transparent",
          tickInterval: 6 * hour,
          tickLength: this.height / 44,
          tickWidth: this.width / 150,
          tickColor: "#999999",
          gridLineColor: "transparent",
          type: "datetime",
          labels: {
            enabled: this.isEnabled && this.isAuto,
            formatter(value) {
              const time = value.value;
              if (time !== 0 && time % day === 0) return "24:00";
              else return dateFormat("%H:%M", this.value);
            },
            style: {
              fontSize: this.height / 25,
            },
          },
          min: minValue,
          max: day,
          title: false,
        },
        series: [0],
      };
    },
  },
  methods: {
    calculateClockMetrics() {
      const margin = [
        this.height * 0.58,
        this.width * 0.1,
        this.height * 0.28,
        this.width * 0.1,
      ];
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin,
      };
    },
    //overgenomen van embedded voor consistentie
    opdelen(time, part1, part2) {
      part1.start = time.start;
      if (time.start < time.end) {
        part1.end = time.end;
        part2.start = 0;
        part2.end = 0;
      } else {
        part1.end = day;
        part2.start = 0;
        part2.end = time.end;
      }
    },
    //overgenomen van embedded voor consistentie
    dubbel(t1, t2, duplicate) {
      duplicate.start = 0;
      duplicate.end = 0;

      if (t1.start === t1.end) return false;

      if (t2.start === t2.end) return false;

      if (t2.start >= t1.start && t2.start < t1.end) {
        // start 2 binnen interval 1
        duplicate.start = t2.start;
        if (t1.end < t2.end) duplicate.end = t1.end;
        else duplicate.end = t2.end;
        return true;
      }

      if (t1.start >= t2.start && t1.start < t2.end) {
        // start 1 binnen interval 2
        duplicate.start = t1.start;
        if (t2.end < t1.end) duplicate.end = t2.end;
        else duplicate.end = t1.end;
        return true;
      }

      return false;
    },
    clockRenderCallback(chart) {
      try {
        this.initialize(chart);
        this.charWidth = this.plotMetrics.height / 5;
        //min 5 ? educated guess
        this.strokeWidth = Math.min(this.plotMetrics.height / 20, 5);

        this.renderBorders();
        this.renderLightGrayBackground();

        if (!this.isEnabled) {
          return;
        }

        if (this.isAuto) {
          this.renderCurrentTimeLineAndText();
          this.renderTimeBlocksAndDuplicates();
        } else {
          this.renderHandIcon();
        }
      } catch (err) {
        console.error(err);
      }
    },
    renderBorders() {
      //todo this is duplicate
      //edged stick out 10% of the smallest (width or height)
      const offWidth =
        this.plotMetrics.width /
        10 /
        Math.max(this.plotMetrics.width / this.plotMetrics.height, 1);
      const offHeight =
        this.plotMetrics.height /
        10 /
        Math.max(this.plotMetrics.height / this.plotMetrics.width, 1);
      const borderPath = [
        "M",
        this.plotMetrics.x,
        this.plotMetrics.y - offHeight,
        "L",
        this.plotMetrics.x,
        this.plotMetrics.endY + offHeight,
        "M",
        this.plotMetrics.x - offWidth,
        this.plotMetrics.endY,
        "L",
        this.plotMetrics.endX,
        this.plotMetrics.endY,
      ];
      this.renderLine(borderPath, { color: this.colors.disabledGray });
    },
    renderLightGrayBackground() {
      //render light gray 'background'
      const backgroundX = this.width * 0.04;
      const backgroundY = this.height * 0.25;
      const backgroundWith = this.width * 0.92;
      const backgroundHeight = this.height * 0.65;

      this.renderRectangle(
        backgroundX,
        backgroundY,
        backgroundWith,
        backgroundHeight,
        {
          color: this.colors.lightGray,
          z: -1,
        }
      );
    },
    renderCurrentTimeLineAndText() {
      const currentTime = this.currentTime;
      const absoluteCurrentTimeX = this.translateX(currentTime);

      const currentTimeX = this.minMax(
        absoluteCurrentTimeX,
        this.plotMetrics.x,
        this.plotMetrics.endX
      );

      //line sticks out 20%
      const currentTimeY = this.plotMetrics.y - this.plotMetrics.height * 0.2;
      const currentTimeLine = [
        "M",
        currentTimeX,
        currentTimeY,
        "L",
        currentTimeX,
        this.plotMetrics.endY,
      ];
      this.renderLine(currentTimeLine, { color: this.colors.darkGreen });

      //drawing the text (actual and target)
      const currentTimeText = dateFormat("%H:%M", currentTime);

      this.renderTextCentered(
        currentTimeText,
        currentTimeX,
        currentTimeY - this.charWidth * 2
      );
    },
    renderTimeBlocksAndDuplicates() {
      const numberOfClocks = this.count;

      const timeBlockHeight = this.plotMetrics.height;
      const timeBlockY = this.plotMetrics.y;

      const renderTimeBlock = (x, y, width, height, color) => {
        this.renderRectangle(x, y, width, height, {
          color: color,
          z: 0,
        });
      };

      const renderTime = (timeStart, timeEnd, blockColor = "black") => {
        if (isUndef(timeStart) || isUndef(timeEnd) || timeStart === timeEnd) {
          return;
        }

        if (timeEnd > timeStart) {
          const timeBlockX = this.translateX(timeStart);
          const timeBlockWidth =
            (timeEnd - timeStart) * this.plotMetrics.oneAmountInPixelsX;

          renderTimeBlock(
            timeBlockX,
            timeBlockY,
            timeBlockWidth,
            timeBlockHeight,
            blockColor
          );
        } else {
          renderTime(timeStart, day, blockColor);
          renderTime(0, timeEnd, blockColor);
        }
      };

      //render all time blocks
      for (let i = 0; i < numberOfClocks; i++) {
        const timeStart = this.startTime[i];
        const timeStop = this.stopTime[i];
        renderTime(timeStart, timeStop);
      }

      //render all duplicate time blocks
      for (let i = 0; i < numberOfClocks; i++) {
        for (let j = i + 1; j < numberOfClocks; j++) {
          let time1 = {
            start: this.startTime[i],
            end: this.stopTime[i],
          };

          let time2 = {
            start: this.startTime[j],
            end: this.stopTime[j],
          };

          //zie opdelen en dubbel methods
          let t1a = {},
            t1b = {},
            t2a = {},
            t2b = {};

          this.opdelen(time1, t1a, t1b);
          this.opdelen(time2, t2a, t2b);

          let duplicate = {};

          if (this.dubbel(t1a, t2a, duplicate)) {
            renderTime(duplicate.start, duplicate.end, this.colors.error);
          }
          if (this.dubbel(t1b, t2a, duplicate)) {
            renderTime(duplicate.start, duplicate.end, this.colors.error);
          }
          if (this.dubbel(t1a, t2b, duplicate)) {
            renderTime(duplicate.start, duplicate.end, this.colors.error);
          }
          if (this.dubbel(t1b, t2b, duplicate)) {
            renderTime(duplicate.start, duplicate.end, this.colors.error);
          }
        }
      }
    },
    renderHandIcon() {
      const iconWidth = this.plotMetrics.width * 1.12;
      const iconHeight = this.plotMetrics.height * 2.5;
      const iconX = this.plotMetrics.x * 0.52;
      const iconY = this.plotMetrics.y * 0.8;

      this.renderIconS3(clockManuallyIcon, iconX, iconY, iconWidth, iconHeight);
    },
  },
  components: {
    GaugeComponent,
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.clockRenderCallback);
  },
};
</script>

<style scoped>
#container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

#gauge {
  position: absolute;
  top: 9%;
  z-index: 1;
  left: 37.5%;
  /*same, 37.5% is center*/
  /*right: 37.5%;*/
}
</style>
