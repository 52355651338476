import { render, staticRenderFns } from "./SetupLayoutDesktop.vue?vue&type=template&id=00729888&scoped=true&"
import script from "./SetupLayoutDesktop.vue?vue&type=script&lang=js&"
export * from "./SetupLayoutDesktop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00729888",
  null
  
)

export default component.exports