import * as types from "./mutation-types";
import { adminService } from "@api/admin-service";

async function init({ commit }) {
  commit(types.CLEAR_CUSTOMERS);
  const customers = await adminService.fetchCustomersMechanic();
  commit(types.SET_CUSTOMERS, customers);
}

export default {
  init,
};
