export const ADD_INDEXES = "ADD_INDEXES";
export const ADD_REFERENCES = "ADD_REFERENCES";
export const UPDATE_VALUES = "UPDATE_VALUES";
export const UPDATE_VALUES_NEW = "UPDATE_VALUES_NEW";
export const INIT_DEVICE = "INIT_DEVICE";
export const SET_DEVICE_DATA = "SET_DEVICE_DATA";
export const SET_TREE = "SET_TREE";
export const SET_LAST_SEEN = "SET_LAST_SEEN";
export const TREE_REGISTERED_FOR_DEVICE = "TREE_REGISTERED_FOR_DEVICE";
export const MODIFY_REFRESH_VARS = "MODIFY_REFRESH_VARS";
export const SET_LC_TEST_RESULTS = "SET_LC_TEST_RESULTS";
export const SET_LC_DEVICE_DATA = "SET_LC_DEVICE_DATA";
export const SET_HISTORY_VARS = "SET_HISTORY_VARS";
export const ADD_HISTORY_VAR = "ADD_HISTORY_VAR";
export const CLEAR_HISTORY_VARS = "CLEAR_HISTORY_VARS";
export const SET_VISIBLE_NODE_KEYS = "SET_VISIBLE_NODE_KEYS";
