<template>
  <v-card outlined>
    <v-card-title class="headline, justify-center">
      <slot name="title" />
    </v-card-title>

    <slot name="subtitle" />

    <v-data-table
      :headers="headers"
      :items="devices"
      :loading="scanning"
      :loading-text="$t('subscription.scanning')"
      :mobile-breakpoint="0"
      hide-default-footer
      item-key="id"
      dense
      :items-per-page="-1"
      :sort-by="['available', 'name']"
      :sort-desc="[true, false]"
      group-by="gatewayId"
      :item-class="itemRowBackground"
    >
      <template #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" x-small icon :ref="group">
            <v-icon v-if="!isOpen">mdi-plus</v-icon>
            <v-icon v-else>mdi-minus</v-icon>
          </v-btn>
          <span class="mx-2 font-weight-bold">
            {{ $t("common.gateway") }}: {{ getGatewayName(group) }}
          </span>
        </td>
      </template>

      <template #item.image="{ item }">
        <v-img
          :height="imageHeight"
          :src="getImageUrl(item)"
          :width="imageHeight"
        >
          <template #placeholder>
            <v-skeleton-loader type="image" />
          </template>
        </v-img>
      </template>

      <template #item.hardware="{ item }">
        {{ hardwareName(item) }}
      </template>

      <template #item.isLicenced="{ item }">
        <v-icon color="gray" v-if="!item.available">mdi-minus-circle</v-icon>
        <v-icon color="primary" v-else-if="item.isLicenced"
          >mdi-check-circle</v-icon
        >
        <v-icon color="error" v-else>mdi-alert-decagram-outline</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionDeviceScan",
  props: {
    devices: {
      type: Array,
      required: true,
    },
    scanning: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDifference: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("common.image"),
          sortable: false,
          value: "image",
        },
        {
          text: this.$t("common.name"),
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("common.hardware"),
          sortable: false,
          value: "hardware",
        },
        this.hideDifference
          ? { sortable: false }
          : {
              text: this.$t("common.isLicenced"),
              sortable: false,
              value: "isLicenced",
            },
      ],
    };
  },
  computed: {
    ...mapGetters(["getGatewayName"]),
    ...mapGetters("subscription", [
      "getImageForHardware",
      "getProductByHardware",
    ]),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40px";
        default:
          return "60px";
      }
    },
  },
  methods: {
    itemRowBackground(item) {
      return item.available ? "" : "grey lighten-2";
    },
    getImageUrl({ hardware }) {
      return this.getImageForHardware(hardware);
    },
    hardwareName({ hardware }) {
      return (
        this.getProductByHardware(hardware)?.name ||
        this.$t("common.notAvailable")
      );
    },
  },
};
</script>

<style scoped></style>
