<template>
  <div :class="{ [levelClasses[this.level]]: true, admin: this.isAdmin }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "NavDrawerContainer.vue",
  data() {
    return {
      levelClasses: [
        "level0",
        "level1",
        "level2",
        "level3",
        "level4",
        "level5",
      ],
    };
  },
  props: {
    level: {
      type: Number,
      required: false,
      default: 0,
      validator: (value) => value >= 0 && value <= 5,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.level0 {
  background-color: var(--v-black-base);
}

.level1 {
  background-color: var(--v-level1-base);
}

.level2 {
  background-color: var(--v-level2-base);
}

.level3 {
  background-color: var(--v-level3-base);
}

.level4 {
  background-color: var(--v-level4-base);
}

.level5 {
  background-color: var(--v-level5-base);
}

.admin {
  padding: 5px 0 5px 0;
}
</style>
