import { _createHardwareVersionName } from "./bll";
import { isUndef, isEmptyObjectOrArray } from "@utils/helpers";
import { isValidDeviceObj } from "@utils/validators";
import { resolveEnumValue } from "@modules/lccpu/model/enum-components";
import { _translate } from "@modules/rtcpu/render/screen-bll";
import { uniq } from "lodash";

const getRefName = (state) => (device, index) => {
  const indexes = state.deviceData[_createHardwareVersionName(device)].indexes;
  return indexes.hasOwnProperty(index) ? indexes[index] : null;
};

const getRef = (state) => (device, name) => {
  if (!name) return null;
  const refs = state.deviceData[_createHardwareVersionName(device)].references;
  return refs.hasOwnProperty(name) ? refs[name] : null;
};

const getRefs = (state) => (device) => {
  return state.deviceData[_createHardwareVersionName(device)].references;
};

const getValueByIndex = (state, getters) => (device, index) => {
  return getters.getValue(device, getters.getRefName(device, index));
};

const getDeviceMeta = (state) => (deviceId) => {
  if (isValidDeviceObj(deviceId)) return deviceId;
  if (typeof deviceId !== "string" || !state.devices.hasOwnProperty(deviceId)) {
    return null;
  }
  return state.devices[deviceId].meta;
};

const getConstants = (state) => (device) => {
  const name = _createHardwareVersionName(device);
  return state.deviceData.versions.includes(name)
    ? state.deviceData[name].constants
    : {};
};

const getDispositionTitle = (state, getters) => (device, disposition) => {
  //is var
  if (disposition.Text) {
    const text = disposition.Text;
    if (text.charAt(0) === "$") {
      return getters.getValue(device, text.slice(1));
    }
  }
  // this is a weird construction
  // case disposition.Name === Inlaatklep_r,1
  // getTranslation with Inlaatklep_r becomes Air inlet flap %r
  // _translate searches for % and replaces next char with disposition.Name split by comma [1], so 1
  // also this kind of overlaps with cache actions translate
  const label = disposition.Name.split(",")[0];
  const translated = getters.getTranslation({
    dev: device,
    label,
  });
  return (
    _translate(disposition.Name, {
      [label]: translated,
    }) || disposition.Name
  );
};

const getTree = (state) => (deviceId) => state.devices[deviceId].tree;

const getTranslation =
  (state, getters, rootState, rootGetters) =>
  ({ dev, label }) => {
    return state.deviceData[
      _createHardwareVersionName(dev || rootGetters.getSelectedDevice)
    ].namedText[label];
  };

const getTranslationById =
  (state, getters, rootState, rootGetters) =>
  ({ device, id }) =>
    state.deviceData[
      _createHardwareVersionName(device || rootGetters.getSelectedDevice)
    ].namedTextIndexed[id];

const getControlText = (state, getters) => (device, index) =>
  getters.getGenericText({ device, index, what: "controlText" });

const getAlarmText = (state, getters) => (device, index) =>
  getters.getGenericText({ device, index, what: "alarmText" });

const getTypedText = (state, getters) => (device, index) =>
  getters.getGenericText({ device, index, what: "typedText" });

const getGenericText =
  (state) =>
  ({ device, index, what }) => {
    const name = _createHardwareVersionName(device);
    if (!state.deviceData[name][what].hasOwnProperty(index)) {
      index = -1;
    }
    return { id: index, text: state.deviceData[name][what][index] };
  };

const getValue = (state) => (device, varName) =>
  state.devices[device.id].values[varName];

const getTestResult = (state) => (device, testName) => {
  const tests = state.devices[device.id].testResults;
  if (
    isUndef(tests) ||
    !tests.hasOwnProperty(testName) ||
    isUndef(tests[testName])
  ) {
    return false;
  } else {
    return tests[testName];
  }
};

const getValuesFor = (state, getters) => (device, names) => {
  // only checking names,
  // dev should be checked with component validators and initial store setup
  if (!Array.isArray(names) || isEmptyObjectOrArray(names)) return {};
  names = uniq(names);
  return names.reduce((acc, name) => {
    const value = getters.getValue(device, name);
    if (isUndef(value)) return acc;
    return {
      ...acc,
      [name]: value,
    };
  }, {});
};

const getHistoryVars = (state, getters, rootState, rootGetters) => {
  const selectedDevice = rootGetters.getSelectedDevice;
  return !isEmptyObjectOrArray(selectedDevice)
    ? state.devices[selectedDevice.id].historyVars
    : [];
};

const getRefreshVars = (state) => (deviceId) => {
  if (!state.devices.hasOwnProperty(deviceId)) return [];
  return Object.keys(state.devices[deviceId].refreshVars);
};

const lcGetValue = (state, getters) => (device, lcVarName) => {
  //static value
  if (isStaticValue(lcVarName)) {
    return lcVarName;
  }

  if (isVarListValue(lcVarName)) {
    const varName = lcVarName.slice(1);
    return getters.getValue(device, varName);
  }
  if (isTestValue(lcVarName)) {
    const testName = resolveTestName(lcVarName);
    const testResult = getters.getTestResult(device, testName);
    const isInVert = isInvertTest(lcVarName);
    return testResult ^ isInVert;
  }
  if (isEnumValue(lcVarName)) {
    return resolveEnumValue(lcVarName);
  }

  return lcVarName;
};

const isStaticValue = (value) => typeof value !== "string";

const isVarListValue = (value) => checkVarPrefix(value, "$");

const isTestValue = (value) => checkVarPrefix(value, "#");

const isEnumValue = (value) => checkVarPrefix(value, "&");

const isInvertTest = (value) => checkVarSuffix(value, "-");

const checkVarPrefix = (value, prefix) => value.charAt(0) === prefix;

const checkVarSuffix = (value, suffix) =>
  value.charAt(value.length - 1) === suffix;

const resolveTestName = (value) =>
  isInvertTest(value) ? value.slice(1, -1) : value.slice(1);

const getVisibleNodeKeys = (state) => (deviceId) =>
  state.devices[deviceId].visibleNodeKeys;

export default {
  getValueByIndex,
  getRefName,
  getRef,
  getRefs,
  getValue,
  getDispositionTitle,
  getHistoryVars,
  getValuesFor,
  getDeviceMeta,
  getConstants,
  getTree,
  getTranslation,
  getTranslationById,
  getControlText,
  getAlarmText,
  getGenericText,
  getTypedText,
  getRefreshVars,
  getTestResult,
  lcGetValue,
  getVisibleNodeKeys,
};
