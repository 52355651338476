import * as types from "./mutation-types";

export default {
  [types.CLEAR_MECHANICS]: (state) => {
    state.mechanics = [];
  },
  [types.SET_MECHANICS]: (state, mechanics) => {
    state.mechanics = mechanics;
  },
  [types.SET_MECHANIC]: (state, newMechanic) => {
    const existingMechanic = state.mechanics.find(
      (mechanic) => mechanic.id === newMechanic.id
    );
    if (existingMechanic) {
      Object.assign(existingMechanic, newMechanic);
    } else {
      state.mechanics.push(newMechanic);
    }
  },
  [types.REMOVE_MECHANIC]: (state, mechanic) => {
    const i = state.mechanics.map((i) => i.id).indexOf(mechanic.id);
    state.mechanics.splice(i, 1);
  },
};
