const getLanguage = (state) => state.language;
const getHome = (state) => state.home;

const getEditModeConfig = (state) => ({
  sendTimeout: state.editMode.sendTimeout,
});

const getStreamerConfig = (state) => ({
  enabled: state.streamer.enabled,
  minDelay: state.streamer.minDelay,
  maxDelay: state.streamer.maxDelay,
  initialDelay: state.streamer.initialDelay,
  requestTimeout: state.streamer.requestTimeout,
});

const getSettingByType = (state) => (group, type) =>
  state[group][type].hasOwnProperty("ENABLED")
    ? state[group][type].ENABLED && state[group][type]
    : state[group][type];

const getCachingModeByType = (state, getters) => (type) =>
  !!getters.getSettingByType("cachingMode", type);

const getCacheEnabled = (state) => state.cachingMode.ENABLED;

const getCollectorConfig = (state) => state.collector;

const getFeatureToggles = (state) => state.featureToggles;

export default {
  getLanguage,
  getHome,
  getStreamerConfig,
  getEditModeConfig,
  getSettingByType,
  getCollectorConfig,
  getCachingModeByType,
  getCacheEnabled,
  getFeatureToggles,
};
