import * as types from "./mutation-types";
import { default as i18n } from "../../../i18n";
import { default as vuetify } from "../../../../src/plugins/vuetify";
import { I18n } from "aws-amplify";

export default {
  [types.SET_LANGUAGE]: (state, language) => {
    language = language ? language : state.language;
    state.language = language;
    i18n.locale = language;
    vuetify.framework.lang.current = language;
    I18n.setLanguage(language);
  },
  [types.SET_HOME]: (state, home) => {
    state.home = home;
  },
  [types.SET_TOOLTIP_CHART]: (state, enabled) =>
    (state.featureToggles.tooltipChart = enabled),
  [types.SET_TOOLTIP_EVENTS]: (state, enabled) =>
    (state.featureToggles.tooltipEvents = enabled),
  [types.SET_TOOLTIP_NUMBER_OF_EVENTS]: (state, numberOfEvents) =>
    (state.featureToggles.tooltipNumberOfEvents = numberOfEvents),
};
