import { Core } from "@stienen/widgets/Core";

function drawNeedle(canvas, x, y, size, offset) {
  const line = canvas.path(["M", x, y + size * 0.15, "L", x, y - size * 0.75]).attr({
    stroke: "red",
    "stroke-width": size * 0.05,
    transform: ["R", offset, x, y]
  });
  canvas.circle(x, y, size * 0.1).attr({ fill: "gray" });
  return line;
}

function drawMinor(canvas, x, y, size, min, max, count) {
  const step = (max - min) / count;
  for (; min <= max; min += step) {
    canvas.path(["M", x, y - size * 0.8, "L", x, y - size * 0.7]).rotate(min, x, y);
  }
}

function drawMajor(canvas, x, y, size, min, max, count) {
  const step = (max - min) / count;
  for (; min <= max; min += step) {
    canvas.path(["M", x, y - size * 0.8, "L", x, y - size * 0.65]).attr({
      "stroke-width": 2
    }).rotate(min, x, y);
  }
}

function drawText(canvas, x, y, size, min, max, count, text) {
  let step = (max - min) / count,
    index = 0, len = text.length;
  for (; min <= max && index < len; min += step) {
    canvas.text(x, y - size * 0.85, text[index]).rotate(min, x, y);
    ++index;
  }
}

function gauge(canvas, x, y, size, min, max, value, ticks, text, values) {
  let count = Math.max(1, text.length - 1), needle;
  drawMinor(canvas, x, y, size, min, max, count * ticks);
  drawMajor(canvas, x, y, size, min, max, count);
  drawText(canvas, x, y, size, min, max, count, text);
  needle = drawNeedle(canvas, x, y, size, min);

  const perc = Core.setAttr.bind({
    Attr: "transform",
    Target: needle,
    Offset: -text[0],
    Scale: (max - min) / (+text[count] - text[0]),
    Fix: min,
    Extra: ["R", null, x, y]
  });
  console.log(values[0]);
  values[0].registerCallback(perc);
}

function gauge90(target, attr, loc, values) {
  const canvas = Core.createCanvas(target, attr, 50, 50),
    width = canvas.width,
    height = canvas.height,
    text = loc.Extra.split(","),
    value = values[text.shift()],
    ticks = +text.shift();
  gauge(canvas, width * 0.1, height * 0.9, Math.min(width * 0.9, height * 0.9), 0, 90, value, ticks, text, values);
}

function gauge180(target, attr, loc, values) {
  const canvas = Core.createCanvas(target, attr, 100, 50),
    width = canvas.width,
    height = canvas.height,
    text = loc.Extra.split(","),
    value = values[text.shift()],
    ticks = +text.shift();
  gauge(canvas, width / 2, height * 0.9, Math.min(width / 2, height * 0.9), -90, 90, value, ticks, text, values);
}

export function gauge270(target, attr, loc, values) {
  const canvas = Core.createCanvas(target, attr, 150, 150),
    width = canvas.width,
    height = canvas.height,
    text = loc.Extra.split(","),
    value = values[text.shift()],
    ticks = +text.shift();
  gauge(canvas, width / 2, height / 2, Math.min(width, height) / 2, -270 / 2, 270 / 2, value, ticks, text, values);
}

