import { apiClient } from "@api/base-client";

const endPoint = "/lc-screens";
const proxyClient = apiClient(endPoint, (response) => response.data);

const getLcDeviceDefinition = async ({ hardware, version }) => {
  return await proxyClient.get(`/${hardware}/${version}/screen.json`);
};

export { getLcDeviceDefinition };
