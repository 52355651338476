<template>
  <v-dialog v-model="dialog" max-width="350" persistent @click.stop>
    <base-card class="fill-height">
      <v-card-title>
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <slot :item="item" name="subTitle" />
      </v-card-text>

      <v-card-actions>
        <base-button class="px-6" outlined text @click="deny">
          {{ $t("common.no") }}
        </base-button>
        <v-spacer />
        <base-button class="px-6" @click="accept">
          {{ $t("common.yes") }}
        </base-button>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseConfirmDialog",
  props: {
    item: {
      type: [String, Object],
      required: true,
    },
    acceptFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
    denyFunction: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  watch: {
    dialog() {
      this.$emit("input", this.dialog);
    },
  },
  methods: {
    accept() {
      this.acceptFunction(this.item);
      this.dialog = false;
    },
    deny() {
      this.denyFunction(this.item);
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
