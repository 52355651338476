<template>
  <v-row no-gutters :class="{ 'fill-height': $vuetify.breakpoint.smAndDown }">
    <v-spacer />
    <v-col cols="12" lg="6">
      <v-overlay v-if="loading">
        <transition name="fade-in" appear>
          <v-container>
            <v-row>
              <v-spacer />
              <v-col>
                <base-fingerprint-spinner />
              </v-col>
              <v-spacer />
            </v-row>
            <v-row class="text-center">
              <v-col>
                <h3 style="color: black">
                  Logging in as {{ selectedCustomer.email }}...
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </transition>
      </v-overlay>
      <base-card v-else title="Select a customer">
        <v-divider />
        <v-data-table
          class="pa-4 row-pointer"
          dense
          :headers="headers"
          :items="customers"
          :search="search"
          :height="14 * 32"
          fixed-header
          hide-default-footer
          :items-per-page="-1"
          single-select
          item-key="id"
          v-model="selected"
          checkbox-color="primary"
          :mobile-breakpoint="0"
          @click:row="select"
          @dblclick:row="next"
          :item-class="itemRowBackground"
          sort-by="email"
        >
          <template #top>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              clearable
            />
          </template>

          <template #item.allowServiceAccess="{ item }">
            <v-icon v-if="!item.allowServiceAccess"> mdi-lock</v-icon>
          </template>
        </v-data-table>

        <button-holder>
          <template #btn1>
            <base-button text outlined @click="$helpers.pushRoute('/logout')">
              {{ $t("common.logout") }}
            </base-button>
          </template>
          <template #btn2>
            <base-button :disabled="selected.length !== 1" @click="next">
              {{ $t("common.next") }}
            </base-button>
          </template>
        </button-holder>
      </base-card>
    </v-col>
    <v-spacer />
    <v-footer app fixed height="48" v-if="isPortrait && !loading">
      <portal-target name="btn1" />
      <v-spacer />
      <portal-target name="btn2" />
    </v-footer>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@router/router";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  components: { ButtonHolder },
  name: "BaseCustomerOverview",
  props: {
    customers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      selected: [],
      headers: [
        { text: "Email", value: "email" },
        { text: "Gateways", value: "gatewayNames" },
        { text: "Allowed", value: "allowServiceAccess" },
      ],
    };
  },
  computed: {
    ...mapGetters("layout", ["isPortrait"]),
    selectedCustomer() {
      return this.selected[0] || {};
    },
  },
  methods: {
    select(customer) {
      if (customer.allowServiceAccess) {
        this.selected = [customer];
      }
    },
    itemRowBackground(customer) {
      if (this.selected.includes(customer)) {
        return "teal lighten-4";
      }
      return customer.allowServiceAccess ? "" : "grey lighten-2";
    },
    async next() {
      if (this.selectedCustomer && this.selectedCustomer.allowServiceAccess) {
        this.loading = true;
        await this.$store.dispatch(
          "auth/setImpersonatedCustomer",
          this.selectedCustomer
        );
        await this.$store.dispatch("subscription/init");
        await this.$store.dispatch("init");
        await router.replace({ name: "alarm" });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  transition: all 1s ease;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
