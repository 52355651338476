export const LcComponents = Object.freeze({
  NAVIGATE: "NAVIGATE",
  VARIABLE: "VARIABLE",
  ICON: "ICON",
  TEXT: "TEXT",

  WDG_ALARM_CODE: "WDG_ALARM_CODE",
  WDG_ALARM_PERCENTAGE: "WDG_ALARM_PERCENTAGE",
  WDG_ALARM_TEMPERATURE: "WDG_ALARM_TEMPERATURE",

  WDG_CLOCK_ONOFF: "WDG_CLOCK_ONOFF",

  WDG_COOLING_ANA: "WDG_COOLING_ANA",
  WDG_COOLING_ONOFF: "WDG_COOLING_ONOFF",
  WDG_COOLING_TP: "WDG_COOLING_TP",

  WDG_CURVE: "WDG_CURVE",
  WDG_CURVE_PERC: "WDG_CURVE_PERC",
  WDG_CURVE_TEMPERATURE: "WDG_CURVE_TEMPERATURE",
  WDG_CURVE_VENTILATION: "WDG_CURVE_VENTILATION",

  WDG_HEATING_ANA: "WDG_HEATING_ANA",
  WDG_HEATING_ONOFF: "WDG_HEATING_ONOFF",

  WDG_METER: "WDG_METER",

  WDG_STEP_VENTILATION: "WDG_STEP_VENTILATION",
  WDG_VENTILATION: "WDG_VENTILATION",
});

//wdgCurveUnit also makes use of these
export const WdgUnit = Object.freeze({
  NO_DIMENSION: "NO_DIMENSION",
  CLASS: "CLASS",
  PERCENT: "PERCENT",
  DEGREE: "DEGREE",
  KILOGRAM: "KILOGRAM",
  LITER: "LITER",
  GRAM: "GRAM",
  MILLIMETER: "MILLIMETER",
  GRAM_PER_ANIMAL: "GRAM_PER_ANIMAL",
  MILLILITER_PER_ANIMAL: "MILLILITER_PER_ANIMAL",
  VENTILATION_PER_ANIMAL: "VENTILATION_PER_ANIMAL",
  VENTILATION: "VENTILATION",
  M3_PER_ANIMAL: "M3_PER_ANIMAL",
});

// clip 🐓 🐥 🍗 🐔 ³ °

export const WdgUnitValues = Object.freeze({
  [WdgUnit.NO_DIMENSION]: "",
  [WdgUnit.CLASS]: "%",
  [WdgUnit.PERCENT]: "%",
  [WdgUnit.DEGREE]: "°C",
  [WdgUnit.KILOGRAM]: "Kg",
  [WdgUnit.LITER]: "L",
  [WdgUnit.GRAM]: "g",
  [WdgUnit.MILLIMETER]: "mm",
  [WdgUnit.GRAM_PER_ANIMAL]: "g/🐔",
  [WdgUnit.MILLILITER_PER_ANIMAL]: "ml/🐔",
  [WdgUnit.VENTILATION_PER_ANIMAL]: "m³/🐔",
  [WdgUnit.VENTILATION]: "%",
  [WdgUnit.M3_PER_ANIMAL]: "m³/🐔",
});

export function resolveEnumValue(item) {
  if (!item.startsWith("&")) {
    throw Error(`Structure not supported (${item})`);
  }
  const parts = item.split(".");
  if (parts.length < 2) {
    throw Error(
      `Format not correct for arg: ${item} (i.e: EnumType.EnumValue)`
    );
  }
  return parts[1];
}
