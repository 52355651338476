import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode,
} from "vee-validate";

import { required, email } from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

setInteractionMode("eager");

/*
  _field_	The field name.
  _value_	The field value that was validated.
  _rule_	The rule name that triggered this message.

  for other info see the docs at:
  https://logaretm.github.io/vee-validate/guide/basics.html
 */
extend("required", {
  ...required,
  message: "{_field_} can not be empty", //todo: capitalize
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
