<template>
  <div class="nav-drawer-footer disable-select">
    <NavDrawerFooterButton
      :icon="'mdi-cog-outline'"
      :route="'/settings'"
      :text="$t('navDrawer.settings')"
      class="pos-1"
    />

    <div class="divider" />

    <NavDrawerFooterButton
      :icon="'mdi-logout'"
      :route="'/logout'"
      :text="$t('navDrawer.logout')"
      class="pos-2"
    />
  </div>
</template>

<script>
import NavDrawerFooterButton from "@components/navdrawer/NavDrawerFooterButton.vue";

export default {
  name: "NavDrawerFooter",
  components: {
    NavDrawerFooterButton,
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer-footer {
  display: grid;
  width: 301px;
  height: 85px;
  grid-template-columns: 148px 5px 148px;
  grid-template-rows: 85px;
  border-top: 1px solid var(--v-grey70-base);

  .pos-1 {
    grid-column: 1;
    grid-row: 1;
  }

  .divider {
    grid-column: 2;
    grid-row: 1;
    width: 1px;
    height: 55px;
    background-color: var(--v-grey40-base);
    margin: 15px 2px 15px 2px;
  }

  .pos-2 {
    grid-column: 3;
    grid-row: 1;
  }
}
</style>
