<template>
  <div class="nav-drawer-combobox">
    <div class="button" @click="toggleMenu">
      <span :class="icon" class="icon mdi" />
      <span class="text">
        <a>{{ text }}</a>
        &nbsp;&nbsp;
        <span v-if="warning" class="icon-warning mdi mdi-alert" />
        <span
          v-if="disconnected"
          class="icon-connected mdi mdi-cloud-off-outline"
        />
      </span>
      <div class="arrow">
        <v-icon :class="[showMenu ? 'up' : 'down', 'arrow']">
          mdi-arrow-down
        </v-icon>
      </div>
    </div>
    <div v-if="showMenu" class="content">
      <NavDrawerStaticNode
        v-for="(item, id) in items"
        :key="id"
        :disconnected="item.disconnected"
        :locked="item.locked"
        :notification="item.notification"
        :text="item.name || item.displayName"
        :warning="item.warning"
        @clicked="updateSelectedItem(item)"
      />
    </div>
  </div>
</template>

<script>
import NavDrawerStaticNode from "@components/navdrawer/NavDrawerStaticNode";

export default {
  components: {
    NavDrawerStaticNode,
  },
  props: {
    icon: {
      default: "",
      required: false,
      type: String,
    },
    text: {
      default: "",
      required: false,
      type: String,
    },
    items: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    closeOnSelect: {
      required: false,
      type: Boolean,
      default: true,
    },
    closeOnOutsideClick: {
      type: Boolean,
      required: false,
      default: true,
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    disconnected: {
      type: Boolean,
      required: false,
      default: false,
    },
    notification: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    updateSelectedItem(item) {
      this.showMenu = this.closeOnSelect ? false : this.showMenu;
      this.$emit("changeSelection", item);
    },
    outsideClickHandler(event) {
      const { target } = event;
      const { $el } = this;

      if (!$el.contains(target)) {
        this.showMenu = false;
        this.$emit("toggled", false);
      }
    },
  },
  mounted() {
    if (this.closeOnOutsideClick) {
      document.addEventListener("click", this.outsideClickHandler);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.outsideClickHandler);
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer-combobox {
  .button {
    display: grid;
    width: 301px;
    grid-template-columns: 20px 35px 206px 40px;
    height: 45px;
    color: var(--v-grey30-base);

    &:hover {
      cursor: pointer;
    }

    span {
      a {
        color: var(--v-grey30-base);
      }

      a:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--v-white-base);
      }
    }

    .icon {
      grid-column: 2;
      grid-row: 1;
      align-items: center;
      font-size: 25px;
    }

    .text {
      grid-column: 3;
      grid-row: 1;
      display: flex;
      align-items: center;
      color: var(--v-grey30-base);
      font-family: var(--v-font-base);
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
    }

    .icon-warning {
      color: var(--v-warning-base);
      margin-left: 5px;
    }

    .icon-connected {
      color: var(--v-error-base);
      margin-left: 5px;
    }

    .arrow {
      grid-column: 4;
      grid-row: 1;
      display: flex;
      align-items: center;
      width: 30px;
      color: var(--v-grey30-base);

      .down {
        transition-duration: 0.4s;
        transition-property: transform;
      }

      .up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transition: 0.4s;
      }
    }
  }

  .content {
  }
}
</style>
