import RequestManager from "@plugins/requestManager";
const GETVALUES = 3;
const SETVALUESRSP = 10;
const TIMEDOUT = 12;
const UPDATEDDEVICEINFO = 23;
const CONNECTION_STATUS = 34;

const processMessage = function (manager, msg) {
  try {
    const gatewayIndex = this.state.gateways.findIndex(
      (gateway) => gateway.id === msg.Gid
    );
    if (gatewayIndex === -1) return; // ignore msg, user has no access to this gateway

    // gateway messages
    // dev is null on type: CONNECTION_STATUS
    // order is important here because of the return statement when dev is null

    if (msg.Type === CONNECTION_STATUS) {
      this.dispatch(
        "updateGatewayStatus",
        { gatewayIndex, status: msg.Data, stamp: msg.Stamp },
        { root: true }
      );
      return;
    }

    // device specific messages
    const getDeviceMeta = this.getters["deviceCache/getDeviceMeta"];
    const device = getDeviceMeta(msg.Did);

    // ignore message for unknown device
    if (!device) return;

    if (msg.Stamp) {
      // device Last seen
      this.dispatch("updateDeviceLastSeen", { device, msg });
      this.dispatch(
        "updateGatewayStatus",
        {
          gatewayIndex,
          status: "PC485Connected",
          stamp: msg.Stamp,
        },
        { root: true }
      );
    }

    if (msg.Data && msg.Type === GETVALUES) {
      manager.notify(device, msg);
      this.dispatch(
        "deviceCache/onDataReceived",
        { dev: device, data: msg.Data },
        { root: true }
      );
      return;
    }

    // Set data response, per send index the status is given
    if (msg.Type === SETVALUESRSP) {
      this.dispatch("edit/setDataResponseHandler", msg, { root: true });
      return;
    }

    if (msg.Type === TIMEDOUT) {
      this.dispatch(
        "onTimeoutMessageReceived",
        { device, msg },
        { root: true }
      );
      return;
    }

    if (msg.Type === UPDATEDDEVICEINFO) {
      this.dispatch("fetchAlarms", true, { root: true });
    }
  } catch (err) {
    console.error(err);
  }
};

export default function createMessengerPlugin(Messenger) {
  return (store) => {
    RequestManager.init(store);
    const streamer = processMessage.bind(store, RequestManager);
    Messenger.RegisterCallback(streamer);
  };
}
