export const ON_INPUT = "ON_INPUT";
export const ON_DISCARD_SINGLE = "ON_DISCARD_SINGLE";
export const CLEAR_ALL = "CLEAR_ALL";
export const CLEAR_TIMER = "CLEAR_TIMER";
export const ON_FSM_TRANSITION = "ON_FSM_TRANSITION";
export const ON_FSM_REJECT = "ON_FSM_REJECT";
export const ON_FSM_TIMEOUT = "ON_FSM_TIMEOUT";
export const CONFIRM_ACTIVE = "CONFIRM_ACTIVE";
export const CONFIRM = "CONFIRM";
export const SET_EVENT_STATUS = "SET_EVENT_STATUS";
export const TOGGLE_SEND_TO_MULTIPLE_ACTIVE = "TOGGLE_SEND_TO_MULTIPLE_ACTIVE";
export const DUPLICATE_CHANGE_EVENTS = "DUPLICATE_CHANGE_EVENTS";
export const DROP_DEVICE_EVENTS = "DROP_DEVICE_EVENTS";
export const SET_EVENT_DEVICES = "SET_EVENT_DEVICES";
