export default {
  data() {
    return {
      loginFormFields: [
        {
          type: "email",
          label: this.$t("login.email"),
          placeholder: this.$t("login.enterEmail"),
          inputProps: {
            required: true,
            autocomplete: "username",
          },
        },
        {
          type: "password",
          label: this.$t("login.password"),
          placeholder: this.$t("login.enterPassword"),
          inputProps: {
            required: true,
            autocomplete: "new-password",
          },
        },
      ],
      signUpFormFields: [
        {
          type: "email",
          label: this.$t("login.email"),
          placeholder: this.$t("login.chooseEmail"),
          inputProps: {
            required: true,
            autocomplete: "username",
          },
        },
        {
          type: "password",
          label: this.$t("login.password"),
          placeholder: this.$t("login.choosePassword"),
          inputProps: {
            required: true,
            autocomplete: "new-password",
          },
        },
      ],
      resetPasswordFormFields: [
        {
          type: "email",
          label: this.$t("login.email"),
          placeholder: this.$t("login.enterEmail"),
          inputProps: {
            required: true,
            autocomplete: "username",
          },
        },
      ],
    };
  },
};
