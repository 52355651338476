<template>
  <LayoutDefault>
    <div :class="$vuetify.breakpoint.lgAndUp ? 'scroll-lg' : 'scroll'">
      <portal to="navBarEnd">
        <TheEditToolbar>
          <base-button
            @click="toggleLast24Hours"
            width="40px"
            height="40px"
            icon
            v-show="last24HourEnabled"
          >
            <v-icon>mdi-chart-line</v-icon>
          </base-button>
        </TheEditToolbar>
      </portal>
      <RtDeviceScreen :disposition="getCurrentDisposition" />
    </div>
  </LayoutDefault>
</template>

<script>
import TheEditToolbar from "@components/edit/TheEditToolbar";
import RtDeviceScreen from "@modules/rtcpu/render/RtDeviceScreen";
import { mapGetters } from "vuex";

export default {
  name: "RtRemotePage",
  page: {
    title: "Remote",
  },
  components: {
    RtDeviceScreen,
    TheEditToolbar,
  },
  computed: {
    ...mapGetters("rtRemote", [
      "getCurrentDisposition",
      "getLast24HourEnabled",
    ]),
    ...mapGetters("layout", ["isDesktop"]),
    ...mapGetters("settings", ["getFeatureToggles"]),
    ...mapGetters("deviceCache", ["getHistoryVars"]),
    last24HourEnabled() {
      return (
        this.getFeatureToggles.rtLast24Hour &&
        this.isDesktop &&
        this.getHistoryVars.length > 0 &&
        !this.getCurrentDisposition.isCustomScreen
      );
    },
  },
  methods: {
    toggleLast24Hours() {
      this.$store.dispatch(
        "rtRemote/setLast24Hours",
        !this.getLast24HourEnabled
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-lg {
  overflow-x: auto;
}

.scroll {
  overflow: scroll;
}
</style>
