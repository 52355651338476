import { _get, _getPositions } from "@state/modules/cache/bll";
import { resolveAfter, isDef } from "@utils/helpers";
import { notifyUserTranslation } from "@error/helpers";

let timers = [];
let values = {};
let initialDelayPending = null;
let config;
let cancelId;
let initialRequestId;
let store;
let getRefreshVars;
let active = true;
const maxAmountOfRetries = 3;

function init(s) {
  store = s;
  config = store.getters["settings/getStreamerConfig"];
  getRefreshVars = store.getters["deviceCache/getRefreshVars"];
  window.onblur = () => (active = false);
  window.onfocus = () => (active = true);
}

function watchDevice(device) {
  store.watch(
    (state) => state.deviceCache.devices[device.id].refreshVars,
    () => update(device),
    { immediate: true, deep: true }
  );
}

function update(device) {
  try {
    clear();
    if (!values.hasOwnProperty(device.id)) {
      values[device.id] = createVarsObj(device);
    }

    // init
    const refs = _get(store.state.deviceCache, device, "references");
    const varNames = getRefreshVars(device.id);
    const varsObj = values[device.id];
    const pos = _getPositions(refs, varNames);
    varsObj.pos = pos;
    varsObj.sendGetData = pos.length > 0;

    varsObj.refreshTimer();
    varsObj.resolve();

    varsObj.isUserNotified = false;

    kick();
  } catch (err) {
    console.error(err);
  }
}

function kick() {
  clearStartupTimer();
  initialRequestId = setTimeout(startup, config.initialDelay);
}

function startup() {
  try {
    clearStartupTimer();
    if (streamerIsConnected()) {
      for (let id of Object.keys(values)) {
        refresh(values[id]);
      }
    }
  } catch (err) {
    console.error(err);
    notifyUserTranslation("error.networkRequestFailed", "error");
  }
}

function notify(device) {
  if (values.hasOwnProperty(device.id)) {
    const varsObj = values[device.id];
    if (isDef(varsObj)) {
      if (isDef(varsObj.resolve)) {
        varsObj.resolve();
      }
      varsObj.requestsTimedOut = 0;

      setRoundTripMs(varsObj);
      varsObj.delay = calculateDelay(varsObj);
    }
  }
}

function notifyTimeout(device) {
  if (values.hasOwnProperty(device.id)) {
    const varsObj = values[device.id];
    if (isDef(varsObj)) {
      if (isDef(varsObj.resolve)) {
        varsObj.resolve();
      }
      varsObj.requestsTimedOut++;

      setRoundTripMs(varsObj);
      varsObj.delay = calculateDelay(varsObj);
    }
  }
}

function refresh(varsObj) {
  if (config.enabled && streamerIsConnected()) {
    varsObj.previousRequest.then((request) => {
      if (isDef(request) && request === "TIMED_OUT") {
        varsObj.requestsTimedOut += 1;
      }
      if (store.getters.checkConnection(varsObj.device.gatewayId) && active) {
        if (varsObj.requestsTimedOut < maxAmountOfRetries) {
          triggerRequest(varsObj);
        } else {
          notifyUserTranslation("error.timeoutDevice", undefined, {
            deviceName: varsObj.device.name,
          });
        }
      } else {
        // eslint-disable-next-line no-undef
        if (__DEV__ && !varsObj.isUserNotified && active) {
          varsObj.isUserNotified = true;
          const gateway = store.getters.getGatewayById(
            varsObj.device.gatewayId
          );
          notifyUserTranslation("error.gatewayNotConnected", undefined, {
            gatewayName: gateway?.name,
            deviceName: varsObj.device.name,
          });
        }
      }
      if (varsObj.sendGetData) {
        setTimer({
          device: varsObj.device,
          id: setTimeout(refresh, varsObj.delay, varsObj),
        });
      }
    });
  }
}

function streamerIsConnected() {
  if (store.state.streamerConnected) {
    return true;
  }
  kick();
  return false;
}

function triggerRequest(varsObj) {
  if (!initialDelayPending) {
    varsObj.isUserNotified = false;
    if (varsObj.sendGetData) {
      fetchFromDevice(varsObj.device, varsObj.pos);
    }

    varsObj.start = new Date().getTime();
    varsObj.refreshTimer(varsObj.delay);
  }
}

function fetchFromDevice(device, pos) {
  store.dispatch(
    "deviceCache/fetchFromDevice",
    { device, pos },
    { root: true }
  );
}

function setTimer(obj) {
  const deviceId = obj.device.id;
  if (timers.hasOwnProperty(deviceId)) {
    clearTimeout(timers[deviceId].id);
  }
  timers[deviceId] = obj;
}

function clearStartupTimer() {
  if (initialRequestId) {
    clearTimeout(initialRequestId);
    initialRequestId = null;
  }
}

function clear() {
  initialDelayPending = true;
  Object.keys(timers).forEach((timer) => clearTimeout(timers[timer].id));
  // timers = {};
  // values = {};
  reset();
}

function reset() {
  if (cancelId) {
    clearTimeout(cancelId);
  }
  cancelId = setTimeout(() => {
    initialDelayPending = false;
    cancelId = null;
  }, config.initialDelay);
}

function createVarsObj(device) {
  return {
    refreshTimer(delay) {
      const timeoutMS =
        delay && delay > config.requestTimeout ? delay : config.requestTimeout;
      this.previousRequest = resolveAfter(
        timeoutMS,
        new Promise((success, error) => {
          this.resolve = success;
          this.reject = error;
        })
      );
    },
    start: null,
    roundTripMs: [],
    device,
    delay: config.initialDelay,
    resolve: null,
    reject: null,
    previousRequest: null,
    requestsTimedOut: 0,
    req: null,
    pos: [],
    sendGetData: false,
    isUserNotified: false,
  };
}

function setRoundTripMs(varsObj) {
  const length = varsObj.roundTripMs.length;
  const roundTripMs = new Date().getTime() - varsObj.start;

  varsObj.roundTripMs.unshift(roundTripMs);
  if (length === 10) {
    varsObj.roundTripMs.pop();
  }
}

function calculateDelay(varsObj) {
  const avg = average(varsObj.roundTripMs);
  const delay = Math.max(avg, config.minDelay);
  return Math.min(delay, config.maxDelay);
}

function average(trips) {
  // if (trips.length === 0) return 0;
  const sum = trips.reduce((acc, current) => acc + current);
  return sum / trips.length;
}

export default {
  init,
  kick,
  watchDevice,
  update,
  notify,
  notifyTimeout,
};
