import { Calc } from "@stienen/Calc";
import { isDef, isUndef } from "@utils/helpers";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import {
  SpecifiedError,
  InvalidDeviceObjectError,
  InitError,
} from "@error/types";
import { localForage } from "@plugins/local-forage";

export function _createHardwareVersionName(dev) {
  return `${dev.hardware}-${dev.version}`;
}

export function _resolveConstants(constants) {
  let cdict = {},
    expr = {};
  constants.forEach(function (c) {
    if (isNaN(c.Value)) {
      expr[c.Name] = Calc.prefix(c.Value);
    } else {
      cdict[c.Name] = +c.Value;
    }
  });
  for (let key in expr) {
    expr[key] = Calc.prepare(expr[key], expr, true);
  }

  for (let key in expr) {
    cdict[key] = Calc.prepare(expr[key], cdict, true);
  }
  return cdict;
}

export function _createDeviceDataObjLc({
  meta,
  typedText,
  namedText,
  initialized,
}) {
  return {
    references: {},
    indexes: {},
    meta,
    typedText, //icons
    namedText, //used for tree
    initialized: initialized || false,
  };
}

export function _createDeviceDataObj({
  constants,
  references,
  indexes,
  namedText,
  namedTextIndexed,
  typedText,
  controlText,
  alarmText,
  initialized,
}) {
  return {
    // constant expressions from "FE_Constants", these are not just constants as the name suggests
    constants: constants || {},
    // key is the name of the varlist entry, value is the object that corresponds to that name
    // maybe use a filter to set this, not sure we need every property that gets returned from the api
    references: references || {},
    // lookup table to search the name that corresponds to a index, use helper to set this from references data
    indexes: indexes || {},
    // text  translations
    namedText: namedText || {},
    namedTextIndexed: namedTextIndexed || {},
    typedText: typedText || {},
    controlText: controlText || {},
    alarmText: alarmText || {},
    initialized: initialized || false,
  };
}

export function _createDevicesObj({
  device,
  varNames,
  alarms,
  events,
  status,
  lastSeen,
  edit,
}) {
  return {
    meta: device || {},
    values: varNames || {}, // varName: value
    alarms: alarms || {}, // index: alarm obj,
    events: events || {}, // index: event obj, should this even be here?
    status: status || "done", // aggregation of dashboard parameters
    lastSeen: lastSeen || "", // timestamp
    lastMessageTimeout: null,
    treeRegistered: false,
    refreshVars: {},
    historyVars: [],
    edit: edit || false,
    tree: {},
    visibleNodeKeys: [], //should put this in tree
  };
}

export function _checkDeviceObj(device) {
  let hardware = null;
  let version = null;
  let id = null;
  if (
    isDef(device) &&
    typeof device === "object" &&
    device.hasOwnProperty("id")
  ) {
    id = device.id;
    version = device.hasOwnProperty("version") ? device.version : null;
    hardware = device.hasOwnProperty("hardware") ? device.hardware : null;
    if (isUndef(hardware) || isUndef(version)) {
      throw new InvalidDeviceObjectError(
        `hardware: ${hardware}, version: ${version}`
      );
    }
  } else {
    throw new InvalidDeviceObjectError(device);
  }
  return { id, hardware, version };
}

export function _deviceValid(state, dev) {
  _checkDeviceObj(dev);
  const version = _createHardwareVersionName(dev);
  if (!state.deviceData.hasOwnProperty(version)) {
    throw new SpecifiedError(`Version unknown: ${version}`);
  }
  if (!state.devices.hasOwnProperty(dev.id)) {
    throw new SpecifiedError("Unknown device");
  } else if (
    isDef(state.devices[dev.id]) &&
    state.devices[dev.id] instanceof InitError
  ) {
    throw new SpecifiedError("Device not initialized properly");
  } else {
    return true;
  }
}

export function _get(state, dev, what) {
  const name = _createHardwareVersionName(dev);
  if (!state.deviceData.versions.includes(name)) {
    throw new SpecifiedError(`Version ${name} uninitialized`);
  }
  const ref = state.deviceData[name][what];
  /* eslint-disable-next-line no-undef */
  if (__DEV__ && typeof ref !== "object") {
    throw new TypeError("_get called on a value type");
  }
  return ref;
}

export function _getPositions(refs, names) {
  let r;
  const pos = [];

  names.forEach((n) => {
    r = refs[n];
    if (r) pos.push({ Index: r.Index, Length: r.Length });
  });
  return _merge(pos);
}

export function _mapTextByLabel(data) {
  return isUndef(data)
    ? {}
    : data.reduce(
        (obj, item) => ({
          ...obj,
          [item.Label]: item.Text,
        }),
        {}
      );
}

export function _mapTextByTranslationNameId(data) {
  return isUndef(data)
    ? {}
    : data.reduce(
        (obj, item) => ({
          ...obj,
          [item.TranslationNameId]: item.Text,
        }),
        {}
      );
}

export function _mapRefs(data) {
  return data.map((ref) => {
    let scale = ref.Mul / ref.Div;
    ref.Min *= scale;
    ref.Max *= scale;
    ref.ReadOnly = isUndef(ref.Defop);
    ref.Disabled = false;
    return ref;
  });
}

export function _pruneLocalCache(versions, cacheEnabled) {
  if (cacheEnabled) {
    localForage.iterate((value, key) => {
      const [, , hardware, version] = key.split(".").map((k) => parseInt(k));
      const found = versions.find(
        (device) => device.hardware === hardware && device.version === version
      );
      if (!found && _expiredAfterDaysPassed(30, value.created)) {
        localForage.removeItem(key).then(() => {});
      }
    });
  } else {
    // remove every key from the database
    localForage.clear().then(() => {});
  }
}

export function _expiredAfterDaysPassed(days, value) {
  const now = new Date().getTime();
  return now - value > DateTimeConverter.MSEC_DAY * days;
}

function _merge(pos) {
  let result = [],
    last = null,
    i = pos.length,
    curr;
  pos.sort(_sort);
  while (--i >= 0) {
    curr = pos[i];
    if (last !== null && curr.Index <= last.Index + last.Length) {
      last.Length =
        Math.max(last.Index + last.Length, curr.Index + curr.Length) -
        last.Index;
    } else {
      result.push((last = curr));
    }
  }
  return result;
}

function _sort(a, b) {
  return b.Index === a.Index ? 0 : b.Index - a.Index;
}

//why all this checking _checkDeviceObj try catch
export function _isLcCpu(device) {
  try {
    const { hardware, version } = _checkDeviceObj(device);
    const LcSeries = [
      {
        hardware: 202,
        version: 201,
      },
      {
        hardware: 168,
        version: 201,
      },
      {
        hardware: 169,
        version: 201,
      },
      {
        hardware: 267,
        version: 201,
      },
    ];
    return (
      LcSeries.findIndex(
        (lc) =>
          lc.hardware === hardware &&
          (isUndef(lc.version) || lc.version <= version)
      ) !== -1
    );
  } catch (e) {
    return false;
  }
}
