<script>
import { _compose } from "../render/screen-bll";
import { mapGetters } from "vuex";
import { screenElementBasics } from "@mixins/props";

export default {
  name: "RtTranslationNamed",
  mixins: [screenElementBasics],
  props: {
    tag: {
      type: String,
      default: "span",
    },
  },
  computed: {
    ...mapGetters("deviceCache", ["getTranslation"]),
    composeText() {
      const list = this.loc.Value.split(",").map(
        (label) =>
          this.getTranslation({
            dev: this.dev,
            label,
          }) || ""
      );
      return _compose(list[0], list.splice(1)) || this.loc.Value;
    },
  },
  render(createElement) {
    return createElement(this.tag, this.composeText);
  },
};
</script>

<style scoped></style>
