import Vue from "vue";
import { _createErrorObj, _createEventObj } from "./bll";
import * as types from "./mutation-types";
import { cloneDeep } from "lodash";
import { isDef } from "@utils/helpers";
import { isUndef } from "@utils/helpers";

export default {
  [types.ON_INPUT](state, event) {
    const setEvent = (id) => {
      if (!state.events[id].hasOwnProperty(event.refName)) {
        Vue.set(state.events[id], refName, _createEventObj(event));
      } else {
        state.events[id][refName].newValue = event.newValue;
        state.events[id][refName].status = -1;
      }
    };

    const { dev, refName } = event;
    const devId = dev.id;
    if (!state.events.hasOwnProperty(devId)) {
      Vue.set(state.events, devId, { [refName]: _createEventObj(event) });
    } else {
      setEvent(devId);
    }

    // copy for each selected device if send to multiple is active
    const devIds = Object.keys(state.events);
    if (devIds.length > 1) {
      devIds.forEach((id) => {
        if (id !== devId) {
          setEvent(id);
        }
      });
    }
  },

  [types.ON_DISCARD_SINGLE](state, { devId, refName }) {
    if (Object.keys(state.events[devId]).length === 1) {
      Vue.delete(state.events, devId);
    } else {
      Vue.delete(state.events[devId], refName);
    }
  },

  [types.CLEAR_ALL](state) {
    Object.keys(state.events).forEach((dev) => Vue.delete(state.events, dev));
    if (state.timers.length !== 0) {
      state.timers.forEach((dev, i) => {
        clearTimeout(dev);
        state.timers.splice(i, 1);
      });
    }
  },

  [types.CLEAR_TIMER](state, did) {
    const idx = state.timers.findIndex((obj) => obj.did === did);
    if (idx !== -1) {
      clearTimeout(state.timers[idx].timer);
      state.timers.splice(idx, 1);
    }
  },

  [types.ON_FSM_TRANSITION]: (state, to) => state.fsm[to](),
  [types.ON_FSM_REJECT]: (state, { translationKey, color }) =>
    state.fsm.reject(translationKey, color),
  [types.ON_FSM_TIMEOUT]: (state, { translationKey, color }) =>
    state.fsm.timeout(translationKey, color),
  [types.DROP_DEVICE_EVENTS]: (state, id) => Vue.delete(state.events, id),
  [types.SET_EVENT_DEVICES]: (state, devs) => {
    state.devices.splice(0);
    state.devices.push(...devs);
  },
  [types.TOGGLE_SEND_TO_MULTIPLE_ACTIVE]: (state) =>
    (state.SendToMultipleActive = !state.SendToMultipleActive),
  [types.CONFIRM_ACTIVE](state, type) {
    if (state.confirmPromise !== null) {
      // reject any existing promises
      state.reject();
    }
    state.confirmActive = true;
    state.type = type;
    state.confirmPromise = new Promise((resolve, reject) => {
      state.resolve = resolve;
      state.reject = reject;
    });
  },

  [types.CONFIRM](state, answer) {
    if (state.confirmPromise !== null) {
      if (answer === "resolve") {
        state.resolve();
      } else {
        state.reject();
      }
    }
    state.confirmPromise = null;
    state.resolve = null;
    state.reject = null;
    state.confirmActive = false;
  },

  [types.SET_EVENT_STATUS](state, { did, varName, status }) {
    if (
      state.events.hasOwnProperty(did) &&
      state.events[did].hasOwnProperty(varName)
    ) {
      // response status code
      const event = state.events[did][varName];
      event.status = status;

      // user feedback
      const error = _createErrorObj(status, event.ref);
      event.errorText = error.text;
      event.errorHint = error.hint;
    }
  },

  [types.DUPLICATE_CHANGE_EVENTS](state, { source, target }) {
    if (
      isUndef(source) ||
      !source.hasOwnProperty("id") ||
      isUndef(target) ||
      !target.hasOwnProperty("id") ||
      source.id === target.id
    )
      return;

    let base;
    if (state.events.hasOwnProperty(source.id)) {
      base = cloneDeep(state.events[source.id]);
    }
    if (isDef(base)) {
      // set device to the added device for each change event
      Object.keys(base).forEach((key) => (base[key].dev = target));
      // add all events for the newly added device
      Vue.set(state.events, target.id, base);
    }
  },
};
