import {Util as util} from "@utils/Util";
import {createErrorObject, createSuccessObject} from "@error/helpers";
import {NoDataError, InvalidDividendError} from "@error/types";
import {isDef, isUndef} from "@utils/helpers";


//arithmetic operators
const add = s => s.pop() + s.pop();
const sub = s => s.pop() - s.pop();
const mul = s => s.pop() * s.pop();
const div = s => s.pop() / divideGuard(s.pop());
const pow = s => Math.pow(s.pop(), s.pop());
const mod = s => s.pop() % s.pop();
const abs = s => Math.abs(s.pop());
const inc = s => {
  let result = s.pop();
  return ++result;
};
const dec = s => {
  let result = s.pop();
  return --result;
};

//n-ary
const sum = s => Math.sum.apply(null, s.splice(0, s.length));
const avg = s => Math.avg.apply(null, s.splice(0, s.length));
const max = s => Math.max.apply(null, s.splice(0, s.length));
const min = s => Math.min.apply(null, s.splice(0, s.length));

//logic compare
const ze = s => s.pop() === 0;
const nz = s => s.pop() !== 0;
const eq = s => s.pop() === s.pop();
const ne = s => s.pop() !== s.pop();
const lt = s => s.pop() < s.pop();
const le = s => s.pop() <= s.pop();
const gt = s => s.pop() > s.pop();
const ge = s => s.pop() >= s.pop();
const andim = (a, b) => a && b;
const andop = s => andim(s.pop(), s.pop());
const orim = (a, b) => a || b;
const orop = s => orim(s.pop(), s.pop());
const xorim = (a, b) => !a != !b;
const xorop = s => xorim(s.pop(), s.pop());
const not = s => !s.pop();

//bitwise operators
const and = s => s.pop() & s.pop();
const or = s => s.pop() | s.pop();
const xor = s => s.pop() ^ s.pop();
const inv = s => ~s.pop();

//other
const nan = s => isNaN(s.pop());
const ifim = (a, b, c) => a ? b : c;
const ifop = s => ifim(s.pop(), s.pop(), s.pop());
const fop = s => false;
const top = s => true;
const idxim = (i, l) => l[i];
const idx = s => idxim(s.pop(), s.splice(0, s.length));

const ex = s => { s.pop(); return true; }

const Operators = {
  //arithmetic operators
  '+': add, add: add,	//add
  '-': sub, sub: sub,	//subtract
  '*': mul, mul: mul,	//multiply
  '/': div, div: div,	//divide
  '**': pow, pow: pow,	//power
  '%': mod, mod: mod,	//modulo
  '@': abs, abs: abs,	//absolute
  '++': inc, inc: inc,	//increment
  '--': dec, dec: dec,	//decrement
  //n-ary
  'E': sum, sum: sum,	//sum
  'L': avg, avg: avg,	//average
  'D': max, max: max,	//maximum
  'd': min, min: min,	//minimum
  //logic compare
  '=0': ze, ze: ze,	//equal zero (only 0)
  '!0': nz, nz: nz,	//not equal zero (only non 0)
  '=': eq, eq: eq,	//equal
  '!=': ne, ne: ne,	//not equal
  '<': lt, lt: lt,	//less than
  '<=': le, le: le,	//less than or equal
  '>': gt, gt: gt,	//greater than
  '>=': ge, ge: ge,	//greater than or equal
  '!': not, not: not,	//not
  '&&': andop, and: andop,	//logic and
  '||': orop, or: orop,	//logic or
  '^^': xorop, xor: xorop,	//logic xor
  //bitwise compare
  '&': and,			//bitwise and
  '|': or,			//bitwise or
  '^': xor,			//bitwise xor
  '~': inv,			//inverse
  //other
  nan: nan,
  'if': ifop,
  'f': fop, 'false': fop,
  't': top, 'true': top,
  '#': idx, 'idx': idx,
  '?': ex, 'ex': ex
};

function parse(str) {
  let result = +str, length, i = 0, c;

  if (isNaN(result)) {
    result = '';
    length = str.length;
    for (; i < length; ++i) {
      c = str.charAt(i);
      if ((c >= '0' && c <= '9') || c === '.') {
        result += c;
      }
    }
  }
  return +result;
}

function find(exprlist, count, prefix) {
  let current = exprlist[count], tmp;

  if (current.charAt && current.length > 1 && current.charAt(0) === '$') {
    if (current.charAt(1) === '(') {
      while (current.charAt(current.length - 1) !== ')') {
        exprlist.splice(count, 1);
        current += ' ' + exprlist[count];
      }
      current = exprlist[count] = '$' + current.substr(2, current.length - 3);
    }
    if (prefix) {
      current = util.prefix(current, prefix);

      if (current.charAt(0) !== '$') {
        console.warn('missing \'$\' >' + current + '< ' + JSON.stringify(exprlist) + '@' + count);
        return null;
      }
      tmp = current.charAt(1);
      if (!((tmp >= 'a' && tmp <= 'z') || (tmp >= 'A' && tmp <= 'Z'))) {
        console.warn('invalid identifier >' + current + '/' + prefix + '< ' + JSON.stringify(exprlist) + '@' + count);
        return null;
      }

      exprlist[count] = current;
    }
    return current.substr(1);
  }
  return null;
}

function prepare(expr, data, more, prefix) {
  let exprlist = typeof expr === 'string' ? expr.split(' ') : expr.slice(0),
    count = exprlist.length,
    current, target, sparen = 0, cparen = 0;

  while (--count >= 0) {
    current = exprlist[count];

    if (!Array.isArray(current)) {
      target = find(exprlist, count, prefix);

      if (target !== null && data) {
        if (data.hasOwnProperty(target)) {
          current = data[target];
        }
        else if (target === 'FC_INT_UserRole') {
          // will this become a problem? used to be getRole
          current = 900;
        }
        else if (target === 'FC_INT_FEVersion') {
          current = 301;
        }
        else {
          if (!more) {
            return null;
          }
          else {
            continue;
          }
        }
      }
      switch (current) {
        case ']':
          ++sparen;
          break;
        case '[':
          --sparen;
          break;
        case ')':
          ++cparen;
          break;
        case '(':
          --cparen;
          break;
      }
    }
    if (Array.isArray(current)) {
      current = prepare(current, data, more, prefix);
    }
    if (current === null) return null;
    exprlist[count] = current;
  }
  if (exprlist.length === 1 && Array.isArray(exprlist[0])) {
    exprlist = exprlist[0];
  }
  if (sparen !== 0) {
    console.warn('[] mismatch: ' + JSON.stringify(exprlist));
    return null;
  }
  if (cparen !== 0) {
    console.warn('() mismatch: ' + JSON.stringify(exprlist));
    return null;
  }
  if (exprlist.length === 1 && !more && isNaN(exprlist[0])) {
    console.warn('string in expr: ' + JSON.stringify(exprlist));
    return null;
  }
  return exprlist;
}

function matchparens(exprlist, count, s, e) {
  let index = 0;

  while (index >= 0 && --count >= 0) {
    switch (exprlist[count]) {
      case s:
        --index;
        break;
      case e:
        ++index;
        break;
    }
  }
  return count;
}

function subexec(ref) {
  return exec(this.Exprlist.slice(this.Count, this.Current), ref, this.Debug);
}

function exec(exprlist, ref, debug) {
  let stack = [], count = exprlist.length, current, operator;

  while (--count >= 0) {
    current = exprlist[count];
    if(isUndef(current)) throw new NoDataError('cannot execute expression when data is undefined');
    if (Array.isArray(current)) {
      stack.push(exec(current, ref, debug));
      continue;
    }
    if (current === '$') {
      stack.push(parse(ref));
      continue;
    }
    if (current === ')') {
      current = count;
      count = matchparens(exprlist, count, '(', ')');
      stack = stack.map(subexec, {Count: count + 1, Current: current, Exprlist: exprlist, Debug: debug});
      continue;
    }
    if (current === ']') {
      current = count;
      count = matchparens(exprlist, count, '[', ']');
      current = exprlist.slice(count + 1, current, exprlist);
      stack.push(exec(current, ref, debug));
      continue;
    }
    operator = Operators[current];
    if (operator) {
      stack.push(operator(stack));
    }
    else {
      stack.push(parse(current));
    }
  }
  if (debug) console.log('EXPR ' + JSON.stringify(exprlist) + ' STACK ' + JSON.stringify(stack) + ' REF ' + JSON.stringify(ref));
  while ((count = stack.length) > 1) {
    stack.push(operator(stack));
    if (count === stack.length) {
      throw new TypeError('malformed expression ' + JSON.stringify(exprlist) + ' STACK ' + JSON.stringify(stack));
    }
  }
  return stack.pop();
}

function values(exprlist, varNames, prefix) {
  let count = exprlist.length, current, target;

  while (--count >= 0) {
    current = exprlist[count];
    if (Array.isArray(current)) {
      values(current, varNames, prefix);
    }
    else {
      target = find(exprlist, count, prefix);
      if (!target) continue;
      if (!['FC_INT_UserRole', 'FC_INT_FEVersion'].includes(target)) {
        varNames.push(target);
      }
    }
  }
  return varNames;
}

function prefix(expr) {
  if (typeof expr === 'string') expr = expr.split(' ');
  if (Array.isArray(expr)) expr = [expr];
  return expr.slice(0);
}

const divideGuard = n => {
  if (n === 0 || isNaN(n)) throw new InvalidDividendError(n);
  return n;
};

export const executeExpr = (expr, data, more= false, prefix = null) => {
  try {
    let prep = Calc.prepare(expr, data, more, prefix);
    if (prep) {
      const result = exec(prep, null, false);
      return isDef(result)
        ? createSuccessObject(result)
        : createErrorObject('Expression result undefined');
    }
    else {
      return createErrorObject(`Calc.prepare failed (missing data for expr: ${expr})`);
    }
  }
  catch (err) {
    return createErrorObject(err);
  }
};

export const Calc = {
  prefix: prefix,
  prepare: prepare,
  execute: executeExpr,
  values: values,
};

