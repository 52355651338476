<template>
  <span>{{ combinedText }}</span>
</template>

<script>
import { _compose } from "../render/screen-bll";
import { mapGetters } from "vuex";
import { screenElementBasics } from "@mixins/props";

export default {
  name: "RtControlText",
  mixins: [screenElementBasics],
  data() {
    return {
      text: "-1",
    };
  },
  computed: {
    ...mapGetters("deviceCache", [
      "getControlText",
      "getValue",
      "getValueByIndex",
    ]),
    varNr() {
      return this.state[this.extra[0]];
    },
    varText() {
      return this.state[this.extra[1]];
    },
    ctrlValue() {
      return this.getValue(this.dev, this.val_obj.RefName);
    },
    ctrlNrValue() {
      return this.getValue(this.dev, this.varNr.RefName);
    },
    ctrlTextValue() {
      return this.getValue(this.dev, this.varText.RefName);
    },
    combinedText() {
      let nr = this.ctrlNrValue;
      return (nr !== 0 ? _compose(this.text, [nr]) : this.text).trim();
    },
  },
  methods: {
    validator() {
      // can't use a validator for this because of cross prop dependencies
      let ctrl = this.val_obj;
      let nr = this.varNr;
      let text = this.varText;
      if (!ctrl && !nr && !text)
        throw new TypeError(`ctrl: ${ctrl} nr: ${nr} text: ${text}`);
      if (ctrl.Number !== nr.Number && ctrl.Number !== text.Number)
        throw new TypeError(`devices are not the same: ${ctrl} ${nr} ${text}`);
    },
  },
  watch: {
    ctrlValue: {
      immediate: true,
      handler: function () {
        if (this.ctrlTextValue === 0) {
          if (this.ctrlValue === 0) {
            this.text = "";
          } else {
            const { text } = this.getControlText(this.dev, this.ctrlValue);
            this.text = text || "";
          }
        }
      },
    },
    ctrlTextValue: {
      immediate: true,
      handler: function (newVal) {
        if (newVal && newVal !== 0) {
          let index = newVal * 2;
          const text = this.getValueByIndex(this.dev, index);
          this.text = text || "";
        }
      },
    },
  },
  created() {
    this.validator();
  },
};
</script>

<style scoped></style>
