import { ScreenNotFoundError } from "@error/types";
import * as types from "./mutation-types";

async function lcInitScreens(
  { commit },
  { meta, screens, testNames, alarmIcons }
) {
  const hardwareVersionName = `${meta.device}-${meta.swVersion}`;

  const testChunks = [];
  const chunkSize = 16;
  for (let i = 0; i < testNames.length; i += chunkSize) {
    const groupName = "TEST" + Math.floor(i / chunkSize);
    testChunks[groupName] = testNames.slice(i, i + chunkSize);
  }

  commit(types.SET_LC_DEVICE_DEFINITION, {
    hardwareVersionName,
    meta,
    screens,
    testChunks,
    alarmIcons,
  });
}

async function lcSelectScreen(
  { dispatch, getters, rootGetters },
  { deviceId, screenId }
) {
  try {
    screenId = parseInt(screenId);

    const device = rootGetters["deviceCache/getDeviceMeta"](deviceId);

    if (!device.isLicenced) {
      return Promise.reject("This device doesn't have a licence yet");
    }

    // dispatch('deviceCache/clearHistoryVars', device.Id, {root: true});

    const screenDefinition = getters.getLcScreenDefinition(device, screenId);

    const testChunks = getters.getTestChunksForDevice(device);
    await dispatch(
      "deviceCache/initLcTestVariables",
      { device, testChunks },
      { root: true }
    );

    const screenAlwaysVisible =
      screenDefinition.id === -1 || screenDefinition.access.length === 0;

    if (!screenAlwaysVisible) {
      let isAuthorized = false;
      let testPassedForRoute = false;

      for (const accesComponent of screenDefinition.access) {
        if (rootGetters["auth/getIsAuthorizedLc"](device, accesComponent)) {
          isAuthorized = true;
          if (getters.getAllTestPassesForComponent(device, accesComponent)) {
            testPassedForRoute = true;
            break;
          }
        }
      }

      if (!isAuthorized) {
        return Promise.reject("Access denied");
      }

      if (!testPassedForRoute) {
        return Promise.reject("error.notVisible"); //will be translated, may need better solution
      }
    }

    const varNames = screenDefinition.usedVariables.map((name) =>
      name.startsWith("$") ? name.slice(1) : name
    );
    const mappedValues = { [deviceId]: { varNames } };
    if (varNames.length > 0) {
      mappedValues[deviceId].data = rootGetters["deviceCache/getValuesFor"](
        device,
        varNames
      );
    }

    const tests = screenDefinition.usedTests.map((name) => name.slice(1));
    const testVariables = getters.getLcTestNamesForDevice(device, tests);
    mappedValues[deviceId].varNames.push(...testVariables);

    return Promise.resolve({ screenDefinition, mappedValues, device });
  } catch (err) {
    console.error(err);
    if (err instanceof ScreenNotFoundError) {
      return Promise.reject(err.message);
    }
    return Promise.reject("something went wrong on lcSelectScreen");
  }
}

export default {
  lcInitScreens,
  lcSelectScreen,
};
