const editModeActive = (state) =>
  state.fsm && typeof state.fsm === "object"
    ? !(state.fsm.state === "read_only")
    : false;

const isEditable = (state) =>
  state.fsm && typeof state.fsm === "object"
    ? !(state.fsm.state === "editable")
    : false;

const getEditedValue = (state) => (dev, varName) => {
  if (
    state.events.hasOwnProperty(dev.id) &&
    state.events[dev.id].hasOwnProperty(varName)
  ) {
    return state.events[dev.id][varName].newValue;
  } else {
    return null;
  }
};

const setDataError = (state) => (dev, varName) => {
  if (
    state.events.hasOwnProperty(dev.id) &&
    state.events[dev.id].hasOwnProperty(varName)
  ) {
    return (
      state.events[dev.id][varName].status === -3 ||
      state.events[dev.id][varName].status === 3 ||
      state.events[dev.id][varName].status === 4
    );
  } else {
    return null;
  }
};

const sendToMultipleLocked = (state) => Object.keys(state.events).length > 1;

const getChangeEvents = (state) => {
  return Object.keys(state.events).reduce((acc, dev) => {
    return acc.concat(
      Object.keys(state.events[dev]).map((event) => state.events[dev][event])
    );
  }, []);
};

export default {
  getEditedValue,
  editModeActive,
  sendToMultipleLocked,
  isEditable,
  getChangeEvents,
  setDataError,
};
