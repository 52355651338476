import * as types from "@state/modules/layout/mutation-types";
import { isMobileOnly, isIOS } from "mobile-device-detect";
import { LayoutTypes } from "./index";

async function detectLayout({ commit }) {
  try {
    let layoutState = LayoutTypes.DESKTOP;
    if (isMobileOnly) {
      const width = isIOS ? window.innerWidth : screen.availWidth;
      const height = isIOS ? window.innerHeight : screen.availHeight;

      if (width < height) {
        layoutState = LayoutTypes.PORTRAIT;
      } else {
        layoutState = LayoutTypes.LANDSCAPE;
      }
    }
    commit(types.SET_LAYOUT_STATE, layoutState);
  } catch (err) {
    console.error(err);
  }
}

export default {
  detectLayout,
};
