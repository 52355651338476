<template>
  <div>
    <slot />
    <v-tooltip bottom open-delay="500">
      <template #activator="{ on }">
        <base-button
          v-on="on"
          icon
          @click="send"
          :disabled="disabled"
          :width="buttonSize"
          :height="buttonSize"
        >
          <v-icon>mdi-content-save</v-icon>
        </base-button>
      </template>
      {{ $t("common.send") }}
    </v-tooltip>
    <v-tooltip bottom open-delay="500">
      <template #activator="{ on }">
        <base-button
          v-on="on"
          icon
          @click="onSendToMultipleClicked"
          :disabled="disabled"
          :width="buttonSize"
          :height="buttonSize"
        >
          <v-icon>mdi-plus-circle</v-icon>
        </base-button>
      </template>
      <span v-if="sendToMultipleLocked">
        {{ $t("editMode.sendToMultipleLocked") }}
      </span>
      <span v-else>
        {{ $t("editMode.sendToMultiple") }}
      </span>
    </v-tooltip>
    <v-tooltip bottom open-delay="500">
      <template #activator="{ on }">
        <base-button
          v-on="on"
          icon
          @click="discardChanges"
          :disabled="disabled"
          :width="buttonSize"
          :height="buttonSize"
          color="error"
        >
          <v-icon color="gray">mdi-close-circle</v-icon>
        </base-button>
      </template>
      <span>
        {{ $t("editMode.discardAllChanges") }}
      </span>
    </v-tooltip>

    <portal to="modals" v-if="!isConfirm && (isRejected || isTimedOut)">
      <SendErrorModal :show="!isConfirm && (isRejected || isTimedOut)" />
    </portal>

    <portal to="modals" v-if="SendToMultipleActive">
      <SendToMultiple :show="SendToMultipleActive" />
    </portal>

    <portal to="modals" v-if="isConfirm && isConfirmLeave">
      <ConfirmLeaveDialog :show="isConfirm && isConfirmLeave" />
    </portal>

    <portal to="modals" v-if="isConfirm && isConfirmEdit">
      <ConfirmEditDialog :show="isConfirm && isConfirmEdit" />
    </portal>

    <portal to="modals" v-else-if="isPending">
      <v-overlay>
        <base-spinner :size="100" :width="8" />
      </v-overlay>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SendErrorModal from "./SendErrorModal";
import ConfirmLeaveDialog from "./ConfirmLeaveDialog";
import ConfirmEditDialog from "./ConfirmEditDialog";
import SendToMultiple from "./SendToMultiple";
import { notifyUser } from "@error/helpers";

export default {
  name: "TheEditToolbar",
  components: {
    SendErrorModal,
    ConfirmLeaveDialog,
    ConfirmEditDialog,
    SendToMultiple,
  },
  computed: {
    ...mapState("edit", {
      isPending: (state) => state.fsm.is("pending"),
      isRejected: (state) => state.fsm.is("rejected"),
      isTimedOut: (state) => state.fsm.is("timed_out"),
      isConfirm: (state) => state.confirmActive,
      isConfirmEdit: (state) => state.type === "EDIT",
      isConfirmLeave: (state) => state.type === "LEAVE",
    }),
    ...mapGetters("edit", ["sendToMultipleLocked", "editModeActive"]),
    SendToMultipleActive: {
      get: function () {
        return this.$store.state.edit.SendToMultipleActive;
      },
      set: function () {
        this.$store.commit("edit/TOGGLE_SEND_TO_MULTIPLE_ACTIVE");
      },
    },
    disabled() {
      return !this.editModeActive || (this.editModeActive && this.isPending);
    },
    buttonSize() {
      return this.$vuetify.breakpoint.smAndDown ? "30px" : "40px";
    },
  },
  methods: {
    send() {
      this.$store.dispatch("edit/send");
    },
    discardChanges() {
      this.$store.dispatch("edit/discard");
    },
    onSendToMultipleClicked() {
      if (!this.sendToMultipleLocked) {
        this.SendToMultipleActive = false;
      } else {
        notifyUser({
          color: "warning",
          text: this.$t("editMode.sendToMultipleNotAvailable"),
        });
      }
    },
    preventRefresh(event) {
      if (this.disabled) {
        return;
      }
      event.preventDefault();

      //chrome requires a returnValue
      event.returnValue = "refresh";
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventRefresh);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventRefresh);
  },
};
</script>

<style scoped></style>
