// import LoadingWrapper from '@modules/rtcpu/LoadingWrapper';
// const AsyncComponent = (namespace, component) => ({
//   // The component to load (should be a Promise)
//   component: import(`/* webpackChunkName: '${namespace}' */ ${component}`),
//   // A component to use while the async component is loading
//   loading: LoadingWrapper,
//   // A component to use if the load fails
//   error: ErrorCard,
//   // Delay before showing the loading component. Default: 200ms.
//   delay: 200,
//   // The error component will be displayed if a timeout is
//   // provided and exceeded. Default: Infinity.
//   timeout: 3000
// });

import Vue from "vue";
import withActiveRefresh from "@hoc/withActiveRefresh";
import { upperFirst, camelCase } from "lodash";

// main
import LayoutDefault from "@layouts/RenderlessLayoutDefault";
import LayoutEmpty from "@layouts/RenderlessLayoutEmpty";
import RtBaseInput from "@modules/rtcpu/components/RtBaseInput";
import RtBuildTemplate from "@modules/rtcpu/components/RtBuildTemplate";
import RtErrorCard from "@modules/rtcpu/components/RtErrorCard";
import LcErrorCard from "@modules/lccpu/components/LcErrorCard";
import LcScreenRender from "@modules/lccpu/render/LcScreenRender";

Vue.component("LayoutDefault", LayoutDefault);
Vue.component("LayoutEmpty", LayoutEmpty);
Vue.component("RtBaseInput", RtBaseInput);
Vue.component("RefreshingRtBuildTemplate", withActiveRefresh(RtBuildTemplate));
Vue.component("LcScreenRender", withActiveRefresh(LcScreenRender));
Vue.component("RtErrorCard", RtErrorCard);
Vue.component("LcErrorCard", LcErrorCard);

const requireBaseComponent = require.context(
  "@components/base",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireBaseComponent.keys().forEach((fileName) => {
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  const componentConfig = requireBaseComponent(fileName);
  Vue.component(componentName, componentConfig.default || componentConfig);
});

// domain (fc2)
import RtAlarmText from "@modules/rtcpu/components/RtAlarmText";
import RtControlText from "@modules/rtcpu/components/RtControlText";
import RtWeekdayText from "@modules/rtcpu/components/RtWeekdayText";
import RtInputOutput from "@modules/rtcpu/components/RtInputOutput";
import RtTranslationNamed from "@modules/rtcpu/components/RtTranslationNamed";
import RtVariableRef from "@modules/rtcpu/components/RtVariableRef";
import RtDisposition from "@modules/rtcpu/components/RtDisposition";
import RtHtmlWrapper from "@modules/rtcpu/components/RtHtmlWrapper";
import RtLink from "@modules/rtcpu/components/RtLink";

Vue.component("RtAlarmText", RtAlarmText);
Vue.component("RtControlText", RtControlText);
Vue.component("RtWeekdayText", RtWeekdayText);
Vue.component("RtInputOutput", RtInputOutput);
Vue.component("RtTranslationNamed", RtTranslationNamed);
Vue.component("RtVariableRef", RtVariableRef);
Vue.component("RtDisposition", RtDisposition);
Vue.component("RtHtmlWrapper", RtHtmlWrapper);
Vue.component("RtLink", RtLink);

import RtWidget from "@modules/rtcpu/components/widgets/RtWidget";

Vue.component("RtWidget", RtWidget);

import RtValvesPFV from "@modules/rtcpu/components/valves/RtValvesPFV";
import RtValvesKFV from "@modules/rtcpu/components/valves/RtValvesKFV";

Vue.component("RtValvesPFV", RtValvesPFV);
Vue.component("RtValvesKFV", RtValvesKFV);

// chart
import RtHistoryDiagram from "@modules/rtcpu/components/RtHistoryDiagram";

Vue.component("RtHistoryDiagram", RtHistoryDiagram);
