import {Data} from "./Data";
import {Encoding} from "./Encoding";

export const DateTimeConverter = {
  EPOCH_20010101: 978307200000, // 2001-01-01 - 1970-01-01
  MSEC_DAY: 86400000,  //24 * 60 * 60 * 1000

  //import (binary to integer)
  toInteger: function (data, offset, count, signed) {
    var max = Math.pow(2, count * 8);
    var value = data[offset];
    while (--count > 0) {
      value = value << 8 | data[++offset];
    }
    if (signed && value >= max / 2) {
      return value - max;
    }
    return value;
  },
  //export (integer to binary)
  fromInteger: function (value, count) {
    var data = new Array(count);
    while (--count >= 0) {
      data[count] = value & 0xff;
      value >>>= 8;
    }
    return data;
  },
  //import (binary to string)
  toString: function (data, offset, count) {
    return Encoding.decode(data, offset, count);
  },
  //export (string to binary)
  fromString: function (value) {
    return Encoding.encode(value);
  },
  dateToString: function (date) {
    var YYYY = ("000" + date.getFullYear()).slice(-4);
    var MM = ("0" + (date.getMonth() + 1)).slice(-2);
    var DD = ("0" + date.getDate()).slice(-2);

    return YYYY + '-' + MM + '-' + DD;
  },
  timeToString: function (date) {
    var hh = ("0" + date.getHours()).slice(-2);
    var mm = ("0" + date.getMinutes()).slice(-2);
    var ss = ("0" + date.getSeconds()).slice(-2);
    return hh + ':' + mm + ':' + ss
  },
  //import (binary to date)
  toDateTime: function (data, offset, count) {
    return DateTimeConverter.ISO8601Date(DateTimeConverter.importDate(DateTimeConverter.toInteger(data, offset, count, false)));
  },
  importDate: function (value) {
    //var date = new Date();
    //date.setTime(DateTimeConverter.EPOCH_20010101 + value * DateTimeConverter.MSEC_DAY);
    //return date;
    return new Date(DateTimeConverter.EPOCH_20010101 + value * DateTimeConverter.MSEC_DAY);
  },
  //export (date to binary)
  fromDateTime: function (value) {
    return DateTimeConverter.fromInteger(DateTimeConverter.exportDate(DateTimeConverter.parseISO8601(value)), 2);
  },
  exportDate: function (value) {
    return (value - DateTimeConverter.EPOCH_20010101) / DateTimeConverter.MSEC_DAY;
  },
  parseDate: function (value) {
    return new Date(+value.slice(6, 19)); // valid from 2001-09-09T01:46:40Z until 2286-11-20T17:46:40Z
  },
  parseDateLocalTime: function (value) {
    var date = this.parseDate(value);
    return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
  },
  makeDate: function (date) {
    return '\/Date(' + (date.getTime() - date.getTimezoneOffset() * 60000) + ')\/';
//		return '\/Date(' + (date.getTime?date.getTime():date) + ')\/';
  },
  formatDate: function (date) {
    return `/Date(${date})/`
  },
  ISO8601Date: function (date) {
    var YYYY = ("000" + date.getFullYear()).slice(-4);
    var MM = ("0" + (date.getMonth() + 1)).slice(-2);
    var DD = ("0" + date.getDate()).slice(-2);
    var hh = ("0" + date.getHours()).slice(-2);
    var mm = ("0" + date.getMinutes()).slice(-2);
    var ss = ("0" + date.getSeconds()).slice(-2);
    var ms = ("00" + date.getMilliseconds()).slice(-3);
    return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss + '.' + ms;
  },
  parseISO8601: function (date) {
    var chars = ['.', ':', ':', ' ', '-', '-'];
    var index = 0, lastindex = 0, count = chars.length;
    var result = [];
    while (--count >= 0 && (index = date.indexOf(chars[count], lastindex)) >= 0) {
      result.push(+date.substring(lastindex, index));
      lastindex = ++index;
    }
    result.push(+date.substring(lastindex));
    if (result.length > 1) --result[1]; // [1,12] => [0,11]
    return Date.UTC.apply(null, result);
  },

  mainDisplayDate: function (date) {
    var YYYY = ("000" + date.getFullYear()).slice(-4);
    var MM = date.getMonth();
    var DD = ("0" + date.getDate()).slice(-2);
    var hh = ("0" + date.getHours()).slice(-2);
    var mm = ("0" + date.getMinutes()).slice(-2);

    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    return YYYY + '-' + month[MM] + '-' + DD + ' ' + hh + ':' + mm;
  },

  parseMainDisplayDate: function (date) {
    var month = new Array();
    month["Jan"] = 0;
    month["Feb"] = 1;
    month["Mar"] = 2;
    month["Apr"] = 3;
    month["May"] = 4;
    month["Jun"] = 5;
    month["Jul"] = 6;
    month["Aug"] = 7;
    month["Sep"] = 8;
    month["Oct"] = 9;
    month["Nov"] = 10;
    month["Dec"] = 11;

    var YYYY = Number(date.substring(0, 4));
    var MM = month[date.substring(5, 8)];
    var DD = Number(date.substring(9, 11));
    var hh = Number(date.substring(12, 14));
    var mm = Number(date.substring(15, 17));

    return new Date(Date.UTC(YYYY, MM, DD, hh, mm));
  },

  timeAgo(date) {
    const prev = new Date(date);
    return this.timeAgoHelper(prev);
  },

  timeAgoParse(date) {
    const prev = DateTimeConverter.parseDate(date);
    return this.timeAgoHelper(prev);
  },

  timeAgoHelper: function (date) {
    const now = new Date();
    const prev = date;
    if (prev && now) {
      let diff = Math.ceil(((now.getTime() - prev.getTime()) / (60000)));
      if (diff < 120) return diff + ' ' + (diff > 1 ? 'mins' : 'min') + ' ago';
      diff /= 60.0;
      if (diff < 72) return diff.toFixed(1) + ' ' + (diff > 1 ? 'hours' : 'hour') + ' ago';
      diff /= 24.0;
      if (diff < 28) return diff.toFixed(1) + ' ' + (diff > 1 ? 'days' : 'day') + ' ago';
      diff /= 7.0;
      if (diff < 52) return diff.toFixed(1) + ' ' + (diff > 1 ? 'weeks' : 'week') + ' ago';
      diff /= 52.0;

      if (!isNaN(diff)) {
        return diff.toFixed(1) + ' years ago';
      }
    }
    return null;
  }

};

DateTimeConverter.convert = function (type, val) {
  switch (type) {
    case 'DateTime':
      var date = DateTimeConverter.parseDate(val);
      return date ? DateTimeConverter.ISO8601Date(date) : '';
      break;
    case 'Boolean':
      return val ? '1' : '0';
      break;
    case 'String':
      return val ? val : '';
      break;
    default :
      return val;
  }
};

DateTimeConverter.convertRawReceive = function (type, data, offset) {
  // default offset to 0
  offset = offset || 0;
  switch (type) {
    case Data.T_VOID:
      return '';
    case Data.T_BOOL:
      return data[offset] != 0;
    case Data.T_STRING:
      return DateTimeConverter.toString(data, offset, 16);
    case Data.T_DATETIME:
      return DateTimeConverter.toDateTime(data, offset, 2);
    case Data.T_TIMESPAN:
      return DateTimeConverter.toInteger(data, offset, 2, false);
    case Data.T_I8:
      return DateTimeConverter.toInteger(data, offset, 1, true);
    case Data.T_I16:
      return DateTimeConverter.toInteger(data, offset, 2, true);
    case Data.T_I32:
      return DateTimeConverter.toInteger(data, offset, 4, true);
    case Data.T_I64:
      return DateTimeConverter.toInteger(data, offset, 8, true);
    case Data.T_UI8:
      return DateTimeConverter.toInteger(data, offset, 1, false);
    case Data.T_UI16:
      return DateTimeConverter.toInteger(data, offset, 2, false);
    case Data.T_UI32:
      return DateTimeConverter.toInteger(data, offset, 4, false);
    case Data.T_UI64:
      return DateTimeConverter.toInteger(data, offset, 8, false);
    case Data.T_INPUT:
      return DateTimeConverter.toInteger(data, offset, 2, false);
    case Data.T_OUTPUT:
      return DateTimeConverter.toInteger(data, offset, 2, false);
  }
};

DateTimeConverter.convertRawSend = function (type, value) {
  switch (type) {
    case Data.T_VOID:
      return [];
    case Data.T_BOOL:
      return [value ? 1 : 0];
    case Data.T_STRING:
      return DateTimeConverter.fromString(value);
    case Data.T_DATETIME:
      return DateTimeConverter.fromDateTime(value);
    case Data.T_TIMESPAN:
      return DateTimeConverter.fromInteger(value, 2);
    case Data.T_I8:
    case Data.T_UI8:
      return DateTimeConverter.fromInteger(value, 1);
    case Data.T_I16:
    case Data.T_UI16:
      return DateTimeConverter.fromInteger(value, 2);
    case Data.T_I32:
    case Data.T_UI32:
      return DateTimeConverter.fromInteger(value, 4);
    case Data.T_I64:
    case Data.T_UI64:
      return DateTimeConverter.fromInteger(value, 8);
    case Data.T_INPUT:
    case Data.T_OUTPUT:
      return DateTimeConverter.fromInteger(value, 2);
  }
};

