import Vue from "vue";
import Vuex from "vuex";

// Root
import root from "./root";

// Modules
import auth from "./modules/auth";
import deviceCache from "./modules/cache";
import rtRemote from "./modules/rt-remote";
import lcRemote from "./modules/lc-remote";
import edit from "./modules/edit-mode";
import settings from "./modules/settings";
import errorService from "./modules/error";
import invitations from "./modules/invitations";
import layout from "./modules/layout";
import subscription from "./modules/subscription";
import service from "./modules/service";
import dealer from "./modules/dealer";
import mechanic from "./modules/mechanic";

// Plugins
import { Messenger } from "@api/messenger";
import createMessengerPlugin from "@plugins/streamer";

const messengerPlugin = createMessengerPlugin(Messenger);

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    deviceCache,
    rtRemote,
    lcRemote,
    edit,
    settings,
    errorService,
    invitations,
    layout,
    subscription,
    service,
    dealer,
    mechanic,
  },
  plugins: [messengerPlugin],
  ...root,
});

export default store;
