<template>
  <v-container fluid>
    <v-row class="d-flex justify-start">
      <v-select
        v-if="groupsActive"
        :items="animalGroups"
        v-model="selectedAnimalGroup"
        item-value="index"
        item-text="value"
        :label="labelAnimalGroups"
        dense
        style="max-width: 175px; margin-right: 24px"
      />
      <v-select
        :items="valves"
        v-model="selectedValve"
        item-value="index"
        item-text="value"
        :label="labelValves"
        dense
        style="max-width: 175px"
      />
    </v-row>
    <v-row>
      <RtDeviceScreen :disposition="disposition" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import RtDeviceScreen from "@modules/rtcpu/render/RtDeviceScreen";
import { isUndef } from "@utils/helpers";

export default {
  name: "RtValvesPFV",
  components: {
    RtDeviceScreen,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    screenId: {
      type: String,
      required: true,
    },
    dispKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedValve: 1,
      selectedAnimalGroup: 1,
      groupsActive: true,
      valveCount: 0,
      groupCount: 0,
    };
  },
  computed: {
    ...mapGetters("deviceCache", [
      "getValue",
      "getTranslation",
      "getValuesFor",
    ]),
    disposition() {
      return {
        Type: this.screenId,
        Prefix: this.createPrefix(this.selectedValve),
        Key: this.dispKey,
      };
    },
    valves() {
      const names = [];
      if (this.groupsActive) {
        const aantalV = this.getValue(
          this.device,
          `DG${this.selectedDGNr}_AANTALV`
        );
        for (let i = 1; i <= aantalV; i++) {
          const ventielNr = this.getValue(
            this.device,
            `DG${this.selectedDGNr}_VENTIEL${i}`
          );
          const val = this.getValue(this.device, `V${ventielNr}_TEKST`);
          names.push({ index: ventielNr, value: val });
        }
      } else {
        const count = this.valveCount;
        for (let i = 1; i <= count; i++) {
          const val = this.getValue(this.device, `V${i}_TEKST`);
          names.push({ index: i, value: val });
        }
      }

      return names;
    },
    animalGroups() {
      const count = this.groupCount;
      const names = [];
      for (let i = 1; i <= count; i++) {
        const val = this.getValue(this.device, `M${i}_TEKST`);
        names.push({ index: i, value: val });
      }
      return names;
    },
    labelAnimalGroups() {
      return this.getTranslation({ dev: this.device, label: "Diergroepen" });
    },
    labelValves() {
      return this.getTranslation({ dev: this.device, label: "Ventielen" });
    },
    selectedDGNr() {
      return 1 + 2 * (this.selectedAnimalGroup - 1);
    },
  },
  created() {
    this.load();
  },
  watch: {
    selectedAnimalGroup: {
      handler() {
        this.selectedValve =
          this.valves.length > 0 ? this.valves[0].index : null;
      },
    },
  },
  methods: {
    load() {
      const varNames = this.getVarnames();
      const getters = this.getValuesFor(this.device, varNames);

      this.groupsActive = !!getters["ALG_DIERGROEPEN"];
      this.groupCount = getters["ALG_A_DIERGROEPEN"];
      this.valveCount = getters["ALG_A_VENTIELEN"];

      if (this.groupsActive) {
        const varNames = this.getVarnamesDiergroepen();
        this.getValuesFor(this.device, varNames);
      }
    },
    createPrefix(valve) {
      if (isUndef(valve)) return null;
      if (this.groupsActive) {
        return `DG${this.selectedDGNr}_,V${valve}_,T${valve}_,C${valve}_`;
      } else {
        return `VK${valve}_,V${valve}_,T${valve},C${valve}_`;
      }
    },
    getVarnames() {
      const varNames = [];

      varNames.push("ALG_DIERGROEPEN");
      varNames.push("ALG_A_DIERGROEPEN");
      varNames.push("ALG_A_VENTIELEN");

      for (let i = 1; i <= 39; i += 2) {
        varNames.push("DG" + i + "_AANTALV");
      }
      for (let i = 1; i <= 60; i++) {
        varNames.push("V" + i + "_TEKST");
      }

      return varNames;
    },
    getVarnamesDiergroepen() {
      const count = this.groupCount;
      const varNamesDiergroepen = [];
      let j = 1;

      for (let i = 0; i < count; i++) {
        for (let k = 1; k <= 40; k++) {
          varNamesDiergroepen.push("DG" + j + "_VENTIEL" + k);
        }
        j += 2;
      }
      for (let i = 1; i <= count; i++) {
        varNamesDiergroepen.push("M" + i + "_TEKST");
      }
      return varNamesDiergroepen;
    },
  },
};
</script>

<style scoped></style>
