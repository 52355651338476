<script>
import {
  makeProps,
  checkTag,
  addExtraCss,
  setStyle,
} from "../render/screen-bll";
import { isDef } from "@utils/helpers";
import RtScreenElement from "./RtScreenElement";

const TYPES = {
  HTML: 0,
  TEXT: 1,
  REFERENCE_TYPE: 2,
  IMAGE: 3,
  HISTORY: 4,
  TRANSLATION: 5,
  LINK: 6,
  CLONE: 7,
  WIDGET: 8,
  ICON: 9,
  DEV_NAME: 10,
  DISPOSITION: 11,
  ALARM: 12,
  CONTROL: 13,
  WEEKDAY: 14,
  TREE_LINK: 15,
};

let _build = (target, loc, screenState, mappedValues, store) => {
  let tag = "span";
  let attr = {};
  const css = makeProps(loc.Properties);
  const domProps = {};
  const restProps = {};

  addExtraCss(css.classList, loc.Extra, screenState.Values);

  const device = screenState.Devices[0].Device;

  switch (loc.Type) {
    case TYPES.HTML:
      if (isDef(loc.Properties) && Array.isArray(loc.Properties)) {
        const idx = loc.Properties.findIndex((prop) => prop.Name === "id");
        if (idx !== -1) {
          if (loc.Properties[idx].Value === "specialScreenValvesPFV") {
            tag = "RtValvesPFV";
            restProps.device = device;
            restProps.dispKey = screenState.disposition.Key;
            restProps.screenId = screenState.disposition.Extra;
          } else if (loc.Properties[idx].Value === "specialScreenValves") {
            tag = "RtValvesKFV";
            restProps.device = device;
            restProps.screenId = screenState.disposition.Extra;
            restProps.dispKey = screenState.disposition.Key;
            restProps.prefix = screenState.disposition.Prefix.split(",");
          }
        }
      }
      if (!tag || tag === "span") {
        tag = isDef(loc.Extra) ? "RtHtmlWrapper" : loc.Value;
        restProps.mappedValues = mappedValues;
      }
      break;

    case TYPES.TEXT:
      tag = checkTag(target, "span");
      if (tag !== "span") {
        attr.value = target.min + target.length;
      }
      if (loc.Value === null) {
        if (screenState.disposition) {
          //same way as translating node
          let titleLabel = store.getters["deviceCache/getDispositionTitle"](
            device,
            screenState.disposition
          );
          domProps.innerHTML = `${titleLabel} ${
            isDef(device.displayName) ? `(${device.displayName})` : ""
          } `;
        }
      } else {
        domProps.innerHTML = loc.Value;
      }
      break;

    case TYPES.REFERENCE_TYPE:
      tag = "RtVariableRef";
      break;

    case TYPES.IMAGE:
      tag = "RtHtmlWrapper";
      attr.src = process.env.VUE_APP_ROOT_API + loc.Value;
      restProps.tag = "img";
      restProps.mappedValues = mappedValues;
      break;

    case TYPES.HISTORY:
      tag = "RtHistoryDiagram";
      restProps.fullState = screenState;
      restProps.device = device;
      break;

    case TYPES.TRANSLATION:
      tag = "RtTranslationNamed";
      restProps.device = screenState.Devices[loc.Extra].Device;
      break;

    case TYPES.LINK:
      tag = "RtLink";
      restProps.screenGroup = loc;
      break;

    case TYPES.CLONE:
      if (loc.template.ok) {
        tag = "RefreshingRtBuildTemplate";
        restProps.screenState = loc.template.data.state;
        restProps.mappedValues = loc.template.data.mappedValues;
        restProps.isRoot = loc.template.data.state.structured.length > 1;
      } else {
        tag = "RtErrorCard";
        domProps.innerHTML = loc.template.error;
      }
      break;

    case TYPES.WIDGET:
      tag = "RtWidget";
      attr = css;
      restProps.fullState = screenState;
      break;

    case TYPES.ICON:
      tag = "RtHtmlWrapper";
      restProps.tag = "span";
      css.classList.push("icon");
      css.classList.push(loc.Value);
      restProps.mappedValues = mappedValues;
      break;

    case TYPES.DEV_NAME:
      tag = "span";
      domProps.innerHTML = screenState.Devices[loc.Value].Device.name;
      break;

    case TYPES.DISPOSITION:
      tag = "RtDisposition";
      restProps.link = loc.Extra;
      break;

    case TYPES.ALARM:
      tag = "RtAlarmText";
      break;

    case TYPES.CONTROL:
      tag = "RtControlText";
      break;

    case TYPES.WEEKDAY:
      tag = "RtWeekdayText";
      restProps.device = screenState.Devices[loc.Extra].Device;
      break;

    case TYPES.TREE_LINK:
      tag = "div";
      break;

    default:
      console.error(`RtScreenElement loc type: ${loc.Type} not implemented`);
      break;
  }

  return {
    tag,
    domProps,
    props: {
      loc,
      state: screenState.Values,
      ...restProps,
    },
    class: css.classList,
    style: setStyle(loc, attr),
    attrs: attr,
    key: `${screenState.Id}_${device.id}_${screenState.prefix}_${loc.L}`,
  };
};

export default {
  name: "RtScreenElement",
  functional: true,
  render(createElement, context) {
    const props = context.data.props;
    const store = context.parent.$store;
    const target =
      props.hasOwnProperty("loc") &&
      props.loc.hasOwnProperty("parent") &&
      isDef(props.loc.parent.tag)
        ? props.loc.parent.tag
        : "div";
    const element = _build(
      target,
      props.loc,
      props.screenState,
      props.mappedValues,
      store
    );
    const c = props.loc.hasOwnProperty("children") ? props.loc.children : null;

    const children =
      isDef(c) && Array.isArray(c)
        ? c.map((loc) =>
            createElement(RtScreenElement, {
              props: {
                loc,
                screenState: props.screenState,
                mappedValues: props.mappedValues,
              },
            })
          )
        : [];

    return createElement(element.tag, element, children);
  },
};
</script>
