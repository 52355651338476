<script>
export default {
  name: "RenderlessDialog",
  data() {
    return {
      data: null,
      dialog: false,
    };
  },
  methods: {
    openDialog(data) {
      this.data = data;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    input(newValue) {
      this.dialog = newValue;
    },
  },
  render(h) {
    const vNodes = [
      this.$scopedSlots.default({
        openDialog: this.openDialog,
      }),
    ];
    if (this.dialog) {
      const dialogVNode = h(
        "portal",
        { props: { to: "modals" } },
        this.$scopedSlots.dialog({
          data: this.data,
          listeners: { input: this.input },
          closeDialog: this.closeDialog,
        })
      );
      vNodes.push([dialogVNode]);
    }
    return h("span", vNodes);
  },
};
</script>
