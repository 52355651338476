<template>
  <transition-group name="slide-up" appear>
    <notification-snackbar
      v-for="(notification, index) in getVisibleNotifications"
      :key="notification.index"
      :index="index"
      :style="`top: ${index * 70 + 8}px`"
      :notification="notification"
    />
  </transition-group>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationSnackbar from "@components/notification/NotificationSnackbar";

export default {
  name: "NotificationQueue",
  components: {
    NotificationSnackbar,
  },
  computed: {
    ...mapGetters("errorService", ["getVisibleNotifications"]),
  },
};
</script>

<style lang="scss">
.slide-up-enter {
  transform: translateY(70px);
  opacity: 0;
}

.slide-up-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active,
.slide-up-move {
  transition: all 0.5s ease;
}
</style>
