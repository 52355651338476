export const SET_GATEWAYS = "SET_GATEWAYS";
export const SET_GATEWAY_STATES = "SET_GATEWAY_STATES";
export const SET_DEVICE_AVAILABLE = "SET_DEVICE_AVAILABLE";
export const SET_SCREENGROUPS = "SET_SCREENGROUPS";
export const SET_SIDE_NAV = "SET_SIDE_NAV";
export const SET_SELECTED_DEVICE = "SET_SELECTED_DEVICE";
export const SET_SELECTED_GATEWAY = "SET_SELECTED_GATEWAY";
export const SET_GATEWAY_STATUS = "SET_GATEWAY_STATUS";
export const SET_DEVICE_TIMEOUT_STATUS = "SET_DEVICE_TIMEOUT_STATUS";
export const SET_DEVICE_LAST_SEEN = "SET_DEVICE_LAST_SEEN";
export const SET_CURRENT_ALARM_DEVICES = "SET_CURRENT_ALARM_DEVICES";
export const ALARMS_LOADED = "ALARMS_LOADED";
export const SET_STREAM_STATUS = "SET_STREAM_STATUS";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_TEXT = "SET_LOADING_TEXT";
export const SET_GATEWAY_ALARM_FILTER = "SET_GATEWAY_ALARM_FILTER";
