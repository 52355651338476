<template>
  <v-container>
    <v-row no-gutters>
      <v-spacer />
      <v-col cols="7">
        <v-stepper
          v-model="currentStep"
          :style="styleObject"
          elevation="2"
          flat
        >
          <v-stepper-header>
            <v-stepper-step :complete="currentStep > 1" step="1">
              <slot name="h1">
                {{ $t("setup.headers.welcome") }}
              </slot>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step :complete="currentStep > 2" step="2">
              <slot name="h2">
                {{ $t("setup.headers.addGateway") }}
              </slot>
            </v-stepper-step>

            <v-divider />

            <v-stepper-step :complete="currentStep > 3" step="3">
              <slot name="h3">
                {{ $t("setup.headers.connectYourDevices") }}
              </slot>
            </v-stepper-step>

            <v-divider v-if="!hideInitial" />

            <v-stepper-step v-if="!hideInitial" step="4">
              {{ $t("setup.headers.verifyYourDevices") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <slot name="1" />
            </v-stepper-content>

            <v-stepper-content step="2">
              <slot name="2" />
            </v-stepper-content>

            <v-stepper-content step="3">
              <slot name="3" />
            </v-stepper-content>

            <v-stepper-content step="4">
              <slot name="4" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row v-if="!hideInitial">
      <v-spacer />
      <LogoStienenBe shadow style="margin-top: 50px" />
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import LogoStienenBe from "@components/base/LogoStienenBe.vue";

export default {
  name: "SetupLayoutDesktop",
  components: {
    LogoStienenBe,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    hideInitial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentStep: {
      get() {
        return this.step;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    styleObject() {
      return {
        "border-top": `solid ${this.$vuetify.theme.currentTheme.primaryDark} 6px`,
      };
    },
  },
};
</script>

<style scoped></style>
