<template>
  <div>
    <v-card outlined>
      <v-container fluid>
        <v-card-title>
          {{ $t("setup.step3.gatewayCreated", { gatewayName: gateway.name }) }}
        </v-card-title>

        <v-row>
          <v-col class="text-center">
            <v-card-subtitle
              :style="{ color: $vuetify.theme.currentTheme.primary }"
            >
              <v-chip label> {{ gateway.web485Key }}</v-chip>
            </v-card-subtitle>
          </v-col>
        </v-row>

        <v-alert>
          {{ $t("setup.step3.explanation") }}
        </v-alert>
      </v-container>
    </v-card>

    <button-holder v-if="showButtons">
      <template #btn2>
        <base-button @click="$emit('next')">
          {{ $t("common.next") }}
        </base-button>
      </template>
    </button-holder>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonHolder from "@components/admin/setup/layout/ButtonHolder";

export default {
  name: "Web485Key",
  components: { ButtonHolder },
  props: {
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getGateways"]),
    ...mapGetters("subscription", ["getCreatedGateway"]),
    gateway() {
      return this.getCreatedGateway || this.getGateways[0] || {};
    },
  },
};
</script>

<style scoped></style>
