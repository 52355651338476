<template>
  <v-tooltip :open-delay="500" bottom color="transparent">
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot />
      </span>
    </template>

    <TooltipEvents v-if="showEvents" :device="device" :varName="varName" />
    <TooltipChart v-if="showChart" :device="device" :varName="varName" />
  </v-tooltip>
</template>

<script>
import TooltipEvents from "@modules/rtcpu/components/tooltip/TooltipEvents";
import TooltipChart from "@modules/rtcpu/components/tooltip/TooltipChart";
import { mapGetters } from "vuex";

export default {
  name: "TooltipContainer",
  components: { TooltipChart, TooltipEvents },
  props: {
    varName: {
      type: String,
      required: false,
      default: "varName",
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("settings", ["getFeatureToggles"]),
    showEvents() {
      return !this.isReadOnly && this.getFeatureToggles.tooltipEvents;
    },
    showChart() {
      return this.isReadOnly && this.getFeatureToggles.tooltipChart;
    },
  },
};
</script>

<style scoped></style>
