import {Encoding as enc} from './Encoding';
import {isDef} from '@utils/helpers';

export const Data = {
  T_VOID: 0,
  T_BOOL: 1,
  T_STRING: 2,
  T_DATETIME: 3,
  T_TIMESPAN: 4,
  T_FLOAT: 5,
  T_DOUBLE: 6,
  T_UI8: 7,
  T_I8: 8,
  T_UI16: 9,
  T_I16: 10,
  T_UI32: 11,
  T_I32: 12,
  T_UI64: 13,
  T_I64: 14,
  T_INPUT: 15,
  T_OUTPUT: 16,
  T_TIMESPANREL: 17,
  T_TIMESPAN_UI32: 18,
  T_STRING_UNICODE: 19,
  T_HEX16: 20,
  T_HEX32: 21,
  compose: function (value, type, step) {
    switch (type) {
      case this.T_VOID:
        return null;
      case this.T_BOOL:
        return value ? 'true' : 'false';
      case this.T_STRING:
        return value;
      case this.T_DATETIME:
        // return value;
        return this.composeDateTime(value);
      case this.T_TIMESPAN:
        return this.composeTimeSpan(value, step);
      case this.T_TIMESPAN_UI32:
        return this.composeTimeSpan32(value, step);
      case this.T_TIMESPANREL:
        return this.composeTimeSpanRelative(value, step);
      case this.T_STRING_UNICODE:
        return value;
      case this.T_HEX16:
        return value;
      case this.T_HEX32:
        return value;
      default:
        return this.composeNumber(value, step);
    }
  },
  parse: function (str, type, step) {
    switch (type) {
      case this.T_VOID:
        return null;
      case this.T_BOOL:
        return str !== 'false';
      case this.T_STRING:
        return str;
      case this.T_DATETIME:
        return this.parseDateTime(str, step);
      case this.T_TIMESPAN:
        return this.parseTimeSpan(str, step);
      case this.T_TIMESPAN_UI32:
        return this.parseTimeSpan32(str, step);
      case this.T_TIMESPANREL:
        return this.parseTimeSpanRelative(str, step);
      case this.T_STRING_UNICODE:
        return str;
      default:
        return this.parseNumber(str, step);
    }
  },
  writeType: function (type, mul, div, length, value) {
    var stream = {Data: [], Offset: 0};

    this.write(stream, type, mul, div, length, value);
    return stream.Data;
  },
  read: function (stream, type, mul, div, length) {
    switch (type) {
      case this.T_VOID:
        break;
      case this.T_BOOL:
        return this.readBool(stream);
      case this.T_STRING:
        return this.readString(stream, length);
      case this.T_STRING_UNICODE:
        return this.readStringUnicode(stream, length);
      case this.T_DATETIME:
        return this.readDateTime(stream);
      case this.T_TIMESPAN:
        return this.readTimeSpan(stream) * mul / div;
      case this.T_TIMESPAN_UI32:
        return this.readTimeSpan32(stream) * mul / div;
      case this.T_TIMESPANREL:
        return this.readTimeSpanRelative(stream) * mul / div;
      //case this.T_FLOAT:	return this.readFloat(stream)*mul/div;
      //case this.T_DOUBLE:	return this.readDouble(stream)*mul/div;
      case this.T_UI8:
        return this.readByte(stream, false) * mul / div;
      case this.T_I8:
        return this.readByte(stream, true) * mul / div;
      case this.T_UI16:
        return this.readShort(stream, false) * mul / div;
      case this.T_I16:
        return this.readShort(stream, true) * mul / div;
      case this.T_UI32:
        return this.readInt(stream, false) * mul / div;
      case this.T_I32:
        return this.readInt(stream, true) * mul / div;
      case this.T_UI64:
        return this.readLong(stream, false) * mul / div;
      case this.T_I64:
        return this.readLong(stream, true) * mul / div;
      case this.T_INPUT:
        return this.readShort(stream, false);
      case this.T_OUTPUT:
        return this.readShort(stream, false);
      case this.T_HEX16:
        return this.readHex16(stream, length);
      case this.T_HEX32:
        return this.readHex32(stream, length);
      default:
        return 0;
    }
  },
  write: function (stream, type, mul, div, length, value) {
    var val;
    switch (type) {
      case this.T_VOID:
        return this;
      case this.T_BOOL:
        this.writeBool(stream, value);
        break;
      case this.T_STRING:
        this.writeString(stream, length, value);
        break;
      case this.T_STRING_UNICODE:
        this.writeStringUnicode(stream, length, value);
        break;
      case this.T_DATETIME:
        this.writeDateTime(stream, value);
        break;
      case this.T_TIMESPAN:
        this.writeTimeSpan(stream, value * div / mul);
        break;
      case this.T_TIMESPAN_UI32:
        this.writeTimeSpan32(stream, value * div / mul);
        break;
      case this.T_TIMESPANREL:
        this.writeTimeSpanRelative(stream, value * div / mul);
        break;
      //case this.T_FLOAT:	this.writeFloat(stream, value*div/mul); break;
      //case this.T_DOUBLE:	this.writeDouble(stream, value*div/mul); break;
      // Check max values
      case this.T_UI8: {
        val = Math.round(value * div / mul);
        if (val > 255) {
          val = 255;
        }
        this.writeByte(stream, val);
        break;
      }
      case this.T_I8: {
        val = Math.round(value * div / mul);
        if (val > 127) {
          val = 127;
        }
        else if (val < -128) {
          val = -128;
        }
        this.writeByte(stream, val);
        break;
      }

      case this.T_UI16: {
        val = Math.round(value * div / mul);
        if (val > 65535) {
          val = 65535;
        }
        this.writeShort(stream, val);
        break;
      }
      case this.T_I16: {
        val = Math.round(value * div / mul);
        if (val > 32767) {
          val = 32767;
        }
        else if (val < -32768) {
          val = -32768;
        }
        this.writeShort(stream, val);
        break;
      }

      case this.T_UI32: {
        val = Math.round(value * div / mul);
        if (val > 4294967295) {
          val = 4294967295;
        }
        this.writeInt(stream, val);
        break;
      }

      case this.T_I32: {
        val = Math.round(value * div / mul);
        if (val > 2147483647) {
          val = 2147483647;
        }
        else if (val < -2147483648) {
          val = -2147483648;
        }
        this.writeInt(stream, val);
        break;
      }

      case this.T_UI64: {
        val = Math.round(value * div / mul);
        if (val > 18446744073709551615) {
          val = 18446744073709551615;
        }
        this.writeLong(stream, val);
        break;
      }
      case this.T_I64: {
        val = Math.round(value * div / mul);
        if (val > 9223372036854775807) {
          val = 9223372036854775807;
        }
        else if (val < -9223372036854775808) {
          val = -9223372036854775808;
        }
        this.writeLong(stream, val);
        break;
      }

      case this.T_INPUT:
      case this.T_OUTPUT:
        this.writeShort(stream, value);
        break;
      case this.T_HEX16:
        break;
      case this.T_HEX32:
        break;
    }
  },
  readNumber: function (stream, count) {
    var value = 0;

    while (--count >= 0) {
      value <<= 8;
      value |= stream.Data[stream.Offset];
      ++stream.Offset;
    }
    // value = value >>> 0;
    return value;
  },
  writeNumber: function (stream, value, count) {
    while (--count >= 0) {
      ++stream.Offset;
      stream.Data.push(value >> (count * 8) & 0xff);
    }
  },
  readByte: function (stream, signed) {
    var value = this.readNumber(stream, 1);

    if (signed && value >= 0x7f) return (value - 0xff) - 1;
    return value;
  },
  readShort: function (stream, signed) {
    var value = this.readNumber(stream, 2);

    if (signed && value >= 0x7fff) return (value - 0xffff) - 1;
    return value;
  },
  readInt: function (stream, signed) {
    var value = this.readNumber(stream, 4);

    if (signed && value >= 0x7fffffff) return (value - 0xffffffff) - 1;
    return value;
  },
  readLong: function (stream, signed) {
    var value = this.readNumber(stream, 8);

    if (signed && value >= 0x7fffffffffffffff) return (value - 0xffffffffffffffff) - 1;
    return value;
  },
  readHex16: function(stream, length) {
    let value = "", data, count = length / 2;
    while (--count >= 0) {
      data = this.readNumber(stream, 2).toString(16);
      if (!data) break;
      value = this.leadingZero(data.toUpperCase(), 4);
    }
    return value;
  },
  readHex32: function(stream, length) {
    let value = "", data, count = length / 2;
    while (--count >= 0) {
      data = this.readNumber(stream, 2).toString(16);
      if (!data) break;
      value += this.leadingZero(data.toUpperCase(), 4);
    }
    return value;
  },
  leadingZero: function (value, places) {
    let zero = places - value.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") +value;
  },
  writeByte: function (stream, value) {
    this.writeNumber(stream, value, 1);
  },
  writeShort: function (stream, value) {
    this.writeNumber(stream, value, 2);
  },
  writeInt: function (stream, value) {
    this.writeNumber(stream, value, 4);
  },
  writeLong: function (stream, value) {
    this.writeNumber(stream, value, 8);
  },
  readBool: function (stream) {

    var value = stream.Data[stream.Offset];

    ++stream.Offset;
    return value;
  },
  writeBool: function (stream, value) {
    ++stream.Offset;
    stream.Data.push(value & 0xff);
  },
  readString: function (stream, length) {
    var value = '', data, count = length;

    while (--count >= 0) {
      data = stream.Data[stream.Offset];
      ++stream.Offset;
      if (!data) break;
      value += enc.decodeByte(data);
    }
    stream.Offset += count;
    return value.trim();
  },
  readStringUnicode: function (stream, length) {
    var value = '',
      data,
      count = length / 2;

    while (--count >= 0) {
      data = this.readNumber(stream, 2);
      if (!data) break;
      value += enc.decodeUnicodeChar(data);
    }

    return value.trim();
  },
  writeString: function (stream, var_length, value) {
    var count = 0, length = length = (var_length - 1);
    var str = value.padEnd(length);
    for (; count < length; ++count, ++stream.Offset) {
      stream.Data[stream.Offset] = enc.encodeChar(str.charCodeAt(count));
    }
    this.writeNumber(stream, 0, 1);
  },
  writeStringUnicode: function (stream, var_length, value) {
    var count = 0, length = ((var_length / 2) - 1);
    var str = value.padEnd(length);
    for (; count < length; ++count) {
      this.writeNumber(stream, enc.encodeUnicodeChar(str.charCodeAt(count)), 2);
    }
    this.writeNumber(stream, 0, 2);
  },
  readDateTime: function (stream) {
    return new Date((this.EPOCH_20010101 + this.readShort(stream)) * this.MSEC_DAY);
  },
  writeDateTime: function (stream, value) {
    this.writeShort(stream, Math.round((value / this.MSEC_DAY) - this.EPOCH_20010101));
  },
  composeDateTime: function (value) {
    const year = value.toLocaleDateString(enc.language, {year: 'numeric'});
    const month = value.toLocaleDateString(enc.language, {month: 'numeric'});
    const day = value.toLocaleDateString(enc.language, {day: 'numeric'});
    return `${year}/${month}/${day}`;
  }, // value.toISOString().substr(0, 10);  },
  parseDateTime: function (str) {
    return new Date(str);
  },
  readTimeSpan: function (stream) {
    return this.readShort(stream);
  },
  writeTimeSpan: function (stream, value) {
    this.writeShort(stream, value);
  },
  composeTimeSpan: function (value, step) {
    var prefix = function (n, d) {
        return ('0000' + n).slice(-d);
      },
      fff = value % 1000,
      ss = Math.floor(value /= 1000) % 60,
      mm = Math.floor(value /= 60) % 60,
      hh = Math.floor(value / 60),
      str = '';

    if (hh < 10) str += '0';
    str += hh;
    if (step < 3600) {
      str += ':' + prefix(mm, 2);
      if (step < 60) {
        str += ':' + prefix(ss, 2);
        if (step < 1) {
          switch (step) {
            case 0.1:
              str += '.' + prefix(fff, 1);
              break;
            case 0.01:
              str += '.' + prefix(fff, 2);
              break;
            default:
              str += '.' + prefix(fff, 3);
              break;
          }
        }
      }
    }
    return str;
  },
  parseTimeSpan: function (str, step) {
    var idxp = str.lastIndexOf('.'),
      idx2 = str.lastIndexOf(':'),
      idx1 = str.indexOf(':'),
      hh = 0, mm = 0, ss = 0, fff = 0, res;

    if (step < 0.001) step = 0.001;
    if (idxp >= 0) {
      if (step < 1) {
        str += '00';
        switch (step) {
          case 0.1:
            fff = +str.substr(idxp + 1, 1) * 100;
            break;
          case 0.01:
            fff = +str.substr(idxp + 1, 2) * 10;
            break;
          case 0.001:
            fff = +str.substr(idxp + 1, 3);
            break;
        }
      }
      str = str.slice(0, idxp);
    }
    if (idx1 < 0 && idx2 < 0) {
      //single number
      if (step < 60) {
        ss = +str;
      } //seconds
      else {
        if (step < 3600) {
          mm = +str;
        } // minutes
        else {
          hh = +str;
        } // hours
      }
    }
    else {
      //34:56 2 digits
      if (idx1 === idx2) {
        if (step < 60) { // seconds:minutes
          mm = +str.slice(0, idx1);
          ss = +str.slice(idx1 + 1);
        }
        else { // hours:minutes
          hh = +str.slice(0, idx1);
          mm = +str.slice(idx1 + 1);
        }
      }
      else {
        //12:34:56 hours:minutes:seconds
        hh = +str.slice(0, idx1);
        mm = +str.slice(idx1 + 1, idx2);
        ss = +str.slice(idx2 + 1);
      }
    }
    res = (((hh * 60 + mm) * 60 + ss) * 1000 + fff);
    return res - res % (step * 1000);
  },
  readTimeSpan32: function (stream) {
    return this.readInt(stream);
  },
  writeTimeSpan32: function (stream, value) {
    this.writeInt(stream, value);
  },
  composeTimeSpan32: function (value, step) {
    return this.composeTimeSpan(value, step);
  },
  parseTimeSpan32: function (str, step) {
    return this.parseTimeSpan(str, step);
  },
  readTimeSpanRelative: function (stream) {
    return this.readShort(stream, true);
  },
  writeTimeSpanRelative: function (stream, value) {
    this.writeShort(stream, value);
  },
  composeTimeSpanRelative: function (value, step) {
    var result;

    // Check if the value is negative
    if (value < 0) {
      result = "-" + this.composeTimeSpan(-value, step);
    }
    else {
      result = "+" + this.composeTimeSpan(value, step);
    }

    return result;
  },
  parseTimeSpanRelative: function (str, step) {
    var result;

    // Check if a negative number is used
    if (str[0] == "-") {
      result = this.parseTimeSpan(str.substring(1), step);
      result = -result;
    }
    else {
      result = this.parseTimeSpan(str, step);
    }

    return result;
  },
  composeNumber: function (value, step) {
    if (isDef(value) && step > 0 && step <= 1) {
      return value.toFixed(-Math.round(Math.log10(step)));
    }
    else {
      return value;
    }
  },
  parseNumber: function (str) {
    return +str;
  },
  EPOCH_20010101: 11323, // 2001-01-01 - 1970-01-01
  MSEC_DAY: 86400000 // 24 * 60 * 60 * 1000
};
