<template>
  <highcharts :options="meterOptions" :callback="meterRenderCallback" />
</template>

<script>
import { Chart } from "highcharts-vue";
import baseChartOptions from "@modules/lccpu/components/widgets/baseChartOptions";
import WidgetBase from "@modules/lccpu/components/widgets/WidgetBase";
import LcWidgetValue from "@modules/lccpu/components/widgets/LcWidgetValue";

export default {
  name: "WidgetMeter",
  props: {
    icon: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    actualMeasurement: {
      type: LcWidgetValue,
      required: true,
    },
    isOk: {
      type: [Boolean, Number],
      required: true,
    },
    isMeasurementVisible: {
      type: [Boolean, Number],
      required: false,
      default: true,
    },
    width: {
      type: Number,
      default: 132,
    },
    height: {
      type: Number,
      default: 216,
    },
  },
  mixins: [WidgetBase],
  computed: {
    gaugeFillColor() {
      return this.isOk ? this.colors.primary : this.colors.error;
    },
    meterOptions() {
      return {
        ...baseChartOptions,
        chart: {
          type: "solidgauge",
          ...this.calculateGaugeMetrics(),
          backgroundColor: "transparent",
        },
        pane: {
          background: [
            {
              backgroundColor: this.colors.darkGray,
              innerRadius: "85%", //default 0
              outerRadius: "100%", //default 105%
              borderWidth: 0,
            },
            {
              backgroundColor: this.colors.background,
              innerRadius: "0", //default 0
              outerRadius: "85%", //default 105%
              borderWidth: 0,
            },
          ],
        },
        yAxis: {
          min: this.min,
          max: this.max,
          minorTickInterval: null,
          lineWidth: 0,
          tickWidth: 0,
          labels: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        colors: [this.gaugeFillColor],
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            innerRadius: "85%", //default 60
            name: "value",
            data: [this.actualMeasurement.raw],
          },
        ],
      };
    },
  },
  methods: {
    calculateGaugeMetrics() {
      //educated guess
      const marginX = this.width / 9;
      return {
        width: this.width,
        height: this.height,
        spacing: [0, 0, 0, 0],
        margin: [-this.height / 1.7, marginX, 0, marginX],
      };
    },
    meterRenderCallback(chart) {
      try {
        this.initialize(chart);
        //educated guess
        this.charWidth = this.chartMetrics.width / 15;
        this.renderCenterIcon();
        if (this.isMeasurementVisible) {
          this.renderActualMeasurementText();
        }
        this.renderLightGrayBackground();
        this.renderBottomDarkGrayLine();
      } catch (err) {
        console.error(err);
      }
    },
    renderCenterIcon() {
      //position (in center x, slightly above center y)
      const iconWidth = this.chartMetrics.width * 0.42;
      const iconHeight = this.chartMetrics.width * 0.28;
      const iconX = this.chartMetrics.centerX - iconWidth * 0.5;
      const iconY = this.chartMetrics.height * 0.05;

      this.renderIconS3(this.icon, iconX, iconY, iconWidth, iconHeight, {
        z: 0,
      });
    },
    renderActualMeasurementText() {
      this.renderTextCentered(
        this.actualMeasurement.formatted,
        this.chartMetrics.centerX,
        this.chartMetrics.width * 0.4
      );
    },
    renderLightGrayBackground() {
      //216 / 45 = 4.8 // calculated from spec

      const backgroundY = this.chartMetrics.height / 4.8;
      this.renderRectangle(
        this.chartMetrics.x,
        backgroundY,
        this.chartMetrics.width,
        this.chartMetrics.height,
        {
          z: -2,
          color: this.colors.lightGray,
        }
      );
    },
    renderBottomDarkGrayLine() {
      const lineY = this.chartMetrics.height * 0.8;
      const lineStartX = this.chartMetrics.x + this.chartMetrics.width / 16.5;
      const lineEndX =
        this.chartMetrics.x +
        this.chartMetrics.width -
        this.chartMetrics.width / 16.5;
      const path = ["M", lineStartX, lineY, "L", lineEndX, lineY];
      const strokeWidth = this.chartMetrics.height / 150;
      this.renderLine(path, {
        strokeWidth: strokeWidth,
        color: this.colors.darkGray,
      });
    },
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.renderCallbacks.push(this.meterRenderCallback);
  },
};
</script>

<style scoped></style>
