export const SET_SELECTED_DISPOSITION = "SET_SELECTED_DISPOSITION";
export const SET_SCREEN_DEFINITION = "SET_SCREEN_DEFINITION";

export const REFRESH_TREE = "REFRESH_TREE";
export const REFRESH_SCREEN = "REFRESH_SCREEN";

export const SET_VISIBLE_NODE_KEYS = "SET_VISIBLE_NODE_KEYS";
export const SET_LAST_24_HOUR_ENABLED = "SET_LAST_24_HOUR_ENABLED";
export const SET_LOADING_ACTUAL = "SET_LOADING_ACTUAL";
export const INCREMENT_LOADING_CURRENT = "INCREMENT_LOADING_CURRENT";
export const RESET_LOADING = "RESET_LOADING";
