<template>
  <div class="nav-drawer-divider" />
</template>

<script>
export default {
  name: "NavDrawerDivider.vue",
};
</script>

<style lang="scss" scoped>
.nav-drawer-divider {
  height: 20px;
  background-color: var(--v-black-base);
  border-top: 1px solid var(--v-grey70-base);
  border-bottom: 1px solid var(--v-grey70-base);
}
</style>
