<template>
  <v-card outlined width="420" class="pt-1">
    <span class="ms-2 card-title">
      {{ $t("common.lastEvents") }}
      <span class="card-subtitle">&emsp;&emsp;( {{ varName }} )</span>
    </span>
    <v-data-table
      :headers="headers"
      :items="eventData"
      hide-default-footer
      :items-per-page="maxEventCount"
      dense
      :loading="loading"
    />
  </v-card>
</template>

<script>
import { GetLastEventsOfVar } from "@api/config-service";
import { DateTimeConverter } from "@stienen/DateTimeConverter";
import { Data } from "@stienen/Data";
import { mapGetters } from "vuex";
import { adminService } from "@api/admin-service";

export default {
  name: "TooltipEvents",
  props: {
    varName: {
      type: String,
      required: false,
      default: "varName",
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eventData: [],
      headers: [
        { text: this.$t("common.date"), sortable: false, value: "date" },
        { text: this.$t("common.user"), sortable: false, value: "user" },
        { text: this.$t("common.remote"), sortable: false, value: "remote" },
        { text: this.$t("common.value"), sortable: false, value: "value" },
      ],
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters(["getSelectedGateway"]),
    ...mapGetters("settings", ["getFeatureToggles"]),
    ...mapGetters("deviceCache", ["getRef"]),
    maxEventCount() {
      return this.getFeatureToggles.tooltipNumberOfEvents;
    },
  },
  methods: {
    async loadEventData() {
      try {
        const data = await GetLastEventsOfVar(
          this.device.id,
          this.varName,
          this.maxEventCount
        );
        this.eventData = await this.processEventData(data);
      } catch (error) {
        this.error = error;
      }
    },
    async processEventData(data) {
      const userNames = {};
      for (const e of data.filter((e) => e.User > 60000)) {
        if (userNames[e.User]) continue;
        userNames[e.User] = await adminService.fetchUserEmailById16(
          e.User - 60000
        );
      }
      return data.map((item) => {
        let value = (item.NewValue * item.Mul) / item.Div;
        const date = DateTimeConverter.parseDateLocalTime(item.Timestamp);
        const dateTime =
          DateTimeConverter.dateToString(date) +
          " " +
          DateTimeConverter.timeToString(date);
        const ref = this.getRef(this.device, this.varName);

        //special datetime case
        //it arrives as a number from EventHistory
        //this is not the best solution
        if (ref.Type === 3) {
          const stream = { Data: [], Offset: 0 };
          Data.writeShort(stream, value);
          stream.Offset = 0;
          value = Data.readDateTime(stream);
        }
        const formatted = Data.compose(value, ref.Type, ref.Step);

        return {
          date: dateTime,
          user:
            item.User < 60000 || item.User >= 65000
              ? item.User
              : this.getSelectedGateway.created < dateTime
              ? userNames[item.User]
              : "FarmConnect 2",
          remote: item.Remote ? this.$t("common.yes") : this.$t("common.no"),
          value: formatted,
        };
      });
    },
  },
  async created() {
    this.loading = true;
    await this.loadEventData();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.card-title {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}

.card-subtitle {
  font-size: 11px;
  color: #666666;
}

.v-data-table::v-deep th {
  font-size: 10px !important;
}
.v-data-table::v-deep td {
  font-size: 10px !important;
}
</style>
