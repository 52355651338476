<template>
  <v-row class="pa-2">
    <v-card outlined class="lc-screen-container">
      <!-- Navigation background -->
      <div v-if="numberOfNavButtons > 0" :style="navBackGround" />

      <!-- Navigation end marker -->
      <div v-if="showNavEnd" class="lc-screen-nav-end" :style="navEnd" />

      <!-- Navigation horizontal line -->
      <div
        v-if="numberOfNavButtons > 1"
        class="lc-screen-line-hor line-hor-pos-1"
      />
      <div
        v-if="numberOfNavButtons > 4"
        class="lc-screen-line-hor line-hor-pos-2"
      />
      <div
        v-if="numberOfNavButtons > 7"
        class="lc-screen-line-hor line-hor-pos-3"
      />
      <div
        v-if="numberOfNavButtons > 10"
        class="lc-screen-line-hor line-hor-pos-4"
      />

      <div
        v-if="numberOfNavButtons > 2"
        class="lc-screen-line-hor line-hor-pos-5"
      />
      <div
        v-if="numberOfNavButtons > 5"
        class="lc-screen-line-hor line-hor-pos-6"
      />
      <div
        v-if="numberOfNavButtons > 8"
        class="lc-screen-line-hor line-hor-pos-7"
      />
      <div
        v-if="numberOfNavButtons > 11"
        class="lc-screen-line-hor line-hor-pos-8"
      />

      <!-- Navigation vertical line-->
      <div
        v-if="numberOfNavButtons > 3"
        class="lc-screen-line-vert line-vert-pos-1"
      />
      <div
        v-if="numberOfNavButtons > 4"
        class="lc-screen-line-vert line-vert-pos-2"
      />
      <div
        v-if="numberOfNavButtons > 5"
        class="lc-screen-line-vert line-vert-pos-3"
      />

      <div
        v-if="numberOfNavButtons > 6"
        class="lc-screen-line-vert line-vert-pos-4"
      />
      <div
        v-if="numberOfNavButtons > 7"
        class="lc-screen-line-vert line-vert-pos-5"
      />
      <div
        v-if="numberOfNavButtons > 8"
        class="lc-screen-line-vert line-vert-pos-6"
      />

      <div
        v-if="numberOfNavButtons > 9"
        class="lc-screen-line-vert line-vert-pos-7"
      />
      <div
        v-if="numberOfNavButtons > 10"
        class="lc-screen-line-vert line-vert-pos-8"
      />
      <div
        v-if="numberOfNavButtons > 11"
        class="lc-screen-line-vert line-vert-pos-9"
      />
      <!--     Screen elements -->

      <div
        v-for="(item, index) in numberOfNavButtons"
        :key="index"
        :class="navButtonPosClass(index)"
      >
        <slot name="navigation" v-bind="{ item, index }" />
      </div>

      <div :style="contentArea" @click="$emit('clicked')">
        <slot name="content" />
      </div>
    </v-card>

    <!--    <v-card outlined class="ms-2" v-if="getHistoryVars.length">-->
    <!--      <rt-last24-hour :history-vars="getHistoryVars"/>-->
    <!--    </v-card>-->
  </v-row>
</template>

<script>
// import RtLast24Hour from '@modules/rtcpu/components/RtLast24Hour';
// import {mapGetters} from 'vuex';

export default {
  name: "LcScreenDesktop",
  // components: {RtLast24Hour},
  props: {
    numberOfNavButtons: {
      type: Number,
      required: true,
    },
  },
  methods: {
    navButtonPosClass(i) {
      return {
        [`nav-button-pos-${i}`]: true,
      };
    },
  },
  computed: {
    // ...mapGetters('deviceCache', [
    //   'getHistoryVars'
    // ]),
    nrOfNavButtonColumns() {
      return Math.ceil(this.numberOfNavButtons / 3);
    },
    columnNr() {
      return this.nrOfNavButtonColumns * 2 + 1;
    },
    showNavEnd() {
      return this.numberOfNavButtons > 0 && this.numberOfNavButtons < 12;
    },
    navEnd() {
      return {
        gridColumn: this.columnNr,
        gridRowStart: 1,
        gridRowEnd: -1,
        zIndex: 2,
      };
    },
    navBackGround() {
      return {
        gridColumnStart: 1,
        gridColumnEnd: this.columnNr,
        gridRowStart: 1,
        gridRowEnd: 8,
        "background-color": "#007c60",
      };
    },
    contentArea() {
      return {
        gridColumnStart: Math.max(1, this.columnNr),
        gridColumnEnd: 10,
        gridRowStart: 1,
        gridRowEnd: 8,
        marginLeft: this.showNavEnd ? "6px" : "0px",
      };
    },
  },
};
</script>

<style scoped>
.lc-screen-container {
  display: grid;
  grid-template-columns: 7px 104px 17px 104px 17px 104px 17px 104px 6px;
  grid-template-rows: 2px 86px 5px 86px 5px 86px 2px;
  width: 480px;
  height: 272px;
  background-color: #f8f8f8;
}

/* Navigation end */
.lc-screen-nav-end {
  background: #f8f8f8;
  width: 6px;
  border-right: 4px solid #007c60;
}

/* Navigation horizontal line */
.lc-screen-line-hor {
  width: 80px;
  height: 1px;
  margin: 2px 12px 2px 12px;
  background-color: #006c50;
}

.line-hor-pos-1 {
  grid-column: 2;
  grid-row: 3;
}

.line-hor-pos-2 {
  grid-column: 4;
  grid-row: 3;
}

.line-hor-pos-3 {
  grid-column: 6;
  grid-row: 3;
}

.line-hor-pos-4 {
  grid-column: 8;
  grid-row: 3;
}

.line-hor-pos-5 {
  grid-column: 2;
  grid-row: 5;
}

.line-hor-pos-6 {
  grid-column: 4;
  grid-row: 5;
}

.line-hor-pos-7 {
  grid-column: 6;
  grid-row: 5;
}

.line-hor-pos-8 {
  grid-column: 8;
  grid-row: 5;
}

/* Navigation vertical line */
.lc-screen-line-vert {
  width: 1px;
  height: 62px;
  margin: 12px 8px 12px 8px;
  background-color: #006c50;
}

.line-vert-pos-1 {
  grid-column: 3;
  grid-row: 2;
}

.line-vert-pos-2 {
  grid-column: 3;
  grid-row: 4;
}

.line-vert-pos-3 {
  grid-column: 3;
  grid-row: 6;
}

.line-vert-pos-4 {
  grid-column: 5;
  grid-row: 2;
}

.line-vert-pos-5 {
  grid-column: 5;
  grid-row: 4;
}

.line-vert-pos-6 {
  grid-column: 5;
  grid-row: 6;
}

.line-vert-pos-7 {
  grid-column: 7;
  grid-row: 2;
}

.line-vert-pos-8 {
  grid-column: 7;
  grid-row: 4;
}

.line-vert-pos-9 {
  grid-column: 7;
  grid-row: 6;
}

/* Navigation buttons */
.nav-button-pos-0 {
  grid-column: 2;
  grid-row: 2;
}

.nav-button-pos-1 {
  grid-column: 2;
  grid-row: 4;
}

.nav-button-pos-2 {
  grid-column: 2;
  grid-row: 6;
}

.nav-button-pos-3 {
  grid-column: 4;
  grid-row: 2;
}

.nav-button-pos-4 {
  grid-column: 4;
  grid-row: 4;
}

.nav-button-pos-5 {
  grid-column: 4;
  grid-row: 6;
}

.nav-button-pos-6 {
  grid-column: 6;
  grid-row: 2;
}

.nav-button-pos-7 {
  grid-column: 6;
  grid-row: 4;
}

.nav-button-pos-8 {
  grid-column: 6;
  grid-row: 6;
}

.nav-button-pos-9 {
  grid-column: 8;
  grid-row: 2;
}

.nav-button-pos-10 {
  grid-column: 8;
  grid-row: 4;
}

.nav-button-pos-11 {
  grid-column: 8;
  grid-row: 6;
}
</style>
