<template>
  <v-card tile elevation="20">
    <v-row style="background-color: white">
      <img src="@assets/brand/LoginImageDesktop.png" alt="Login" />
      <img class="logo" src="@assets/brand/LoginLogo.svg" alt="Logo" />

      <v-col cols="4" class="d-flex justify-start">
        <amplify-authenticator class="px-8 pt-4">
          <amplify-sign-in
            slot="sign-in"
            username-alias="email"
            :form-fields.prop="loginFormFields"
          />
          <amplify-sign-up
            slot="sign-up"
            username-alias="email"
            :form-fields.prop="signUpFormFields"
          />
          <amplify-forgot-password
            slot="forgot-password"
            username-alias="email"
            :form-fields.prop="resetPasswordFormFields"
          />
        </amplify-authenticator>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import authenticatorLabels from "@pages/account/AuthenticatorLabels";

export default {
  name: "AuthenticatorDesktop",
  mixins: [authenticatorLabels],
};
</script>

<style scoped lang="scss">
amplify-authenticator {
  --container-height: 0; /* default 100vh */
  --padding: 0;
  --box-shadow: 0;
  --border-radius: 0;
  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: var(--v-primary-base);
  --amplify-primary-shade: var(--v-primary-base);
  height: 400px;
}

.logo {
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 10px;
}
</style>
