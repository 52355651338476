import { isDef } from "@utils/helpers";

const loggedIn = (state) => !!state.token;

const getUserId = (state) => state.user.sub;

const getUserEmail = (state) => state.user.email;
const getChannel = (state) => state.channel;

const getUserName = (state) => {
  if (isDef(state.user) && isDef(state.user.email)) {
    return state.user.email.split("@", 1)[0] || "";
  }
};

const getIsCustomer = (state, getters) =>
  getters.checkClaim("cognito:groups", "customer");
const isService = (state, getters) =>
  getters.checkClaim("cognito:groups", "service");
const isDealer = (state, getters) =>
  getters.checkClaim("cognito:groups", "dealer");
const isMechanic = (state, getters) =>
  getters.checkClaim("cognito:groups", "mechanic");
const getIsProvidingService = (state, getters) =>
  getters.isService || getters.isMechanic;

const checkClaims = (state, getters) => (prop, claims) =>
  claims.some((claim) => getters.checkClaim(prop, claim));

const checkClaim = (state) => (prop, claim) => {
  if (state.user && state.user.hasOwnProperty(prop)) {
    return Array.isArray(state.user[prop])
      ? state.user[prop].includes(claim)
      : state.user[claim] === true;
  }
  return false;
};

const checkStatus = (state) => (status) => state.userStatus === status;

const getIsAuthorizedLc = (state) => (device, lcComponent) => {
  const mappedRole = state.lcRoles[device.role];
  return lcComponent.authorization.some((requiredRoles) =>
    mappedRole.includes(requiredRoles)
  );
};

const getHasAcceptedLicence = (state) => state.mainAccount?.hasAcceptedLicence;
const getAllowServiceAccess = (state) =>
  state.customerAccount?.allowServiceAccess ?? true;
const getImpersonatedCustomer = (state) => state.impersonatedCustomer;
const getCustomerAccount = (state) => state.customerAccount;
const getId16 = (state) => state.mainAccount.id16;

export default {
  loggedIn,
  getUserId,
  checkStatus,
  getUserEmail,
  getChannel,
  getIsCustomer,
  isService,
  isDealer,
  isMechanic,
  getIsProvidingService,
  getUserName,
  checkClaims,
  checkClaim,
  getIsAuthorizedLc,
  getHasAcceptedLicence,
  getAllowServiceAccess,
  getImpersonatedCustomer,
  getCustomerAccount,
  getId16,
};
