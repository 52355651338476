<template>
  <v-navigation-drawer
    v-model="$store.state.sideNav"
    app
    class="nav-drawer disable-select"
    width="320"
  >
    <!-- User account -->
    <NavDrawerContainer :level="0">
      <NavDrawerHeader
        :impersonated-customer="getImpersonatedCustomer"
        :userName="getUserName"
        icon="mdi-account"
      />
    </NavDrawerContainer>

    <!-- Admin -->
    <NavDrawerContainer v-if="!!adminRoute" :level="1">
      <NavDrawerStaticNode
        :text="$t('navDrawer.admin')"
        :warning="adminRouteChildNodes.some((route) => route.warning)"
      >
        <NavDrawerStaticNode
          v-for="(childRoute, id) in adminRouteChildNodes"
          :key="id"
          :text="$t(`navDrawer.${childRoute.translationKey}`)"
          :warning="childRoute.warning"
          @clicked="navigateToAdminRoute(childRoute)"
        />
      </NavDrawerStaticNode>
    </NavDrawerContainer>

    <NavDrawerDivider />

    <!-- All locations -->
    <NavDrawerContainer :level="1">
      <NavDrawerStaticHeader
        :text="$t('navDrawer.allLocations')"
        icon="mdi-router-wireless-settings"
      />
    </NavDrawerContainer>

    <NavDrawerContainer :level="2">
      <NavDrawerStaticNode
        :notification="totalAlarmCount"
        :text="$t('navDrawer.alarmOverview')"
        @clicked="navigateToAlarm"
      />
      <NavDrawerStaticNode
        v-if="getAllUserScreenGroups.length > 0"
        :text="$t('navDrawer.customScreens')"
      >
        <NavDrawerStaticNode
          v-for="(screenGroup, id) in getAllUserScreenGroups"
          :key="id"
          :indent="1"
          :text="screenGroup.displayName"
          child
        >
          <NavDrawerStaticNode
            v-for="(customScreen, id) in screenGroup.Screens"
            :key="id"
            :indent="1"
            :text="customScreen.Name"
            @clicked="onChangeScreenGroupHandler(customScreen)"
          />
        </NavDrawerStaticNode>
      </NavDrawerStaticNode>
    </NavDrawerContainer>

    <NavDrawerDivider />

    <!-- Location -->
    <NavDrawerContainer :level="1">
      <NavDrawerCombobox
        :disconnected="gatewayDisconnected"
        :items="gatewaysWithState"
        :text="gatewayButtonText"
        icon="mdi-router-wireless-settings"
        @changeSelection="onGatewayChangeHandler"
      />
    </NavDrawerContainer>

    <NavDrawerContainer :level="2">
      <NavDrawerStaticNode
        :notification="alarmCountForGateway(getSelectedGateway)"
        :text="$t('navDrawer.alarmOverview')"
        @clicked="navigateToAlarm(getSelectedGateway)"
      />
      <NavDrawerStaticNode
        :text="$t('navDrawer.overview')"
        @clicked="navigateToGatewayPage"
      />
    </NavDrawerContainer>

    <NavDrawerDivider />

    <!-- Device -->
    <NavDrawerContainer :level="1">
      <NavDrawerCombobox
        :disconnected="deviceDisconnected"
        :items="devicesWithState"
        :text="deviceButtonText"
        icon="mdi-devices"
        @changeSelection="onDeviceChangeHandler"
      />
    </NavDrawerContainer>

    <NavDrawerContainer :level="2">
      <NavDrawerStaticNode
        v-if="selectedDeviceIsLc"
        :text="$t('navDrawer.viewDevice')"
        @clicked="onDeviceChangeHandler(getSelectedDevice)"
      />
      <DeviceTree class="ms-9 pb-2" />
    </NavDrawerContainer>

    <NavDrawerFooter />

    <portal to="modals" v-if="isLoading">
      <v-overlay>
        <base-builder-spinner>
          <div v-if="loadingText" class="pt-4">{{ loadingText }}</div>
        </base-builder-spinner>
      </v-overlay>
    </portal>
  </v-navigation-drawer>
</template>

<script>
import NavDrawerContainer from "@components/navdrawer/NavDrawerContainer";
import NavDrawerHeader from "@components/navdrawer/NavDrawerHeader";
import NavDrawerStaticNode from "@components/navdrawer/NavDrawerStaticNode";
import NavDrawerDivider from "@components/navdrawer/NavDrawerDivider";
import NavDrawerStaticHeader from "@components/navdrawer/NavDrawerStaticHeader";
import NavDrawerCombobox from "@components/navdrawer/NavDrawerCombobox";
import DeviceTree from "@components/navdrawer/DeviceTree";
import NavDrawerFooter from "@components/navdrawer/NavDrawerFooter";
import { mapGetters, mapState } from "vuex";
import { _isLcCpu } from "@state/modules/cache/bll";
import { _isSameVersion } from "@state/modules/rt-remote/bll";

export default {
  name: "NavDrawer",
  components: {
    NavDrawerContainer,
    NavDrawerHeader,
    NavDrawerStaticNode,
    NavDrawerDivider,
    NavDrawerStaticHeader,
    NavDrawerCombobox,
    DeviceTree,
    NavDrawerFooter,
  },
  data() {
    return {
      routerIsOnLcRemote: this.$router.currentRoute.name === "lc-remote",
    };
  },
  computed: {
    ...mapState({
      currentAlarmDevices: (state) => state.currentAlarmDevices,
      gateways: (state) => state.gateways,
      isLoading: (state) => state.loading,
      loadingText: (state) => state.loadingText,
    }),
    ...mapGetters([
      "getDevicesOnSelectedGateway",
      "getGateways",
      "getSelectedDevice",
      "getSelectedGateway",
      "getAllUserScreenGroups",
      "getGatewayStates",
    ]),
    ...mapGetters("auth", [
      "getUserName",
      "checkClaims",
      "getIsProvidingService",
      "getImpersonatedCustomer",
    ]),
    ...mapGetters("layout", ["isDesktop"]),
    ...mapGetters("subscription", ["getIsSubscriptionUpdated"]),
    selectedDeviceIsLc() {
      return _isLcCpu(this.getSelectedDevice);
    },
    gatewaysWithState() {
      return this.gateways.map((gateway) => ({
        ...gateway,
        notification: this.alarmCountForGateway(gateway),
        disconnected: !this.isGatewayConnected(gateway),
      }));
    },
    devicesWithState() {
      return this.getDevicesOnSelectedGateway.map((device) => ({
        ...device,
        locked: !device.isLicenced,
        disconnected: this.isDeviceDisconnected(device),
      }));
    },
    totalAlarmCount() {
      return this.currentAlarmDevices.length;
    },
    gatewayButtonText() {
      return this.getSelectedGateway.name || this.$t("navDrawer.noGateways");
    },
    deviceButtonText() {
      return this.getSelectedDevice.name || this.$t("navDrawer.noDevices");
    },
    gatewayDisconnected() {
      return !this.isGatewayConnected(this.getSelectedGateway);
    },
    deviceDisconnected() {
      return this.isDeviceDisconnected(this.getSelectedDevice);
    },
    adminRoute() {
      const result = this.navItems.filter(
        (item) => item.type === "admin-pages"
      );
      return result.length === 1 ? result[0] : null;
    },
    adminRouteChildNodes() {
      return this.adminRoute
        ? this.adminRoute.children
            .filter((item) => item.type === "admin-page-item")
            .map((item) => {
              if (item.name === "subscription_management") {
                item.warning =
                  !this.getIsSubscriptionUpdated && !!this.getGateways.length;
              }
              if (item.name === "setup_gateway") {
                item.warning = !this.getGateways.length;
              }
              return item;
            })
        : [];
    },
    navItems() {
      return this.$router.options.routes.filter(
        (route) =>
          (route.meta.authRequired && !route.meta.hasOwnProperty("claims")) ||
          (route.meta.authRequired &&
            route.meta.hasOwnProperty("claims") &&
            this.checkClaims("cognito:groups", route.meta.claims))
      );
    },
  },
  methods: {
    isGatewayConnected(gateway) {
      return (
        this.getGatewayStates.find((state) => state.Id === gateway.id)
          ?.State === "Connected"
      );
    },
    // eslint-disable-next-line no-unused-vars
    isDeviceDisconnected(device) {
      //const now = new Date();
      //const prev = DateTimeConverter.parseDate(device.End);
      /*if (prev && now) {
        let diff = Math.ceil(((now.getTime() - prev.getTime()) / (60000)));
        if (diff > 30) {
          return true;
        }
      }*/
      return false;
    },
    alarmCountForGateway(gateway) {
      return this.currentAlarmDevices.filter(
        (alarm) => alarm.Gid === gateway.id
      ).length;
    },
    navigateToAlarm(gateway) {
      this.$store.dispatch("setGatewayAlarmFilter", gateway?.id);
      this.$helpers.pushRoute({ name: "alarm" });
    },
    navigateToGatewayPage() {
      this.$helpers.pushRoute({ name: "gateway_overview" });
    },
    navigateToAdminRoute(route) {
      this.$helpers.pushRoute(route);
    },
    async onGatewayChangeHandler(gateway) {
      await this.navigateToAlarm(gateway);
      await this.$store.dispatch("setGatewayAlarmFilter", gateway?.id);
      this.$store
        .dispatch("edit/leaveEditConfirmed", null, { root: true })
        .then((confirmed) => {
          if (confirmed) {
            // this.$helpers.pushDeviceRoute(this.getSelectedDevice);
            this.$store.dispatch(
              "onGatewayChange",
              { gateway },
              { root: true }
            );
          }
        });
    },
    async onDeviceChangeHandler(device) {
      let screenId = null;

      if (_isLcCpu(device)) {
        if (device === this.getSelectedDevice) {
          screenId = -1;
        }
        if (_isSameVersion(this.getSelectedDevice, device)) {
          if (this.$router.currentRoute.name === "lc-remote") {
            screenId = this.$router.currentRoute.params.screenId;
          } else {
            screenId = -1;
          }
        } else {
          screenId = -1;
        }
      }

      await this.$store.dispatch("onSelectDevice", { device }, { root: true });
      await this.$helpers.pushDeviceRoute(device, screenId);
    },
    onChangeScreenGroupHandler(screenGroup) {
      this.$helpers.pushRoute({ name: "remote" });
      this.$store.dispatch("rtRemote/onScreenSelect", screenGroup);
    },
    setSubscriptionNotification(isUpToDate) {
      const subscriptionRoute = this.adminRouteChildNodes.find(
        (route) => route.name === "subscription_management"
      );
      if (subscriptionRoute) {
        subscriptionRoute.notification = !isUpToDate;
      }
    },
  },
  watch: {
    getIsSubscriptionUpdated(newValue) {
      this.setSubscriptionNotification(newValue);
    },
    "$route.name": {
      handler: function (newVal) {
        this.routerIsOnLcRemote = newVal === "lc-remote";
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.setSubscriptionNotification(this.getIsSubscriptionUpdated);
  },
  mounted() {
    this.$store.dispatch("toggleSideNav", this.isDesktop);
  },
};
</script>

<style lang="scss" scoped>
.nav-drawer {
  color: var(--v-grey30-base);
  border-left: 19px solid var(--v-primary-base);
  background-color: var(--v-black-base);
  border-image: linear-gradient(
      90deg,
      var(--v-primary-base),
      var(--v-black-base)
    )
    0 50%;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
