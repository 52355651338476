<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ subTitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text>
      <slot />
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
export default {
  name: "InfoCardRow",
  props: {
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: "primary",
    },
    title: {
      type: [String, typeof TranslateResult],
      required: false,
    },
    subTitle: {
      type: [String, typeof TranslateResult],
      required: false,
    },
  },
};
</script>

<style scoped></style>
