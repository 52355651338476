<template>
  <v-checkbox
    :input-value="value"
    color="primary"
    v-bind="$attrs"
    @change="changeValue"
    v-on="$listeners"
  >
    <slot />
    <slot slot="label" name="label" />
  </v-checkbox>
</template>

<script>
export default {
  name: "BaseCheckbox",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    changeValue(event) {
      this.$emit("changed", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
