<template>
  <div class="default">
    <tree-node
      v-for="node of tree"
      :key="node.Key"
      :device="getSelectedDevice"
      :node="node"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TreeNode from "@components/navdrawer/TreeNode";

export default {
  name: "DeviceTree",
  components: {
    TreeNode,
  },
  data() {
    return {
      loadTreePromise: null,
      tree: [],
    };
  },
  computed: {
    ...mapGetters(["getSelectedDevice"]),
    ...mapState({
      treeCreatedAt: (state) => state.rtRemote.treeCreatedAt,
    }),
    ...mapGetters("deviceCache", ["getTree"]),
  },
  methods: {
    refresh() {
      const tree = this.getTree(this.getSelectedDevice.id);
      this.tree = tree.builtTree;
    },
  },
  watch: {
    getSelectedDevice() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped />
