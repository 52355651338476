<template>
  <input
    class="bi-input"
    autocomplete="off"
    :type="type"
    :value="value"
    v-on="listeners"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Date],
      default: "",
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit("input", e.target.value, this.value, e),
        mouseenter: (e) => this.$parent.$emit("eventEnter", e),
        mouseleave: (e) => this.$parent.$emit("eventLeave", e),
      };
    },
  },
};
</script>

<style scoped>
.bi-input {
  display: inline-block;
  position: relative;
}
</style>
