<script>
import { isDef, isUndef } from "@utils/helpers";
import { screenElementBasics } from "@mixins/props";

export default {
  // todo: doing expr here is far from efficient, better to create a "Actions" store/module
  // todo i think dirty is not needed
  name: "RtHtmlWrapper",
  mixins: [screenElementBasics],
  props: {
    tag: {
      type: String,
      required: false,
    },
    mappedValues: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dirty: false,
    };
  },
  methods: {
    invert(name) {
      return name.charAt(0) === "-";
    },
    collectResultsForType(actionType, initial = false) {
      let result;
      const actions = this.extraToArray.filter(
        (val) => isDef(val) && val.Action === actionType
      );
      for (let val of actions) {
        // dev can be null on expressions without devices
        // be it combined or non combined expressions
        result = this.mappedValues[val.Dev?.id]?.data[val.Name];
        if (isUndef(result)) {
          break;
        }
        let invert = false;
        this.extra.forEach((name) => {
          if (val.Name === name.slice(1)) {
            invert = true;
          }
        });
        result = invert ? !result : !!result;
        if (result === false) {
          break;
        }
      }
      return isUndef(result) ? initial : result;
    },
  },
  created() {
    this.extraToArray.forEach(
      (val) => val.registerCallback(() => (this.dirty = true)),
      this
    );
  },
  computed: {
    /* eslint-disable vue/no-side-effects-in-computed-properties */
    extraToArray() {
      const list =
        this.loc.Extra && typeof this.loc.Extra === "string"
          ? this.loc.Extra.split(",")
          : [];
      return list.map((name) =>
        this.invert(name) ? this.state[name.slice(1)] : this.state[name]
      );
    },
    warning() {
      this.dirty = false;
      return this.collectResultsForType("warning", false);
    },
    alarm() {
      this.dirty = false;
      return this.collectResultsForType("alarm", false);
    },
    show() {
      this.dirty = false;
      return this.collectResultsForType("show", true);
    },
    hide() {
      this.dirty = false;
      return this.collectResultsForType("hide", false);
    },
    classList() {
      return {
        warning: this.warning,
        alarm: this.alarm,
        hidden: this.hide,
      };
    },
    findTag() {
      const tag = this.tag ? this.tag : this.loc.Value;
      try {
        // test if this is a valid html tag
        document.createElement(tag);
        return tag;
      } catch {
        return "span";
      }
    },
  },
  render(createElement) {
    const data = {
      class: this.classList,
      attrs: this.$attrs,
    };
    return this.show
      ? createElement(this.findTag, data, this.$slots.default)
      : createElement();
  },
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
}

.warning {
  background-color: yellow;
}

.alarm {
  background-color: red;
}
</style>
