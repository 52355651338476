<template>
  <v-data-table
    :items-per-page="-1"
    :mobile-breakpoint="0"
    dense
    hide-default-footer
    sort-by="dateTime"
    sort-desc
    v-bind="$attrs"
  >
    <template #item.dateTime="{ item }">
      <slot :dateTime="item.dateTime" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "RtTable",
};
</script>

<style scoped></style>
