import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export const LayoutTypes = Object.freeze({
  DESKTOP: "DESKTOP",
  PORTRAIT: "PORTRAIT",
  LANDSCAPE: "LANDSCAPE",
});

const state = {
  layoutState: LayoutTypes.DESKTOP,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
