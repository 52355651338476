<template>
  <tooltip-container
    :device="dev"
    :var-name="hoverVarName"
    is-read-only
    v-if="enabled"
    :mul="mul"
    :div="div"
  >
    <svg ref="widget" width="230" height="210" />
  </tooltip-container>
</template>

<script>
/* How to use
    Parameters
    0: DeviceIndex	(Template = 0)
    1: CLK_1_TYPE
    2: CLK_1_VALUE
    3: CLK_1_MIN
    4: CLK_1_MAX
    5: CLK_1_SETPOINT
    6: CLK_1_SETVALUE
    7:
    8:
    9: FormatData   (0, 1)
  */

import { clockConfiguration } from "@modules/rtcpu/components/widgets/clockConfiguration";

import { isDef, isUndef } from "@utils/helpers";
import { mapGetters } from "vuex";
import TooltipContainer from "@modules/rtcpu/components/tooltip/TooltipContainer";

export default {
  name: "DashClock",
  components: { TooltipContainer },
  props: ["values", "device", "fullState"],
  data() {
    return {
      hoverVarName: this.values[2]?.Name,
      title: null,
      snap: null,
      formatData: false,
      isInitialized: false,
      width: 230,
      height: 210,
      centerX: 115,
      centerY: 109,
      radius: 85,
      beginZone: -220,
      endZone: 40,
      alarmZone: 25,
      alarmZoneActive: false,
      mul: 1,
      div: 100,
      decimals: 0,
      widgetData: {
        type: null,
        actual: null,
        min: null,
        max: null,
        show_setpoint: null,
        setpoint: null,
      },
      colors: {
        greenDark: "rgb(1, 74, 58, 1.0)",
        green: "rgb(0, 125, 100, 1.0)",
        red: "rgb(203, 20, 23, 1.0)",
        black: "rgb(0, 0, 0, 1.0)",
        white: "rgb(255, 255, 255, 1.0)",
        grey: "rgb(150, 150, 150)",
        greyLight: "rgb(225, 225, 225, 1.0)",
        greyDark: "rgb(74, 74, 74, 1.0)",
      },
    };
  },
  computed: {
    ...mapGetters("deviceCache", ["getTranslation"]),
    enabled() {
      return this.widgetData.type !== 0;
    },
    dev() {
      if (this.device) return this.device;
      else if (isDef(this.values) && isDef(this.fullState)) {
        return this.fullState.Devices[this.values[0]].Device;
      }
      return null;
    },
  },
  methods: {
    initWidgetData() {
      if (this.values.length === 10) {
        this.formatData = !!parseInt(this.values[9]);
      }

      const varNames = [
        "device",
        "type",
        "actual",
        "min",
        "max",
        "show_setpoint",
        "setpoint",
      ];

      varNames.forEach(function (varName, index) {
        if (index === 0) return;
        if (index < this.values.length) {
          if (!isNaN(this.values[index])) {
            this.updateWidgetData(varName, this.values[index]);
          } else if (this.values[index].hasOwnProperty("subject")) {
            this.values[index].registerCallback((value) =>
              this.updateWidgetData(varName, value)
            );
          }
        }
      }, this);
    },
    createWidget() {
      const target = this.$refs.widget;
      this.snap = this.$snap(target);

      if (!this.snap) {
        throw new Error("no snap");
      }
      try {
        this.isInitialized = true;
        this.draw();

        if (this.enabled) {
          const varName = this.values[2]?.Name;
          this.$store.dispatch("deviceCache/registerHistoryVar", {
            varName,
            label: this.title || varName,
            mul: this.mul,
            div: this.div,
          });
        }
      } catch (err) {
        console.error("Error:", err);
      }
    },
    updateWidgetData(key, newValue) {
      switch (key) {
        case "device":
          break;
        case "type":
          this.widgetData.type = newValue;
          this.setClockTypeData();
          break;
        case "show_setpoint":
          this.widgetData.show_setpoint = !!parseFloat(newValue);
          break;
        default:
          this.widgetData[key] = this.preciseRound(
            (newValue * this.mul) / this.div,
            this.decimals
          );
      }
      this.draw();
    },
    setClockTypeData() {
      if (isUndef(this.dev) || isUndef(this.widgetData.type)) {
        return;
      }

      const config = clockConfiguration[this.widgetData.type];
      this.decimals = config.decimals;
      this.div = config.div;
      this.alarmZoneActive = config.alarmZoneActive;
      this.unit = this.getTranslation({
        dev: this.dev,
        label: config.unitLabel,
      });
      this.title = this.getTranslation({
        dev: this.dev,
        label: config.titleLabel,
      });
      if (config.unitMethod) {
        this.unit = config.unitMethod(this.unit);
      }
    },
    draw() {
      if (this.isInitialized) {
        this.snap.clear();
        this.calculateZones();
        this.drawBackground();
        this.drawZones();
        this.drawMinMaxValue();
        this.drawNeedleAndValue();
        this.drawSetpoint();
        this.drawUnit();
        this.drawTitle();
      }
    },
    calculateZones() {
      const range = this.widgetData.max - this.widgetData.min;
      const rangeDegree =
        Math.abs(this.beginZone) + Math.abs(this.endZone) - 2 * this.alarmZone;
      let degree = 0;

      if (this.widgetData.show_setpoint) {
        let leftRange = this.widgetData.setpoint - this.widgetData.min;
        let rightRange = this.widgetData.max - this.widgetData.setpoint;

        if (leftRange > rightRange) {
          degree = rangeDegree / 2 / leftRange;
          this.angle =
            180 -
            rangeDegree / 2 +
            (this.widgetData.actual - this.widgetData.min) * degree;
        } else {
          degree = rangeDegree / 2 / rightRange;
          this.angle =
            180 -
            degree * leftRange +
            (this.widgetData.actual - this.widgetData.min) * degree;
        }

        this.endGreen = parseInt(-90 + rightRange * degree, 10);
        this.beginGreen = -parseInt(90 + leftRange * degree, 10);
      } else {
        degree = rangeDegree / range;
        this.angle =
          180 -
          (degree * range) / 2 +
          (this.widgetData.actual - this.widgetData.min) * degree;
        this.endGreen = this.endZone - this.alarmZone;
        this.beginGreen = this.beginZone + this.alarmZone;
      }

      this.beginRed = this.beginZone;
      this.endRed = this.endZone;

      if (this.angle <= 50) {
        this.angle = 50;
      }

      if (this.angle >= 310) {
        this.angle = 310;
      }
    },
    preciseRound(num, decimals) {
      let t = Math.pow(10, decimals);
      return (
        Math.round(
          num * t +
            (decimals > 0 ? 1 : 0) *
              (Math.sign(num) * (10 / Math.pow(100, decimals)))
        ) / t
      ).toFixed(decimals);
    },
    drawBackground() {
      this.snap
        .circle(this.centerX, this.centerY, this.radius)
        .attr({ fill: this.colors.greyLight, "stroke-width": 0 });
      this.snap
        .rect(30, 109, 170, 94)
        .attr({ fill: this.colors.greyLight, "stroke-width": 0 });
    },
    drawZones() {
      let colorZone, colorAlarmZone;
      colorZone = this.colors.green;
      colorAlarmZone = this.alarmZoneActive
        ? this.colors.red
        : this.colors.grey;
      this.snap
        .path(
          this.drawArc(
            [this.centerX, this.centerY],
            this.radius - 13,
            this.beginRed,
            this.endRed
          )
        )
        .attr({
          stroke: colorAlarmZone,
          "stroke-width": 15,
          fill: "none",
        });
      this.snap
        .path(
          this.drawArc(
            [this.centerX, this.centerY],
            this.radius - 13,
            this.beginGreen,
            this.endGreen
          )
        )
        .attr({
          stroke: colorZone,
          "stroke-width": 15,
          fill: "none",
        });
    },
    drawArc(center, radius, start, end) {
      let angle = start;
      let coords = this.goToCoords(center, radius, angle);
      let path = "M " + coords[0] + " " + coords[1];
      while (angle <= end) {
        coords = this.goToCoords(center, radius, angle);
        path += " L " + coords[0] + " " + coords[1];
        angle += 1;
      }
      return path;
    },
    drawMinMaxValue() {
      let minCoords = this.goToCoords(
        [this.centerX, this.centerY],
        102,
        this.beginGreen
      );
      let maxCoords = this.goToCoords(
        [this.centerX, this.centerY],
        102,
        this.endGreen
      );

      if (this.widgetData.min !== this.widgetData.setpoint) {
        this.snap
          .text(minCoords[0], minCoords[1], this.widgetData.min)
          .attr({
            fill: this.colors.black,
            "font-size": 11,
            "font-weight": 400,
            "text-anchor": "middle",
          })
          .mouseover(() => {
            this.hoverVarName = this.values[3].Name;
          });
      }

      if (this.widgetData.max !== this.widgetData.setpoint) {
        this.snap
          .text(maxCoords[0], maxCoords[1], this.widgetData.max)
          .attr({
            fill: this.colors.black,
            "font-size": 11,
            "font-weight": 400,
            "text-anchor": "middle",
          })
          .mouseover(() => {
            this.hoverVarName = this.values[4].Name;
          });
      }
    },
    drawNeedleAndValue() {
      let color = this.colors.green;

      if (
        parseFloat(this.widgetData.actual) < parseFloat(this.widgetData.min) ||
        parseFloat(this.widgetData.actual) > parseFloat(this.widgetData.max)
      ) {
        color = this.colors.red;
      }
      this.snap.circle(this.centerX, this.centerY, this.radius - 30).attr({
        fill: this.colors.white,
        "stroke-width": 2,
        stroke: color,
      });

      this.snap
        .text(this.centerX, this.centerY + 5, this.widgetData.actual)
        .attr({
          fill: color,
          "font-size": 34,
          "font-weight": 700,
          "text-anchor": "middle",
        })
        .mouseover(() => {
          this.hoverVarName = this.values[2].Name;
        });

      this.snap
        .path(
          "M " +
            (this.centerX - 5) +
            " " +
            (this.centerY + 54) +
            " L " +
            (this.centerX - 5) +
            " " +
            (this.centerY + (this.radius - 10)) +
            " L " +
            (this.centerX + 5) +
            " " +
            (this.centerY + (this.radius - 10)) +
            " L " +
            (this.centerX + 5) +
            " " +
            (this.centerY + 54)
        )
        .attr({
          fill: this.colors.white,
          "stroke-width": 2,
          stroke: color,
        })
        .attr({
          fill: this.colors.white,
          "stroke-width": 2,
          stroke: color,
          transform: "R" + this.angle + "," + this.centerX + "," + this.centerY,
        });
    },
    drawSetpoint() {
      if (this.widgetData.show_setpoint) {
        this.snap
          .rect(111, 23, 10, 14)
          .attr({ fill: this.colors.greenDark, "stroke-width": 0 });
        this.snap
          .text(115, 12, this.widgetData.setpoint)
          .attr({
            fill: this.colors.black,
            "font-size": 11,
            "font-weight": 400,
            "alignment-baseline": "middle",
            "text-anchor": "middle",
          })
          .mouseover(() => {
            this.hoverVarName = this.values[5].Name;
          });
      }
    },
    drawUnit() {
      this.snap.text(this.centerX, this.centerY + 35, this.unit).attr({
        fill: this.colors.greenDark,
        "font-size": 14,
        "font-weight": 600,
        "alignment-baseline": "middle",
        "text-anchor": "middle",
      });
    },
    drawTitle() {
      this.snap.text(this.centerX, this.centerY + 75, this.title).attr({
        fill: this.colors.greenDark,
        "font-size": 16,
        "font-weight": 600,
        "alignment-baseline": "middle",
        "text-anchor": "middle",
      });
    },
    goToCoords(center, radius, angle) {
      let radians = (angle / 180) * Math.PI;
      let x = center[0] + Math.cos(radians) * radius;
      let y = center[1] + Math.sin(radians) * radius;
      return [x, y];
    },
  },
  created() {
    this.initWidgetData();
  },
  mounted() {
    this.$nextTick(this.createWidget);
  },
  beforeDestroy() {
    if (this.snap) {
      this.snap.clear();
    }
  },
};
</script>

<style scoped></style>
