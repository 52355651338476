import store from "@state/store";
// system pages
import LoginPage from "@pages/account/LoginPage";
// User bundle
import AlarmPage from "@pages/alarm/AlarmPage";
import RtRemotePage from "@pages/remote/RtRemotePage";
import LcRemotePage from "@pages/remote/LcRemotePage";
import GatewayOverviewPage from "@pages/overview/GatewayOverviewPage";
import EventLogPage from "@pages/overview/EventLogPage";
import BackupPage from "@pages/overview/BackupPage";
import SetupNext from "@components/admin/setup/SetupNext";

const ErrorPage = () => import("@pages/error/ErrorPage");
const SettingsPage = () => import("@pages/settings/SettingsPage");
const AdminPage = () => import("@pages/admin/AdminPage");
const InvitationOverview = () =>
  import("@components/admin/invitations/InvitationOverview");
const SubscriptionManagement = () =>
  import("@components/admin/subscription/SubscriptionManagement");
const SetupPage = () => import("@pages/setup/SetupPage");
const ServicePage = () => import("@pages/service/ServicePage");
const DealerPage = () => import("@pages/dealer/DealerPage");
const MechanicPage = () => import("@pages/mechanic/MechanicPage");

//todo constants for routes
//
// const routeNames = {
//     root: '/',
//
// }

export default [
  {
    id: 1,
    path: "/admin",
    name: "admin",
    translationKey: "admin",
    component: AdminPage,
    meta: {
      authRequired: true,
      claims: ["customer", "service"],
    },
    level: 1,
    type: "admin-pages",
    children: [
      {
        path: "users",
        name: "users",
        component: InvitationOverview,
        translationKey: "users",
        props: true,
        meta: {
          authRequired: true,
          claims: ["customer", "service"],
        },
        level: 1,
        type: "admin-page-item",
      },
      {
        path: "subscription/:step?",
        name: "subscription_management",
        component: SubscriptionManagement,
        translationKey: "subscriptions",
        props: (route) => ({
          step: route.query.step,
        }),
        meta: {
          authRequired: true,
          claims: ["customer", "service"],
        },
        level: 1,
        type: "admin-page-item",
      },
      {
        path: "add_gateway/:step?",
        name: "add_gateway",
        component: SetupNext,
        translationKey: "addGateway",
        meta: {
          authRequired: true,
          claims: ["customer", "service"],
        },
        level: 1,
        type: "admin-page-item",
      },
    ],
  },
  {
    id: 2,
    path: "/",
    name: "alarm",
    displayName: "Alarm",
    component: AlarmPage,
    meta: {
      authRequired: true,
      icon: "mdi-alarm",
      role: 800,
    },
    level: 1,
    type: "button",
  },
  {
    id: 3,
    path: "/lc-remote/:deviceId/:screenId",
    name: "lc-remote",
    props: true,
    displayName: "Lc-cpu",
    component: LcRemotePage,
    meta: {
      authRequired: true,
      icon: "mdi-home",
      role: 800,
    },
    beforeEnter(to, from, next) {
      //this is on direct navigate
      //so here I assume there is no route
      to.query.backButtonIcon = null;
      next();
    },
    level: 1,
    type: "route",
  },
  {
    id: 4,
    path: "/remote",
    name: "remote",
    displayName: "Remote",
    component: RtRemotePage,
    meta: {
      authRequired: true,
      icon: "format_list_bulleted",
      role: 800,
    },
    level: 2,
    type: "route",
  },
  {
    id: 6,
    path: "/gateway_overview",
    name: "gateway_overview",
    translationKey: "gatewayOverview",
    component: GatewayOverviewPage,
    meta: {
      authRequired: true,
      icon: "mdi-home",
      role: 800,
    },
    level: 1,
    type: "route",
  },
  {
    id: 7,
    path: "/event_log",
    name: "event_log",
    translationKey: "eventLog",
    props: true,
    component: EventLogPage,
    meta: {
      authRequired: true,
      icon: "mdi-home",
      role: 500,
    },
    level: 1,
    type: "route",
  },
  {
    id: 9,
    path: "/backups",
    name: "backups",
    translationKey: "backups",
    props: true,
    component: BackupPage,
    meta: {
      authRequired: true,
      icon: "mdi-home",
      role: 500,
    },
    level: 1,
    type: "route",
  },
  {
    id: 40,
    path: "/service",
    name: "service",
    component: ServicePage,
    meta: {
      authRequired: true,
      icon: "subscriptions",
      claims: ["service"],
    },
    level: 1,
  },
  {
    id: 50,
    path: "/setup/:step?",
    name: "setup",
    component: SetupPage,
    props: (route) => ({
      step: route.query.step,
    }),
    meta: {
      authRequired: true,
      icon: "subscriptions",
      claims: ["customer"],
    },
    level: 1,
  },
  {
    id: 60,
    path: "/dealer",
    name: "dealer",
    component: DealerPage,
    meta: {
      authRequired: true,
      claims: ["dealer"],
    },
    level: 1,
  },
  {
    id: 61,
    path: "/mechanic",
    name: "mechanic",
    component: MechanicPage,
    meta: {
      authRequired: true,
      claims: ["mechanic"],
    },
    level: 1,
  },
  {
    id: 89,
    path: "/login",
    name: "login",
    displayName: "Login",
    component: LoginPage,
    meta: {
      authRequired: false,
      icon: "",
    },
    level: 999,
    type: "programmatic-route",
  },
  {
    id: 90,
    path: "/logout",
    name: "logout",
    displayName: "Logout",
    meta: {
      authRequired: false,
      icon: "exit_to_app",
    },
    async beforeEnter() {
      await store.dispatch("auth/logout", null, { root: true });
      window.location.href = "/login";
    },
    level: 999,
    type: "programmatic-route",
  },
  {
    id: 91,
    path: "/settings",
    name: "settings",
    displayName: "Settings",
    component: SettingsPage,
    meta: {
      authRequired: true,
      icon: "settings",
      role: 800,
    },
    level: 1,
    type: "programmatic-route",
  },
  {
    id: 94,
    path: "/error",
    name: "error",
    displayName: "Error",
    component: ErrorPage,
    props: true,
    meta: {
      authRequired: false,
      icon: "",
    },
    level: 999,
    type: "programmatic-route",
  },
  {
    // Redirect any unmatched routes to the 404 page.
    id: 95,
    path: "*",
    name: "all",
    displayName: "ALL",
    redirect: () => ({
      name: "error",
      params: {
        message: "404 Page Not Found",
      },
    }),
    meta: {
      authRequired: false,
    },
    level: 999,
    type: "programmatic-route",
  },
];
