import { translateStatic } from "@plugins/helpers";

const TYPES = {
  NOT_YET_SENT: -1,
  PENDING: -2,
  TIMEOUT: -3,

  //spec
  OK: 0,
  INVALID_ARGUMENT: 3,
  ILLEGAL_ARGUMENT: 4,
};

export function _createEventObj({ originalValue, newValue, ref, dev }) {
  return {
    originalValue, // the original value before any changes
    newValue, // the latest value of the target
    ref,
    dev,
    status: TYPES.NOT_YET_SENT,
    errorText: null,
    errorHint: null,
  };
}

export function _createErrorObj(status, ref) {
  let error = {
    text: "",
    hint: "",
  };
  if (status === TYPES.TIMEOUT) {
    error.text = translateStatic("editMode.errorTimeOutText");
    error.hint = translateStatic("editMode.errorTimeOutHint");
  }
  if (status === TYPES.INVALID_ARGUMENT) {
    error.text = translateStatic("editMode.errorInvalidArgumentText");
    error.hint = translateStatic("editMode.errorInvalidArgumentHint");
  }
  if (status === TYPES.ILLEGAL_ARGUMENT) {
    // check ref for ranges
    error.text = translateStatic("editMode.errorIllegalArgumentText");
    error.hint = translateStatic("editMode.errorIllegalArgumentHint", {
      min: ref.Min,
      max: ref.Max,
    });
  }
  return error;
}
