<template>
  <base-card
    v-if="getIsProvidingService"
    error
    :title="$t('common.noAccess')"
  />
  <component
    v-else
    :is="layoutComponent"
    v-model="step"
    :step="step"
    disabled
    hide-initial
  >
    <template #h1>
      {{ $t("setup.headers.addGateway") }}
    </template>

    <template #h2>
      {{ $t("setup.headers.connectYourDevices") }}
    </template>

    <template #h3>
      {{ $t("setup.headers.verifyYourDevices") }}
    </template>

    <template #1>
      <add-gateway-card
        :show-buttons="step === addGatewayStep"
        hide-back
        @next="step = web485KeyStep"
      />
    </template>

    <template #2>
      <web485-key
        :show-buttons="step === web485KeyStep"
        @next="goToDeviceScan"
      />
    </template>

    <template #3>
      <device-scan-initial
        :show-buttons="step === deviceScanStep"
        @back="step = web485KeyStep"
        @complete="complete"
      />
    </template>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import AddGatewayCard from "@components/admin/setup/AddGatewayCard";
import SetupLayoutDesktop from "@components/admin/setup/layout/SetupLayoutDesktop";
import SetupLayoutPortrait from "@components/admin/setup/layout/SetupLayoutPortrait";
import AddGatewayQR from "@components/admin/setup/AddGatewayQR";
import Web485Key from "@components/admin/setup/Web485Key";
import DeviceScanInitial from "@components/admin/setup/DeviceScanInitial";

export default {
  name: "SetupNext",
  components: {
    DeviceScanInitial,
    Web485Key,
    AddGatewayQR,
    SetupLayoutDesktop,
    SetupLayoutPortrait,
    AddGatewayCard,
  },
  data() {
    return {
      step: 1,
      addGatewayStep: 1,
      web485KeyStep: 2,
      deviceScanStep: 3,
      refreshTimerId: null,
      refreshInterval: 10 * 1000,
    };
  },
  computed: {
    ...mapGetters("layout", ["isPortrait"]),
    ...mapGetters("auth", ["getIsProvidingService"]),
    layoutComponent() {
      if (this.isPortrait) {
        return SetupLayoutPortrait;
      }
      return SetupLayoutDesktop;
    },
  },
  methods: {
    async goToDeviceScan() {
      this.step = this.deviceScanStep;
      await this.refresh();
      this.refreshTimerId ??= setInterval(this.refresh, this.refreshInterval);
    },
    async refresh() {
      await this.$store.dispatch("subscription/initSupportedDevices", true);
    },
    async complete(devices) {
      await this.$store.dispatch("subscription/updateSubscription", devices);
      window.location.href = "/admin/subscription";
    },
  },
};
</script>

<style lang="scss" scoped></style>
