<template>
  <gateway-validation
    v-if="mode === 'new' || getMigratingGateway !== null"
    :disabled="disabled"
    :show-buttons="showButtons"
    @back="mode = 'none'"
    @next="$emit('next')"
  />
  <add-gateway-migration
    v-else-if="mode === 'migrate'"
    :disabled="disabled"
    :show-buttons="showButtons"
    @back="mode = 'none'"
  />
  <choose-type
    v-else
    :disabled="disabled"
    :show-buttons="showButtons"
    :hide-back="hideBack"
    @onChoice="changeMode"
    @back="onBack"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ChooseType from "@components/admin/setup/ChooseType";
import GatewayValidation from "@components/admin/setup/GatewayValidation";
import AddGatewayMigration from "@components/admin/setup/AddGatewayMigration";

export default {
  name: "AddGatewayCard",
  components: {
    AddGatewayMigration,
    GatewayValidation,
    ChooseType,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mode: "none",
    };
  },
  computed: {
    ...mapGetters("subscription", ["getMigratingGateway"]),
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
    },
    onBack() {
      this.$emit("back");
      this.mode = "none";
    },
  },
};
</script>

<style scoped></style>
