<template>
  <base-renderless-dialog>
    <template #default="{ openDialog }">
      <base-button :disabled="disabled" icon @click.stop="openDialog(device)">
        <v-icon>mdi-delete</v-icon>
      </base-button>
    </template>

    <template #dialog="{ data, listeners }">
      <base-confirm-dialog
        :accept-function="deleteDevice"
        :item="data"
        v-on="listeners"
      >
        <template #title>
          {{ $t("devices.deleteDevice") }}
        </template>
        <template #subTitle="{ item }">
          <p>
            {{ $t("devices.deleteDeviceExplanation") }}
          </p>
          <p class="font-weight-bold">
            {{ $t("common.noUndone") }}
          </p>
          <p>{{ item.name }} ({{ item.hardware }}, {{ item.version }})</p>
        </template>
      </base-confirm-dialog>
    </template>
  </base-renderless-dialog>
</template>

<script>
import { DeleteDevice } from "@api/config-service";

export default {
  name: "DeleteDeviceDialog",
  props: {
    device: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async deleteDevice() {
      await DeleteDevice(this.device);
    },
  },
};
</script>

<style scoped></style>
