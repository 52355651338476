<template>
  <base-card>
    <v-toolbar v-if="optionHeaderVisible" flat width="100%">
      <v-col class="pt-10">
        <StienenDatePicker v-model="dates" :rangeoffset="initialRangeOffset" />
      </v-col>

      <v-spacer />

      <v-tooltip v-if="optionSwitchableGraph" bottom>
        <template #activator="{ on }">
          <base-button icon @click="isTable = !isTable" v-on="on">
            <v-icon>
              {{ isTable ? "mdi-chart-line" : "mdi-table-large" }}
            </v-icon>
          </base-button>
        </template>
        {{
          isTable ? $t("history.goToChartView") : $t("history.goToTableView")
        }}
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on }">
          <base-button icon @click="onExport" v-on="on">
            <v-icon>mdi-export</v-icon>
          </base-button>
        </template>
        {{ $t("history.exportTooltip") }}
      </v-tooltip>
    </v-toolbar>

    <rt-chart
      v-if="!isTable"
      :column-infos="columns"
      :end="startDateEndDate.actualEndDate"
      :loading="loading"
      :start="startDateEndDate.startDate"
      :table-info="table"
      :values="data"
    />
    <rt-table
      v-else
      :column-infos="columns"
      :headers="headers"
      :items="computedValues"
      :loading="loading"
    >
      <template v-slot="{ dateTime }">
        {{ formatDateTime(dateTime) }}
      </template>
    </rt-table>
  </base-card>
</template>

<script>
import { screenElementBasics } from "@mixins/props";
import { combineExprResults } from "@mixins/combineExprResults";
import StienenDatePicker from "@components/StienenDatePicker";
import RtChart from "@modules/rtcpu/components/widgets/RtChart";
import RtTable from "@modules/rtcpu/components/widgets/RtTable";
import { isDef } from "@utils/helpers";
import { createCsv } from "@stienen/Export";
import { mapGetters } from "vuex";

export default {
  name: "RtHistoryDiagram",
  mixins: [screenElementBasics, combineExprResults],
  props: ["fullState"], // -> screenState
  components: { RtTable, RtChart, StienenDatePicker },
  data() {
    return {
      dates: [],
      isTable: false,
      loading: false,
      data: [],
      table: {},
      columns: [],
    };
  },
  computed: {
    ...mapGetters(["getSelectedGateway", "getSelectedDevice"]),
    startDateEndDate() {
      const [start, end] = this.dates;
      const actualEndDate = new Date(end);
      const endDate = new Date(
        actualEndDate.setDate(actualEndDate.getDate() + 1)
      );
      const startDate = new Date(start);
      return { startDate, endDate, actualEndDate };
    },
    optionSwitchableGraph() {
      return (this.displayType & 0x00010) === 0;
    },
    optionHeaderVisible() {
      return (this.displayType & 0x00100) === 0;
    },
    displayType() {
      let displayType = 0;
      try {
        if (this.loc && this.loc.hasOwnProperty("Extra")) {
          const extraData = JSON.parse(this.loc.Extra);
          if (extraData.hasOwnProperty("DisplayType")) {
            displayType = extraData.DisplayType;
          }
        }
      } catch (ex) {
        /* no action */
      }
      return displayType;
    },
    initialRangeOffset() {
      return this.fullState.Tables[this.loc.Value].RangeOffset;
    },
    headers() {
      const headers = [
        { value: "dateTime", text: this.$t("common.dateTime"), divider: true },
      ];
      for (const column of this.columns.filter((c) => c.isVisible)) {
        headers.push({
          value: column.Order.toString(),
          text: column.Translated || column.Name,
          align: "center",
          divider: true,
        });
      }
      return headers;
    },
    isShortDateTime() {
      return this.table.Interval >= 0 && this.table.Interval < 5;
    },
    computedValues() {
      const result = [];
      for (let i = 0; i < this.data.length; i++) {
        const column = this.columns[i];
        if (!column.isVisible) {
          continue;
        }
        const values = this.data[i];
        for (let j = 0; j < values.length; j++) {
          const value = values[j];
          result[j] ??= {};
          result[j].dateTime = value.dateTime;
          result[j][column.Order.toString()] = value.composedValue;
        }
      }
      return result;
    },
  },
  methods: {
    async initHist() {
      this.data = [];
      this.table = {};
      this.columns = [];

      const { data, table, columns } = await this.$store.dispatch(
        "rtRemote/initChart",
        {
          ...this.startDateEndDate,
          fullState: this.fullState,
          table: this.fullState.Tables[this.loc.Value],
          collectResultsForType: this.collectResultsForType,
        }
      );
      this.data = data;
      this.table = table;
      this.columns = columns;
    },
    formatDateTime(dateTime) {
      return this.isShortDateTime
        ? dateTime.toLocaleDateString()
        : dateTime.toLocaleString();
    },
    onExport() {
      if (!(isDef(this.data) && this.data.length > 0)) {
        return;
      }
      const delimiter = ";";
      const endLine = "\r\n";
      const rows = this.computedValues.map(
        (value) =>
          this.formatDateTime(value.dateTime) +
          delimiter +
          Object.entries(value)
            .filter((v) => v[0] !== "dateTime")
            .map((v) => v[1])
            .join(delimiter)
      );
      rows.unshift(this.headers.map((h) => h.text).join(delimiter));
      createCsv(
        rows.join(endLine),
        this.TableInfo?.Translated,
        this.getSelectedDevice.hasOwnProperty("name")
          ? this.getSelectedDevice.name
          : "",
        this.getSelectedGateway.hasOwnProperty("name")
          ? this.getSelectedGateway.name
          : ""
      );
    },
  },
  watch: {
    async startDateEndDate() {
      this.loading = true;
      await this.initHist();
      this.loading = false;
    },
  },
  mounted() {
    this.isTable = this.displayType === 0;
  },
};
</script>

<style></style>
