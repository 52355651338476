import { Auth } from "aws-amplify";

async function getIdToken() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.getSignInUserSession().getIdToken().getJwtToken();
  } catch (err) {
    throw new Error("getIdToken failed: " + err);
  }
}

export async function getAuthHeader() {
  return `Bearer ${await getIdToken()}`;
}