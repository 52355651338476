<template>
  <div>
    <img
      v-if="shadow"
      src="@assets/brand/LogoStienenBeShadow.svg"
      :width="width"
    />
    <img v-else src="@assets/brand/LogoStienenBe.svg" :width="width" />
  </div>
</template>

<script>
export default {
  name: "LogoStienenBe",
  props: {
    width: {
      type: Number,
      required: false,
      default: 200,
    },
    shadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
