<template>
  <div>
    <fieldset>
      <legend>
        {{ $t("error.error") }}
      </legend>
      <slot>
        {{ $t("error.unknown") }}
      </slot>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "RtErrorCard",
};
</script>

<style scoped></style>
