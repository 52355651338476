export default {
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
  },
  yAxis: {
    title: false,
    labels: {
      enabled: false,
    },
  },
  chart: {
    spacing: [0, 0, 0, 0],
  },
};
