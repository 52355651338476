<template>
  <div class="fill-height">
    <v-navigation-drawer
      mini-variant
      absolute
      permanent
      style="background-color: #f8f8f8"
      class="elevation-4"
      floating
      disable-resize-watcher
      touchless
    >
      <v-col class="d-flex justify-center">
        <NavBarMenuIcon />
      </v-col>
      <v-col class="d-flex justify-center">
        <img
          src="@assets/brand/LogoFarmConnectVert.svg"
          height="150px"
          alt="logo FarmConnect"
        />
      </v-col>
    </v-navigation-drawer>
    <NavDrawer />

    <v-main class="fill-height">
      <v-container fluid class="ps-14 py-0">
        <slot />
      </v-container>
    </v-main>

    <NavFooter />
  </div>
</template>

<script>
import NavBarMenuIcon from "@/components/navbar/NavBarMenuIcon";
import NavDrawer from "@/components/navdrawer/NavDrawer";
import NavFooter from "@components/navfooter/NavFooter";

export default {
  name: "LayoutDefaultLandscape",
  components: {
    NavBarMenuIcon,
    NavDrawer,
    NavFooter,
  },
  methods: {
    toggle() {
      this.$store.dispatch("toggleSideNav", !this.$store.state.sideNav);
    },
  },
};
</script>

<style scoped></style>
