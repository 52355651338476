import { Data } from "@stienen/Data";
import { SetValues } from "@api/config-service";
import * as types from "./mutation-types";
import { notifyUserTranslation } from "@error/helpers";

async function onInput({ commit, dispatch, getters }, event) {
  try {
    if (!getters.editModeActive) {
      commit(types.ON_FSM_TRANSITION, "edit");
    }

    commit(types.ON_INPUT, event);
  } catch (err) {
    console.error(err);
    await notifyUserTranslation("error.editError", "error");
    dispatch("discard");
  }
}

async function transition({ commit }, payload) {
  try {
    commit(types.ON_FSM_TRANSITION, payload);
  } catch (err) {
    console.error(err);
  }
}

function confirm({ commit, dispatch }, payload) {
  try {
    commit(types.CONFIRM, payload);
  } catch (err) {
    console.error(err);
    dispatch("discard");
  }
}

async function onConfirmChange({ state, commit, dispatch }, event) {
  commit(types.CONFIRM_ACTIVE, "EDIT");
  await state.confirmPromise.catch(() => dispatch("dropEvent", event));
}

function discard({ state, commit }) {
  try {
    commit(types.ON_FSM_TRANSITION, "discard");
    commit(types.CLEAR_ALL);
    state.devices.splice(0);
  } catch (err) {
    console.error(err);
  }
}

function dropEvent({ commit, dispatch }, event) {
  const devId = event.dev.id;
  const refName = event.ref.Name;
  commit(types.ON_DISCARD_SINGLE, { devId, refName });
  dispatch("checkLeaveEditMode");
}

function checkLeaveEditMode({ state, commit }) {
  // leave edit mode if user has deleted all events from the preview screen
  if (Object.keys(state.events).length === 0 && !state.fsm.is("pending")) {
    commit(types.ON_FSM_TRANSITION, "discard");
  }
}

async function send({ state, commit, dispatch, rootGetters }) {
  try {
    await dispatch("prepareSend");
    const getRef = rootGetters["deviceCache/getRef"];
    Object.keys(state.events).forEach((did) => {
      const data = state.events[did];
      let dps = [],
        gid;
      const names = Object.keys(data);
      for (let varName of names) {
        const event = data[varName];
        if (event.status !== -2) {
          gid = event.dev.gatewayId || event.dev.Gid; //Gid needs to be removed after new api's
          const ref = getRef(event.dev, varName);
          if (ref) {
            const value = Data.parse(event.newValue, ref.Type, ref.Step);
            dps.push({
              Index: ref.Index,
              Data: Data.writeType(
                ref.Type,
                ref.Mul,
                ref.Div,
                ref.Length,
                value
              ),
            });

            // set status to pending
            commit(types.SET_EVENT_STATUS, { did, varName, status: -2 });
          }
        }
      }
      if (dps.length > 0) {
        const id16 = rootGetters["auth/getId16"];
        SetValues(gid, did, dps, id16);
      }
      const timeoutAmount =
        rootGetters["settings/getEditModeConfig"].sendTimeout;
      const timer = setTimeout(
        (dispatch, did) => dispatch("handleTimeout", did),
        timeoutAmount,
        dispatch,
        did
      );
      state.timers.push({ did, timer });
    });
  } catch (err) {
    console.error(err);
    commit(types.ON_FSM_REJECT, {
      color: "error",
      translationKey: "editDataError",
    });
    dispatch("discard");
  }
}

async function prepareSend({ state, commit, getters, dispatch }) {
  //check for values that need to be confirmed
  for (const deviceId of Object.keys(state.events)) {
    const data = state.events[deviceId];

    const names = Object.keys(data);

    for (let varName of names) {
      const event = data[varName];
      if (event.ref.AcknowledgeChange) {
        await dispatch("onConfirmChange", event);
        break;
      }
    }
  }
  commit(types.ON_FSM_TRANSITION, "send");
  if (!getters.sendToMultipleLocked && state.devices.length > 1) {
    await dispatch("duplicateEvents");
    state.devices.splice(0);
  }
}

async function duplicateEvents({ state, commit, rootGetters }) {
  const source = rootGetters.getSelectedDevice;
  for (let target of state.devices) {
    commit(types.DUPLICATE_CHANGE_EVENTS, { source, target });
  }
}

function handleTimeout({ state, dispatch, commit }, did) {
  dispatch("setLoading", { value: false }, { root: true });

  commit(types.ON_FSM_TIMEOUT, {
    color: "warning",
    translationKey: "editDataTimeoutError",
  });
  commit(types.CLEAR_TIMER, did);

  for (let varName of Object.keys(state.events[did])) {
    commit(types.SET_EVENT_STATUS, { did, varName, status: -3 }); // set status to timeout
  }
}

function setDataResponseHandler({ state, commit, dispatch, rootGetters }, msg) {
  let debug = {};
  try {
    commit(types.CLEAR_TIMER, msg.Did);
    const getRefName = rootGetters["deviceCache/getRefName"];
    const getDevice = rootGetters["deviceCache/getDeviceMeta"];
    const device = getDevice(msg.Did);
    debug = { device, msg };
    msg.Data.forEach((obj) => {
      const varName = getRefName(device, obj.Index);
      if (obj.Status === 0) {
        dispatch("dropEvent", { dev: device, ref: { Name: varName } });
      } else {
        commit(types.SET_EVENT_STATUS, {
          did: msg.Did,
          varName,
          status: obj.Status,
        });
      }
    });

    const names = rootGetters["deviceCache/getRefreshVars"](device.id);
    dispatch("deviceCache/scheduleOnce", { device, names }, { root: true });
    if (state.timers.length === 0) {
      dispatch("transitionPending");
    }
  } catch (err) {
    console.warn(debug);
    console.error(err);
    commit(types.ON_FSM_REJECT, {
      color: "error",
      translationKey: "editDataError",
    });
    dispatch("discard");
  } finally {
    dispatch("setLoading", { value: false }, { root: true });
  }
}

function transitionPending({ state, commit }) {
  // todo: set status per device and report only for failed
  let setDataSuccess = true;
  Object.keys(state.events).forEach((did) => {
    const data = state.events[did];
    if (data) {
      for (let varName of Object.keys(data)) {
        const event = state.events[did][varName];
        if (event.status !== 0) {
          setDataSuccess = false;
          return;
        }
      }
    }
  });
  if (setDataSuccess) {
    commit(types.ON_FSM_TRANSITION, "accept");
    commit(types.CLEAR_ALL);
  } else {
    commit(types.ON_FSM_REJECT, {
      color: "error",
      translationKey: "editDataRejectError",
    });
  }
}

async function leaveEditConfirmed({ state, commit, getters }) {
  if (!getters.editModeActive) {
    return true;
  }
  commit(types.CONFIRM_ACTIVE, "LEAVE");
  return await state.confirmPromise.then(() => true).catch(() => false);
}

export default {
  onInput,
  confirm,
  discard,
  send,
  prepareSend,
  setDataResponseHandler,
  transitionPending,
  handleTimeout,
  dropEvent,
  checkLeaveEditMode,
  duplicateEvents,
  leaveEditConfirmed,
  onConfirmChange,
  transition,
};
