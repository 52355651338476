<template>
  <v-dialog v-model="show" persistent max-width="350">
    <v-card>
      <v-card-title>
        {{ $t("common.confirm") }}
      </v-card-title>
      <v-card-text>
        <p v-if="isPending">
          {{ $t("editMode.pending") }}
        </p>
        <p v-else>
          {{ $t("editMode.discardQuestion") }}
        </p>
      </v-card-text>
      <v-card-actions>
        <base-button text outlined @click.native="edit">
          {{ $t("common.no") }}
        </base-button>
        <v-spacer />
        <base-button @click.native="discard">
          {{ $t("common.yes") }}
        </base-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ConfirmLeaveDialog",
  props: ["show"],
  methods: {
    edit() {
      this.$store.dispatch("edit/confirm", "reject", { root: true });
    },
    discard() {
      this.$store.dispatch("edit/discard", null, { root: true });
      this.$store.dispatch("edit/confirm", "resolve", { root: true });
    },
  },
  computed: {
    ...mapState("edit", {
      isPending: (state) => state.fsm.is("pending"),
    }),
  },
};
</script>

<style scoped></style>
