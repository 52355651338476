import { apiClient } from "@api/base-client";

const endPoint = process.env.VUE_APP_ROOT_API + "Designer.svc";
const designerClient = apiClient(endPoint, (response) => response.data);

export async function GetScreen(id) {
  return await designerClient.get("/Screen", {
    params: {
      id: id,
      dids: [].join(","),
    },
  });
}
