import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export const subscriptionStatus = Object.freeze({
  NO: "no_subscription",
  ACTIVE: "active",
  TRIAL: "trial",
});

const state = {
  migratingGateway: null,
  createdGateway: null,
  hardwareProducts: [],
  subscriptionStatus: subscriptionStatus.NO,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
